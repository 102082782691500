
import AccessControl from "common/components/AccessControl/AccessControl";
import Button from "common/components/Button/Button";
import React from 'react';
import styled from "styled-components";

const RightBtn = styled.div`
    float: right;
    padding-left: 10px;
`;

export default class ButtonAccessControl extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
    }

    toggleModal = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    render() {

      return (
        <AccessControl
          docStatus={this.props.docStatus}
          userPermissions={this.props.userPermissions}
          docType={this.props.docType}
          submodule={this.props.submodule}
          button={this.props.id || this.props.button}
        >
          <RightBtn>
            <Button themeColor={this.props.themeColor} onClick={this.toggleModal}>{this.props.button}</Button>
          </RightBtn>
          {this.props.modal &&
                React.createElement(this.props.modal,
                    {isOpen: this.state.isOpen, close: this.toggleModal, ...this.props.modalProps }
                )}
        </AccessControl>
      )
    }
  }
