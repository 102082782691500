import CDLButton from '@cdl/button';
import Grid from '@material-ui/core/Grid';
import Table from "common/components/Table/Table";
import TableCheckbox from "common/components/TableCheckbox/TableCheckbox";
import MasterDocService from "common/services/masterDoc.service";
import { isAC } from 'common/utils/AppUtil';
import $ from "jquery";
import React from 'react';
import { connect } from 'react-redux';

const edsFieldMap = {
  bankaddr1: "bankAddr1",
  bankaddr2: "bankAddr2",
  bankaddr3: "bankAddr3",
  bankbranch: "bankBranch",
  bankcbidcode: "bankCBIDCode",
  bankcity: "bankCity",
  bankcountry: "bankCountry",
  bankname: "bankName",
  bankpostcode: "bankPostCode",
  bankref: "bankRef",
  bankstate: "bankState",
  banktradedept: "bankTradeDept"
}

const edsFieldMapForDrawee = {
  bankaddr1: "BankAddr1",
  bankaddr2: "BankAddr2",
  bankaddr3: "BankAddr3",
  bankbranch: "BankBranch",
  bankcbidcode: "bankCBIDCode",
  bankcity: "BankCity",
  bankcountry: "BankCountry",
  bankname: "BankName",
  bankpostcode: "BankPostCode",
  bankref: "BankRef",
  bankstate: "BankState",
  banktradedept: "BankTradeDept"
}

class BankTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            tableID: `bankTable${this.props.id}`,
        };
    }

    static getDerivedStateFromProps (props) {
      if (!props.masterDoc) {
        return {
          tableData: []
        }
      }

      return null;
  }

    getList = () => {
        const data = [];
        const banklistService = isAC() ? MasterDocService.getMasterDocBankList2(this.props.masterDoc.CoDocExpressId) : MasterDocService.getMasterDocBankList();
        banklistService.then(res2 => {
          if (res2 && res2.data.list) {
            res2.data.list.forEach(element =>{
              data.push({ 'accountNumber': element[0], 'accountTitle': element[1], 'bankCode': element[2] });
            });
            data.forEach(d => {
              d.checkbox = <TableCheckbox onChange={(event) => this.onCheck(event, d)} value={d.bankCode} />
            })
            this.setState({tableData: data});
          }
        });
      }

    onCheck = (event, item) => {
        const idStr = `#${this.state.tableID}`;
        $(idStr).find('input[type=checkbox]').prop('checked', false);
        $(idStr).find('.row-selected').removeClass('row-selected');

        const checkbox = $(event.target);
        checkbox.prop('checked', true);
        const row = checkbox.parent().parent().parent();
        row.addClass('row-selected');
        const bankCode = checkbox.val();


        item.isChecked = !item.isChecked;
        this.setState(prevState => {
          prevState.tableData.forEach(d => {
            d.isChecked = false;
            d.checkbox = null;
          })
          item.isChecked = !item.isChecked;

          return {
            preChecked: event.target,
            tableData: prevState.tableData,
            isRefresh: !prevState.isRefresh
          }
        }, () => {
          this.setState(prevState =>{
            prevState.tableData.forEach(d => {
              d.checkbox = <TableCheckbox defaultChecked={d.isChecked} onChange={(e) => this.onCheck(e, d)} value={d.bankCode} />;
            })
            return {
              tableData: prevState.tableData,
              isRefresh: !prevState.isRefresh
            }
          })
        })

        MasterDocService.getMasterDocBankDetail(bankCode).then(res => {
          const data = [];
          Object.keys(res).forEach(key => {

            let keyStr = '';

            if (this.props.isEDS && this.props.fieldNamePrefix) {
              keyStr = edsFieldMapForDrawee[key.toLowerCase()];
            } else if (this.props.isEDS) {
              keyStr = edsFieldMap[key.toLowerCase()];
            } else {
              keyStr = key.toLowerCase();
            }

            let fieldName = "";
            if(this.props.updateFields){
                fieldName = this.props.updateFields[keyStr];
            }else if(this.props.fieldNamePrefix){
                fieldName = this.props.fieldNamePrefix + keyStr;
            }else{
                fieldName = keyStr;
            }
            const val = res[key];

            if(fieldName && fieldName !== ""){
                data.push({field: fieldName, data: val});
            }
          });

          this.props.updateMasterDocByFieldArray({fieldArray : data});
          if(this.props.updateStatus){
            this.props.updateStatus();
          }
          $(`${idStr} input[type='checkbox'][value='${bankCode}']`).prop('checked', true);
        });
      }


    sortChange = () => {
      this.setState(prevState => {
        prevState.tableData.forEach(d => {
          d.checkbox = null;
        });
        return {
          tableData: prevState.tableData,
          isRefresh: !prevState.isRefresh
        }
      }, () => {
        this.setState(prev => {
          prev.tableData = prev.tableData.map(d => {
            d.checkbox = <TableCheckbox defaultChecked={d.isChecked} onChange={(event) => this.onCheck(event, d)} value={d.bankCode} />;
            return d;
          })
          return {
            tableData: prev.tableData,
            isRefresh: !prev.isRefresh
          }
        })
      })
    }

    render() {

        return (
          <div id={this.props.id}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <div className="rightBtn">
                  <CDLButton size="small" onClick={this.getList}>Get list</CDLButton>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={0} style={{ paddingRight: '20px' }}>
              <Grid item xs={12}>
                <Table
                  noShowNotFound
                  maxRows={6}
                  showPagination={false}
                  defaultPageSizeOption={{ 'value': 0 }}
                  id={this.state.tableID}
                  data={this.state.tableData}
                  sortedChange={this.sortChange}
                  isRefresh={this.state.isRefresh}
                  columns={[
                                {
                                    Header: '',
                                    accessor: 'checkbox',
                                    width: 50,
                                    sortable: false
                                },
                                {
                                    Header: 'City',
                                    accessor: 'accountTitle'
                                },
                                {
                                    Header: 'Bank name',
                                    accessor: 'accountNumber'
                                }
                            ]}
                />
              </Grid>
            </Grid>
          </div>
        )
    }
}

const mapStateToProps = state => ({
  masterDoc: state.masterDocAction?.initDoc
})

export default connect(mapStateToProps, null)(BankTable)
