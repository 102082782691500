import Collapsible from "common/components/Collapsible/Collapsible";
import Bank from "common/views/CommonPage/Bank";
import ExporterDetails from "common/views/CommonPage/ExporterDetails";
import Other from "common/views/CommonPage/Other";
import Common from "de/views/DocPreparation/Sections/Common";
import React from 'react';
import { getStatus } from 'common/utils/StatusUtil';
import { connect } from "react-redux";

class CommonSections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  updateStatus = () =>{
    const {masterDoc} = this.props;
    const updatedStatus = getStatus('bank', masterDoc);
    this.setState({updatedStatus});
  }

  render(){

    return (
      <div>
        <Collapsible label="1. Common" className="collapsible">
          <Common />
        </Collapsible>

        <Collapsible label="2. Exporter / importer details" className="collapsible" section='importer'>
          <ExporterDetails />
        </Collapsible>


        <Collapsible label="3. Bank / agent / consignee" className="collapsible" section='bank' updatedStatus={this.state.updatedStatus}>
          <Bank updateStatus={this.updateStatus} />
        </Collapsible>

        <Collapsible label="4. Other information" className="collapsible" section='other'>
          <Other />
        </Collapsible>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc,
})

export default connect(mapStateToProps)(CommonSections)
