import Grid from '@material-ui/core/Grid';
import Divider from 'common/components/Divider/Divider';
import Heading from "common/components/Heading/Heading";
import renderInput from 'common/components/ReduxForm/RenderInput';
import renderDatePicker from 'common/components/ReduxForm/RenderDatePicker';
import renderDropdown from 'common/components/ReduxForm/RenderDropdown';
import { setMasterDoc, setMasterDocState, updateMasterDocByFields } from 'common/redux/actions';
import { getCountry, NO_OF_BOE } from 'common/utils/OptionUtil';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import InvoiceService from "common/services/invoice.service";
import docPrepService from 'common/services/doc-prep.service';

class BillExchangeSections extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
      this.optCountry = getCountry();
    }

    componentDidMount(){
      const {masterDoc} = this.props;
      console.log(masterDoc)
      if (masterDoc && masterDoc.mdocid?.length > 0) {

        const metaData = {mdocid: masterDoc.mdocid, mdoctype: masterDoc.mdoctype};

        docPrepService.loadBillOfExchange(metaData).subscribe(responses => {
            this.props.setMasterDoc(null);
            const [masterDocResponse, billOfExchangeResponse] = responses;
            masterDocResponse.data.billofexchangedto = billOfExchangeResponse.data;
            docPrepService.populateBankBOE(masterDocResponse);
            if(masterDocResponse.data.invoicedetaildto?.length > 0){
                masterDocResponse.data.billofexchangedto.boeexpref = masterDocResponse.data.invoicedetaildto[0].invno;
            }

            this.props.setMasterDoc(masterDocResponse.data);
            this.props.setMasterDocState(masterDocResponse.data.status);

        });
      }


      this.loadTplInvoice(masterDoc);
      if(masterDoc.invoicedetaildto?.length > 0){
        this.props.updateMasterDocByFields({
          field: 'billofexchangedto.boeexpref',
          data: masterDoc.invoicedetaildto[0].invno,
          isInit: true,
        });
      }
    }

    componentDidUpdate(prevProps) {
      const {masterDoc} = this.props;
      if(masterDoc && prevProps.masterDoc?.mdocid !== masterDoc.mdocid){
        this.loadTplInvoice(masterDoc);
      }
    }

    loadTplInvoice(masterDoc) {
      if (masterDoc.mdoctype === "TM" && masterDoc.invoicedetaildto === null) {
        const metaData = { mdocid: masterDoc.mdocid, mdoctype: masterDoc.mdoctype };
        InvoiceService.getInvoiceList('INV', metaData.mdocid, metaData.mdoctype).then(res => {
          if (res && res.statusCode === 200 && res.data.list?.length > 0) {
            this.props.updateMasterDocByFields({
              field: 'billofexchangedto.boeexpref',
              data: res.data.list[0].invno,
              isInit: true,
            });
          }
        });
      }
    }

    renderBOE = () => {
      return (
        <Grid container spacing={0} style={{ paddingTop: '20px' }}>
          <Grid item xs={3}>
            <Field
              component={renderDropdown}
              labelOutside="No. of bill of exchange*"
              id="NoOfBOE"
              name="billofexchangedto.noofboe"
              options={NO_OF_BOE}
              maxLength={35}
              selectWidth={275}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              label="Date*"
              id="BOEDate"
              name='billofexchangedto.boedate'
              component={renderDatePicker}
              defaultDate={new Date()}
              dateFormat="DD MMM yyyy"
            />
          </Grid>
          <Grid item xs={3}>

            <Field
              label="Bill of exchange number"
              id="BOEnumber"
              name='billofexchangedto.boeexpref'
              component={renderInput}
              maxLength={35}
            />


          </Grid>

          <Grid item xs={6}>
            <Field
              label="Pay to the order of*"
              id="payToTheOrderOf"
              name='billofexchangedto.paytotheorderof'
              component={renderInput}
              maxLength={150}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              label="Value*"
              id="BOEValue"
              name='billofexchangedto.boevalue'
              component={renderInput}
              maxLength={35}
            />
          </Grid>
        </Grid>
      );
    }

    render() {

      return (
        <>
          {this.renderBOE()}

          <Divider short />

          <Heading level={2} brandbar><span className="medium">To</span></Heading>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Field
                label="To"
                id="BOETo"
                name='billofexchangedto.boeto'
                component={renderInput}
                maxLength={35}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                label="Branch"
                id="BankBranch"
                name='billofexchangedto.bankbranch'
                component={renderInput}
                maxLength={35}
              />
            </Grid>

            <Grid item xs={4}>
              <Field
                label="Address line 1"
                id="BOEToAddr1"
                name='billofexchangedto.boetoaddr1'
                component={renderInput}
                maxLength={35}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="Address line 2"
                id="BOEToAddr2"
                name='billofexchangedto.boetoaddr2'
                component={renderInput}
                maxLength={35}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                label="Address line 3"
                id="BOEToAddr3"
                name='billofexchangedto.boetoaddr3'
                component={renderInput}
                maxLength={35}
              />
            </Grid>

            <Grid item xs={3}>
              <Field
                label="City"
                id="BOEToCity"
                name='billofexchangedto.boetocity'
                component={renderInput}
                maxLength={35}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                label="State"
                id="BOEToState"
                name='billofexchangedto.boetostate'
                component={renderInput}
                maxLength={35}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderDropdown}
                labelOutside="Country / Territory"
                id="BOEToCountry"
                name="billofexchangedto.boetocountry"
                options={this.optCountry}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderInput}
                label="Postal code"
                id="BOEToPostCode"
                name="billofexchangedto.boetopostcode"
                maxLength={10}
              />
            </Grid>
          </Grid>

          <Divider short />

          <Heading level={2} brandbar><span className="medium">For and on behalf of</span></Heading>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Field
                label="For and on behalf of*"
                id="ForAndOnBehalfOf"
                name='billofexchangedto.forandonbehalfof'
                component={renderInput}
                maxLength={100}
              />
            </Grid>
            <Grid item xs={6} />

            <Grid item xs={4}>
              <Field
                component={renderInput}
                label="Address line 1"
                id="ForAndOnBehalfOfAddr1"
                name="billofexchangedto.forandonbehalfofaddr1"
                maxLength={35}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={renderInput}
                label="Address line 2"
                id="ForAndOnBehalfOfAddr2"
                name="billofexchangedto.forandonbehalfofaddr2"
                maxLength={35}
              />
            </Grid>
            <Grid item xs={4}>
              <Field
                component={renderInput}
                label="Address line 3"
                id="ForAndOnBehalfOfAddr3"
                name="billofexchangedto.forandonbehalfofaddr3"
                maxLength={35}
              />
            </Grid>

            <Grid item xs={3}>
              <Field
                component={renderInput}
                label="City"
                id="ForAndOnBehalfOfCity"
                name="billofexchangedto.forandonbehalfofcity"
                maxLength={35}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderInput}
                label="State"
                id="ForAndOnBehalfOfState"
                name="billofexchangedto.forandonbehalfofstate"
                maxLength={35}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderDropdown}
                labelOutside="Country / Territory"
                id="ForAndOnBehalfOfCountry"
                name="billofexchangedto.forandonbehalfofcountry"
                options={this.optCountry}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderInput}
                label="Postal code"
                id="ForAndOnBehalfOfPostCode"
                name="billofexchangedto.forandonbehalfofpostcode"
                maxLength={10}
              />
            </Grid>
          </Grid>
        </>
      );
    }


}


const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values,
})

const mapDispatchToProps = dispatch => {
  return {
    setMasterDoc: (data) => dispatch(setMasterDoc(data)),
    setMasterDocState: (data) => dispatch(setMasterDocState(data)),
    updateMasterDocByFields: (payload) => dispatch(updateMasterDocByFields(payload))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillExchangeSections)
