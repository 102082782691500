import React from 'react';
import TenorService from 'common/services/tenor.service';
import GenericPopup from 'common/views/Popup/GenericPopup';

const initalData  = Object.freeze({ tenorID: "", tenorDesc: ""});

export default class AddTenor extends React.Component {

    save = (data) => {
      if (this.props.edit) {
        if (this.props.raFlag){
          return TenorService.updateTenor(data);
        }
        return TenorService.saveTenor(data);
      }
      return TenorService.addTenor(data, this.props.raFlag);
    }

    render(){
      const descField = this.props.raFlag ? {id:'tenorDesc', label:'Tenor description*', name:'tenorDesc', maxLength: 35, isMandatory: true}
                                          : {id:'tenorDesc', label:'Tenor description', name:'tenorDesc', maxLength: 35, }
      const fields = [
        {id:'tenorID', label:'Tenor*', name:'tenorID', maxLength: 35, isMandatory: true, disabled: this.props.edit, trim: true},
        descField,
      ]
      return (
        <GenericPopup
          initalData={initalData}
          fields={fields}
          {...this.props}
          save={this.save}
          displayField={['tenorID', 'tenorDesc']}
          displayFieldTile="Tenor"
        />
      )
    }

}
