import BaseService from 'common/services/BaseService';
import { packingListLabelHeadersObj } from 'common/utils/Constants';
import { isBlank } from 'common/utils/StringUtils';
import { formatEmptyValues } from 'common/utils/ObjectUtil';
import exportInstructionService from "./export-instruction.service";
import masterDocService from './masterDoc.service';

const _ = require('lodash');

class PackingListService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/PackingListBO/';
  }


  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  postWithStatus(url, paras, showLoading) {
    return super.postReturnWithStatus(this.basePath + url, paras, showLoading);
  }

  getInvNumberList(mdocid) {
    const requestBody = {
      mdocid,
      ...this.getUserDTO()
    }
    return this.postWithStatus('getInvNumberList', requestBody, true);
  }

  getPackingList(formid, mdocid, mdocType) {
    const requestBody = {
      "formid": formid,
      "mdocid": mdocid,
      "mdoctype": mdocType,
      ...this.getUserDTO()
    }

    return this.postWithStatus('getPackingList', requestBody, true);
  }

  saveNewPackingList(arrpackinglistdetaildto, masterDocDTO, metadata) {
    masterDocDTO.tmplmdocid = masterDocDTO.mdocid;
    masterDocDTO.formid = "PL";

    const userDTO = this.getUserDTO();
    const {user} = userDTO.userdto;
    return new Promise((resolve, reject) => {
      masterDocService.getMasterDocMDocID(user.codocexpressid, user.groupmemberid, '', '', user.codocexpressid, metadata.mdoctype).then(newMasterDocID => {
        if (!isBlank(newMasterDocID)) {
          masterDocDTO.mdocid = newMasterDocID;
          masterDocDTO.mdoctype = metadata.mdoctype;
          masterDocDTO.mdocdesc = metadata.mdocdesc ? metadata.mdocdesc : '';
          this.removePackingListActionButton(arrpackinglistdetaildto);
          const requestBody = this.constructPackingListRequestBody(_.cloneDeep(arrpackinglistdetaildto), masterDocDTO);
          if(masterDocDTO.tmplmdocid.startsWith("TM")){
            resolve(this.postWithStatus('saveNewPackingList', requestBody, true));
          }else{
            this.postWithStatus('saveNewPackingList', requestBody, true).then(()=>{
              resolve(exportInstructionService.addExportInstruction(masterDocDTO.eidto, masterDocDTO));
            });
          }
        } else {
          reject('cannot get master doc id');
        }

      });
    })

  }

  updatePackingList(packingListDTO, masterDocDTO) {
      this.removePackingListActionButton(packingListDTO);
      const requestBody = this.constructPackingListRequestBody(_.cloneDeep(packingListDTO), masterDocDTO);

      if (masterDocDTO.mdoctype === 'MD') {
        return this.postWithStatus('savePackingList', requestBody, true);
      }
      return this.postWithStatus('saveTMPLPackingList', requestBody, true);
  }

  constructPackingListRequestBody(packingListDTO, masterDocDTO) {
    const decimalFields = ['PLDetCubic', 'PLDetGrossMass', 'PLDetNetMass', 'PLDetNoPKGS', 'PLDetQty', 'plcubictotal', 'plgmasstotal', 'plnetmasstotal', 'plpkgstotal', 'plqtytotal']
    const requiredDecimalField = ['plqtytotal', 'plpkgstotal', 'plgmasstotal', 'plnetmasstotal', 'plcubictotal'];
    if (packingListDTO) {
      packingListDTO.forEach(packingItem => {
        if (packingItem.labelHeaders) {
          delete packingItem.labelHeaders;
        }

        Object.keys(packingListLabelHeadersObj).forEach(key => {
          if (isBlank(packingItem[key])) {
            packingItem[key] = packingListLabelHeadersObj[key];
          }
        })

        formatEmptyValues(packingItem, requiredDecimalField);

        if (packingItem.packinglistitemdetaildtolist) {
          packingItem.packinglistitemdetaildtolist = packingItem.packinglistitemdetaildtolist.filter(p => !p.isDelete);
          packingItem.packinglistitemdetaildtolist.forEach(detailItem => {
            detailItem.PLNo = packingItem.plno;
            formatEmptyValues(detailItem, decimalFields);
          })
        }
      });
    }


    const userDTO = this.getUserDTO();
    masterDocService.massageMasterDocBeforeSubmission(masterDocDTO, userDTO);
    const submitMasterDocDTO = { ...masterDocDTO};


    submitMasterDocDTO.formid = 'PL';
    return {
      arrpackinglistdetaildto: packingListDTO.filter(p => !p.isDelete),
      masterdocdto: submitMasterDocDTO,
      ...userDTO
    };
  }

  // eslint-disable-next-line class-methods-use-this
  removePackingListActionButton(values) {
    if(values){
      values.forEach(value=>{
        delete value.actionBtn;
        if (value.packinglistitemdetaildtolist) {
          value.packinglistitemdetaildtolist.forEach(packingListItem=>{
            delete packingListItem.actionBtn;
          });
        }
        if (value.itemlist) {
          value.itemlist.forEach(item => {
            delete item.actionBtn;
          })
        }
      });
    }
  }

}

export default new PackingListService();
