import React from 'react';
import { ModalTitle, ModalBtnList, ModalBtn, ModalAction, ModalActionExtraButton, ModalActionButton } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Grid from '@material-ui/core/Grid';
import CDLTabs, {TabList, TabPanels, TabPanel, Tab} from '@cdl/tabs';
import Modal from "common/components/Modal/Modal";
import ModalHeader from "common/components/Modal/ModalHeader";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import CDLRadioGroup, {Radio} from '@cdl/radio-group';
import Table from "common/components/Table/Table";
import TableCheckbox from "common/components/TableCheckbox/TableCheckbox";
import PrintService from "common/services/print.service";
import UserService from "common/services/user.service";
import { connect } from 'react-redux';
import FileService from 'common/services/file.service';
import TextFieldForm from "common/components/TextField/TextFieldForm";
import ModuleService from 'common/services/module.service';
import Notice from 'common/views/Popup/Notice';
import Confirm from 'common/views/Popup/Confirm';
import { PRINT_CMD, ROW_SELECTED_CLASS, WOLF_SCAN_FIELDS } from 'common/utils/Constants';
import { wolfScan } from 'common/utils/FieldValidation';
import MasterDocService from "common/services/masterDoc.service";
import EDSService from "common/services/eds.service";
import FinancedAmount from 'common/views/Popup/FinancedAmount';
import $ from "jquery";
import DropdownField from 'common/components/Dropdown/DropdownField';
import WolfMsg from 'common/views/Popup/WolfMsg';

const _ = require('lodash');

const options=[];
for (let index = 0; index < 11; index++) {
  options.push({label: `${index}`, value: index});
}

const selWidth = 140;
const INVOICE = "INVOICE";
const PACKING_LIST = "PACKING_LIST";
const BENEFICIARY = "BENEFICIARY";
const BOE = "BOE";
const ETC = "ETC";
const EDS = "EDS";

class PrintDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msg: "",
      openNotice: false,
      email: "",
      data: [],
      tableData: [],
      additionalFieldData: {},
      selectedRadio: INVOICE,
      confirmPrint: false,
      openAmount: false,
      openWolfMsg: false,
      optValues: {},
    };
    this.refEmail = React.createRef();
    this.columns = [
      {
        Header: '',
        accessor: 'MD_FieldChk',
        width: 40,
        Cell: (cell) => (<TableCheckbox
          onChange={this.onCheck}
          checked={cell.original.MD_FieldChk}
          value={cell.original.FldPos}
        />),
        sortable: false
      },
      {
        Header: 'Field name',
        accessor: 'MD_FieldLbl'
      },
      {
        Header: 'Field value',
        accessor: 'MD_Field'
      }
    ]
  }

  static getDerivedStateFromProps (props, prevState) {
    if(props.masterDoc?.mdocid !== prevState.prevID){
        return {
          prevID: props.masterDoc?.mdocid,
        };
    }
    return null;
  }

  componentDidMount() {
    PrintService.initPrintPanel(this.props.masterDoc?.mdocid).then(res =>{
      if(res){
        const optValues = this.genOptionValues(res.list);
        this.setState({
          data: res.list,
          optValues,
        });
      }
    });

    this.initAddtionalFields();
  }

  genOptionValues = (data) => {
    const optValues = {};

    this.genDefaultValues(data[0], INVOICE, optValues);
    this.genDefaultValues(data[1], PACKING_LIST, optValues);
    this.genDefaultValues(data[2], BENEFICIARY, optValues);
    this.genDefaultValues(data[3], BOE, optValues);
    this.genDefaultValues(data[4], ETC, optValues);

    const {printCmd} = this.props;
    if(printCmd===PRINT_CMD.PRINT_DIRECT){
      optValues.EDS_SET = { label: '1', value: 1, dataID: 'EDS_SET' };
      optValues.EDS_ORI = { label: '1', value: 1, dataID: 'EDS_ORI' };
      optValues.EDS_COPY = { label: '0', value: 0, dataID: 'EDS_COPY' };
    }else if(printCmd===PRINT_CMD.PRINT_EDS || printCmd===PRINT_CMD.PRINT_EDS_EMAIL){
      optValues.EDS_SET = { label: '1', value: 1, dataID: 'EDS_SET' };
      optValues.EDS_ORI = { label: '0', value: 0, dataID: 'EDS_ORI' };
      optValues.EDS_COPY = { label: '0', value: 0, dataID: 'EDS_COPY' };
    }else{
      this.genDefaultValues(0, EDS, optValues);
    }

    return optValues;
  }

  genDefaultValues = (data, section, optValues) => {
    const keys = this.getKeys(section);
    keys.forEach(key=>{
      optValues[key] = this.genDefaultValue(data, key);
    });
  }

  genDefaultValue = (data, key) => {
    let defaultVal = {};
    if(data>0 && !key.includes('_COPY') && this.props.printCmd===PRINT_CMD.PRINT_DRAFT){
      defaultVal = { label: '1', value: 1, dataID: key };
    }else{
      defaultVal = { label: '0', value: 0, dataID: key };
    }
    return defaultVal;
  }

  getKeys = (sectionName) => {
    return [`${sectionName}_SET`, `${sectionName}_ORI`, `${sectionName}_COPY`];
  }


  appendPrintSection = (hasInvoice, printSetting, section, doc, shortName) => {
    if (hasInvoice) {
      const keys = this.getKeys(section);
      printSetting.push({
        "sets": this.state.optValues[keys[0]].value || 0,
        "original": this.state.optValues[keys[1]].value || 0,
        "copy": this.state.optValues[keys[2]].value || 0,
        "document": doc,
        "docshortname": shortName,
        "sinvno": ""
      });
    }
  }

  isEDS = () => {
    const {printCmd} = this.props;
    return (printCmd===PRINT_CMD.PRINT_DIRECT
            || printCmd===PRINT_CMD.PRINT_EDS
            || printCmd===PRINT_CMD.PRINT_EDS_EMAIL);
  }

  isShowEmail = () => {
    const {printCmd} = this.props;
    return ( printCmd===PRINT_CMD.PRINT_TRANSMIT
              || printCmd===PRINT_CMD.PRINT_EDS_EMAIL
              || printCmd===PRINT_CMD.PRINT_EMAIL );
  }

  isReprint = () => {
    const {printCmd} = this.props;
    return printCmd===PRINT_CMD.PRINT_EDS || printCmd===PRINT_CMD.PRINT_EDS_EMAIL;
  }

  showPrintMsg = (msg) =>{
    this.setState({
      title: "Notice",
      msg,
      openNotice: true,
    });
    this.props.close(msg);
  }

  saveFinancedAmount = (result) => {
    if(result){
      this.print();
    }else{
      this.setState({
        msg: "Financed Amount update fail",
        openNotice: true,
      });
    }
  }

  EDSValidateStep3 = (result, edsValues) => {
			if (ModuleService.isFinancedEDS() && !this.isReprint()) {
        if (!ModuleService.isGenericEDS()) {
          if (result !== "ForFinance") {
            this.setState({
              updateStatus: true,
              status: "ForFinance",
              statusMsg: "To print for Financed Electronic Direct Send, 'For Finance' must be selected. \nDo you wish to update the record to 'For Finance'?",
            });
          } else {
            this.EDSValidateStep4(edsValues);
          }
        } else if (result === "ForFinance") {
            this.EDSValidateStep4(edsValues);
        } else {
          this.print();
        }
      } else {
        this.print();
      }
		}

  EDSValidateStep4 = (edsValues) => {
    if (edsValues === "") {
      this.setState({
        msg: "[Brief Description of Goods] field is mandatory for financed transaction. Please provide a valid commodity before proceeding.",
        openNotice: true,
      });
    } else if (this.props.printCmd===PRINT_CMD.PRINT_DIRECT) {
      this.setState({
        openAmount: true,
      });
    } else {
      this.print();
    }
  }

  updateStatus = () => {
    EDSService.updateEDSValues(this.props.masterDoc?.mdocid, this.state.status).then(res => {
      if(res > 0){
        this.EDSValidateStep3("ForFinance", this.state.tmpBriefDescription);
      }
    });
  }


  validateEDS = (mdocid) => {
    EDSService.chkForEDSValues(mdocid, ModuleService.hasLFEDSModule()).then(res => {
      if (res && res.list && res.list.length > 3) {
        const result = res.list[0];
        const tmpBriefDescription = res.list[2];
        this.setState({
          tmpBriefDescription,
          maxAmount: res.list[3],
        })
        if (result === "ERROR") {
          console.log("ERROR");
        } else if (result === "NODADP") {
          this.setState({
            msg: "No Collection Order - Non-DC has been created under this master document. Print for EDS has been cancelled.",
            openNotice: true,
          });
        } else if (result === "NODC") {
          this.setState({
            msg: "No Collection Order - DC has been created under this master document. Print for EDS has been cancelled.",
            openNotice: true,
          });
        } else if (ModuleService.isGenericEDS() && !ModuleService.hasLFEDSModule()) {
            this.validateVariousField(mdocid, result, tmpBriefDescription);
        } else {
          this.EDSValidateStep3(result, res.list[2]);
        }
      }
    });

  }

  printScan = () => {
    const hasInvoice = this.isSelectedSection(INVOICE);
    const hasPackingList = this.isSelectedSection(PACKING_LIST);
    const hasBeneficiary = this.isSelectedSection(BENEFICIARY);
    const hasBOE = this.isSelectedSection(BOE);
    const hasETC = this.isSelectedSection(ETC);
    const hasEDS = this.isSelectedSection(EDS);

    if(this.hasSectionSelected(hasInvoice, hasPackingList, hasBeneficiary, hasBOE, hasETC, hasEDS)){
      if(this.isShowEmail() && !this.refEmail.current.validate()){
        return;
      }
      this.setState({title: 'Print'})
      const {masterDoc,printCmd} = this.props;
      if(printCmd === PRINT_CMD.PRINT_DRAFT
        || printCmd === PRINT_CMD.PRINT_EDS
        || printCmd === PRINT_CMD.PRINT_EDS_EMAIL){
          this.print();
      }else{
        this.checkMasterDocStatus(masterDoc);
      }

    }else{
      this.setState({
        msg: "Please specify at least 1 set to print.",
        openNotice: true,
      });
    }
  }

  openConfirmPrint = () => {
    const {masterDoc} = this.props;
    if(!masterDoc){
      return;
    }
    const errorMsg = wolfScan(masterDoc, WOLF_SCAN_FIELDS);
    if(errorMsg.length > 0){
      this.setState({
        openWolfMsg: true,
        wolfMsg: errorMsg,
      })
      return;
    }

    this.setState({
      confirmPrint: true
    });
  }

  showNotUpdateMsg = () => {
    this.setState({
      title: "Notice",
      msg: 'This Master Document has been updated by others, please reload this document.',
      openNotice: true,
    });
  }

  isLatest = (masterDoc) => {
    return MasterDocService.getMasterDoc(masterDoc.mdocid, masterDoc.mdoctype).then(res=>{
        if(res && res.statusCode === 200){
            const doc = res.data;

            if(doc.status !== masterDoc?.status
                || doc.lastupdatedate !== masterDoc?.lastupdatedate
                || doc.lastupdatetime !== masterDoc?.lastupdatetime
                || doc.dateupdate !== masterDoc?.dateupdate){
                  this.showNotUpdateMsg();
                  return false;
            }
            return true;
        }

        this.showNotUpdateMsg();
        return false;
    })
  }

  print = () => {

    const { masterDoc, printCmd } = this.props;

    const printSetting = [];
    this.appendPrintSection(this.isSelectedSection(INVOICE), printSetting, INVOICE, "GENERIC_INVOICE", "INV");
    this.appendPrintSection(this.isSelectedSection(PACKING_LIST), printSetting, PACKING_LIST, "GENERIC_PL", "PL");
    this.appendPrintSection(this.isSelectedSection(BENEFICIARY), printSetting, BENEFICIARY, "GENERIC_BC", "BC");
    this.appendPrintSection(this.isSelectedSection(BOE), printSetting, BOE, "GENERIC_BOE", "BOE");
    this.appendPrintSection(this.isSelectedSection(ETC), printSetting, ETC, "GENERIC_EXPORTINSTR", "EI");
    this.appendPrintSection(this.isSelectedSection(EDS), printSetting, EDS, "GENERIC_EDS", "EDS");


    const user = UserService.getCurrentUser();
    const dcEDS = ModuleService.hasLFEDSModule() ? "TRUE" : "";

    const printPara = {
      "codocexpressid": masterDoc.codocexpressid,
      "dbname": user.countrycode + user.groupmemberid,
      "dc_dadp_eds": dcEDS,
      "docdate": "",
      "docprintsettings": printSetting,
      "doctype": "",
      "emailaddr": this.state.email,
      "impname": masterDoc.impname,
      "iseds": this.isEDS(),
      "mdocdesc": masterDoc.mdocdesc,
      "mdocid": masterDoc.mdocid,
      "mdoctype": masterDoc.mdoctype,
      "printoptions": printCmd,
      "username": user.username
    }


    this.isLatest(masterDoc).then(resp=>{
      if(resp){
        this.showPrintFinalMsg(masterDoc, printCmd);

        if (this.hasAdditionalField()) {
          const updateList = [];

          this.genCheckedFields(INVOICE, updateList);
          this.genCheckedFields(PACKING_LIST, updateList);
          this.genCheckedFields(BENEFICIARY, updateList);

          PrintService.updateAddFld(masterDoc.mdocid, updateList).then(res => {
            if (res) {
              this.initAddtionalFields();
            }
          });
        }

        this.genPDF(printPara, printSetting, printCmd);
      }
    })
  }

    handleUpdateValue = (selectedOption) => {
      const key = selectedOption.dataID;
      this.setState(prevState =>{
        const newOpt = prevState.optValues;
        newOpt[key] = selectedOption;
        return {
          [key] : selectedOption.value,
          optValues: newOpt,
        }
      });
    };

    handleChange = (event) => {
      this.setState({ [event.target.name]: event.target.value});
    }

    genOption = (key) => {
      const opt = [];
      options.forEach(option=>{
        opt.push({...option, dataID: key});
      });
      return opt;
    }

    handleClose = () => {
      this.setState({});
      this.props.close();
    }

    hasSectionSelected = (hasInvoice, hasPackingList, hasBeneficiary, hasBOE, hasETC, hasEDS) => {
      return hasInvoice || hasPackingList || hasBeneficiary || hasBOE || hasETC || hasEDS;
    }


    isDisabledSection = (index) => {
      return this.state.data[index] === "0" || !this.state.data[index];
    }

    onCheck = (event) =>{
      const checkbox = $(event.target);
      const row = checkbox.parent().parent().parent();
      const isChecked = checkbox.prop('checked');
      if(isChecked){
          row.addClass(ROW_SELECTED_CLASS);
      }else{
          row.removeClass(ROW_SELECTED_CLASS);
      }
      const {value} = event.currentTarget;
      const {selectedRadio} = this.state;

      this.setState(prevState => {
        const newAdditionalFieldData =  _.cloneDeep(prevState.additionalFieldData);
        const selectedCheckbox = newAdditionalFieldData[selectedRadio].find(item => item.FldPos===Number(value));

        if(isChecked){
          selectedCheckbox.MD_FieldChk = true;
        }else{
          selectedCheckbox.MD_FieldChk = false;
        }

        return {
          additionalFieldData: newAdditionalFieldData,
          tableData: newAdditionalFieldData[selectedRadio],
        }
      })
    }

    onRadioChange = (event) =>{
      const {value} = event.currentTarget;
      this.setState({
          tableData:[]
        },
        ()=>{
          this.setState(prevState => {
            return {
              selectedRadio: value,
              tableData: prevState.additionalFieldData[value] || [],
            }
          }, this.setRowSelected);
        }
      )
    }

    isSelectedSection = (sectionName) => {
      const keys = this.getKeys(sectionName);
      return this.state.optValues[keys[0]].value > 0
                && (this.state.optValues[keys[1]].value > 0 || this.state.optValues[keys[2]].value > 0);
    }

    setRowSelected = () => {
      setTimeout(() => {
        $('#additionalFieldTable').find('.row-selected').removeClass(ROW_SELECTED_CLASS);
        $(`#additionalFieldTable input[type='checkbox'][checked]`).parent().parent().parent().addClass(ROW_SELECTED_CLASS);
      }, 200);
    }

    isDisablePrint = (disabledInvoice, disabledPackingList, disabledBeneficiary, disabledBOE, disabledETC, isEDS) => {
      let disabledPrint = false;
      if (disabledInvoice && disabledPackingList && disabledBeneficiary && disabledBOE && disabledETC && !isEDS) {
        disabledPrint = true;
      }
      return disabledPrint;
    }

  showPrintFinalMsg(masterDoc, printCmd) {
    let msg = `'${masterDoc.mdocid}' has successfully been sent to the server for processing, `;
    if (printCmd === PRINT_CMD.PRINT_DIRECT) {
      if (ModuleService.hasLFEDSModule()) {
        msg += "collection schedule will be sent to processing centre.";
      } else {
        msg += "please wait whilst the system generates the collection schedule.";
      }
      this.showPrintMsg(msg);
    } else if (printCmd === PRINT_CMD.PRINT_TRANSMIT || printCmd === PRINT_CMD.PRINT_EDS_EMAIL) {
      this.showPrintMsg(`'${masterDoc.mdocid}' has successfully been sent to the server for emailing.`);
    } else {
      msg += " please wait whilst the system generates the document(s).";
      this.showPrintMsg(msg);
    }
  }

  checkMasterDocStatus(masterDoc) {
    MasterDocService.printMasterDocScanCheck(masterDoc.status, masterDoc).then(res => {
      if (res) {
        if (res.checkResult && res.checkResult.resultMsgReturnToUser) {
          this.setState({
            openNotice: true,
            msg: res.checkResult.resultMsg,
          });
        } else if (this.isEDS()) {
          this.validateEDS(masterDoc.mdocid);
        } else {
          this.print();
        }
      } else {
        this.setState({
          msg: "Unable to print master document.",
          openNotice: true,
        });
      }
    });
  }

  hasAdditionalField() {
    let hasAdditionalField = false;
    const { additionalFieldData } = this.state;
    if (additionalFieldData) {
      Object.keys(additionalFieldData).forEach(key => {
        if (additionalFieldData[key].length > 0) {
          hasAdditionalField = true;
        }
      });
    }
    return hasAdditionalField;
  }

  isDownload(printCmd) {
    switch(printCmd){
      case PRINT_CMD.PRINT_DRAFT:
      case PRINT_CMD.PRINT_EDS:
      case PRINT_CMD.PRINT_TRADITIONAL:
      case PRINT_CMD.PRINT_EMAIL:
        return true;
      case PRINT_CMD.PRINT_DIRECT:
        return !(this.props.isLFEDS && ModuleService.isLFEDS());
      default:
        return false;
    }
  }

  genPDF(printPara, printSetting, printCmd) {
    PrintService.generatePdf(false, printPara, printSetting).then(res => {
      if (res) {
        if (res.includes('Error')) {
          this.props.close(res, true);
        } else {
          if (this.isDownload(printCmd)) {
              FileService.download(res);
          }

          if ([PRINT_CMD.PRINT_DIRECT, PRINT_CMD.PRINT_TRADITIONAL, PRINT_CMD.PRINT_TRANSMIT, PRINT_CMD.PRINT_EMAIL, PRINT_CMD.PRINT_DRAFT].includes(printCmd)) {
            this.props.reload();
          }
        }
      } else if (printCmd === PRINT_CMD.PRINT_TRANSMIT || printCmd === PRINT_CMD.PRINT_EDS_EMAIL) {
        this.props.reload();
      } else {
        this.showPrintMsg("Unable to print master document.");
      }
    });
  }


    validateVariousField(mdocid, result, tmpBriefDescription) {
      EDSService.validateVariousField(mdocid).then(resp => {
        if (resp) {
          if (typeof resp === 'string') {
            const arr1 = resp.split(";");
            if (["0","1","11","12","13","14","15"].includes(arr1[0])) {
              this.setState({
                msg: `Invalid ${arr1[1]}. Please open the master document and validate the ${arr1[1]} before attempt to send EDS again.`,
                openNotice: true,
              });
            }
            if (arr1[0] === "-1") {
              this.setState({
                msg: `Invalid ${arr1[1]}. Please open the master document and relevent document forms to re-select the country fields.`,
                openNotice: true,
              });
            }
          } else if (!ModuleService.isFinancedEDS()) {
            if (result === "ForFinance") {
              this.setState({
                updateStatus: true,
                status: "SendForCollection",
                statusMsg: "To print for Electronic Direct Send, 'Send for Collection' must be selected. Do you wish to update the record to 'Send for Collection'?",
              });
            } else {
              this.EDSValidateStep3(result, tmpBriefDescription);
            }
          } else {
            this.EDSValidateStep3(result, tmpBriefDescription);
          }
        }
      });
    }

    initAddtionalFields() {
      PrintService.initPrintPanelAddFld(this.props.masterDoc?.mdocid).then(res => {
        if (res) {
          const data = res.list;

          this.setState({
            additionalFieldData: {
              [INVOICE]: data[0],
              [PACKING_LIST]: data[1],
              [BENEFICIARY]: data[2],
            },
            tableData: data[0] || [],
          }, this.setRowSelected);
        }
      });
    }

    genCheckedFields(section, updateList) {
      const {additionalFieldData} = this.state;
      const tmp = [];
      additionalFieldData[section].forEach(element => {
        if(element.MD_FieldChk){
          tmp.push(element.FldPos);
        }
      });
      if (tmp.length > 0) {
        updateList.push(tmp.join(","));
      } else {
        updateList.push("");
      }
    }

    renderDropdown(key, disabled) {
      let opts = this.genOption(key);
      const {printCmd} = this.props;
      if(printCmd===PRINT_CMD.PRINT_DIRECT){
        if(key==='EDS_COPY'){
          opts = [{ label: '0', value: 0, dataID: key }];
        }else{
          opts = [{ label: '1', value: 1, dataID: key }];
        }
      }else if(printCmd===PRINT_CMD.PRINT_EDS || printCmd===PRINT_CMD.PRINT_EDS_EMAIL){
        if(key==='EDS_SET'){
          opts = [{ label: '1', value: 1, dataID: key }];
        }else if(key==='EDS_ORI'){
          opts = [{ label: '0', value: 0, dataID: key }];
        }
      }

      return <DropdownField
        selectWidth={selWidth}
        options={opts}
        onChange={this.handleUpdateValue}
        isDisabled={disabled}
        value={this.state.optValues[key]?.value}
        returnOption
      />;
    }

    renderDropdownRow(section, disabled) {
      return (
        <>
          <Grid item xs={3}>
            {this.renderDropdown(`${section  }_SET`, disabled, 1)}
          </Grid>
          <Grid item xs={3}>
            {this.renderDropdown(`${section  }_ORI`, disabled, 1)}
          </Grid>
          <Grid item xs={3}>
            {this.renderDropdown(`${section  }_COPY`, disabled, 0)}
          </Grid>
        </>
      );
    }


    render(){
      const isEDS = this.isEDS();
      const showEmail = this.isShowEmail();
      let disabledInvoice = true;
      let disabledPackingList = true;
      let disabledBeneficiary = true;
      let disabledBOE = true;
      let disabledETC = true;
      if(!isEDS){
        disabledInvoice = this.isDisabledSection(0);
        disabledPackingList = this.isDisabledSection(1);
        disabledBeneficiary = this.isDisabledSection(2);
        disabledBOE = this.isDisabledSection(3);
        disabledETC = this.isDisabledSection(4);
      }
      const disabledPrint = this.isDisablePrint(disabledInvoice, disabledPackingList, disabledBeneficiary, disabledBOE, disabledETC, isEDS);

      const {openAmount, openNotice, confirmPrint, updateStatus, openWolfMsg} = this.state

      return (
        <div>
          {openAmount &&
          <FinancedAmount
            isOpen={openAmount}
            close={()=>{this.setState({openAmount: false})}}
            mdocid={this.props.masterDoc?.mdocid}
            save={this.saveFinancedAmount}
            maxAmount={this.state.maxAmount}
            decimalScale={this.props.masterDoc.amtdeccat}
          />}
          {openNotice &&
          <Notice
            title={this.state.title}
            content={this.state.msg}
            isOpen={openNotice}
            close={()=>{this.setState({openNotice: false})}}
          />}
          {confirmPrint &&
          <Confirm
            content="Do you wish to print the selected record now?"
            isOpen={confirmPrint}
            close={()=>{this.setState({confirmPrint: false})}}
            confirm={this.printScan}
          />}
          {updateStatus &&
          <Confirm
            content={this.state.statusMsg}
            isOpen={updateStatus}
            close={()=>{this.setState({updateStatus: false})}}
            confirm={this.updateStatus}
          />}

          {openWolfMsg &&
          <WolfMsg
            fields={this.state.wolfMsg}
            isOpen={openWolfMsg}
            close={()=>{this.setState({openWolfMsg: false})}}
          />}

          <Modal
            ariaLabelledby="modal-heading"
            classname="myClass"
            isOpen={this.props.isOpen && !openAmount && !openNotice
              && !confirmPrint && !updateStatus && !openWolfMsg}
            width={800}
          >
            <ModalHeader>
              <ModalTitle>Print document</ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={this.handleClose} />
              </ModalBtnList>
            </ModalHeader>
            <ModalContent>
              <CDLTabs>
                <TabList style={{boxShadow: '0 -0.0625rem 0 0 #253038 inset'}}>
                  <Tab>No. of set and copy</Tab>
                  <Tab>Additional fields</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Grid
                      container
                      spacing={0}
                      style={{paddingTop: '20px', paddingBottom:'10px'}}
                    >
                      <Grid item xs={3} />
                      <Grid item xs={3}>No. of sets</Grid>
                      <Grid item xs={3}>No. of originals</Grid>
                      <Grid item xs={3}>No. of copies</Grid>
                    </Grid>

                    <Grid container spacing={0} style={{paddingBottom:'20px'}}>
                      <Grid item xs={3} style={{lineHeight: '30px'}}>
                        Invoice
                      </Grid>
                      {this.renderDropdownRow(INVOICE, disabledInvoice)}
                    </Grid>

                    <Grid container spacing={0} style={{paddingBottom:'20px'}}>
                      <Grid item xs={3} style={{lineHeight: '30px'}}>
                        Packing list
                      </Grid>
                      {this.renderDropdownRow(PACKING_LIST, disabledPackingList)}
                    </Grid>

                    <Grid container spacing={0} style={{paddingBottom:'20px'}}>
                      <Grid item xs={3}>
                        Beneficiary cert. / miscellaneous docs.
                      </Grid>
                      {this.renderDropdownRow(BENEFICIARY, disabledBeneficiary)}
                    </Grid>

                    <Grid container spacing={0} style={{paddingBottom:'20px'}}>
                      <Grid item xs={3} style={{lineHeight: '30px'}}>
                        Bill of exchange
                      </Grid>
                      {this.renderDropdownRow(BOE, disabledBOE)}
                    </Grid>

                    {!isEDS &&
                    <Grid container spacing={0} style={{paddingBottom:'20px'}}>
                      <Grid item xs={3}>
                        Export trade collection / negotiation instructions
                      </Grid>
                      {this.renderDropdownRow(ETC, disabledETC)}
                    </Grid>}

                    {isEDS &&
                    <Grid container spacing={0} style={{paddingBottom:'20px'}}>
                      <Grid item xs={3} style={{lineHeight: '30px'}}>
                        Collection Schedule
                      </Grid>
                      {this.renderDropdownRow(EDS, false)}
                    </Grid>}

                    {showEmail &&
                    <Grid container spacing={0} style={{paddingBottom:'20px'}}>
                      <Grid item xs={3} style={{lineHeight: '30px'}}>
                        <TextFieldForm
                          inline
                          email
                          name="email"
                          label="Email Address"
                          value={this.state.email}
                          onChange={this.handleChange}
                          validations={["required","multiEmail"]}
                          inputWidth={340}
                          ref={this.refEmail}
                        />
                      </Grid>
                    </Grid>}

                  </TabPanel>
                  <TabPanel>
                    <Grid
                      container
                      spacing={0}
                      style={{paddingTop: '20px', paddingBottom:'10px'}}
                    >
                      <Grid item xs={12}>
                        <CDLRadioGroup name="fieldType">
                          <Radio defaultChecked id="invoiceRadio" label="Invoice" value={INVOICE} onChange={this.onRadioChange} />
                          <Radio id="packingListRadio" label="Packing list" value={PACKING_LIST} onChange={this.onRadioChange} />
                          <Radio id="beneficiaryRadio" label="Beneficiary cert. / miscellaneous doc." value={BENEFICIARY} onChange={this.onRadioChange} />
                        </CDLRadioGroup>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0} style={{paddingBottom:'20px'}}>
                      <Grid item xs={12}>
                        <Table
                          id="additionalFieldTable"
                          defaultPageSizeOption={{ 'value': 0 }}
                          showPagination={false}
                          style={{ overflowY: 'hidden' }}
                          data={this.state.tableData}
                          columns={this.columns}
                        />
                      </Grid>
                    </Grid>

                  </TabPanel>

                </TabPanels>
              </CDLTabs>

            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>
                <ModalActionExtraButton>
                  <Button onClick={this.handleClose}>Cancel</Button>
                </ModalActionExtraButton>
                <ModalActionButton>
                  <Button disabled={disabledPrint} themeColor="primary" onClick={this.openConfirmPrint}>Print</Button>
                </ModalActionButton>
              </ModalAction>
            </ModalFooter>
          </Modal>
        </div>
      )
    }


}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values,
})


export default connect(mapStateToProps)(PrintDocument)
