import Footer from "common/components/Footer/Footer";
import Header from "common/components/Header/Header";
import LoadingSpinner from "common/components/LoadingSpinner/LoadingSpinner";
import PrivateRoute from "common/components/PrivateRoute/PrivateRoute";
import AuthService from "common/services/auth.service";
import ModuleService from "common/services/module.service";
import React from 'react';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';
import { isAC } from "common/utils/AppUtil";

class UserPage extends React.Component {

    constructor(props) {
      super(props);

      this.state = {};
      this.interval = setInterval(function(){
        AuthService.keepAlive().then(response => {
          if (response && response.data !== 'keepAlive') {
            const msg = isAC() ? "Concurrent Login has logged out the User." : "User has been logged out by concurrent login.";
            AuthService.logout(msg).then(res => {
              if(res){
                res.keepAlivefailed = true;
                props.history.push("/Logout", res);
              }
            });
          }
        });
      }, 1000*5);
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }


    render() {

      return (
        <div className="App">
          <Header showNav isLogin links={ModuleService.getNavLinks()} {...this.props} />
          {this.props.children}

          {ModuleService.getModules().map(item =>{
              return (
                <React.Fragment key={item.name}>
                  <PrivateRoute path={item.url} component={item.component} title={item.title} module={item.name} />
                  {item.redirect &&
                  <PrivateRoute exact path={item.url} component={item.component} title={item.title} module={item.name}>
                    <Redirect to={item.redirect} />
                  </PrivateRoute>}
                </React.Fragment>
              )
          })}
          {this.props.location.pathname === '/' &&
          <Redirect from='/' to='/Home' />}
          <Footer />
          <LoadingSpinner showLoading={this.props.isLoading}  />
        </div>
      );
    }
  }

  const mapStateToProps = state => ({
    isLoading: state.loadingChange?.showLoading
  })


  export default connect(mapStateToProps, null)(UserPage)
