import {isAC} from "common/utils/AppUtil";
import {Cookies} from "react-cookie";

const CryptoJS = require("crypto-js");

const secretKey = 'xSVH6sdmpNWjRDIqCc2rdxs01lwHzfr3';

const cookie = new Cookies();
const basePara = {path: "/", secure: true, sameSite: 'strict'};

class UserService{

    getCurrentUser() {
        const data = cookie.get(this.getUserKey());

        if(data){
            const bytes  = CryptoJS.AES.decrypt(data, secretKey);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }

        return null;
    }

    setCurrentUser(userData) {
        const data = CryptoJS.AES.encrypt(JSON.stringify(userData), secretKey).toString();
        const name = this.getUserKey();
        cookie.remove(name, basePara);
        cookie.set(name, data, basePara);
    }

    // eslint-disable-next-line class-methods-use-this
    getUserKey(){
        return isAC() ? "tknAC" : "tkn";
    }

    removeUser() {
        cookie.remove(this.getUserKey(), basePara);
        sessionStorage.removeItem('INV');
    }

}

export default new UserService();
