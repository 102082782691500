import BaseService from 'common/services/BaseService';
import { invoiceLabelHeadersObj } from 'common/utils/Constants';
import { isBlank } from 'common/utils/StringUtils';
import { formatEmptyValues } from 'common/utils/ObjectUtil';
import exportInstructionService from "./export-instruction.service";
import masterDocService from './masterDoc.service';


class InvoiceService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/InvoiceBO/';
  }


  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  postWithStatus(url, paras, showLoading) {
    return super.postReturnWithStatus(this.basePath + url, paras, showLoading);
  }

  getInvoiceItemList(invoiceno, mdocid) {
    const requestBody = {
      "invoiceno": invoiceno,
      "mdocid": mdocid,
      ...this.getUserDTO()
    }

    return this.postWithStatus('getInvoiceItemList', requestBody, true);
  }

  getInvoiceList(formid, mdocid, mdoctype) {

    const userDTO = this.getUserDTO();

    userDTO.formid = 'INV';

    const requestBody = {
        "formid": formid,
        "mdocid": mdocid,
        mdoctype,
        ...userDTO
      }
    return new Promise((resolve, reject) => {
      this.postWithStatus('getInvoiceList', requestBody, true).then(response => {
        const invoiceList = response.data.list;
        invoiceList.forEach(invoice => {
          invoice.userupdate = true;
          invoice.invitemdetaildtolist.forEach(invoiceItem => {
            invoiceItem.UserUpdate = true;
          });
        });
        resolve(response);
      }, (err) => {
        reject(err);
      });
    })
  }

  getInvoiceProductCode(expcode) {
    const requestBody = {
        "codocid": this.getDocExpressId(),
        "expcode": expcode,
        ...this.getUserDTO()
      }
      return this.postWithStatus('getInvoiceProductCode', requestBody, true);
  }

  getPackingList(mdocid) {
    const requestBody = {
      "mdocid": mdocid,
      ...this.getUserDTO()
    }

    return this.postWithStatus('getPackingList', requestBody, false);
  }

  saveInvoiceList(arrinvoicedetaildto, masterdocdto) {
    const userDTO = this.getUserDTO();

    const submitMasterDocDTO = { ...masterdocdto};
    const submitInvoiceDto = arrinvoicedetaildto.filter(invoice => !invoice.isDelete);
    this.massageInvoiceDTOBeforeSubmission(arrinvoicedetaildto, userDTO, masterdocdto.mdocid);
    masterDocService.massageMasterDocBeforeSubmission(submitMasterDocDTO, userDTO);
    submitMasterDocDTO.formid = 'INV';

    const requestBody = {
        "arrinvoicedetaildto": submitInvoiceDto,
        "masterdocdto": submitMasterDocDTO,
        ...userDTO
      }

    return this.postWithStatus('saveInvoiceList', requestBody, true);
  }

  saveNewMasterDoc(masterdocdto) {
    const requestBody = {
        "masterdocdto": masterdocdto,
        ...this.getUserDTO()
      }
      return this.postWithStatus('saveMasterDoc', requestBody, true);
  }

  saveNewInvoice(arrinvoicedetaildto, masterdocdto, metaData) {
    const userDTO = this.getUserDTO();

    masterDocService.massageMasterDocBeforeSubmission(masterdocdto, userDTO);
    masterdocdto.mdoctype = metaData.mdoctype;
    masterdocdto.mdocdesc = metaData.mdocdesc ? metaData.mdocdesc : '';
    masterdocdto.tmplmdocid = masterdocdto.mdocid;
    masterdocdto.formid = 'INV';

    const {user} = userDTO.userdto;
    return new Promise((resolve, reject) => {
      masterDocService.getMasterDocMDocID(user.codocexpressid, user.groupmemberid, '', '', user.codocexpressid, metaData.mdoctype).then(newMasterDocID => {
        masterdocdto.mdocid = newMasterDocID;
        this.massageInvoiceDTOBeforeSubmission(arrinvoicedetaildto, userDTO, newMasterDocID);
        const submitInvoiceDto = arrinvoicedetaildto.filter(invoice => !invoice.isDelete);

        const requestBody = {
          "arrinvoicedetaildto": submitInvoiceDto,
          "masterdocdto": masterdocdto,
          ...userDTO
        }

        if(masterdocdto.tmplmdocid.startsWith("TM")){
          resolve(this.post('saveNewInvoice', requestBody, true));
        }else{
          this.post('saveNewInvoice', requestBody, true).then(()=>{
            resolve(exportInstructionService.addExportInstruction(masterdocdto.eidto, masterdocdto));
          });
        }
      }, (err) => {
        reject(err);
      })

    });
  }

  saveTMPLInvoiceList(arrinvoicedetaildto, masterdocdto) {
    const requestBody = {
        "arrinvoicedetaildto": arrinvoicedetaildto,
        "masterdocdto": masterdocdto,
        ...this.getUserDTO()
      }
      return this.postWithStatus('saveTMPLInvoiceList', requestBody, true);
  }

  updateMasterDoc(masterdocdto) {
    const userDTO = this.getUserDTO();
    masterDocService.massageMasterDocBeforeSubmission(masterdocdto, userDTO);
    masterdocdto.formid = 'INV';
    this.massageInvoiceDTOBeforeSubmission(masterdocdto.invoicedetaildto, userDTO, masterdocdto.mdocid);
    const requestBody = {
        "masterdocdto": masterdocdto,
        ...userDTO
      }

    return this.postWithStatus('updateMasterDoc', requestBody, true);
  }

  // eslint-disable-next-line class-methods-use-this
  massageInvoiceDTOBeforeSubmission(invoiceDTOList, userDTO, mdocid) {

    const decimalFields = ['invqtytotal', 'invsubchrg1', 'invsubchrg2', 'invsubchrg3', 'invsubtotal', 'invtotal'];
    invoiceDTOList.forEach(invoiceDTO => {
      invoiceDTO.mdocid = mdocid;
      invoiceDTO.groupmemberid = userDTO.userdto.user.groupmemberid;
      invoiceDTO.countrycode = userDTO.userdto.user.countrycode;
      invoiceDTO.codocexpressid = userDTO.userdto.user.codocexpressid;
      if (invoiceDTO.actionBtn) {
        delete invoiceDTO.actionBtn;
      }

      formatEmptyValues(invoiceDTO, decimalFields);

      Object.keys(invoiceLabelHeadersObj).forEach(key => {
        if (isBlank(invoiceDTO[key])) {
          invoiceDTO[key] = invoiceLabelHeadersObj[key];
        }
      });

      const decimalFieldsForDetails = ['InvDetQty', 'InvDetSubTotal', 'InvDetUnitPrice'];

      invoiceDTO.invitemdetaildtolist.forEach(item => {
        item.MDOCID = invoiceDTO.mdocid;
        item.InvNo = invoiceDTO.invno;

        if (item.InvDetSubTotalDisplay) {
          delete item.InvDetSubTotalDisplay;
        }

        formatEmptyValues(item, decimalFieldsForDetails);
      })
    })
  }
}

export default new InvoiceService();
