import { Paragraph } from '@cdl/layout';
import UserMessage from 'common/components/UserMessage/UserMessage';
import Divider from "common/components/Divider/Divider";
import Footer from "common/components/Footer/Footer";
import Header from "common/components/Header/Header";
import LoadingSpinner from 'common/components/LoadingSpinner/LoadingSpinner';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from "common/services/auth.service";
import ConcurrentLogon from "common/views/Popup/ConcurrentLogon";
import {
  LOGIN_STATUSCODE_AUTHORISEDLOGON,
  LOGIN_STATUSCODE_CONCURRENTLOGON
} from 'common/utils/Constants';
import LoginForm from "common/views/LoginPage/LoginForm";
import UserService from 'common/services/user.service';
import PwdService from 'common/services/pwd.service';

function LoginPage(props) {
  const [error, setError] = useState("");
  const [maintenanceMsg, setMaintenanceMsg] = useState("");
  const [open, setOpen] = useState(false);
  const refForm = React.useRef();

  const isLoading = useSelector(state => state.loadingChange.showLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    AuthService.getCustomerMsg().then(res=>{
      setMaintenanceMsg(res);
    })
  })

  function login(user, pwd) {

    return AuthService.login(user, pwd, 0)
    .then(res => {

      if (res.status_code === LOGIN_STATUSCODE_AUTHORISEDLOGON ) {
        UserService.setCurrentUser(res);
        props.history.push('/Home');
        dispatch({ type: 'USER_LOGIN'});
      } else if (PwdService.isForceChange(res.status_code)) {
        UserService.setCurrentUser(res);
        props.history.push('/ForceChangePWD');
        dispatch({ type: 'USER_LOGIN'});
      } else if (res.status_code === LOGIN_STATUSCODE_CONCURRENTLOGON) {
        setOpen(true);
      } else {
        setError(res.status);
      }
    })
    .catch(err => {
      console.log(err);
      setError("System Error. Please contact administrator");
      dispatch({ type: 'SYS_ERR'});
    });
  }


  function submit(values) {
    return login(values.username, values.password);
  }


  const onClose = () => {
    setError("");
  }

  function close(){
      setOpen(false);
  }


  return (
    <div className="App">
      <Header />

      <div className="mainContent mainContent1">

        {error!=="" &&
        <div style={{marginTop: '20px'}}>
          <UserMessage
            showCloseButton
            border
            type="userError"
            iconName="triangle-error"
            iconColor="ERROR"
            handleCloseButtonClick={onClose}
            closeText="Close"
          >
            {error}
          </UserMessage>
        </div>}

        <h1 className="loginTitle">Welcome to DocumentExpress!</h1>
        <Paragraph>For registered <span style={{'fontFamily': 'HSBC-Universe-Medium'}}>DocumentExpress</span> users,
          enter your user name and password to logon to the system.
        </Paragraph>

        <LoginForm history={props.history} onSubmit={submit} ref={refForm} />


        <Divider />
        {maintenanceMsg.length>0 &&
        <p style={{fontSize: '14px', color: 'red'}}>
          {maintenanceMsg}
        </p>}
        <p style={{fontSize: '14px'}}>
          Click&nbsp;
          <a
            href="https://www.hsbc.com/online-security"
            target="_blank"
            rel="noopener noreferrer"
          >here
          </a> to learn more
          about how HSBC protects you online and steps you can improve your online security.
          Please note that DocumentExpress does not use digital certificates.
        </p>

        <Paragraph>
          Please note that transactions over the Internet may be subject to interruption,
          transmission blackout, delayed transmission due to Internet traffic,
          or incorrect data transmission due to the public nature of the Internet.
          HSBC cannot assume responsibility for malfunctions in communications facilities
          not under our control that may affect the accuracy or timeliness of messages you send.
        </Paragraph>

      </div>

      <Footer />

      <ConcurrentLogon
        isOpen={open}
        close={close}
        history={props.history}
        user={refForm.current && refForm.current.values.username}
        pwd={refForm.current && refForm.current.values.password}
        login={login}
      />
      <LoadingSpinner showLoading={isLoading}  />
    </div>
  );
}

export default LoginPage;
