import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import { Tooltip, TooltipContent } from '@cdl/tooltip';
import Grid from '@material-ui/core/Grid';
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import Button from "common/components/Button/Button";
import DropdownHeader from "common/components/Dropdown/DropdownHeader";
import Icon from "common/components/Icon/Icon";
import IconButton from "common/components/IconButton/IconButton";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import NumberField from "common/components/NumberField/NumberField";
import Table from 'common/components/Table/Table';
import TextArea from "common/components/TextArea/TextArea";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import invoiceService from 'common/services/invoice.service';
import { formatAmountWords } from 'common/utils/AmountFormatter';
import { invoiceLabelHeadersObj, OVERFLOW_CLASS } from 'common/utils/Constants';
import { CASE_NO, PROD_CODE, PROD_DESC, QUANTITY } from "common/utils/OptionUtil";
import { required } from 'common/utils/ReduxFormValidation';
import { isBlank } from 'common/utils/StringUtils';
import Confirm from "common/views/Popup/Confirm";
import InvoiceItemDetail from "common/views/Popup/InvoiceItemDetail";
import React from 'react';
import { connect } from 'react-redux';

const buttonArray = [{id: 'openDetail', name: 'Edit', showModal: 'showInvoiceItemDetails'},
                    {id: 'openDelete', name: 'Delete', action: 'deleteInvoiceDetails', modal: Confirm,
                     modalProps :{content: 'Do you wish to delete the invoice item "@title"?'} }
                   ];


const limitDecmialInput = (s) => {
  const rgx = /^[\d]*\.?[\d]*$/;
  return (s.match(rgx)?s:'');
}


class InvoiceItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isOpen: props.isOpen,
          data: {...props.data} || {invitemdetaildtolist: [], invsubtotal: 0, invsubchrg1: 0, invsubchrg2: 0, invsubchrg3: 0,
            labelHeaders: { ...invoiceLabelHeadersObj}
          },
          prodCodeList: [],
          errors: {}
        };
    }

    componentDidMount() {
      this.setState(prevState => {
        this.setSubcharges(prevState);
        if (prevState.data?.invitemdetaildtolist) {
          Object.keys(prevState.data.invitemdetaildtolist).forEach(key => {
            prevState.data.invitemdetaildtolist[key].InvDetQty = Number(prevState.data.invitemdetaildtolist[key].InvDetQty)
                                                                    .toFixed(this.props.masterDoc.values.qtydeccat).toString()||'';
            prevState.data.invitemdetaildtolist[key].InvDetUnitPrice = Number(prevState.data.invitemdetaildtolist[key].InvDetUnitPrice)
                                                                          .toFixed(this.props.masterDoc.values.unitpricedeccat).toString()||'';
          });

          if (!prevState.data?.labelHeaders) {
            if(prevState.data.casenoheading){
              prevState.data.labelHeaders = {
                casenoheading: prevState.data.casenoheading,
                partnoheading: prevState.data.partnoheading,
                proddescheading: prevState.data.proddescheading,
                qtynoheading: prevState.data.qtynoheading,
              };
            }else{
              prevState.data.labelHeaders = { ...invoiceLabelHeadersObj};
            }
          }
          return ({
            data: prevState.data
          })
        }
          prevState.data.invitemdetaildtolist = [];
          return ({
            data: prevState.data
          })

      });

        invoiceService.getInvoiceProductCode(this.props.masterDoc.values.exportercode).then(response => {
          if (response.statusCode === 200) {
            this.setState({
              prodCodeList: response.data.list
            })
          }
        })

    }

    componentWillUnmount() {
      document.documentElement.classList.remove(OVERFLOW_CLASS);
    }

    setSubcharges = (prevState) => {
      if (prevState.data.invsubchrg1) {
        prevState.data.invsubchrg1 = Number(prevState.data.invsubchrg1).toFixed(this.props.masterDoc.values.amtdeccat).toString() || '';
      } else {
        prevState.data.invsubchrg1 = Number(0).toFixed(this.props.masterDoc.values.amtdeccat).toString();
      }
      if(prevState.data.invsubchrg2){
        prevState.data.invsubchrg2 = Number(prevState.data.invsubchrg2).toFixed(this.props.masterDoc.values.amtdeccat).toString()||'';
      }else{
        prevState.data.invsubchrg2 = Number(0).toFixed(this.props.masterDoc.values.amtdeccat).toString()
      }
      if(prevState.data.invsubchrg3){
        prevState.data.invsubchrg3 = Number(prevState.data.invsubchrg3).toFixed(this.props.masterDoc.values.amtdeccat).toString()||'';
      }else{
        prevState.data.invsubchrg3 = Number(0).toFixed(this.props.masterDoc.values.amtdeccat).toString()
      }
    }

    addItem = () => {
        this.setState({openItem: true});
    }

    close = () => {
        this.setState({openItem: false});
    }

    showInvoiceItemDetails = (rowIndex) => {
      this.setState({
        openItem: true,
        selectedIndex: rowIndex
      })
    }

    closeItemDetails = () => {
      this.setState({
        openItem: false,
        selectedIndex: null
      })
    }

    handleChange = (event) => {
      event.persist();
      this.setState(prevState => {
          prevState.data[event.target.name] = event.target.value;
          prevState.errors[event.target.name] = !isBlank(prevState.data[event.target.name]) ? null : prevState.errors[event.target.name];
        return {
          data: prevState.data,
          errors: prevState.errors
        }
      });
    }

    handleAmountChange = (event) => {
      event.persist();
      this.setState(prevState => {
          prevState.data[event.target.name] = limitDecmialInput(event.target.value);
          prevState.errors[event.target.name] = !isBlank(prevState.data[event.target.name]) ? null : prevState.errors[event.target.name];
        return {
          data: prevState.data,
          errors: prevState.errors
        }
      });
    }

    handleSummation = (event) => {
      event.persist();
      this.setState(prevState => {
        prevState.data[event.target.name] = (event.target.value)? Number(event.target.value).toFixed(this.props.masterDoc.values.amtdeccat).toString():"";
        prevState.errors[event.target.name] = !isBlank(prevState.data[event.target.name]) ? null : prevState.errors[event.target.name];
      return {
        data: prevState.data,
        errors: prevState.errors
      }
    });
    }

    validateSubCharge = (field, desc) => {
      const val = this.state.data[field];
      const valDesc = this.state.data[desc];
      let result = true;
      if(Number(val)>0){
        const msg = required(valDesc);
        this.setState(prevState => {
          prevState.errors[desc] = msg;
          return {
          errors: prevState.errors
        }
      })
        result = (msg.length === 0);
      }
      return result;
    }

    validate = () => {
      let result = true;
      result = this.validateSubCharge('invsubchrg1', 'invsubchrg1desc');
      result = this.validateSubCharge('invsubchrg2', 'invsubchrg2desc') && result;
      result = this.validateSubCharge('invsubchrg3', 'invsubchrg3desc') && result;
      return result;
    }


    saveInvoiceItem = () => {
      if(this.validate()){
        this.setState({
          selectedIndex: null
        })

        const invsubtotal = this.state.data?.invitemdetaildtolist?.length > 0 ? this.state.data?.invitemdetaildtolist?.reduce((acc, cur) => {
          return Number(acc) + Number(cur.InvDetSubTotal || 0)
        }, 0) : 0;

        const invqtytotal = this.state.data?.invitemdetaildtolist?.length > 0 ? this.state.data?.invitemdetaildtolist?.reduce((acc, cur) => {
          return Number(acc) + Number(cur.InvDetQty || 0)
        }, 0) : 0;

        const invtotal = parseFloat(invsubtotal) + (parseFloat(this.state.data.invsubchrg1) || 0) +
        (parseFloat(this.state.data.invsubchrg2) || 0) + (parseFloat(this.state.data.invsubchrg3) || 0);

        const obj = {
          invsubtotal,
          invqtytotal,
          invsubchrg1: this.state.data.invsubchrg1,
          invsubchrg1desc: this.state.data.invsubchrg1desc,
          invsubchrg2: this.state.data.invsubchrg2,
          invsubchrg2desc: this.state.data.invsubchrg2desc,
          invsubchrg3: this.state.data.invsubchrg3,
          invsubchrg3desc: this.state.data.invsubchrg3desc,
          invtotalword: formatAmountWords(this.props.masterDoc.values.currencycode||'USD', invtotal, this.props.masterDoc.values.amtdeccat),
          invtotal,
        }

        this.props.saveInvoiceItem(this.state.data.invitemdetaildtolist, obj, this.state.data.labelHeaders);
      }
    }


    deleteInvoiceDetails = (rowIndex) => {
      this.setState(prevState => {
        prevState.data.invitemdetaildtolist = prevState.data.invitemdetaildtolist.filter((item, index) => index !== rowIndex);
        return ({
          data: prevState.data,

        });
      })
    }

    save = (invoiceItemDetails, selectedIndex) => {
      this.setState(prevState => {
        if (selectedIndex || selectedIndex === 0) {
          Object.keys(invoiceItemDetails).forEach(key => {
              prevState.data.invitemdetaildtolist[selectedIndex][key] = invoiceItemDetails[key];
              if (key === 'InvDetQty') {
                prevState.data.invqtytotal = prevState.data.invqtytotal ? Number(prevState.data.invqtytotal) + Number(invoiceItemDetails[key]) : Number(invoiceItemDetails[key]);
              } else if(key === 'InvDetSubTotal') {
                prevState.data.invsubtotal = prevState.data.invsubtotal ? Number(prevState.data.invsubtotal) + Number(invoiceItemDetails[key]) : Number(invoiceItemDetails[key]);
              }
          });
        } else {
          prevState.data.invitemdetaildtolist.push(invoiceItemDetails);
        }
          return ({
            openItem: false,
            data: prevState.data,
            selectedIndex: null,
          });
      });
    }

    changeDropHeader = (header, value) => {
      this.setState(prevState => {
        prevState.data.labelHeaders[header] = value;
        return {
          data: prevState.data
        }
      });
    }

    constructColumn = () => {
      return [
        {
            Header:  <DropdownHeader
              editable
              userInput={this.state.data?.casenoheading}
              valueChange={(value) => this.changeDropHeader('casenoheading', value)}
              onItemClick={(value) => this.changeDropHeader('casenoheading', value)}
              options={CASE_NO}
              width="65px"
            />,
            accessor: 'InvDetCaseNo',
            sortable: false,
            Cell: row => <span title={row.original.InvDetCaseNo}>{row.original.InvDetCaseNo}</span>
        },
        {
            Header: <DropdownHeader
              editable
              userInput={this.state.data?.partnoheading}
              valueChange={(value) => this.changeDropHeader('partnoheading', value)}
              onItemClick={(value) => this.changeDropHeader('partnoheading', value)}
              options={PROD_CODE}
              width='65px'
            />,
            accessor: 'InvDetProdCode',
            sortable: false,
            Cell: row => <span title={row.original.InvDetProdCode}>{row.original.InvDetProdCode}</span>
        },
        {
            Header: <DropdownHeader
              editable
              userInput={this.state.data?.proddescheading}
              valueChange={(value) => this.changeDropHeader('proddescheading', value)}
              onItemClick={(value) => this.changeDropHeader('proddescheading', value)}
              options={PROD_DESC}
              width='140px'
            />,
            accessor: 'InvDetProdDesc',
            sortable: false,
            width: 190,
            Cell: row => <span title={row.original.InvDetProdDesc}>{row.original.InvDetProdDesc}</span>
        },
        {
            Header: <DropdownHeader
              editable
              userInput={this.state.data?.qtynoheading}
              valueChange={(value) => this.changeDropHeader('qtynoheading', value)}
              onItemClick={(value) => this.changeDropHeader('qtynoheading', value)}
              options={QUANTITY}
              width='65px'
            />,
            accessor: 'InvDetQty',
            sortable: false,
            Cell: ( cell ) => (<NumberField
              displayType="text"
              decimalScale={this.props.masterDoc.values.qtydeccat}
              value={cell.original.InvDetQty}
            />)
        },
        {
            Header: 'Unit price',
            accessor: 'InvDetUnitPrice',
            sortable: false,
            Cell: ( cell ) => (<NumberField
              displayType="text"
              decimalScale={this.props.masterDoc.values.unitpricedeccat}
              value={cell.original.InvDetUnitPrice}
            />)
        },
        {
            Header: 'Amount',
            accessor: 'InvDetSubTotalDisplay',
            sortable: false,
            Cell: ( cell ) => (<NumberField
              displayType="text"
              decimalScale={this.props.masterDoc.values.amtdeccat}
              value={cell.original.InvDetSubTotalDisplay}
            />)
        },
        {
          Header: 'Action',
          accessor: 'accountNumber',
          width: 120,
          sortable: false
        }
        ]
    }

    displayNumber = (field) => {
      return parseFloat(field.toString().replace(/,/g, ""))
        .toFixed(this.props.masterDoc.values.amtdeccat)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    genTableData = () => {
      return this.state.data.invitemdetaildtolist?.map((t, i) => {

        if (!t.InvDetSubTotalDisplay) {
          t.InvDetSubTotalDisplay = 0;
        }
        if (!t.InvDetSubTotal) {
          t.InvDetSubTotal = 0;
        }
        t.InvDetSubTotalDisplay = this.displayNumber(t.InvDetSubTotal);

        let deleteTitle = t.InvDetCaseNo;
        if(t.InvDetProdCode){
          deleteTitle += ` - ${  t.InvDetProdCode}`;
        }

        const actionButton = {
          accountNumber: <ActionButtons
            rowIndex={i}
            buttonArray={buttonArray}
            deleteInvoiceDetails={this.deleteInvoiceDetails}
            showInvoiceItemDetails={this.showInvoiceItemDetails}
            justify='space-between'
            titleField={deleteTitle}
          />
        };
        return Object.assign(t, actionButton);
      }) || [];
    }

    handleInputValue = (name, val) => {
      const {data} = this.state;
      data[name] = val;
      this.setState({ data });
    }

    render(){
      const columns = this.constructColumn();
      const displayData = this.genTableData();
      const totalQuantity = this.state.data?.invitemdetaildtolist?.length > 0 ? this.state.data?.invitemdetaildtolist?.reduce((acc, cur) => {
        return Number(acc) + Number(cur.InvDetQty || 0)
      }, 0) : 0;

      const subTotal = this.state.data?.invitemdetaildtolist?.length > 0 ? this.state.data?.invitemdetaildtolist?.reduce((acc, cur) => {
        return Number(acc) + Number(cur.InvDetSubTotal || 0)
      }, 0) : 0;

      const displaySubTotal =  this.displayNumber(subTotal);

      const invoiceTotal = subTotal + (Number(this.state.data.invsubchrg1) || 0) + (Number(this.state.data.invsubchrg2) || 0) + (Number(this.state.data.invsubchrg3) || 0);
      const displayTotal =  this.displayNumber(invoiceTotal);
      const amountWord = formatAmountWords(this.props.masterDoc.values.currencycode||'USD', displayTotal, this.props.masterDoc.values.amtdeccat);


        return (
          <div>
            <Modal
              ariaLabelledby="modal-heading"
              isOpen={this.state.isOpen && !this.state.openItem}
              width={946}
            >
              <ModalHeader hasTopLine>
                <ModalTitle>Invoice item(s)</ModalTitle>
                <ModalBtnList><ModalBtn name="delete" title="close" onClick={this.props.close} /></ModalBtnList>
              </ModalHeader>
              <ModalContent>
                <Grid container spacing={0} style={{paddingRight: '10px'}}>
                  <Grid item xs={10} />
                  <Grid item xs={2}>
                    <div style={{float: 'right'}}>
                      <IconButton onClick={this.addItem} name="add">Add new item</IconButton>
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{padding: '20px 0'}}>
                    <Table
                      maxRows={displayData.length}
                      showPagination={false}
                      data={displayData}
                      columns={columns}
                      defaultPageSizeOption={{'value':0}}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={0} style={{paddingRight: '10px'}} justify="flex-end">
                  <Grid item xs={12}>
                    <NumberField
                      name="invsubtotal"
                      id="invsubtotal"
                      label="Sub total"
                      value={displaySubTotal}
                      inline
                      right
                      disabled
                      decimalScale={this.props.masterDoc.values.amtdeccat}
                    />

                    <NumberField
                      name="invqtytotal"
                      id="invqtytotal"
                      label="Total quantity"
                      value={totalQuantity}
                      inline
                      disabled
                      right
                      decimalScale={this.props.masterDoc.values.qtydeccat}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldForm
                      name="invsubchrg1"
                      id="invsubchrg1"
                      inline
                      right
                      noLabel
                      value={this.state.data.invsubchrg1}
                      onChange={(event) => this.handleAmountChange(event)}
                      onBlur={(event) => this.handleSummation(event)}
                      maxLength={14}
                    />
                    <TextFieldForm
                      inline
                      right
                      noLabel
                      id="invsubchrg1desc"
                      name="invsubchrg1desc"
                      value={this.state.data.invsubchrg1desc}
                      onChange={(event) => this.handleChange(event)}
                      inputWidth={500}
                      style={{paddingRight: '10px'}}
                      invalidMessage={this.state.errors?.invsubchrg1desc}
                      invalid={this.state.errors?.invsubchrg1desc}
                      maxLength={35}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldForm
                      name="invsubchrg2"
                      id="invsubchrg2"
                      inline
                      right
                      noLabel
                      value={this.state.data.invsubchrg2}
                      onChange={(event) => this.handleAmountChange(event)}
                      onBlur={(event) => this.handleSummation(event)}
                      maxLength={14}
                    />
                    <TextFieldForm
                      inline
                      right
                      noLabel
                      id="invsubchrg2desc"
                      name="invsubchrg2desc"
                      inputWidth={500}
                      style={{paddingRight: '10px'}}
                      value={this.state.data.invsubchrg2desc}
                      onChange={(event) => this.handleChange(event)}
                      invalidMessage={this.state.errors?.invsubchrg2desc}
                      invalid={this.state.errors?.invsubchrg2desc}
                      maxLength={35}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldForm
                      name="invsubchrg3"
                      id="invsubchrg3"
                      inline
                      right
                      noLabel
                      value={this.state.data.invsubchrg3}
                      onChange={(event) => this.handleAmountChange(event)}
                      onBlur={(event) => this.handleSummation(event)}
                      maxLength={14}
                    />
                    <TextFieldForm
                      inline
                      right
                      noLabel
                      id="invsubchrg3desc"
                      name="invsubchrg3desc"
                      inputWidth={500}
                      style={{paddingRight: '10px'}}
                      value={this.state.data.invsubchrg3desc}
                      onChange={(event) => this.handleChange(event)}
                      invalidMessage={this.state.errors?.invsubchrg3desc}
                      invalid={this.state.errors?.invsubchrg3desc}
                      maxLength={35}
                    />
                  </Grid>

                  <Grid item xs={6} />
                  <Grid item xs={6} style={{textAlign: 'right'}}>
                    <span>
                      Invoice total
                      <Tooltip style={{textAlign: 'left', paddingLeft: '5px'}}>
                        <TooltipContent direction="bottom">
                          This is a computed field - add up the <strong>Sub Total</strong> and <strong>Additional Charges</strong> (if there are any)
                        </TooltipContent>
                        <Icon name="circle-help-solid" size={25} />
                      </Tooltip>
                    </span>
                    <span style={{width: '178px', display: 'inline-block'}}>
                      <NumberField displayType="text" decimalScale={this.props.masterDoc.values.amtdeccat} value={displayTotal} />
                    </span>
                  </Grid>

                  <Grid item xs={12}>
                    <TextArea
                      label="Amount in words"
                      name="invtotalword"
                      id="invtotalword"
                      value={amountWord}
                      onChange={(event) => this.handleChange(event)}
                      fullWidth
                      maxLength={255}
                      disabled
                    />
                  </Grid>
                </Grid>
              </ModalContent>
              <ModalFooter hasBottomLine>
                <ModalAction>
                  <ModalActionExtraButton>
                    <Button onClick={this.props.close}>Cancel</Button>
                  </ModalActionExtraButton>
                  <ModalActionButton>
                    <Button onClick={this.saveInvoiceItem} themeColor="primary">Save</Button>
                  </ModalActionButton>
                </ModalAction>
              </ModalFooter>
            </Modal>
            {this.state.openItem &&
              <InvoiceItemDetail
                isOpen={this.state.openItem}
                data={displayData[this.state.selectedIndex]}
                prodCodeList={this.state.prodCodeList}
                labelHeaders={this.state.data.labelHeaders || invoiceLabelHeadersObj}
                selectedIndex={this.state.selectedIndex}
                save={this.save}
                close={this.closeItemDetails}
              />}
          </div>
        )
    }

}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc
})

export default connect(mapStateToProps, null)(InvoiceItems)
