import React from 'react';
import styled from "styled-components";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import CDLButton from '@cdl/button';
import Grid from '@material-ui/core/Grid';
import { runValidations } from 'common/utils/FieldValidation';

const ButtonContainer = styled.div`
    padding-top: ${props => props.noLabel ? '10px': '24px'};
    padding-left: 10px;   
`;

const Container = styled.div`
    flex-grow: 1;
    width: calc(100% - 100px);
    ${props => props.noLabel ? 'padding-top: 10px;': ''};

    & input:disabled{
      color: #333;
      border: 1px solid ${props => props.invalid ? '#af000d': '#767676'};
      background-color: ${props => props.invalid ? '#f9f2f3': '#F3F3F3'};
      opacity: 1;
    }

    #${props => props.name}Label {
      ${props => props.noLabel ? 'display:none': ''};
    }
`;

const magicNumMap = {
  '89504E47': 'image/png',
  '47494638': 'image/gif',
  '25504446': 'application/pdf',
  'FFD8': 'image/jpeg',
  '504B0304': 'application/zip',
  '4949': 'image/tiff',
  '4D4D': 'image/tiff',
  '424D': 'image/bmp',
  '504B34': [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ],
  'D0CF11E0': ['application/msword', 'application/vnd.ms-excel'],
  '7B5C7274': 'application/msword',
}

export default class UploadFile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: "",
            invalid: false,
            invalidMessage: "",
        };

        this.inputRef = React.createRef();
    }

    handleClick = () => {
      this.inputRef.current.click();
    }

    validate = () =>{
      const { validations } = this.props;
      const msg = runValidations(validations, this.state.value);
      if(msg.length > 0){
        this.setState({
          invalid: true,
          invalidMessage: msg
        });
      }
      return !this.state.invalid && msg.length === 0;
    }

    resetErr = () =>{
      this.setState({
        invalid: false,
        invalidMessage: "",
      });
    }

    checkFileSize = (num) => {

			if(num <= 0 || num >(1024 * 1024 * 10)) {
        this.setState({
          invalid: true,
          invalidMessage: "Maximum file size should not exceed 10MB and greater than 0KB. Please select another file.",
        });
				return false;
			}

      return true;

    }

    getBinaryType = (hex) =>{
      let type = magicNumMap[hex];
      if(!type){
        type = magicNumMap[hex.substring(0,4)];
      }
      return type;
    }

    checkMagicNumber = (filetype, ext, hex) => {
      const binaryFileType = this.getBinaryType(hex);

      if(!binaryFileType && (filetype==='text/plain' || ext?.toLowerCase()==='.csv') ){
        return true;
      }


      if(typeof binaryFileType === 'string'){
        return binaryFileType === filetype;
      }

      if(typeof binaryFileType === 'object'){
        if(ext && ext.toLowerCase()==='.msg' && hex==='D0CF11E0'){
          return true;
        }
          return binaryFileType.includes(filetype);

      }
      return false;
    }

    isInvalidExtension = (ext) => {
      if (this.props.accept) {
        const acceptType = this.props.accept.replace(/\s/g, "").split(",");

        if (!acceptType.includes(ext?.toLowerCase())) {
          this.setState({
            invalid: true,
            invalidMessage: `Only file with extension ${this.props.accept} is allowed`,
          });
          return true;
        }
      }
      return false;
    }

    isInvalidSize = (file) => {
      const fileSize = file.size;
      const maxSize = this.props.maxSize ? 1024 * this.props.maxSize : 1024 * 1024 * 10;
      if(fileSize <= 0 || fileSize > maxSize) {
        const tmp = this.props.maxSize ? `${this.props.maxSize}KB` : "10MB";
        this.setState({
          invalid: true,
          invalidMessage: `Maximum file size should not exceed ${tmp} and greater than 0KB. Please select another file.`,
        });
        return true;
      }
      return false;
    }

    isInvalidFileNameLength = (file) => {
      const fileName = file.name;

      if(this.props.fileNameLength && fileName.length > this.props.fileNameLength) {
        this.setState({
          invalid: true,
          invalidMessage: `Maximum file name length should not exceed ${this.props.fileNameLength}. Please select another file.`,
        });
        return true;
      }
      return false;
    }

    handleChange = () => {
      this.resetErr();
      const that = this;

      const { files } = this.inputRef.current;

      const fileNames = [];
      if (files && files.length > 0) {

        Array.from(files).forEach(file => {
          fileNames.push(file.name);
          const ext = `.${file.name.split('.').pop()}`;

          this.isInvalidExtension(ext);

          this.loadFile(that, ext, file);

          this.isInvalidSize(file);

          this.isInvalidFileNameLength(file);
        });

      }

      this.setState({ value: fileNames.join(", ") },
        () => {
          if (this.props.onChange) {
            this.props.onChange(this.props.name, this.state.value);
          }
        });


    }


    loadFile = (that, ext, file) => {
      const filetype = file.type ? file.type : 'Unknown/Extension missing';
      const filereader = new FileReader();
      filereader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          const uint = new Uint8Array(evt.target.result);
          const bytes = [];
          uint.forEach((byte) => {
            bytes.push(byte.toString(16));
          });

          const hex = bytes.join('').toUpperCase();

          if (!that.checkMagicNumber(filetype, ext, hex)) {
            that.setState({
              invalid: true,
              invalidMessage: "The file is invalid",
            });
            return false;
          }

        }

        return true;
      };

      const blob = file.slice(0, 4);
      filereader.readAsArrayBuffer(blob);

    }

    reset = () => {
      this.resetErr();
      this.setState({value:""});
      this.inputRef.current.value = '';
    }

    render() {
        const {label, button, invalid, invalidMessage, noLabel, name, ...rest} = this.props;

        return (
          <Grid
            container
            direction="row"
            justify="flex-end"
          >
            <Container invalid={invalid || this.state.invalid} noLabel={noLabel} name={name}>
              <TextFieldForm
                label={label}
                fullWidth
                disabled
                name={name}
                value={this.state.value}
                invalid={invalid || this.state.invalid}
                invalidMessage={invalidMessage || this.state.invalidMessage}
              />
            </Container>
            <ButtonContainer noLabel={noLabel}>
              <CDLButton
                disabled={this.props.disabled}
                themeColor="secondary"
                variant="slate"
                onClick={this.handleClick}
                size="small"
              >{button}
              </CDLButton>
              <input
                {...rest}
                ref={this.inputRef}
                type="file"
                style={{display:'none'}}
                name="icon-button-file"
                onChange={this.handleChange}
              />
            </ButtonContainer>
          </Grid>
        );
    }
}

