import UserService from 'common/services/user.service';
import BaseService from 'common/services/BaseService';
import { masterDocCommonMandatoryFields } from 'common/utils/DocPrepConstant';

class DrawerService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/DrawerBO/';
  }


  // eslint-disable-next-line class-methods-use-this
  getDummiedPropsFromDrawer() {
    const dummiedProps = {};
    masterDocCommonMandatoryFields.forEach(element => {
      dummiedProps[element] = 0;
    });
    return {
      "userupdate": "1",
      ...dummiedProps,
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getExtraPropsFromUserDTO() {
    const currentUser = UserService.getCurrentUser();
    return {
      "codocexpressid": currentUser.codocexpressid,
      "countrycode": currentUser.countrycode,
      "groupmemberid": currentUser.groupmemberid,
    }
  }

  // eslint-disable-next-line class-methods-use-this
  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  addDrawer(formData) {
    const userDTO = this.getUserDTO();
    const extraPropsFromUser = this.getExtraPropsFromUserDTO();
    const dummiedProps = {};
    masterDocCommonMandatoryFields.forEach(element => {
      dummiedProps[element] = parseInt(formData[element] || 0, 10);
    });

    const requestBody = {
      "drawer": {
        ...formData,
        ...extraPropsFromUser,
        ...dummiedProps,
        "tradegatecustomsid": formData?.tradegatecustomsid ? formData.tradegatecustomsid : null,
      },
      ...userDTO
    }
    return this.post('addDrawer', requestBody, true);
  }

  deleteDrawer(drawerDTO) {
    const userDTO = this.getUserDTO();
    const extraPropsFromUser = this.getExtraPropsFromUserDTO();
    const dummiedPropsFromDrawer = this.getDummiedPropsFromDrawer();
    const keysum = Object.keys(drawerDTO);
    keysum.pop();
    const obj = keysum.reduce((i, v) => {
      i[v] = drawerDTO[v];
      return i;
    }, {});
    const requestBody = {
      "drawer": {
        ...obj,
        ...extraPropsFromUser,
        ...dummiedPropsFromDrawer
      },
      ...userDTO
    }

    return this.post('deleteDrawer', requestBody, true);
  }

  getDrawer(selectedID) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      "id": selectedID,
      ...userDTO
    }
    return this.post('getDrawer', requestBody, true);
  }

  loadDrawer() {
    return this.post('loadDrawer', this.getUserDTO(), true);
  }

  saveDrawer(drawerDTO) {
    const userDTO = this.getUserDTO();
    const extraPropsFromUser = this.getExtraPropsFromUserDTO();

    const requestBody = {
      "drawer": {
        ...drawerDTO,
        ...extraPropsFromUser
      },
      ...userDTO
    }
    return this.post('saveDrawer', requestBody, true);
  }

}

export default new DrawerService();
