import Grid from '@material-ui/core/Grid';
import ActionBar from "common/components/ActionBar/ActionBar";
import DocInfo from "common/components/DocInfo/DocInfo";
import PageHeader from "common/components/PageHeader/PageHeader";
import Tab from 'common/components/Tab/Tab';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import UserMessage from 'common/components/UserMessage/UserMessage';
import { setCountryList, setMasterDoc, setPreviousPage, showMasterDocMessage } from 'common/redux/actions';
import masterDocService from 'common/services/masterDoc.service';
import ModuleService from "common/services/module.service";
import React from 'react';
import { connect } from 'react-redux';
import { detectChanges } from 'common/utils/MasterDocUtil';
import MasterDocForm from './MasterDocForm';


class MasterDocument extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
      this.linkClick = this.linkClick.bind(this);
      if (!this.props.masterDoc || !this.props.masterDoc.initial) {
        this.props.setMasterDoc({});
        masterDocService.initMasterDoc().then(response => {
          this.props.setMasterDoc(response);
        })
      }

    }

    componentDidMount() {
      masterDocService.getMasterDocCountry().then(res => {
        if (res && res.list) {
          const countryArr = res.list.map(c => {
            return  {label: c.countryName, value: c.countryName}
          })
          this.props.setCountryList(countryArr);
        }
      })
    }

    handleInputValue = (name, val) => {
        this.setState({ [name]: val });
    }


    linkClick = (targetUrl) => {

      if (this.props.location.pathname !== targetUrl) {
        detectChanges( ()=>{
          this.props.history.push(targetUrl)
        } );
      } else {
        this.props.history.push(targetUrl);
      }
    }

    closeMessage = () => {
      this.props.showMasterDocMessage({isShowMessage: false, masterDocMessage: ''});
    }


    render(){
        const tabs = ModuleService.getTabs(this.props.module);
        return (
          <div>
            <PageHeader title={this.props.title} />
            <div className="mainContent">
              <Tab tabs={tabs} click={this.linkClick} />
              <Grid container spacing={0}>
                {this.props.isShowMessage &&
                  <Grid item xs={12} style={{paddingTop: '20px'}}>
                    {!this.props.messageType &&
                    <SuccessMessage message={this.props.masterDocMessage} handleCloseButtonClick={this.closeMessage} />}

                    {this.props.messageType === 'ERROR' &&
                    <UserMessage
                      showCloseButton
                      border
                      type="userError"
                      iconName="triangle-error"
                      iconColor="ERROR"
                      handleCloseButtonClick={this.closeMessage}
                      closeText="Close"
                    >
                      {this.props.masterDocMessage}
                    </UserMessage>}

                  </Grid>}
                <Grid item xs={12}>
                  <DocInfo />
                </Grid>
              </Grid>
              <MasterDocForm tabs={tabs} />


            </div>
            <ActionBar formData={this.state} history={this.props.history} handleInputValue={this.props.handleInputValue} />

          </div>


        )
    }
}


const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc,
  isShowMessage: state.masterDocAction?.isShowMessage,
  masterDocMessage: state.masterDocAction?.masterDocMessage,
  messageType: state.masterDocAction.messageType,
})

const mapDispatchToProps = dispatch => {
  return {
    setMasterDoc: (data) => dispatch(setMasterDoc(data)),
    setCountryList: (data) => dispatch(setCountryList(data)),
    showMasterDocMessage: (data) => dispatch(showMasterDocMessage(data)),
    setPreviousPage: (payload) => dispatch(setPreviousPage(payload)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MasterDocument)
