import BaseService from 'common/services/BaseService';


class FileService extends BaseService {

    upload(formData, uploadPath){
        const path = uploadPath || '/GENERICupload.htm';
        return this.axiosPost(path, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    }

    // eslint-disable-next-line class-methods-use-this
    download(url){
        window.open(url);
    }
}

export default new FileService();
