import React from "react";
import styled from "styled-components";
import { ModalFooter as ModalFooterCDL } from '@cdl/modal';

const Container = styled(ModalFooterCDL)`
    margin-bottom: 20px;
`;


export default class ModalFooter extends React.PureComponent {
  render() {
    return (

      <Container {...this.props} />

    );
  }
}

