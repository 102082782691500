/* eslint-disable react/jsx-indent */
import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import CDLRadioGroup, { Radio } from '@cdl/radio-group';
import Grid from '@material-ui/core/Grid';
import Autocomplete from "common/components/Autocomplete/Autocomplete";
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import TextArea from "common/components/TextArea/TextArea";
import { getOption } from 'common/utils/OptionUtil';
import { required } from 'common/utils/ReduxFormValidation';
import React from 'react';

const discrepancyInit = {
  "comments": "",
  "dateupdate": "",
  "discrepancy": "",
  "fixcomments": "",
  "fixedby": "",
  "fixedon": "",
  "history": "",
  "id": "",
  "isgeneralcomment": "",
  "userroles": "",
  "userupdate": ""
}

const _ = require('lodash');

export default class CommentDiscrepancy extends React.Component {
    constructor(props) {
      console.log(props.data);
      super(props);
      this.state = {
        data: ({ ...props.data}) ||  _.cloneDeep(discrepancyInit),
        isEdit: props.data && Object.keys(props.data).length > 0,
        isComment: props.isComment || false,
        viewMode: props.viewMode || false,
      };
      this.toggleChecked = this.toggleChecked.bind(this);
      this.refDiscrepancy = React.createRef();
      this.refComment = React.createRef();
      this.discrepancyOpt = getOption('getDiscrepancy');
    }

    toggleChecked = () => this.setState(prevState => ({
      isComment: !prevState.isComment,
      data: _.cloneDeep(discrepancyInit),
    }));

    handleChange = (event) => {
      const {data} = this.state;
      data[event.target.name] = event.target.value
      console.log(data);
      this.setState({ data });
    }

    handleInputValue = (name, val) => {
      const {data} = this.state;
      data[name] = val;
      this.setState({ data });
    }

    save = () => {
      console.log(this.state.data);
      let isValid = true;
      if(!this.state.isComment && !this.props.isFix){
        isValid = this.refDiscrepancy.current.validate();
      }

      if(this.props.isFix || this.state.isComment){
        isValid = this.refComment.current.validate();
      }

      if(isValid){
        const dataObj = {};
        Object.keys(discrepancyInit).forEach(key => {
            dataObj[key] = this.state.data[key];
        });

        if(this.props.isFix){
          dataObj.comments = dataObj.fixcomments;
          this.props.fix(dataObj);
        }else{
          this.props.save(dataObj);
        }

      }

    }


    render(){
      console.log(this.props);
      let contents = "";
      if(this.props.isFix){
        contents = <Grid item xs={12}>
          <TextArea
            name="fixcomments"
            maxLength={1500}
            label="Comment*"
            value={this.state.data?.fixcomments}
            handleChange={this.handleChange}
            validations={["required"]}
            ref={this.refComment}
          />
                   </Grid>;
      }else if(this.state.isComment){
        contents = <Grid item xs={12}>
                      <TextArea
                        name="comments"
                        maxLength={1500}
                        label="Comment*"
                        value={this.state.data?.comments}
                        handleChange={this.handleChange}
                        validations={["required"]}
                        ref={this.refComment}
                      />
                   </Grid>;
      }else{
        contents = <>
                      <Grid item xs={6}>
                        <Autocomplete
                          label="Discrepancy*"
                          id="discrepancy"
                          name="discrepancy"
                          validate={required}
                          userInput={this.state.data?.discrepancy}
                          onChange={value => this.handleInputValue('discrepancy', value)}
                          validations={["required"]}
                          ref={this.refDiscrepancy}
                          options={this.discrepancyOpt}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextArea
                          name="comments"
                          maxLength={1500}
                          label="Discrepancy remarks"
                          value={this.state.data?.comments}
                          handleChange={this.handleChange}
                        />
                      </Grid>
                   </>
      }

      let title = "New comment / discrepancy";
      if(this.state.viewMode){
        if(this.state.isComment){
          title = "View comment";
        }else{
          title = "View discrepancy";
        }
      }else if(this.state.isEdit){
        title = "Discrepancy fix";
      }

      return (
        <div>
          <Modal
            ariaLabelledby="modal-heading"
            isOpen={this.props.isOpen}
            height={390}
            width={560}
          >
            <ModalHeader hasTopLine>
              <ModalTitle>{title}</ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={this.props.close} />
              </ModalBtnList>

            </ModalHeader>
            <ModalContent>
              <Grid container spacing={0}>
                {!this.props.isFix &&
                <Grid item xs={12}>
                  <CDLRadioGroup name="cbGeneralComment">
                    <Radio
                      id="discrepancyRadio"
                      label="Discrepancy"
                      value="discrepancy"
                      defaultChecked={!this.state.isComment}
                      onChange={this.toggleChecked}
                    />
                    <Radio
                      id="commentRadio"
                      label="Genaral comment"
                      value="comment"
                      defaultChecked={this.state.isComment}
                      onChange={this.toggleChecked}
                    />
                  </CDLRadioGroup>
                </Grid>}

                {contents}

              </Grid>
            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>
                <ModalActionExtraButton>
                  <Button onClick={this.props.close}>Cancel</Button>
                </ModalActionExtraButton>
                <ModalActionButton>
                  <Button onClick={this.save} disabled={this.state.viewMode} themeColor="primary">Save</Button>
                </ModalActionButton>
              </ModalAction>
            </ModalFooter>
          </Modal>
        </div>
      )
    }
}
