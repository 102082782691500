import CDLButton from '@cdl/button';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import styled from "styled-components";

const Container = styled.div`
    div.ActionButtons div {
        box-sizing: content-box;
    }    
`;

export default class ActionButtons extends React.PureComponent{
    constructor(props) {
        super(props);
        this.state = {};
    }

      closeModal = (modal) => {
        this.setState({
          [modal]: false
        });
      }

      showModal = (button) => {
        if (button.modal) {
          this.setState({
            [button.id]: true
          });
        } else {
          this.props[button.showModal](this.props.rowIndex);
        }

      }

      confirmModal = (button) => {
        this.setState({
          [button.id]: false
        });

        this.props[button.action](this.props.rowIndex);
      }

      handleClick = (button) =>{
        if(button.onClick){
          button.onClick();
        }else{
          this.showModal(button);
        }
      }

    render() {
        const that = this;
        return (
          <Grid
            container
            direction="row"
            justify={this.props.justify}
            alignItems="center"
          >
            {
                    this.props.buttonArray.map(function(button) {

                        const key = that.state[button.id];
                        return (
                          <React.Fragment key={button.id}>
                            <CDLButton size="small" disabled={button.disabled} onClick={() => that.handleClick(button)} plain style={{paddingRight: '10px', fontSize: '12px'}}>
                              {button.name}
                            </CDLButton>
                            { button.modal && key &&
                            <Container className="ActionButtons">
                                {React.createElement(button.modal, {isOpen: key, close: that.closeModal.bind(that, button.id),
                                 confirm: that.confirmModal.bind(that, button),
                                 titleField: that.props.titleField,
                                 ...button.modalProps })}
                            </Container>}
                          </React.Fragment>)
                    })
                }

          </Grid>
        )
    }
}
