import DatePicker from 'common/components/DatePicker/DatePicker';
import React from 'react';

const renderDatePicker = ({ input, label, name, dateFormat, id, disabled, meta: { error } }) => {
  if(input.value === 'Invalid date'){
    input.value = '';
  }
  return <DatePicker
    label={label}
    name={name}
    id={id}
    invalid={error}
    invalidMessage={error}
    dateFormat={dateFormat}
    disabled={disabled}
    {...input}
  />
}
export default renderDatePicker
