import React, { Component } from "react";
import styled from "styled-components";
import CDLIcon from '@cdl/icon';
import { Link } from 'react-router-dom';
import UserService from 'common/services/user.service';
import { detectChanges } from 'common/utils/MasterDocUtil';

const Container = styled.div`
    margin-right: 30px;
    float: left;
    font-size: 12px;
    text-align: right;
    cursor: pointer;
`;

const UserInfoIcon = styled.div`
    float: left;
    padding-left: 5px;
    line-height: 32px;
`;

const HeaderMenu = styled.div`
    position: fixed;
    top: 60px;
    z-index: 100;
    background-color: #344049;
    padding: 12px 20px;
    width: 170px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);

    & > a{
        color: #D7D8D6;
        text-decoration: none;
    }

    &:hover{
        background-color: #253038;
    }

    & > a:hover{
        color: #fff;
        text-decoration: underline;
    }
`;


class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }


  toggle = () => {
    this.setState(prevState => ({
        isOpen: !prevState.isOpen
    }));
  }

  handleClick = (event) => {
    event.preventDefault();
    detectChanges( ()=>{
      this.props.history.push('/ChangePWD')
    } );
  }

  render() {
    const {
        toggle,
        state: { isOpen }
      } = this;
    const iconName = isOpen ? 'chevron-up' : 'chevron-down';
    const menuItem = isOpen ? (
      <HeaderMenu onClick={(e) => {e.stopPropagation()}}>
        <Link to="/ChangePWD" onClick={this.handleClick}>
          Change Password
        </Link>
      </HeaderMenu>) : '';
    const currentUser = UserService.getCurrentUser();

    return (
      <Container onClick={toggle}>
        <div style={{float: 'left'}}>
          {currentUser &&
            <span>
              {currentUser.fullname}
              <br />Last login: {currentUser.lastlogindatetime}
            </span>}
        </div>
        <UserInfoIcon>
          <CDLIcon id="iconDown" name={iconName} color="White" />
        </UserInfoIcon>
        {menuItem}
      </Container>

    );
  }
}

export default UserInfo;
