import DropdownCommon from 'common/components/Dropdown/DropdownCommon';
import React from 'react';

const renderDropdownCommon = ({ input, label, name, selectWidth, setValue, options, id, meta: { error } }) => (
  <DropdownCommon
    labelOutside={label}
    selectWidth={selectWidth}
    options={options}
    setValue={setValue}
    invalid={error}
    touched={error}
    id={id}
    name={name}
    {...input}
  />
  )

export default renderDropdownCommon
