import FileService from 'common/services/file.service';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Button from "common/components/Button/Button";
import Heading from "common/components/Heading/Heading";
import ReportType from 'common/views/CommonPage/Sections/ReportType';
import Criteria from 'common/components/Criteria/Criteria';
import UserService from 'common/services/user.service';
import UserActLogService from 'common/services/userActLog.service';
import DatetimeSection from 'common/views/CommonPage/Sections/DatetimeSection';


export default class UserActLog extends React.Component
{
  constructor(props) {
    super(props);

    this.state = {
      reportTypeList: [],
      userNameList: [],
      companyList: [],
      reportID : '',
      reportTitle : "",
      byUserName : false,
      byCompany : false,
      company: '',
      username: '',
      country: '',
    }

    this.datetimeRef = React.createRef();
  }


  componentDidMount() {
    this.getReportType();
    const user = UserService.getCurrentUser()
    const userRole = user.userroles;
    this.setState({
      country: user.countrycode + user.groupmemberid,
    })
    if(!(userRole==='RA' || userRole==='RU')){
      this.setState({
        country: user.countrycode + user.groupmemberid,
        company: user.codocexpressid,
      })
    }
  }

  setDropdownOptions = (value) => {
    if (this.isShowCountry()) {
      UserActLogService.getCountryList(value.reportRole).then(res => {
        if (res && res.list) {
          const options = [];
          res.list.forEach(element => {
            options.push({ "label": element, "value": element });
          });
          this.setState({
            countryOpt: options,
            country: options[0].value,
          });

        }
      });
    }
    if (this.isShowCompany()) {
      this.getCompany();
    }
  }

  handleDropdown = (dropDownId, value) => {
    if(dropDownId === 'reportType'){
      this.setState({
        reportID : value.value,
        reportTitle: value.label,
        reportType: value.reportType,
        reportUserRole: value.reportRole,
        byUserName: false,
        byCompany: false,
        company: '',
        username: '',
      },
      ()=>{
        this.setDropdownOptions(value);
      });
    }else if(dropDownId === 'country'){
      this.setState({
        country : value
      },
      this.getUserList);
    }else if(dropDownId === 'company'){
      this.setState({
        company : value
      },
      this.getUserList);
    }else{
      this.setState({
        [dropDownId] : value
      });
    }
  }

  handleChange = (e) => {
    const {id, checked} = e.target;
    this.setState({
      [id]: checked,
    })

    if(!checked){
      if(id==='byCompany'){
        this.setState({
          company: '',
        })
      }else if(id==='byUserName'){
        this.setState({
          username: '',
        })
      }
    }

    setTimeout(this.getUserList, 200);
  }


  getCompany = () =>{

      UserActLogService.getCompany(this.state.country).then(response => {
        if (response && typeof response !== 'undefined' && response.list.length > 0) {
            let index = 0;
            const tempCompanyList = [];
            for(; index < response.list.length; ++index)
            {
              const tempObject = {label : response.list[index].display, value: response.list[index].coDocExpressID}
              tempCompanyList.push(tempObject);
            }

            this.setState(
              {
                companyList : tempCompanyList,
                company: '',
              }
            );
        }

      }).catch(err => {
        throw err;
      });

  }

  getCompanyCode = () =>{
    let companyCode = this.state.company;
    const user = UserService.getCurrentUser()
    const userRole = user.userroles;
    if(userRole==='BA'){
      companyCode = user.codocexpressid;
    }
    return companyCode;
  }

  getUserList = () =>{


      const {reportUserRole, country} = this.state;
      const getUserListDTO ={"scompanycode": this.getCompanyCode(), "scountrycode" : country, "susertype": reportUserRole};
      UserActLogService.getUserList(getUserListDTO).then(res => {
        if (res && res.list) {
          const options = [];
          res.list.forEach(element => {
            options.push({ "label": element, "value": element });
          });
          this.setState({
            userNameList: options,
            username: '',
          });
        }
      }).catch(err => {
        throw err;
      });

  }

  getReportType = () =>{
    UserActLogService.getReportType().then(response => {
      if (response) {
        let index = 0;
        if( typeof response !== 'undefined' && typeof response.list !== 'undefined' && response.list.length > 0)
        {
          const tempReportTypeList = [];
          for(; index< response.list.length; ++index) {
            const reportListObject = response.list[index];
            const tempReportTypeObject = {label : reportListObject.reportName, value :reportListObject.reportID,
                                           reportRole : reportListObject.reportRole, reportType : reportListObject.reportType}
            tempReportTypeList.push(tempReportTypeObject);
          }

          this.setState(
            {
              reportTypeList : tempReportTypeList
            }
          );
        }
      }
    }).catch(err => {
      throw err;
    });
  }


  generateReport = () =>{
      const {reportType, username, reportTitle, country, reportUserRole} = this.state;


      const activityReportDto = {
        "scodocexpressid" : this.getCompanyCode(),
        "sreportname": reportTitle,
        "sroleid" : reportUserRole,
        "srpttype" : reportType,
        "susername" : username,
        "sdbname": reportUserRole==='RA' ? "REGIONAL" : country,
        ...this.datetimeRef.current.getDatetime()
      };

      UserActLogService.generateActivityReport(activityReportDto).then(response => {
        if (response && response !== 'Error'){
            FileService.download(response);
        }
      }).catch(err => {
        throw err;
      });
  }

  getCurrentUserRole = () => {
    const user = UserService.getCurrentUser();
    return user.userroles;
  }

  isShowCountry = () => {
    const {reportID} = this.state;
    const userRole = this.getCurrentUserRole();
    return (userRole==='RA' || userRole==='RU') && (reportID==='CAUActLog' || reportID==='BAActLog' || reportID==='BUActLog');
  }

  isShowCompany = () => {
    const {reportID} = this.state;
    const userRole = this.getCurrentUserRole();
    if(userRole==='RA' || userRole==='RU' || userRole==='CA' || userRole==='CU'){
      return (reportID==='BAActLog' || reportID==='BUActLog')
    }
    return false;
  }

  render() {

      const {countryOpt, reportID, username, byUserName, byCompany, company, country} = this.state;
      const showCountry = this.isShowCountry();
      const showCompany = this.isShowCompany();
      let enableReport = true;
      if(reportID===''){
        enableReport = false;
      }
      if(showCountry && country===''){
        enableReport = false;
      }
      if(byCompany && company === ''){
        enableReport = false;
      }
      if(byUserName && username === ''){
        enableReport = false;
      }
      return (
        <div>
          <Grid container spacing={0}>
            <ReportType
              reportOpt={this.state.reportTypeList}
              countryOpt={countryOpt}
              reportType={reportID}
              changeReport={(value) => this.handleDropdown("reportType", value)}
              changeCountry={(value) => this.handleDropdown("country", value)}
              country={country}
              showCountry={showCountry}
              returnOption
            />

            <Grid item xs={12}>
              <Heading level={2} brandbar><span className="medium">Report criteria</span></Heading>
            </Grid>


            {showCompany &&
              <Criteria
                label="By company"
                id="byCompany"
                idDropdown="company"
                options={this.state.companyList}
                value={company}
                disabled={!byCompany}
                changeCheckbox={this.handleChange}
                changeDropdown={(value) => this.handleDropdown("company", value)}
              />}

            <Criteria
              label="By user name"
              id="byUserName"
              idDropdown="userName"
              options={this.state.userNameList}
              value={username}
              disabled={!byUserName}
              changeCheckbox={this.handleChange}
              changeDropdown={(value) => this.handleDropdown("username", value)}
              disabledCheckbox={!this.state.reportType}
              checked={byUserName}
            />

          </Grid>

          <DatetimeSection ref={this.datetimeRef} defaultType="byDate" />

          <Grid>
            <Button themeColor="primary" onClick={this.generateReport} disabled={!enableReport}>Generate report</Button>
          </Grid>
        </div>

      );

    }

}
