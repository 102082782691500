import store from 'common/redux/store';
import UserService from 'common/services/user.service';
import { SUB_MODULES } from 'common/utils/Constants';
import { moduleMap } from "de/Constants";

class ModuleService {
    // eslint-disable-next-line class-methods-use-this
    getRedirent(item) {
        let redirect = {};
        const state = store.getState();
        if (item.forms && item.forms.length > 0) {
            const prevPage = item.name === 'GenericDocPrep' ? state.masterDocAction?.previousPage : null;
            redirect = { redirect: `/${item.name}/${prevPage || item.forms[0].name}` };
        }
        return redirect;
    }


    getModules() {
        const modules = [];
        const user = UserService.getCurrentUser();
        if(user && user.modules){
            user.modules.forEach(item => {
                if(item.name !== "ChangePassword"){
                    const component = moduleMap[item.name]?.component;
                    if(component){
                        const redirect = this.getRedirent(item);
                        modules.push({
                            name: item.name,
                            title: item.description,
                            component,
                            url: `/${  item.name}`,
                            forms: item.forms,
                            ...redirect
                        });
                    }
                }
            });
        }
        return modules;
    }

    // eslint-disable-next-line class-methods-use-this
    getSubModules() {
        const subModules = [];
        const user = UserService.getCurrentUser();
        if(user && user.submodules){
            user.submodules.forEach(item => {
                subModules.push(item.name);
            });
        }
        return subModules;
    }

    hasLFEDSModule(){
        const submodules = this.getSubModules();
        return submodules.includes(SUB_MODULES.LIFUNG_EDS)
                || submodules.includes(SUB_MODULES.EDS_WORKFLOW)
                || submodules.includes(SUB_MODULES.EDS_WORKFLOW_2_LEVEL);
    }

    isLFEDS(){
        const submodules = this.getSubModules();
        return submodules.includes(SUB_MODULES.LIFUNG_EDS);
    }

    hasSettlement(){
        const submodules = this.getSubModules();
        return submodules.includes(SUB_MODULES.SETTLEMENT);
    }

    isGenericEDS(){
        const submodules = this.getSubModules();
        return submodules.includes(SUB_MODULES.EDS);
    }

    isEDSWorkflow(){
        const submodules = this.getSubModules();
        return submodules.includes(SUB_MODULES.EDS_WORKFLOW)
                || submodules.includes(SUB_MODULES.EDS_WORKFLOW_2_LEVEL);
    }

    is2LevelWorkflow(){
        const submodules = this.getSubModules();
        return submodules.includes(SUB_MODULES.EDS_WORKFLOW_2_LEVEL);
    }

    isFinancedEDS(){
        const submodules = this.getSubModules();
        return submodules.includes(SUB_MODULES.EDS_FINANCED);
    }


    getNavLinks() {
        const navLinks = []

        this.getModules().forEach(item => {
            navLinks.push({
                title: item.title,
                url: item.url
            });
        });

        return navLinks;
    }

    getTabs(moduleName) {
        const tabMap = {};
        this.getModules().forEach(module => {
            if(module.forms){
                const tabs = [];
                module.forms.forEach(form => {
                    const formComponet = moduleMap[module.name]?.forms[form.name];
                    if(formComponet){
                        tabs.push({
                            title: form.description,
                            url: `/${ module.name }/${form.name}`,
                            name: form.name,
                            component: formComponet
                        });
                    }else{
                        console.log(`${module.name  } - ${ form.name } Not implement`);
                    }

                });
                tabMap[module.name] = tabs;
            }
        });


        return tabMap[moduleName];
    }


}

export default new ModuleService();


