import CDLIcon from "@cdl/icon";
import React from "react";
import styled from "styled-components";


const Container = styled.div`
    border: ${props => props.noBorder ? 'none' : '1px solid #333'};
    padding: ${props =>props.noBorder ? '1px 1px' : '1px 15px'};
    display: inline-block;
    cursor: pointer;
    box-sizing: border-box;
    background: ${props => props.transparent ? 'transparent' : 'white'};

    &.disabled{
      cursor: not-allowed;
      border: 1px solid #929292;
    }

    &:hover {
      background-color: #ededed;
    }

    &.disabled > svg > path{
      fill: gray;
    }
`;

const Button = styled.button`
    font-family: HSBC-Universe;
    font-size: 12px;
    height: 28px;
    line-height: 28px;
    padding: ${props => props.haveIcon ? '0 0 0 10px' : '0'};
    display: inline-block;    
    margin: 0;
    white-space: nowrap;
    border: none;
    background: transparent;
    outline: none;
    cursor: pointer;

    &:hover {
      background-color: #ededed;
    }

    &:disabled{
      cursor: not-allowed;
    }
`;


export default class IconButton extends React.PureComponent {


  render() {
    const {onClick, disabled, children, name} = this.props;
    let className = "";
    let handleClick;
    if(disabled){
      className = "disabled";
      handleClick = null;
    }else{
      handleClick = onClick;
    }

    return (
      <Container onClick={handleClick} noBorder={this.props.noBorder} className={className}>
        {name &&
          <CDLIcon name={name} size={25} />}
        <Button transparent={this.props.transparent} disabled={disabled} haveIcon={name}><span>{children}</span></Button>
      </Container>

    );
}

}
