import React from 'react';
import styled from "styled-components";
import CDLCollapsible from '@cdl/collapsible';
import $ from "jquery";
import { connect } from "react-redux";
import { getStatus } from 'common/utils/StatusUtil';


const CollapsibleContainer = styled.div`

    & > div > div:first-child {
        padding-top: 22px;
        padding-bottom: 20px;
        padding-right: 20px;

        > div:first-child {
            > div:first-child {
                font-size: 20px;
                font-family: "HSBC-Universe-Medium";
            }
        }

        > div:last-child {
            padding-right: 5px;
            padding-left: 25px;
            height: 34px;
            border-left: 1px solid #333;
        }

    }

    
`;

class Collapsible extends React.Component {
    componentDidMount() {
        $(".collapsible").hover(()=>{
            $(".collapsible > div > div > div > div").css("text-decoration", "none");
        });
    }

    render() {
        const {masterDocProps, section, children, label, className, updatedStatus} = this.props;

        let status = null;

        if(section){
            if(updatedStatus){
                status = updatedStatus
            }else{
                status = getStatus(section, masterDocProps);
            }
        }
        return (
          <CollapsibleContainer className={className}>
            <CDLCollapsible status={status} label={label}>
              {children}
            </CDLCollapsible>
          </CollapsibleContainer>
        );
    }
}

const mapStateToProps = state => ({
    masterDocProps: state.form?.masterDoc,
})

export default connect(mapStateToProps)(Collapsible)
