import React from 'react';
import Icon from "common/components/Icon/Icon";
import styled from 'styled-components';

const Container = styled.div`
    font-size: 14px;
`;

const Label = styled.div`
    padding-bottom: 10px;
`;

export default class ReadonlyField extends React.PureComponent {

    render() {
        const val = this.props.value ? this.props.value : '';
        return (
          <Container>
            <Label>{this.props.label}</Label>
            <strong>{val}</strong>
            {this.props.icon &&
            <span style={{paddingLeft:'10px'}}><Icon name="edit" size={18} /></span>}
          </Container>
        );
    }
}

