import CDLIcon from "@cdl/icon";
import React from 'react';

export const masterDocCommonMandatoryFields = ['unitpricedeccat', 'amtdeccat', 'qtydeccat', 'noofpkgdeccat',
 'grossmassdeccat', 'netmassdeccat', 'cubicmassdeccat', 'interestratedeccat'];

export const masterDocExporterImporterMandatoryFields = ['exportercode', 'importercode'];

export const masterDocBankConsigneeMandatoryFields = [];

export const masterDocOtherInformationMandatoryFields = [
    'transportationmode', 'portloading', 'portdischarge', 'countryoforigin', 'finaldestination', 'commoditydesc'
];

export const masterDocOtherLCMandatoryFields = [
    'commoditydesc'
];

export const billOfExchangeFields = ['noofboe', 'boedate', 'paytotheorderof', 'forandonbehalfof', 'boevalue'];

export const exportInstructionsFields = ['eitype'];

export const masterDocExporterImporterMandatoryFieldsForEDS = ['exporterCode', 'importerCode', 'expRef', 'expAcctNo'];

export const masterDocBankConsigneeMandatoryFieldsForEDS = [];

export const masterDocOtherInformationMandatoryFieldsForEDS = [
    'currencyCode', 'transportationMode',
    'portLoading', 'portDischarge', 'countryOfOrigin', 'finalDestination', 'commodityDesc'
];

export const masterDocOtherLCMandatoryFieldsForEDS = [
    'currencyCode', 'commodityDesc'
];

export const exportInstructionsFieldsForEDS = ['EIType'];


// all fields
export const masterDocCommonFields = [...masterDocCommonMandatoryFields, ['invaddinfo1', 'pladdinfo1', 'bcaddinfo1', 'boeaddinfo1']];

export const masterDocExporterImporterFields = [...masterDocExporterImporterMandatoryFields, ['expname', 'expcontact', 'expref']];

export const masterDocOtherInformationFields = [...masterDocOtherInformationMandatoryFields, 'countryOri'];

export const conditionalBankFields = ['bankname'];

export const conditionalOtherFields = ['dcno', 'dcdate', 'vesselname', 'voyage', 'tenorid', 'commoditydesc', 'invgrandtotal', 'billamt'];

export const conditionalExporterFields = ['expemail', 'impemail'];

export const conditionalExporterFieldsForEDS = ['expEmail', 'impEmail'];

export const conditionalBankFieldsForEDS = ['bankName'];

export const conditionalOtherFieldsForEDS = ['DCNo', 'DCDate', 'VesselName', 'Voyage', 'tenorID', 'CommodityDesc', 'InvGrandTotal', 'billamt'];

export const EDSFields = ['dcno', 'dcdate'];

export function getFormSubmissionApi(tabName) {
    switch(tabName) {
        case 'MasterDoc':
            return '/DocExpress2/api/MasterDoc/';
        case 'Invoice':
            return '/DocExpress2/api/Invoice/';
        case 'PackingList':
            return '/DocExpress2/api/PackingList/';
        case 'Beneficiary':
            return '/DocExpress2/api/Beneficiary/';
        case 'BillExchange':
            return '/DocExpress2/api/BillExchange/';
        case 'Export':
            return '/DocExpress2/api/Export/';
        default:
            return null;
    }
}


export const masterDocDefaultValue = {
    unitpricedeccat : '3',
    amtdeccat : '2',
    qtydeccat : '2',
    noofpkgdeccat : '0',
    grossmassdeccat : '2',
    netmassdeccat : '2',
    cubicmassdeccat : '2',
    interestratedeccat : '4',
    verno: '1.0',
    status: 'PENDING',
    invoicedetaildto : [],
    billofexchangedto : {
        noofboe: '2',
        paytotheorderof: 'The Hongkong and Shanghai Banking Corporation Limited',
        boevalue: 'Received',
        boecurrencycode: "USD",
        billamt: 0,
        billamtinwords: '********** USD Zero Only **********',
    },
    currencycode: 'USD',
    invgrandtotalinwords: '********** USD Zero Only **********',

    eidto: {
        draft_set: '1',
        custom_set: '1',
        insurance_set: '1',
        weight_set: '1',
        inspection_set: '1',
        nonnego_set: '1',
        comminv_set: '1',
        custominv_set: '1',
        bl_set: '1',
        airwaybill_set: '1',
        draft_orig: '2',
        packinglist_set: '1',
        certoforigin_set: '1',
        explic_set: '1',
        gsp_set: '1',
        shipment_set: '1',
        beneficiary_set: '1',
        cargo_set: '1',
        quality_set: '1',
        otherdoc1_set: '1',
        otherdoc2_set: '1',
        otherdoc3_set: '1',
        otherdoc4_set: '1',
        otherdoc5_set: '1',
        otherdoc6_set: '1',
        otherdoc1_label: 'Other Docs. 1',
        otherdoc2_label: 'Other Docs. 2',
        otherdoc3_label: 'Other Docs. 3',
        otherdoc4_label: 'Other Docs. 4',
        otherdoc5_label: 'Other Docs. 5',
        otherdoc6_label: 'Other Docs. 6',
        noofboe: '2',
        releasedoc_payment: false,
        releasedoc_accept: false,
        acceptawaitarrival: false,
        incaseofneedreferto: false,
        collectoverseacharges: false,
        deductproceedcharges: false,
        collectinterest: false,
        protest: false,
        donotprotest: false,
        waive: false,
        donotwaive: false,
        deduct: false,
        creditacctno: false,
        applyproceedstoback: false,
        financeindemnity: false,
        loans_request: false,
        eiprinted: false,
        interestrate: 0,
        byairmail: false,
        bycable: false,
    }


}

export const statusIncomplete = (
  <div style={{ paddingRight: '6px' }}>
    <span style={{ paddingRight: '6px' }}>Incomplete</span>
    <CDLIcon name="circle-error-solid" color="WARNING" size={26} />
  </div>
);

export const statusError = (
  <div style={{ paddingRight: '6px' }}>
    <span style={{ paddingRight: '6px' }}>Error</span>
    <CDLIcon name="triangle-error" color="ERROR" size={26} />
  </div>
);
