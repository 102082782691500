import PrivateRoute from 'common/components/PrivateRoute/PrivateRoute';
import { validate } from 'common/utils/MasterDocUtil';
import CommonSections from "de/views/DocPreparation/Sections/CommonSections";
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { masterDocAction } from 'common/redux/reducers/masterDoc';
import submit from 'common/views/CommonPage/Submit';


const resetForm = () => {
  console.log('reset form');
}

const MasterDocForm = props => {

    const masterDocSubmit = (event) => {
      event.preventDefault();
    }
    console.log(props.initialValues);
    return (
      <form onSubmit={masterDocSubmit}>
        <CommonSections />

        {props.tabs?.map(item =>{
          return <PrivateRoute key={item.name} path={item.url} component={item.component} />
        })}
      </form>

    )


  }


const mapStateToProps = state => ({
  initialValues: state.masterDocAction?.initDoc
})

  export default connect(mapStateToProps, { load: masterDocAction})(reduxForm({
    form: 'masterDoc',
    onSubmit: submit,
    // preserve form data
    destroyOnUnmount: false,
    // unregister fields on unmount
    forceUnregisterOnUnmount: true,
    enableReinitialize: true,
    reset: resetForm,
    validate
  })(MasterDocForm))
