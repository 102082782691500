import Icon from "common/components/Icon/Icon";
import React from 'react';
import styled from "styled-components";


const EditButtonContainer = styled.div`
    position: relative;
    border: 1px solid #929292;
    padding: 3px 10px;

    >button:hover {
        background: white;
    }
`;

const ListContainer = styled.ul`
    text-decoration: none;
    list-style: none;
    padding: 0;
    margin: 0;
    font-weight: normal;
    position: absolute;
    width: 100%;
    background: white;
    border: none;
`;

const Item = styled.li`
    padding-left: 10px;
    border-left: 1px solid #767676;
    border-right: 1px solid #767676;
    cursor: pointer;
    &:last-child  {
        border-bottom: 1px solid #767676;
    }

    &:hover {
        background-color: #EDEDED; 
    }

    :not(:hover) {
        ${props => props.matchValue ? 'background-color: #EDEDED;' : ''};
    }
`;

const widthStyle = props => props.inputWidth ? `width: ${props.inputWidth}px`: '';

const EditInputContainer = styled.span`
    border-right: 1px solid;
    margin-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;

    & input {
        border: none;
        background-color: transparent;
        ${props => widthStyle(props)};
    }

    & input:focus {
        outline: none;
    }

`;

const showListStyle = props => props.showList ? `
  >span>svg {
      &>path {
          fill: black;
          stroke: white;
      }
  }
` : '';

const IconWrapper = styled.span`
    ${props => showListStyle(props)};
`;

export default class EditableButton extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
          showList: false,
          disabled: true,
          value: props.value
      };
      this.ref = React.createRef();
      this.inputRef = React.createRef();
      this.buttonClick = this.buttonClick.bind(this);
      this.itemClick = this.itemClick.bind(this);
      this.inputChange = this.inputChange.bind(this);
      this.handleClickOutside = this.handleClickOutside.bind(this);
      this.inputBlur = this.inputBlur.bind(this);
  }

  static getDerivedStateFromProps (props, prevState) {
    if (props.value !== prevState.value) {
      return {
        value: props.value || ''
      };
    }
    return null;
  }

  componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(e) {
      setTimeout(() => {
        if (this.ref.current && !this.ref.current.contains(e.target)) {
            this.setState({
                showList: false,
                disabled: true
            });
        }
      }, 0);

}

  itemClick(event) {
        this.setState({
            disabled: true,
            showList: false,
            value: event.currentTarget.innerHTML
            });
            if (this.props.onClick) {
                this.props.onClick(event.currentTarget.innerHTML);
            }

  }

  inputChange(event) {
        this.setState({
            value: event.target.value
        });
        if (this.props.onChange) {
            this.props.onChange(event.target.value);
        }
  }

  inputBlur(event) {
      if (this.props.onBlur) {
          this.props.onBlur(event.target.value);
      }
  }

  buttonClick() {

    this.setState(preState => {

      setTimeout(() => {
        if (preState.disabled) {
          this.inputRef.current.select();
        }
      }, 0);

      return {
        showList: !preState.showList,
        disabled: !preState.disabled,
      }
    });
  }

  render() {

    const itemList = this.props.options.map(o => {
        if (o === this.state.value) {
            return <Item matchValue="true" onClick={this.itemClick} key={o}>{o}</Item>
        }
        return <Item onClick={this.itemClick} key={o}>{o}</Item>
    });


    return (
      <div ref={this.ref}>
        <EditButtonContainer>

          <EditInputContainer inputWidth={this.props.inputWidth}>
            <input
              id={this.props.id}
              name={this.props.name}
              ref={this.inputRef}
              value={this.state.value}
              onChange={this.inputChange}
              onBlur={this.inputBlur}
              disabled={this.state.disabled}
              maxLength={this.props.maxLength}
            />
          </EditInputContainer>
          <IconWrapper showList={this.state.showList}>
            <Icon color="black" name="edit" onClick={this.buttonClick} />
          </IconWrapper>

          {this.state.showList ?
            <ListContainer>
              {itemList}
            </ListContainer> : null}
        </EditButtonContainer>
      </div>


    );
  }
}

