import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import { Radio } from '@cdl/radio-group';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import RadioVertical from "common/components/RadioVertical/RadioVertical";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import { resetMasterDocForm, setMasterDoc, setMasterDocState, setMasterDocSubmitType } from 'common/redux/actions';
import masterDocService from 'common/services/masterDoc.service';
import { getCurrentTab } from 'common/utils/AppUtil';
import React from 'react';
import { submit } from 'redux-form';
import docPrepService from "common/services/doc-prep.service";
import store from 'common/redux/store';
import Message from 'common/views/Popup/Message';

export default class SaveAsDetectChange extends React.Component {
  static saveAsInstance

  static confirm(continueAction) {
    this.saveAsInstance._show(continueAction);
  }

  constructor(props) {
    super(props)

    this.state = {
      visible: false,
    }

    SaveAsDetectChange.saveAsInstance = this;
  }

  _show = (continueAction) => {
    this.setState({
      visible: true,
      description: '',
      documentType: 'MD',
      continueAction,
    })
  }

  close = () => {
    this.setState({ visible: false })
    if (this.state.noCallback) {
      this.state.noCallback();
    }
  }

  isValidAmount = (masterDoc, documentType) => {
    let result = true;
    if(documentType==='MD'){
      const currentTab = getCurrentTab();
      if(currentTab!== 'GenericBillOfExchange'){
        result = masterDoc.invgrandtotal >= 0.000001;
      }else {
        result = masterDoc.billofexchangedto?.billamt >= 0.000001;
      }
    }
    return result;
  }

  save = () => {
    const {continueAction, documentType, description} = this.state;
    const state = store.getState();
    store.dispatch(setMasterDocSubmitType(documentType));
    store.dispatch(submit('masterDoc'));

    setTimeout(() => {
      store.dispatch(setMasterDocSubmitType(null));
      if (state.form.masterDoc.submitSucceeded && this.isValidAmount(state.form.masterDoc.values, documentType)) {
        const metaData = { mdoctype: documentType, mdocdesc: description };

        docPrepService.saveAs(state.form, metaData, state.masterDocAction?.packingList, state.masterDocAction?.beneficiaryCert).then(res=>{
          if(res){
            masterDocService.getMasterDoc(state.form.masterDoc.values.mdocid, documentType).then(resp=>{
              if(resp){
                store.dispatch(setMasterDoc(resp.data));
                store.dispatch(setMasterDocState(resp.data.status));
                store.dispatch(resetMasterDocForm({ isReset: false }));
                continueAction();
                Message.notice(`Master Document "${state.form.masterDoc.values.mdocid}" has been saved successfully.`);
              }
            })
          } else {
            Message.error(`Master Document "${state.form.masterDoc.values.mdocid}" fail to save.`);
          }
        });
      }
      this.close();
    }, 0);
  }

  onChangeType = (event) => {
    this.setState({
      documentType: event.target.value
    })
  }


  render(){
    const {documentType, description} = this.state;
    return (
      <div>
        <Modal
          ariaLabelledby="modal-heading"
          isOpen={this.state.visible}
          width={450}
        >
          <ModalHeader>
            <ModalTitle>Save as</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.close} />
            </ModalBtnList>
          </ModalHeader>
          <ModalContent style={{ fontFamily: "HSBC-Universe" }}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <RadioVertical name="docType" legend="Doc type*">
                  <Radio onChange={this.onChangeType} checked={documentType === 'MD'} id="MD" label="Save as new master document" value="MD" />
                  <Radio onChange={this.onChangeType} checked={documentType === 'TM'} id="TM" label="Save as new master document template" value="TM" />
                </RadioVertical>
              </Grid>
              <Grid item xs={12}>
                <TextFieldForm
                  fullWidth
                  inline
                  label="Description"
                  id="masDocDesc"
                  onChange={(event) => this.setState({description: event.target.value})}
                  value={description}
                />
              </Grid>
            </Grid>
          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button themeColor="primary" onClick={this.save}>Save</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

}
