/* eslint-disable class-methods-use-this */
import { setBeneficiaryCert, setMasterDoc, setMasterDocState, setPackingList, showMasterDocMessage } from 'common/redux/actions';
import store from 'common/redux/store';
import { getCurrentTab } from "common/utils/AppUtil";
import { DOC_STATUS, TABS } from 'common/utils/Constants';
import { masterDocDefaultValue } from "common/utils/DocPrepConstant";
import { isBlank } from "common/utils/StringUtils";
import { forkJoin, Observable } from "rxjs";
import { isDADP } from 'common/utils/MasterDocUtil';
import beneficiaryCertService from "./beneficiary-cert.service";
import billOfExchangeService from "./bill-of-exchange.service";
import exportInstructionService from "./export-instruction.service";
import invoiceService from "./invoice.service";
import masterDocService from "./masterDoc.service";
import packingListService from "./packing-list.service";

const _ = require('lodash');

class DocPrepService {


    loadMasterDocSection(metaData) {
        return forkJoin([masterDocService.getMasterDoc(metaData.mdocid, metaData.mdoctype), billOfExchangeService.getBOEList('BOE', metaData.mdocid, metaData.mdoctype)]);
    }


    loadInvoiceSection(metaData) {
        return forkJoin([masterDocService.getMasterDoc(metaData.mdocid, metaData.mdoctype),
         billOfExchangeService.getBOEList('BOE', metaData.mdocid, metaData.mdoctype),
        invoiceService.getInvoiceList('INV', metaData.mdocid, metaData.mdoctype)]);
    }


    loadPackingListSection(metaData) {
        return forkJoin([masterDocService.getMasterDoc(metaData.mdocid, metaData.mdoctype), billOfExchangeService.getBOEList('BOE', metaData.mdocid, metaData.mdoctype),
            packingListService.getPackingList('PL', metaData.mdocid, metaData.mdoctype)]);
    }


    loadBeneficiaryCertSection(metaData) {
        return forkJoin([masterDocService.getMasterDoc(metaData.mdocid, metaData.mdoctype), billOfExchangeService.getBOEList('BOE', metaData.mdocid, metaData.mdoctype),
        beneficiaryCertService.getBenecertList('BC', metaData.mdocid, metaData.mdoctype)]);
    }


    loadBillOfExchange(metaData) {
        return forkJoin([masterDocService.getMasterDoc(metaData.mdocid, metaData.mdoctype),
            billOfExchangeService.getBOEList('BOE', metaData.mdocid, metaData.mdoctype)]);
    }


    loadExportInstruction(metaData) {
        return forkJoin([masterDocService.getMasterDoc(metaData.mdocid, metaData.mdoctype), billOfExchangeService.getBOEList('BOE', metaData.mdocid, metaData.mdoctype),
            exportInstructionService.getExportInstruction(metaData.mdocid, metaData.mdoctype)]);
    }


    loadAllDocument(metaData) {
        return forkJoin([masterDocService.getMasterDoc(metaData.mdocid, metaData.mdoctype),
            invoiceService.getInvoiceList('INV', metaData.mdocid, metaData.mdoctype),
            packingListService.getPackingList('PL', metaData.mdocid, metaData.mdoctype),
            beneficiaryCertService.getBenecertList('BC', metaData.mdocid, metaData.mdoctype),
            billOfExchangeService.getBOEList('BOE', metaData.mdocid, metaData.mdoctype),
            exportInstructionService.getExportInstruction(metaData.mdocid, metaData.mdoctype)]);
    }

    populateBankBOE(masterDocResponse){
        if(masterDocResponse.data.billofexchangedto.dcbankname && masterDocResponse.data.billofexchangedto.dcbankname!==""){
            masterDocResponse.data.bankname = masterDocResponse.data.billofexchangedto.dcbankname;
            masterDocResponse.data.bankbranch = masterDocResponse.data.billofexchangedto.dcbankbranch;
            masterDocResponse.data.bankref = masterDocResponse.data.billofexchangedto.dcbankref;
            masterDocResponse.data.banktradedept = masterDocResponse.data.billofexchangedto.dcbanktradedept;
            masterDocResponse.data.bankaddr1 = masterDocResponse.data.billofexchangedto.dcbankaddr1;
            masterDocResponse.data.bankaddr2 = masterDocResponse.data.billofexchangedto.dcbankaddr2;
            masterDocResponse.data.bankaddr3 = masterDocResponse.data.billofexchangedto.dcbankaddr3;
            masterDocResponse.data.bankcity = masterDocResponse.data.billofexchangedto.dcbankcity;
            masterDocResponse.data.bankstate = masterDocResponse.data.billofexchangedto.dcbankstate;
            masterDocResponse.data.bankcountry = masterDocResponse.data.billofexchangedto.dcbankcountry;
            masterDocResponse.data.bankpostcode = masterDocResponse.data.billofexchangedto.dcbankpostcode;
        }
    }

    disableAmount(masterDocResponse, props) {
        if (masterDocResponse.data.invoicedetaildto?.length > 0) {
            props.disableInvoiceAmount(true);
        } else {
            props.disableInvoiceAmount(false);
        }
    }

    setDoNotProtest(eidto){
        const eiType = eidto.eitype || eidto.EIType;
        if(isDADP(eiType)){
            eidto.donotprotest = !eidto.protest;
        }
    }

    handleResp(currentTab, responses, props, resolve, reject){
        if (responses.find(response => response.statusCode !== 200)) {
            reject('ERROR');
        } else {
            const [masterDocResponse, billOfExchangeResponse, extRes] = responses;
            masterDocResponse.data.billofexchangedto = billOfExchangeResponse.data;
            if (currentTab === TABS.EI) {
                masterDocResponse.data.eidto = isBlank(extRes.data) ? { ...masterDocDefaultValue.eidto} : extRes.data;
                this.setDoNotProtest(masterDocResponse.data.eidto);
            } else if (currentTab === TABS.INVOICE) {
                masterDocResponse.data.invoicedetaildto = extRes.data.list;
            } else if (currentTab === TABS.BOE) {
                this.populateBankBOE(masterDocResponse);
                if(masterDocResponse.data.invoicedetaildto?.length > 0){
                    masterDocResponse.data.billofexchangedto.boeexpref = masterDocResponse.data.invoicedetaildto[0].invno;
                }
            }
            props.setMasterDoc(null);
            props.setMasterDoc(masterDocResponse.data);
            props.setMasterDocState(masterDocResponse.data.status);
            if (currentTab === TABS.PACKINGLIST) {
                props.setPackingList(extRes.data.list);
            } else if (currentTab === TABS.BENECERT) {
                props.setBeneficiaryCert(extRes.data.list);
            }

            this.disableAmount(masterDocResponse, props);

            resolve(true);
        }
    }

    loadSectionByTab(metaData, props) {
        const currentTab = getCurrentTab();
        switch (currentTab) {
            case TABS.INVOICE:
                return new Promise((resolve, reject) => {
                    this.loadInvoiceSection(metaData).subscribe(responses => {
                        this.handleResp(currentTab, responses, props, resolve, reject);
                    });
                });
            case TABS.PACKINGLIST:
              return new Promise((resolve, reject) => {
                this.loadPackingListSection(metaData).subscribe(responses => {
                    this.handleResp(currentTab, responses, props, resolve, reject);
                })
              });
            case TABS.BENECERT:
              return new Promise((resolve, reject) => {
                return this.loadBeneficiaryCertSection(metaData).subscribe(responses => {
                    this.handleResp(currentTab, responses, props, resolve, reject);
                })

              });
            case TABS.BOE:
                return new Promise((resolve, reject) => {
                    return this.loadBillOfExchange(metaData).subscribe(responses => {
                        this.handleResp(currentTab, responses, props, resolve, reject);
                    });
                });
            case TABS.EI:
              return new Promise((resolve, reject) => {
                return this.loadExportInstruction(metaData).subscribe(responses => {
                    this.handleResp(currentTab, responses, props, resolve, reject);
                })

              });
            default:
                return new Promise((resolve, reject) => {
                    this.loadMasterDocSection(metaData).subscribe(responses => {
                        this.handleResp(currentTab, responses, props, resolve, reject);
                    })
                });
        }
    }


    performUpdateDocument(props) {
        const masterDoc = props.form.values;

        const lockedStatus = [DOC_STATUS.APPROVED, DOC_STATUS.PRINTED, DOC_STATUS.PARTIAL,
            DOC_STATUS.SUBMITTED, DOC_STATUS.BANK_APPROVED, DOC_STATUS.BANK_REJECTED];
        if (lockedStatus.find(status => status === props.form.values.status)) {
            let msg = 'The current document cannot be saved as it is locked. Please unlock the document before saving it.';
            if(props.form.values.status === DOC_STATUS.SUBMITTED){
                msg = "Currently the record has already been submitted, it can't be edited until HSBC has approved or rejected it.";
            }
            store.dispatch(showMasterDocMessage({
                isShowMessage: true,
                masterDocMessage: msg,
                messageType: 'ERROR'
            }))
            return new Observable ((observer) => {
                observer.next(null);
            })
        }

        const currentTab = getCurrentTab();
        switch (currentTab) {
            case 'GenericMasterDocument':
                return forkJoin([masterDocService.updateMasterDoc(props.form.values), exportInstructionService.updateForm(masterDoc.eidto, masterDoc, 'MD')]);
            case 'GenericInvoice':
                return new Observable ((observer) => {
                    invoiceService.updateMasterDoc(props.form.values).then(response => {
                        console.log(`mdoctype: ${  props.form.values.mdoctype}`);
                        if (props.form.values.mdoctype === 'TM'){
                            invoiceService.saveTMPLInvoiceList(props.form.values.invoicedetaildto, props.form.values).then(res => {
                                console.log(res);
                                observer.next([response, res]);
                            });
                        }else{
                            invoiceService.saveInvoiceList(props.form.values.invoicedetaildto, props.form.values).then(res => {
                                console.log(res);
                                observer.next([response, res]);
                            });
                        }
                    })
                    exportInstructionService.updateForm(masterDoc.eidto, masterDoc, 'INV')
                });
            case 'GenericPackingList':
                return new Observable ((observer) => {
                    masterDocService.updateMasterDocWithPackingList(props.form.values).then(response => {
                        packingListService.updatePackingList(props.packingList.values, props.form.values).then(res => {
                            console.log(res);
                            observer.next([response, res]);
                        });
                    })
                    exportInstructionService.updateForm(masterDoc.eidto, masterDoc, 'PL')
                });
            case 'GenericBeneCert':
                return new Observable ((observer) => {
                    console.log(props.form.values);
                    beneficiaryCertService.updateMasterDoc(props.form.values).then(response => {
                        const saveBenecertList = props.form.values.mdoctype === 'MD' ? beneficiaryCertService.saveBenecertList(props.beneficiaryCert.values, props.form.values) :
                                beneficiaryCertService.saveTMPLBenecertList(props.beneficiaryCert.values, props.form.values);
                                saveBenecertList.then(res => {
                                observer.next([response, res]);
                        });
                    })
                    exportInstructionService.updateForm(masterDoc.eidto, masterDoc, 'BC')
                });
            case 'GenericBillOfExchange':
                return forkJoin([
                    billOfExchangeService.updateBoe(props.form.values.billofexchangedto, props.form.values),
                    exportInstructionService.updateForm(masterDoc.eidto, masterDoc, 'BOE')
                ]);
            case 'GenericExportInstruction':
                return new Observable ((observer) => {
                    exportInstructionService.updateForm(props.form.values.eidto, props.form.values).then(res => {
                        console.log(res);
                        observer.next([res]);
                    })
                });
            default:
                console.log("performUpdateDocument - Unknown case");
                return new Observable ((observer) => {
                    observer.next(null);
                })
        }
    }

    performResetDocument(data) {
        console.log("perform reset document", data);
        const currentTab = getCurrentTab();
        console.log(this);
        store.dispatch(setMasterDocState('isNew'));
        store.dispatch(setMasterDoc(null));
        store.dispatch(setMasterDoc(data));

        switch (currentTab) {
            case 'GenericMasterDocument':
                break;
            case 'GenericInvoice':
                break;
            case 'GenericPackingList':
                console.log('set packing list to null')
                store.dispatch(setPackingList([]));
                break;
            case 'GenericBeneCert':
                store.dispatch(setBeneficiaryCert([]));
                break;
            case 'GenericBillOfExchange':
                break;
            case 'GenericExportInstruction':
                break;
            default:
                console.log("performResetDocument - Unknown case");
        }
        return true;
    }


    noDirty() {
        const state = store.getState();
        const {masterDoc} = state.form;
        if(!masterDoc || !masterDoc.initial){
            return true;
        }
        const {packingList} = state.masterDocAction;
        const {beneficiaryCert} = state.masterDocAction;
        const {isDirty} = state.masterDocAction;

        const masterDocToCompare = _.cloneDeep(masterDoc);

        masterDocService.removeActionButton(masterDocToCompare.initial);
        masterDocService.removeActionButton(masterDocToCompare.values);

        let isSamePackingList = true;
        if (packingList) {
            packingListService.removePackingListActionButton(packingList.initial);
            packingListService.removePackingListActionButton(packingList.values);

            isSamePackingList = JSON.stringify(packingList.initial) === JSON.stringify(packingList.values);
        }

        let isSameBeneficiaryCert = true;
        if (beneficiaryCert) {
            beneficiaryCertService.removeBeneCertActionButton(beneficiaryCert.initial);
            beneficiaryCertService.removeBeneCertActionButton(beneficiaryCert.values);

            isSameBeneficiaryCert = JSON.stringify(beneficiaryCert.initial) === JSON.stringify(beneficiaryCert.values)
        }

        if (( JSON.stringify(masterDocToCompare.initial) === JSON.stringify(masterDocToCompare.values))
            && isSamePackingList
            && isSameBeneficiaryCert
            && !isDirty) {
              return true;
        }
        return false;
    }

    performGetDoucmnet = (currentTab, mdocid, mdoctype) => {
        switch (currentTab) {
          case TABS.MASTERDOC:
            return forkJoin([masterDocService.getMasterDoc(mdocid, mdoctype), billOfExchangeService.getBOEList('BOE', mdocid, mdoctype)]);
          case TABS.INVOICE:
            return forkJoin([masterDocService.getMasterDoc(mdocid, mdoctype), billOfExchangeService.getBOEList('BOE', mdocid, mdoctype),
              invoiceService.getInvoiceList('INV', mdocid, mdoctype)]);
          case TABS.PACKINGLIST:
            return forkJoin([masterDocService.getMasterDoc(mdocid, mdoctype), billOfExchangeService.getBOEList('BOE', mdocid, mdoctype),
              packingListService.getPackingList('PL', mdocid, mdoctype)]);
          case TABS.BENECERT:
            return forkJoin([masterDocService.getMasterDoc(mdocid, mdoctype), billOfExchangeService.getBOEList('BOE', mdocid, mdoctype),
              beneficiaryCertService.getBenecertList('BC', mdocid, mdoctype)]);
          case TABS.BOE:
            return forkJoin([masterDocService.getMasterDoc(mdocid, mdoctype), billOfExchangeService.getBOEList('BOE', mdocid, mdoctype)]);
          case TABS.EI:
            return forkJoin([masterDocService.getMasterDoc(mdocid, mdoctype), billOfExchangeService.getBOEList('BOE', mdocid, mdoctype),
              exportInstructionService.getExportInstruction(mdocid, mdoctype)]);
          default:
            return forkJoin([masterDocService.getMasterDoc(mdocid, mdoctype), billOfExchangeService.getBOEList('BOE', mdocid, mdoctype)]);
        }
    }

    saveAs = (form, metaData, packingList, beneficiaryCert) => {
        const currentTab = getCurrentTab();
        switch (currentTab) {
            case TABS.EI:
                return exportInstructionService.saveNewExportInstruction(form.masterDoc.values.eidto, form.masterDoc.values, metaData);
            case TABS.INVOICE:
                return invoiceService.saveNewInvoice(form.masterDoc.values.invoicedetaildto, form.masterDoc.values, metaData);
            case TABS.PACKINGLIST:
                return packingListService.saveNewPackingList(packingList.values, form.masterDoc.values, metaData);
            case TABS.BENECERT:
                return beneficiaryCertService.saveNewBenecertList(beneficiaryCert.values, form.masterDoc.values, metaData);
            case TABS.BOE:
                return billOfExchangeService.saveNewBoe(form.masterDoc.values.billofexchangedto, form.masterDoc.values, metaData);
            default:
                return masterDocService.masterDocSaveNewForm(form.masterDoc.values, metaData);
        }
    }

}

export default new DocPrepService();


