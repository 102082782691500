import Grid from '@material-ui/core/Grid';
import IconButton from "common/components/IconButton/IconButton";
import TableHelper from 'common/components/Table/TableHelper';
import UserMessage from 'common/components/UserMessage/UserMessage';
import { setBeneficiaryCert, setHideError, setMasterDoc, setMasterDocState, updateSubmitErrors } from 'common/redux/actions';
import docPrepService from 'common/services/doc-prep.service';
import BeneficiaryDetail from "common/views/Popup/BeneficiaryDetail";
import Confirm from "common/views/Popup/Confirm";
import React from 'react';
import { connect } from 'react-redux';


const buttonArray = [{id: 'openDetail', name: 'Edit', showModal: 'showRowItem'},
                     {id: 'openDelete', name: 'Delete', modal: Confirm, action: 'lazyDeleteRow',
                      modalProps :{content: 'Do you wish to delete the beneficiary certificate / miscellaneous docs "@title"?'} }
                    ];

class BeneficiarySection extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
      this.detailRef = React.createRef();
      this.tableRef = React.createRef();
      this.props.setBeneficiaryCert([]);
    }

    componentDidMount() {
      if (this.props.docStatus !== 'isNew' && this.props.masterDoc.mdocid) {
        const metaData = {mdocid: this.props.masterDoc.mdocid, mdoctype: this.props.masterDoc.mdoctype};
        docPrepService.loadBeneficiaryCertSection(metaData).subscribe(responses => {
          this.props.setMasterDoc(null);
          const [masterDocResponse, billOfExchangeResponse, beneCertResponse] = responses;
          masterDocResponse.data.billofexchangedto = billOfExchangeResponse.data
          this.props.setMasterDoc(masterDocResponse.data);
          this.props.setMasterDocState(masterDocResponse.data.status);
          this.props.setBeneficiaryCert(beneCertResponse.data.list);
        })
      }

    }

    newBeneficiary = () => {
      this.tableRef.current.newRow();
    }

    save = (rowItem) => {
      const rows = this.props.beneficiaryCert.values;
      rows.push(rowItem);
      this.props.updateBeneficiaryCertErrors('beneficiaryCert');
      this.props.updateStatus();
    }

    refreshData = (data) => {
      this.props.beneficiaryCert.values = data;
    }


    render() {

      return (
        <div>
          <Grid container spacing={0} style={{paddingTop:'20px'}}>
            <Grid item xs={12} style={{paddingRight: '20px'}}>
              {this.props.showErrorMessage &&
              <UserMessage
                showCloseButton
                border
                type="userError"
                iconName="triangle-error"
                iconColor="ERROR"
                handleCloseButtonClick={() => this.props.setHideError('beneficiaryCert')}
                closeText="Close"
              >
                Please create at least 1 beneficiary cert
              </UserMessage>}
            </Grid>
            <Grid item xs={12}>
              <div className="rightBtn">
                <IconButton onClick={this.newBeneficiary} name="add">Add new bene. cert. / misc. doc</IconButton>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{paddingRight:'20px'}}>
            <Grid item xs={12}>
              <TableHelper
                ref={this.tableRef}
                component={BeneficiaryDetail}
                buttonArray={buttonArray}
                titleField="bccerttitle"
                data={this.props.beneficiaryCert?.values || []}
                callback={this.save}
                refreshData={this.refreshData}
                columns={[
                        {
                          Header: 'Title',
                          accessor: 'bccerttitle',
                          width: 500
                        },
                        {
                          Header: 'Date',
                          accessor: 'bcdate'
                        },
                        {
                          Header: 'Invoice number',
                          accessor: 'bcinvno'
                        },
                        {
                          Header: 'Action',
                          accessor: 'actionBtn',
                          width: 120,
                          sortable: false
                        }
                      ]}
              />
            </Grid>
          </Grid>

        </div>
      )
    }
}

const mapStateToProps = state => ({
  beneficiaryCert: state.masterDocAction?.beneficiaryCert,
  masterDoc: state.form?.masterDoc?.values,
  docStatus: state.masterDocAction?.masterDocState || 'isNew'
})

const mapDispatchToProps = dispatch => {
  return {
    setBeneficiaryCert: (data) => dispatch(setBeneficiaryCert(data)),
    setMasterDoc: (data) => dispatch(setMasterDoc(data)),
    setMasterDocState: (data) => dispatch(setMasterDocState(data)),
    updateBeneficiaryCertErrors: (data) => dispatch(updateSubmitErrors(data)),
    setHideError: (data) => dispatch(setHideError(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiarySection)
