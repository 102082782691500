import { ModalAction, ModalActionButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import React from 'react';
import { Link } from 'react-router-dom';
import FileService from 'common/services/file.service';
import Notice from 'common/views/Popup/Notice';
import DocImageService from 'common/services/doc-image.service';

export default class DocumentImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openImage = (index) => {
    DocImageService.getFiles(this.props.mdocid).then(res =>{
      if(res && res.list){
        FileService.download(res.list[index].url);
      }
    });
  }

  render() {

    const imgSize = this.props.imageData?.length;

    if(imgSize === 0){
      return (
        <Notice
          content="There is no document image attached to this Master Document."
          isOpen={this.props.isOpen}
          close={this.props.close}
        />
      )
    }

    if(imgSize === 1 && this.props.isOpen){
        this.openImage(0);
        return <div>{this.props.close()}</div>;
    }

    const links = this.props.imageData?.map((element,index) => {
      return (
        <React.Fragment key={element.filename}>
          <Link
            style={{ paddingBottom: '10px', display: 'inline-block' }}
            onClick={() => this.openImage(index)}
            to="#"
          >
            {element.filename}
          </Link>
          <br />
        </React.Fragment>
      )
    });

    return (
      <Modal
        ariaLabelledby="modal-heading"
        classname="myClass"
        height={500}
        isOpen={this.props.isOpen}
        width={600}
      >
        <ModalHeader hasTopLine>
          <ModalTitle>Document image</ModalTitle>
          <ModalBtnList>
            <ModalBtn name="delete" title="close" onClick={this.props.close} />
          </ModalBtnList>
        </ModalHeader>
        <ModalContent>
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ paddingBottom: '20px' }}>
              <span style={{ paddingBottom: '10px', display: 'inline-block' }}>
                Select a document image in this master document to open:
              </span>
              <br />
              {links}
            </Grid>
          </Grid>
        </ModalContent>
        <ModalFooter hasBottomLine>
          <ModalAction>
            <ModalActionButton>
              <Button onClick={this.props.close}>Close</Button>
            </ModalActionButton>
          </ModalAction>
        </ModalFooter>
      </Modal>
    )

  }
}

