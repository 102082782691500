import PageHeader from "common/components/PageHeader/PageHeader";
import PrivateRoute from 'common/components/PrivateRoute/PrivateRoute';
import Tab from "common/components/Tab/Tab";
import React from 'react';
import ModuleService from "common/services/module.service";


export default class FormPage extends React.PureComponent {

    render(){
        const tabs = ModuleService.getTabs(this.props.module);
        return (
          <>
            <PageHeader title={this.props.title} />
            <div className="mainContent">
              <Tab tabs={tabs} />

              {tabs?.map(item =>{
                  return <PrivateRoute path={item.url} component={item.component} />
              })}

            </div>
          </>
        )
    }
}
