/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import Grid from '@material-ui/core/Grid';
import React from 'react';
import Button from "common/components/Button/Button";
import Heading from "common/components/Heading/Heading";
import { Radio } from '@cdl/radio-group';
import DatePicker from "common/components/DatePicker/DatePicker";
import LiFungService from "common/services/liFung.service";
import UserService from 'common/services/user.service';
import FileService from 'common/services/file.service';


const moment = require('moment');

const radioIdArr = ["imageAttachment", "approval", "printing","summary"];
const from = new Date();
from.setDate(from.getDate() -30);
const dateFormat = "DD MMM YYYY";

export default class LiFungSection extends React.Component{


  constructor(props) {
    super(props);


    this.state = {
        radioArr: [true, false, false, false],
        from ,
        to : new Date(),
        disableDatePicker : true
      }

      this.onChangeType = this.onChangeType.bind(this);
      this.generateReport = this.generateReport.bind(this);
      this.onDateSelected = this.onDateSelected.bind(this);
  }


  onChangeType = (event) => {
    const targetId = event.target.id;
    let index = 0;
    if(typeof targetId !== 'undefined' && targetId !== '')
    {
      for(;index < this.state.radioArr.length ;++index)
      {
        if(radioIdArr[index] === targetId)
        {
          this.state.radioArr[index] = true;
        }
        else
        {
          this.state.radioArr[index] = false;
        }
      }

      if(this.state.radioArr[3])
      {
        this.setState(
          {
            disableDatePicker: false
          }
        );
      }
      else{
        this.setState(
          {
            disableDatePicker: true
          }
        );
      }
    }
    this.forceUpdate();
}

  onDateSelected = (dateObj) => {
    this.setState(dateObj)
  }

  generateReport = () =>{
    let reprotNumberSelected = 0;
    let index = 0;
    for(; index < radioIdArr.length; ++index)
    {
      if(this.state.radioArr[index] === true)
      {
        reprotNumberSelected = index +1;
        break;
      }
    }
    let stringDateFrom;
    let stringDateTo;
    let liFungDto;

    const userDto = UserService.getCurrentUser();
    const sdbname = userDto.countrycode+userDto.groupmemberid;


    if(reprotNumberSelected === 4)
    {
      stringDateFrom = this.state.from;
      stringDateTo = this.state.to;

      liFungDto = {"nreportnum": reprotNumberSelected,
      "sdatefrom": moment(stringDateFrom).format(dateFormat),
      "sdateto": moment(stringDateTo).format(dateFormat),
      "sdbname": sdbname,
      "susername": userDto.username
      };
    }
    else
    {
      liFungDto = {"nreportnum": reprotNumberSelected,
      "sdatefrom": null,
      "sdateto": null,
      "sdbname": sdbname,
      "susername": userDto.username
      };
    }


    LiFungService.generateReport(liFungDto).then(res=>{
      if(typeof res !== 'undefined' && res !== null)
      {
        FileService.download(res);
      }
    }).catch(error => {
      console.log(error);
    });
  }


  render()
  {
    const { disableDatePicker } = this.state;
    return(
      <div>

        <Grid container spacing={0} style={{ paddingTop: '20px', paddingBottom: '30px' }}>
          <Grid item xs={12}>
            <Heading level={2} brandbar><span className="medium">Report type and criteria</span></Heading>
          </Grid>
          <Grid item xs={12} style={{  paddingLeft: '20px' }}>
            <Radio
              onChange={this.onChangeType}
              checked={this.state.radioArr[0]}
              id={radioIdArr[0]}
              radioNumber={0}
              label="Document pending for image attachment"
              value="imageAttachment"
            />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '20px', paddingLeft: '20px'  }}>
            <Radio onChange={this.onChangeType} checked={this.state.radioArr[1]} id={radioIdArr[1]} label="Document pending for approval" value="approval" />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '20px', paddingLeft: '20px'  }}>
            <Radio onChange={this.onChangeType} checked={this.state.radioArr[2]} id={radioIdArr[2]} label="Document pending for printing" value="printing" />
          </Grid>

          <Grid item xs={12} style={{ paddingTop: '20px', paddingLeft: '20px'  }}>
            <Radio onChange={this.onChangeType} checked={this.state.radioArr[3]} id={radioIdArr[3]} label="Document summary" value="summary" />
          </Grid>
          <Grid item xs={3} style={{  paddingLeft: '50px'  }}>
            <DatePicker
              id="RangeDatePicker"
              name="RangeDatePicker"
              inputFormat="DD MMM yyyy"
              displayFormat="DD MMM yyyy"
              type="quickRange"
              onDateSelected={this.onDateSelected}
              defaultDate={{from: this.state.from, to: this.state.to}}
              disabled={disableDatePicker}
              hidden={disableDatePicker}
            />
          </Grid>

        </Grid>

        <Button themeColor="primary" onClick={this.generateReport}>Generate report</Button>

      </div>
    );

  }


}
