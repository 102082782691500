import React, { useState, useEffect } from 'react';
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import Grid from '@material-ui/core/Grid';
import IconButton from "common/components/IconButton/IconButton";
import DrawerService from 'common/services/drawer.service';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import Msg from "common/views/Popup/Msg";
import DrawerAddDetail from "common/views/Popup/DrawerAddDetail"
import Table from 'common/components/Table/Table';

const buttonArray = [
  { id: 'openEdit', name: 'Edit', showModal: 'openEditDrawerPopup' },
  { id: 'openDelete', name: 'Delete', showModal: 'showDeleteRequestMessage' }
];

export default function Drawer() {
  const [openDrawerPopup, setDrawerPopupOpen ] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [openDeletePopup, setDeletePopupOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [exporterCode, setExporterCode] = useState("");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [openMessageDialog, setMessageDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    const fetchTableData = () => {
      DrawerService.loadDrawer().then(response => {
        if (response && response.list) {
          setTableData(response.list);
        }
      }).catch(error => {
        throw error;
      });
    }
    fetchTableData();
  }, [isRefresh]);

  const showSuccessMessage = (msg) => {
      setMessage(msg);
      setSuccess(true);
      setMessageDialogOpen(true);
      setIsRefresh(!isRefresh);
      setIsEdit(false);
  }

  const openEditDrawerPopup = (rowIndex) => {
    setSelectedIndex(rowIndex);
    setIsEdit(true);
    const selectedID = tableData.find((item, index) => index === rowIndex)?.exportercode;
    setExporterCode(selectedID);
    setDrawerPopupOpen(true);
  }

  const showDeleteRequestMessage = (rowIndex) => {
    setSelectedIndex(rowIndex);
    setDeletePopupOpen(true);
  }

  const closeDeletePopup = () => {
    setSelectedIndex(null);
    setDeletePopupOpen(false);
  }

  function closeDrawerPopup() {
    setIsEdit(false);
    setDrawerPopupOpen(false);
  }

  const closeMessage = () => {
    setMessage("");
    setMessageDialogOpen(false);
  }

  const deleteDrawer = () => {
    const selectedItem = tableData.find((item, index) => index === selectedIndex);
    setSelectedIndex(selectedIndex);
    DrawerService.deleteDrawer(selectedItem).then(response => {
      if (response) {
        showSuccessMessage(`Drawer "${tableData[selectedIndex]?.exportercode}" has been deleted successfully.`);
      }
    }).then(() => {
      setDeletePopupOpen(false);
    }).catch(error => {
      throw error;
    });
  }

  const displayData = tableData.filter(d => !d.deleted).map((t, i) => {
    return Object.assign(t, {actionBtn: <ActionButtons
      rowIndex={i}
      buttonArray={buttonArray}
      openEditDrawerPopup={openEditDrawerPopup}
      showDeleteRequestMessage={showDeleteRequestMessage}
      justify='flex-start'
    />});
  });

  const getDisplayName = () => {
    let displayName = tableData[selectedIndex]?.exportercode;
    if(tableData[selectedIndex]?.expname){
      displayName += ` - ${tableData[selectedIndex]?.expname}`;
    }
    return displayName;
  }

  return (
    <div>
      <Msg
        title="Are you sure?"
        content={`Do you wish to delete the drawer "${getDisplayName()}"?`}
        width={500}
        confirm={deleteDrawer}
        isOpen={openDeletePopup}
        close={closeDeletePopup}
        warning
      />
      <Grid container spacing={0} style={{ paddingTop: '20px' }}>
        {success && openMessageDialog &&
          <Grid item xs={12}>
            <SuccessMessage message={message} handleCloseButtonClick={closeMessage} />
          </Grid>}
        <Grid item xs={10} />
        <Grid item xs={2}>
          <div className="rightBtn" style={{paddingRight: '0px'}}>
            <IconButton onClick={() => {setDrawerPopupOpen(true)}} name="add">Add new drawer</IconButton>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Table
            maxRows={12}
            showPagination={false}
            defaultPageSizeOption={{'value':0}}
            data={displayData}
            columns={[
              {
                Header: 'Drawer ID',
                accessor: 'exportercode',
                maxWidth: 230
              },
              {
                Header: 'Drawer name',
                accessor: 'expname',
                maxWidth: 400,
                Cell: row => <span title={row.original.expname}>{row.original.expname}</span>
              },
              {
                Header: 'Email address',
                accessor: 'expemail',
                Cell: row => <span title={row.original.expemail}>{row.original.expemail}</span>
              },
              {
                Header: 'Action',
                accessor: 'actionBtn',
                maxWidth: 140,
                sortable: false
              }
            ]}
          />
        </Grid>
      </Grid>
      {openDrawerPopup &&
      <DrawerAddDetail
        isOpen={openDrawerPopup}
        edit={isEdit}
        exporterCode={exporterCode}
        close={closeDrawerPopup}
        success={showSuccessMessage}
      />}
    </div>
  );
}
