import { CDLModal2 } from '@cdl/modal';
import { OVERFLOW_CLASS } from 'common/utils/Constants';
import React, { Component } from "react";
import styled from "styled-components";

const Container = styled.div`
    
    &> div > div:first-of-type{
      z-index: 101;
    }

    & .modal_container {

    }


`;

export default class Modal extends Component {
  componentDidMount() {
    setTimeout(() => {
      if (this.props.isOpen) {
        document.documentElement.classList.add(OVERFLOW_CLASS);
      }
    }, 0);
  }

  componentDidUpdate(prevProps) {
    if(this.props.isOpen!==prevProps.isOpen){
      if(this.props.isOpen){
        document.documentElement.classList.add(OVERFLOW_CLASS);
      }else{
        document.documentElement.classList.remove(OVERFLOW_CLASS);
      }
    }
  }

  componentWillUnmount() {
    if (this.props.isOpen) {
      document.documentElement.classList.remove(OVERFLOW_CLASS);
    }
  }

  render() {
    return <Container><CDLModal2 {...this.props} /></Container>;
  }
}

