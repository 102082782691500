import React from "react";
import styled from "styled-components";
import { ModalContent as ModalContentCDL } from '@cdl/modal';

const Container = styled(ModalContentCDL)`
    padding-top: 20px;
    margin-right: 0;
    padding-right: 0;
    font-size: 14px;
    white-space: normal;
`;


export default class ModalContent extends React.PureComponent {
  render() {
    const {children, ...rest} = this.props
    return (

      <Container {...rest}>{children}</Container>

    );
  }
}

