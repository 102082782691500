import Grid from '@material-ui/core/Grid';
import NewButton from "common/components/NewButton/NewButton";
import renderInput from 'common/components/ReduxForm/RenderInput';
import renderAutocomplete from 'common/components/ReduxForm/RenderAutocomplete';
import renderDatePicker from 'common/components/ReduxForm/RenderDatePicker';
import renderDropdown from 'common/components/ReduxForm/RenderDropdown';
import renderNumberField from 'common/components/ReduxForm/RenderNumberField';
import renderTextArea from 'common/components/ReduxForm/RenderTextArea';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import { setVesselList, updateMasterDocByFields, updateSubmitErrors, updateMasterDocByFieldArray } from 'common/redux/actions';
import MasterDocService from 'common/services/masterDoc.service';
import ModuleService from 'common/services/module.service';
import { formatAmountWords } from 'common/utils/AmountFormatter';
import { getCurrentTab } from "common/utils/AppUtil";
import { TABS } from 'common/utils/Constants';
import { formatDate } from "common/utils/DateUtil";
import { getFieldName } from 'common/utils/FieldNameUtil';
import { getCountry, getOption, getOptionRealTime } from 'common/utils/OptionUtil';
import AddCarrier from 'common/views/Popup/AddCarrier';
import AddTenor from 'common/views/Popup/AddTenor';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { isDADP } from 'common/utils/MasterDocUtil';

const NOT_APPLICABLE = "Not Applicable";

const amountWordsBOE = 'billofexchangedto.billamtinwords';
const amountWordsEDS = 'invGrandTotalInWords';
const amountWords = 'invgrandtotalinwords';
const transportationModeOption= [{label:"By Sea", value:"By Sea"},
{label:"By Air", value:"By Air"},
{label:"By Truck", value:"By Truck"},
{label:"By Railway", value:"By Railway"},
{label:NOT_APPLICABLE, value:NOT_APPLICABLE}];


class Other extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        openCarrierPopup: false,
        openTenorPopup: false,
        openMessageDialog: false,
        message: "",
        exporterReferenceOptions: [],
        disabledAmount: false,
        vesselNameList: []
      };
      this.onDateSelected = this.onDateSelected.bind(this);

      this.optPortName = getOption('getMasterDocPortName');

      this.optIncoTerm = getOption('getMasterDocIncoTerm');

      this.optCurrencyCode = getOption('getMasterDocCurrencyCode');

      this.optCommodityDesc = getOption('getMasterDocCommodityDesc');

      this.optCountry = getCountry();

    }

    componentDidMount(){
      this.disableAmount();
      this.getVesselListRealTime().then(res=>{
        if(res.length>0){
          this.setState({
            vesselNameList: res
          });
        }
      })
      if(this.props.isEDS && this.optCurrencyCode.length === 0 && this.props.masterDoc.CoDocExpressId){
        MasterDocService.getMasterDocCurrencyCode(this.props.masterDoc.CoDocExpressId).then(res=>{
          const options = [];
          if(res && res.list){
            res.list.forEach(element => {
              options.push(element);
            });
          }
          this.optCurrencyCode = options;
        })
      }
    }

    componentDidUpdate(prevProps){
      this.disableAmount();
      if (this.props.masterDoc?.mdocid !== prevProps.masterDoc?.mdocid) {
        this.closeMessage();
      }
    }

    onDateSelected = (date, field) => {
      this.props.updateMasterDocByFields({field, data: formatDate(date.from)});
    }

    static getDerivedStateFromProps (props, prevState) {
      if (props.masterDoc !== prevState.masterDoc) {
        return {
          exporterReferenceOptions: props.masterDoc?.invoicedetaildto?.map(invoice => invoice.invno) || [],
          vesselNameList: props.vesselList ? props.vesselList : prevState.vesselNameList
        };
      }
      return null;
    }


    showSuccessMessage = (msg) => {
      this.setState({
        message: msg,
        openMessageDialog: true,
      });
    }

    closeCarrierPopup = () => {
      this.setState({openCarrierPopup: false});
    }

    closeTenorPopup = () => {
      this.setState({openTenorPopup: false});
    }

    closeMessage = () => {
      this.setState({
        message: "",
        openMessageDialog: false,
      });
    }

    onCurrencyChange = (value) => {
      const currentTab = getCurrentTab();
      if(currentTab === TABS.BOE){
        const words = formatAmountWords(value, this.props.masterDoc.billofexchangedto.billamt, this.props.masterDoc.amtdeccat);
        this.props.updateMasterDocByFields({field: amountWordsBOE, data: words});
      } else if (currentTab === TABS.EDS_DASHBOARD) {
        const words = formatAmountWords(value, this.props.masterDoc.invGrandTotal, this.props.masterDoc.amtDecCat);
        this.props.updateMasterDocByFields({field: amountWordsEDS, data: words});
      } else{
        const words = formatAmountWords(value, this.props.masterDoc.invgrandtotal, this.props.masterDoc.amtdeccat);
        this.props.updateMasterDocByFields({field: amountWords, data: words});
      }
    }

    updateAmountWords = (value) => {
      const currentTab = getCurrentTab();
      if (currentTab === TABS.BOE) {
        const words = formatAmountWords(this.props.masterDoc.billofexchangedto.boecurrencycode, value, this.props.masterDoc.amtdeccat);
        this.props.updateMasterDocByFields({ field: amountWordsBOE, data: words });
      } else if (currentTab === TABS.EDS_DASHBOARD) {
        const words = formatAmountWords(this.props.masterDoc.CurrencyCode, value, this.props.masterDoc.amtDecCat);
        this.props.updateMasterDocByFields({field: amountWordsEDS, data: words});
      } else {
        const words = formatAmountWords(this.props.masterDoc.currencycode, value, this.props.masterDoc.amtdeccat);
        this.props.updateMasterDocByFields({ field: amountWords, data: words });
      }
    }

    onAmountChange = (event) => {
      const {value} = event.currentTarget;
      this.props.updateMasterDocByFields({field: event.target.name, data: value});
      if (Number(value) > 0) {
        this.props.updateSubmitErrors(event.target.name);
      }
      this.updateAmountWords(value);
    }

    onTransportationModeChange = (value) => {
      if(value===NOT_APPLICABLE){
        const {isEDS} = this.props;
        const fields = [
          { field: getFieldName(isEDS, 'departureDate'), data: '' },
          { field: 'awbno', data: '' },
          { field: getFieldName(isEDS, 'receiptNo'), data: '' },
          { field: getFieldName(isEDS, 'vesselName'), data: '' },
          { field: 'voyage', data: '' },
          { field: getFieldName(isEDS, 'flightNo'), data: '' },
          { field: getFieldName(isEDS, 'portLoading'), data: '' },
          { field: getFieldName(isEDS, 'portDischarge'), data: '' },
          { field: getFieldName(isEDS, 'countryOfOrigin'), data: '' },
          { field: 'location', data: '' },
          { field: getFieldName(isEDS, 'finalDestination'), data: '' }
        ];
        this.props.updateMasterDocByFieldArray({fieldArray : fields});
      }
    }

    hasInvoice = () => {
      const invoiceData = this.props.masterDoc?.invoicedetaildto;
      if (invoiceData?.length > 0) {
        const invDeletedData = invoiceData.filter(item => item.isDelete === true);
        return this.props.masterDoc.invoicedetaildto.length - invDeletedData.length > 0
      }

      return Number(this.props.masterDoc?.totalinv) > 0
    }

    disableAmount = () => {
      if (this.hasInvoice() && !this.state.disabledAmount && sessionStorage.getItem('INV')) {
          this.setState({disabledAmount: true});
      }else if(!this.hasInvoice() && this.state.disabledAmount){
          this.setState({disabledAmount: false});
      }
    }

    isDisableLCFields = () => {
      const transportationMode = this.props.isEDS ? this.props.masterDoc.transportationMode : this.props.masterDoc.transportationmode;
      return transportationMode===NOT_APPLICABLE;
    }

    isDisableDCFields = () => {
      const eiType = this.props.isEDS ? this.props.masterDoc?.eidto?.EIType : this.props.masterDoc?.eidto?.eitype;
      return this.props.isEDS && isDADP(eiType);
    }

    getVesselListRealTime = async () => {
      if (this.props.isEDS) {
        return getOptionRealTime('getMasterDocVessel', this.props.masterDoc.CoDocExpressId);
      }

      return getOptionRealTime('getMasterDocVessel');
    }

    clearValue = (fieldName)=> {
      this.props.updateMasterDocByFields({field: fieldName, data: '', isInit: true})
    }

    renderInputField = (id, name, label, length, disabled) => {
      return <Field
        component={renderInput}
        id={id}
        name={name}
        label={label}
        maxLength={length}
        disabled={disabled}
      />
    }

    renderAutocompleteField = (id, name, label, options, length, disabled) => {
      return <Field
        component={renderAutocomplete}
        id={id}
        name={name}
        label={label}
        options={options}
        maxLength={length}
        disabled={disabled}
      />
    }

    resetEIType = () => {
      if(window.location.pathname.includes('GenericEDSDashboard') || window.location.pathname.includes('GenericExportInstruction')){
        this.props.updateMasterDocByFields({field: this.props.isEDS ? 'eidto.EIType' : 'eidto.eitype', data: ""});
        this.props.updateMasterDocByFields({field: 'clearOption', data: true});
      }
    }

    onDateChange = (date) => {
      const {isEDS} = this.props;
      const eiType = isEDS ? this.props.masterDoc?.eidto?.EIType : this.props.masterDoc?.eidto?.eitype;
      if(isDADP(eiType) && date !== 'Invalid date' && date.length > 0){
        this.resetEIType();
      }
    }

    onDcChange = (event) => {
      const {isEDS} = this.props;
      const eiType = isEDS ? this.props.masterDoc?.eidto?.EIType : this.props.masterDoc?.eidto?.eitype;
      const {value} = event.target;
      if(isDADP(eiType) && value.trim().length > 0){
        this.resetEIType();
      }
    }

    renderFields(){
      const {isEDS} = this.props;
      const star = ModuleService.hasLFEDSModule() || isEDS ? "*" : "";
      const starBrief = ModuleService.isEDSWorkflow() || isEDS ? "*" : "";

      const isBOE = getCurrentTab() === TABS.BOE;

      const currencyField = isBOE ? 'billofexchangedto.boecurrencycode' : getFieldName(isEDS, 'currencyCode');
      const amountField = isBOE ? 'billofexchangedto.billamt' : getFieldName(isEDS, 'invGrandTotal');
      const amountWordsField = isBOE ? amountWordsBOE : getFieldName(isEDS, amountWordsEDS);

      const disableTransportFields = this.isDisableLCFields();
      const disableDcFields = this.isDisableDCFields();

      return (
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <Field
              component={renderInput}
              id="creditNo"
              name='dcno'
              label="Documentary credit number"
              maxLength={20}
              onChange={this.onDcChange}
              disabled={disableDcFields}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              label="Documentary credit issue date"
              id="documentary-credit-issue-date"
              name='dcdate'
              component={renderDatePicker}
              onDateSelected={(date) => this.onDateSelected(date, isEDS ? 'DCDate' : 'dcdate')}
              defaultDate={new Date()}
              dateFormat="DD MMMM yyyy"
              onChange={this.onDateChange}
              disabled={disableDcFields}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              label="Departure date - shipment date"
              id="departuredate"
              name={getFieldName(isEDS, 'departureDate')}
              component={renderDatePicker}
              onDateSelected={(date) => this.onDateSelected(date, getFieldName(isEDS, 'departureDate'))}
              defaultDate={new Date()}
              dateFormat="DD MMMM yyyy"
              disabled={disableTransportFields}
            />
          </Grid>
          <Grid item xs={3}>{this.renderInputField("AWBNo", 'awbno', "B/L. AWB number", 35, disableTransportFields)}</Grid>


          <Grid item xs={3}>
            {this.renderInputField("receiptNo", getFieldName(isEDS, 'receiptNo'), "FCR. PO receipt number", 35, disableTransportFields)}
          </Grid>
          <Grid item xs={3}>
            <Field
              component={renderAutocomplete}
              label="Transportation mode*"
              id="transportation"
              name={getFieldName(isEDS, 'transportationMode')}
              options={transportationModeOption}
              maxLength={15}
              onChange={this.onTransportationModeChange}
            />
          </Grid>
          <Grid item xs={3}>
            <div style={{position: 'relative'}}>
              <NewButton id="addCarrier" onClick={() => this.setState({openCarrierPopup: true})} />
            </div>
            <Field
              component={renderDropdown}
              labelOutside="Carrier"
              id="vesselName"
              name={getFieldName(isEDS, 'vesselName')}
              fetchData={this.getVesselListRealTime}
              options={this.state.vesselNameList}
              setOptions={(res) => this.setState({vesselNameList: res})}
              clearValue={()=>this.clearValue(getFieldName(isEDS, 'vesselName'))}
              disabled={disableTransportFields}
            />
          </Grid>
          <Grid item xs={3}>
            {this.renderInputField("Voyage", 'voyage', "Vessel", 35, disableTransportFields)}
          </Grid>


          <Grid item xs={3}>
            {this.renderInputField("flightNo", getFieldName(isEDS, 'flightNo'), "Flight number", 35, disableTransportFields)}
          </Grid>
          <Grid item xs={3}>
            <Field
              component={renderDropdown}
              labelOutside="Seaport / Airport of loading*"
              id="portLoading"
              name={getFieldName(isEDS, 'portLoading')}
              options={this.optPortName}
              clearValue={()=>this.clearValue(getFieldName(isEDS, 'portLoading'))}
              disabled={disableTransportFields}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={renderDropdown}
              labelOutside="Port of discharge*"
              id="portOfDischarge"
              name={getFieldName(isEDS, 'portDischarge')}
              options={this.optPortName}
              clearValue={()=>this.clearValue(getFieldName(isEDS, 'portDischarge'))}
              disabled={disableTransportFields}
            />
          </Grid>
          <Grid item xs={3}>
            {this.renderAutocompleteField("countryOfOrigin", getFieldName(isEDS, 'countryOfOrigin'), "Country of origin*", this.optCountry, 35, disableTransportFields)}
          </Grid>


          <Grid item xs={2}>{this.renderInputField("poNo", 'pono', "PO number", 35)}</Grid>
          <Grid item xs={2}>
            <Field component={renderAutocomplete} label="Incoterm" id="incotermid" name={getFieldName(isEDS, 'incotermID')} options={this.optIncoTerm} maxLength={35} />
          </Grid>
          <Grid item xs={2}>
            <Field
              component={renderDropdown}
              labelOutside="Location name"
              id="location"
              name='location'
              options={this.optPortName}
              clearValue={()=>this.clearValue('location')}
              disabled={disableTransportFields}
            />

          </Grid>
          <Grid item xs={3}>
            <Field
              component={renderDropdown}
              labelOutside="Final destination, if on carriage*"
              id="finalDestination"
              name={getFieldName(isEDS, 'finalDestination')}
              options={this.optPortName}
              clearValue={()=>this.clearValue(getFieldName(isEDS, 'finalDestination'))}
              disabled={disableTransportFields}
            />
          </Grid>
          <Grid item xs={3}>
            <div style={{position: 'relative', zIndex: 1}}>
              <NewButton id="addTenor" onClick={() => this.setState({openTenorPopup: true})} />
            </div>
            <Field
              component={renderAutocomplete}
              label={`Tenor${star}`}
              id="tenorId"
              name={getFieldName(isEDS, 'tenorID')}
              options={[]}
              fetchData={()=>getOptionRealTime('getMasterDocTenor')}
            />
          </Grid>


          <Grid item xs={2}>
            <Field
              component={renderAutocomplete}
              label="Currency code"
              id="currencyCode"
              name={currencyField}
              options={this.optCurrencyCode}
              onChange={this.onCurrencyChange}
              maxLength={3}
              disableCustom
            />
          </Grid>
          <Grid item xs={2}>
            <Field
              label="Amount"
              id="invGrandTotal"
              name={amountField}
              component={renderNumberField}
              maxLength={24}
              onClick={this.disableAmount}
              onBlur={this.onAmountChange}
              decimalScale={(this.props.isEDS ? this.props.masterDoc?.AmtDecCat : this.props.masterDoc?.amtdeccat) || 2}
              readOnly={this.state.disabledAmount}
              allowNegative={false}
            />
          </Grid>
          <Grid item xs={3}>
            {this.renderAutocompleteField("exprefinvno", getFieldName(isEDS, 'eidto.ExpRefInvNo'), "Exporter reference / invoice no.", this.state.exporterReferenceOptions, 35)}
          </Grid>
          <Grid item xs={3}>
            {this.renderAutocompleteField("commodityDesc", getFieldName(isEDS, 'commodityDesc'), `Brief description of goods${starBrief}`, this.optCommodityDesc, 100)}
          </Grid>
          <Grid item xs={2}>
            {this.renderInputField("descriptionOfGoods", getFieldName(isEDS, 'descOfGoods'), "Description of goods", 280)}
          </Grid>

          <Grid item xs={9}>
            <Field component={renderTextArea} id="invGrandTotalInWords" name={amountWordsField} label="Amount in words" maxLength={255} disabled />
          </Grid>
          <Grid item xs={3}>
            <Field component={renderTextArea} id="marksandNo" name={getFieldName(isEDS, 'marksAndNo')} label="Marks and number" maxLength={300} />
          </Grid>

          <Grid item xs={12}>
            <Field component={renderTextArea} id="generalAddInfo" name={getFieldName(isEDS, 'generalAddInfo')} label="General additional information" maxLength={200} />
          </Grid>

        </Grid>
      )
    }

    render(){
      const {isEDS} = this.props;
      const {
        openCarrierPopup,
        openTenorPopup,
        openMessageDialog,
        message,
      } = this.state;


    return (
      <div style={{paddingTop:'30px'}}>
        {openCarrierPopup &&
          <AddCarrier
            isOpen={openCarrierPopup}
            close={this.closeCarrierPopup}
            success={this.showSuccessMessage}
            title="Add Carrier"
            codocexpressid={this.props.masterDoc.CoDocExpressId}
            isEDS={isEDS}
          />}

        {openMessageDialog &&
          <div style={{paddingRight: '20px'}}>
            <SuccessMessage message={message} handleCloseButtonClick={this.closeMessage} />
          </div>}

        {openTenorPopup &&
        <AddTenor
          isOpen={openTenorPopup}
          close={this.closeTenorPopup}
          success={this.showSuccessMessage}
          title="Add Tenor"
          labelID="Tenor*"
          labelDesc="Tenor description"
          isDocPrep
        />}

        {this.renderFields()}

      </div>
    )
        }
}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values || {},
  disableInvoiceAmount: state.masterDocAction?.disableInvoiceAmt,
  vesselList: state.masterDocAction?.vesselList
})

const mapDispatchToProps = dispatch => {
  return {
    updateMasterDocByFields: (payload) => dispatch(updateMasterDocByFields(payload)),
    updateSubmitErrors: (payload) => dispatch(updateSubmitErrors(payload)),
    setVesselList: (payload) => dispatch(setVesselList(payload)),
    updateMasterDocByFieldArray: (payload) => dispatch(updateMasterDocByFieldArray(payload))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Other)
