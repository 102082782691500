import Collapsible from "common/components/Collapsible/Collapsible";
import { fixActionBarBottom, initActionBarPosition } from 'common/utils/UserInterfaceUtil';
import BeneficiarySection from "de/views/DocPreparation/Sections/BeneficiarySection";
import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getStatus } from 'common/utils/StatusUtil';

export default function Beneficiary() {

  const masterDoc = useSelector(state => state.form?.masterDoc);
  const beneficiaryCertError = masterDoc?.submitErrors?.beneficiaryCert;
  const [isConstruct, setIsConstruct] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(false);

  useEffect(() => {
    if (!isConstruct) {
      initActionBarPosition();
      fixActionBarBottom();
      setIsConstruct(true);
    }
    }, [isConstruct]
  );


  const hideErrors = useSelector(state => state.masterDocAction?.hideErrors?.beneficiaryCert);

  const accordionMark = {};
  if (beneficiaryCertError) {
    accordionMark.beneficiaryCert = true;
  } else {
    accordionMark.beneficiaryCert = false;
  }

  const beneficiaryCert = useSelector(state => state.masterDocAction?.beneficiaryCert);

  const updateStatus = () => {
    masterDoc.beneficiaryCert = beneficiaryCert.values;
    const status = getStatus('beneficiaryCert', masterDoc);
    setUpdatedStatus(status);
  }


    return (


      <Collapsible label="5. Beneficiary cert. / miscellaneous doc.(s)" className="collapsible last" section='beneficiaryCert' updatedStatus={updatedStatus}>
        <BeneficiarySection showErrorMessage={accordionMark.beneficiaryCert && !hideErrors} updateStatus={updateStatus} />
      </Collapsible>


    )
}
