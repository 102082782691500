import React from 'react';
import PortService from 'common/services/port.service';
import { WOLF_SCAN_PORT_FIELDS } from 'common/utils/Constants';
import GenericPopup from 'common/views/Popup/GenericPopup';

const initalData  = Object.freeze({
  portName: "",
  portCountry: ""
});

export default class AddPort extends React.Component {

    save = (data) => {
      if (this.props.edit) {
        return PortService.updatePorts(data);
      }
      return PortService.addPorts(data);
    }

    render(){
      const fields = [
        {id:'portName', label:'Port name*', name:'portName', maxLength: 35, isMandatory: true, disabled: this.props.edit, trim: true},
        {id:'portCountry', label:'Port country / territory*', name:'portCountry', maxLength: 35, isMandatory: true},
      ]
      return (
        <GenericPopup
          initalData={initalData}
          fields={fields}
          {...this.props}
          save={this.save}
          displayField={['portName','portCountry']}
          displayFieldTile="Port"
          title="Ports details"
          wolfField={WOLF_SCAN_PORT_FIELDS}
        />
      )
    }
}
