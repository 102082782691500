import store from 'common/redux/store';
import BaseService from 'common/services/BaseService';
import UserService from 'common/services/user.service';
import { clearOption } from 'common/utils/OptionUtil';


class AuthService extends BaseService {

  login(username, password, statuscode) {

    const requestBody = {
      "dto": {
        userName: username,
        password,
        statuscode
      }
    }

    return this.postReturnWithStatus('/api/Login/authenticate', requestBody, true).then(response => {
      delete requestBody.dto.password;
      if (response.statusCode === 200 && response.data.user) {
          return response.data.user;
      }
        console.log(`unexpected result: ${  response}`);
        return false;

    })
    .catch(error => {
      console.log(error);
    });
  }

  logout(msg) {

    const user = UserService.getCurrentUser() || {};

    const requestBody = {
      "logoutdto": {
        "username": user.username,
        "fullname": user.fullname,
        "event": msg || "User has log out.",
        "codocexpressid": user.codocexpressid,
        "countrycode": user.countrycode,
        "groupmemberid": user.groupmemberid
      },
      "userdto": {
        "user": user
      }
    }

    return this.postReturnWithStatus('/api/Logout/dologout', requestBody, true)
      .then(response => {
        if(response.statusCode === 200){
          UserService.removeUser();
          clearOption();
          store.dispatch({ type: 'USER_LOGOUT'});

          response.data.loginDate = user.logindateonly;
          response.data.loginTime = user.logintimeonly;
          return response.data;
        }
          console.log(`Error: ${response}`);
          return response;
      })
      .catch(error => {
        console.log(error);
      });

  }


  concurrentLogin() {
    return this.postReturnWithStatus('/api/Login/concurrentLogin', {});
  }


  keepAlive() {
    return this.postReturnWithStatus('/api/Login/keepAlive', {});
  }

  getCustomerMsg() {
    return this.post('/api/Login/getCustomerMsg', {});
  }

}

export default new AuthService()
