import CompanyService from 'common/services/company.service';
import MasterDocService from "common/services/masterDoc.service";
import ProcessingCentreService from 'common/services/processing-centre.service';

export function mapToLabelValue(val) {
  return {
    label: val,
    value: val
  }
}

function appendOption(data, options) {
  data.forEach(element => {
    if (Array.isArray(element)) {
      options.push(mapToLabelValue(element[0]));
    }
    else {
      options.push(mapToLabelValue(element));
    }
  });
}

let optionMap = {};
export function getOption(name){
    if(optionMap[name] && optionMap[name].length > 0){
        return optionMap[name];
    }

    const options = [];

    MasterDocService[name]().then(res => {
        if(res && res.list){
          appendOption(res.list, options);
        }
    });
    optionMap[name] = options;
    return optionMap[name];
}

export async function getOptionRealTime(name, param){
  const options = [];
  await MasterDocService[name](param).then(response => {
        if(response && response.list){
          appendOption(response.list, options);
        }
  });

  return options;
}


export function clearOption(){
  optionMap = {};
}

export const CASE_NO = [
  { label: "Case No.", data: "Case No" },
  { label: "Carton No.", data: "Carton No" }
]

export const PROD_CODE = [
  { label: "Prod. Code", data: "Prod. Code" },
  { label: "Part No.", data: "Part No" },
  { label: "Ref. No.", data: "Ref. No" }
]

export const QUANTITY = [
  { label: "Quantity", data: "Quantity" },
  { label: "Weight", data: "Weight" }
]

export const PROD_DESC = [{label:"Product description", data:"Product Description"}]

export const NO_OF_BOE = [
  {label: 'One BOE', value: '1'},
  {label: 'Two BOE', value: '2'},
]

export function getCountry(isRealTime){
  const name = 'getCountry';
  if(!isRealTime && optionMap[name] && optionMap[name].length > 0){
    return optionMap[name];
  }

  const options = [];
  MasterDocService.getMasterDocCountry().then(res => {
    if (res && res.list) {
      res.list.forEach(element => {
        options.push({ "label": element.countryName, "value": element.countryName });
      });
    }
  });
  optionMap[name] = options;
  return options;
}


export function getProcessingCtr(showFullName, hasAllOption){
  const options = [];
  if(hasAllOption){
    options.push({"label": "All", "value": ""})
  }
  ProcessingCentreService.loadProcessingCtr().then(response => {
    if (response && response.list) {
      response.list.forEach(element => {
        let label = element.processCtrName;
        if(!showFullName && label.includes(' - ')){
          label = label.split(' - ')[1];
        }
        options.push({ "label": label, "value": element.processCtrID });
      });
    }
  });
  return options;
}

export function getCompanyByProcessingCtr(processCtrID, hasAllOption){
  const options = [];
  if(hasAllOption){
    options.push({"label": "All", "value": ""})
  }
  CompanyService.getCompanyByProcCtrID(processCtrID).then(response => {
    if (response && response.list) {
      response.list.forEach(element => {
        options.push({ label: `${element.coDocExpressID  } - ${  element.coName}`, value: element.coDocExpressID });
      });
    }
  });
  return options;
}
