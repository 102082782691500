/* eslint-disable camelcase */
import Paragraph from '@cdl/layout';
import { Radio } from '@cdl/radio-group';
import Grid from '@material-ui/core/Grid';
import Datagrid from "common/components/Datagrid/Datagrid";
import Divider from "common/components/Divider/Divider";
import EditableButton from 'common/components/EditableButton/EditableButton';
import Heading from "common/components/Heading/Heading";
import RadioVertical from "common/components/RadioVertical/RadioVertical";
import UserMessage from 'common/components/UserMessage/UserMessage';
import { updateMasterDocByFields, updateSubmitErrors } from 'common/redux/actions';
import ModuleService from 'common/services/module.service';
import UserService from 'common/services/user.service';
import { getFieldName } from 'common/utils/FieldNameUtil';
import React from 'react';
import { connect } from 'react-redux';
import { formateAmount } from 'common/utils/AmountFormatter';
import Checkbox from 'common/components/Checkbox/Checkbox';
import { isDC, isDADP } from 'common/utils/MasterDocUtil';

class ExportInstruction extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        eidto: props.eidto,
        error: false,
        oriDC: false,
        oriDADP: false
      };

      this.releasedocPayment = getFieldName(props.isEDS, 'eidto.ReleaseDoc_Payment');
      this.protest = getFieldName(props.isEDS, 'eidto.Protest');
      this.donotprotest = getFieldName(props.isEDS, 'eidto.DoNotProtest');
      this.collectoverseacharges = getFieldName(props.isEDS, 'eidto.CollectOverseaCharges');
      this.deductproceedcharges = getFieldName(props.isEDS, "eidto.DeductProceedCharges");
      this.waive = getFieldName(props.isEDS, 'eidto.Waive');
      this.notWaive = getFieldName(props.isEDS, 'eidto.DoNotWaive');
      this.releaseDocAccept = getFieldName(props.isEDS, 'eidto.ReleaseDoc_Accept');
      this.incaseofneedreferto = getFieldName(props.isEDS, "eidto.InCaseOfNeedReferTo");
      this.acceptawaitarrival = getFieldName(props.isEDS, "eidto.AcceptAwaitArrival");
      this.fromproceed = getFieldName(props.isEDS, "eidto.FromProceed");
      this.collectinterest = getFieldName(props.isEDS, "eidto.CollectInterest");
      this.incaseofneed = getFieldName(props.isEDS, "eidto.InCaseOfNeed");
      this.fromproceeddeduct = getFieldName(props.isEDS, "eidto.FromProceedDeduct");
      this.intereststartdate = getFieldName(props.isEDS, "eidto.InterestStartDate");
      this.interestenddate = getFieldName(props.isEDS, "eidto.InterestEndDate");
      this.interestRate = getFieldName(props.isEDS, "eidto.InterestRate");
      this.cable = getFieldName(props.isEDS, 'eidto.ByCable');
      this.airmail = getFieldName(props.isEDS, 'eidto.ByAirmail');
    }

    componentDidMount() {
      if(this.props.isEDS){
        const eiType = this.props.eidto.EIType;

        if(isDC(eiType)){
          this.setState({
            oriDC: true,
          })
        }else if(isDADP(eiType)){
          this.setState({
            oriDADP: true
          })
        }

      }
    }

    static getDerivedStateFromProps (props, state) {

      if (props.eidto !== state.eidto) {
        const eiType = props.isEDS ? props.eidto.EIType : props.eidto.eitype;
        let disposal = '';
        if(isDC(eiType)){
          disposal = 'pn';
        }else if(isDADP(eiType)){
          disposal = 'dadp';
        }
        return {
          eidto: props.eidto,
          error: false,
          disposal,
        }
      }
      if (props.isEDS) {
        if(props.submitErrors?.exportinstructions?.EIType){
          return {
            error: true
          }
        }
      } else if(props.submitErrors?.exportinstructions?.eitype){
          return {
            error: true
          }
        }

      if(props.masterDoc.clearOption){
        return {
          disposal: '',
        }
      }
      return null;
    }

    updateLabel = (name, value) => {
      this.props.updateMasterDocByFields({field: `eidto.${  name}` , data: value});
      this.setStateForEditing(name, value);
    }

    setStateForEditing = (name, value) => {
      this.setState(prevState => {
        if (!prevState.eidto) {
          prevState.eidto = {};
        }
        prevState.eidto[name] = value;
        return {
          eidto: prevState.eidto
        }
      })
    }

    changeEditableLabel = (name, value) => {
      this.setStateForEditing(name, value);
    }

    changeSelection = (event) => {
      const {value} = event.target;

      this.props.updateMasterDocByFields({field: this.props.isEDS ? 'eidto.EIType' : 'eidto.eitype', data: value});
      const isLFEDS = ModuleService.hasLFEDSModule();
      if((value === 'ND' || value === 'NA') && !isLFEDS){
        this.props.updateMasterDocByFields({field: this.props.isEDS ? 'eidto.FinanceIndemnity' : 'eidto.financeindemnity', data: true});
      }else{
        this.props.updateMasterDocByFields({field: this.props.isEDS ? 'eidto.FinanceIndemnity' : 'eidto.financeindemnity', data: false});
      }
      this.setStateForEditing((this.props.isEDS ? 'EIType' : 'eitype'), value);
      this.deleteErrorMsg();
      this.setOtherInstrValues(value);
      if(this.props.updateStatus){
        this.props.updateStatus();
      }
      this.setState({disposal: isDC(value) ? 'pn' : 'dadp'});
    }

    setOtherInstrValues = (eiType) => {
      if (eiType==='FI' || eiType==='PP') {
        this.props.updateMasterDocByFields({field: this.releasedocPayment, data: true, isInit: true});
        this.props.updateMasterDocByFields({field: this.releaseDocAccept, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.protest, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.donotprotest, data: true, isInit: true});
        this.props.updateMasterDocByFields({field: this.collectoverseacharges, data: true, isInit: true});
        this.props.updateMasterDocByFields({field: this.deductproceedcharges, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.waive, data: true, isInit: true});
        this.props.updateMasterDocByFields({field: this.notWaive, data: false, isInit: true});

      } else {
        this.props.updateMasterDocByFields({field: this.releasedocPayment, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.releaseDocAccept, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.protest, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.donotprotest, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.collectoverseacharges, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.deductproceedcharges, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.waive, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.notWaive, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.incaseofneedreferto, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.acceptawaitarrival, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.fromproceed, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.collectinterest, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.incaseofneed, data: '', isInit: true});
        this.props.updateMasterDocByFields({field: this.fromproceeddeduct, data: null, isInit: true});
        this.props.updateMasterDocByFields({field: this.intereststartdate, data: '', isInit: true});
        this.props.updateMasterDocByFields({field: this.interestenddate, data: '', isInit: true});
        this.props.updateMasterDocByFields({field: this.cable, data: false, isInit: true});
        this.props.updateMasterDocByFields({field: this.airmail, data: false, isInit: true});

        const decimalScale = this.props.isEDS ? this.props.masterDoc.InterestRateDecCat : this.props.masterDoc.interestratedeccat;
        const val = formateAmount(0, decimalScale);
        this.props.updateMasterDocByFields({field: this.interestRate, data: val, isInit: true});

      }
    }

    dataGridValueChange = (col, row, value) => {
      this.props.updateMasterDocByFields({field: `eidto.${  col  }_${  row}`, data: value});
    }

    deleteErrorMsg = () =>{
      this.props.updateSubmitErrors('exportinstructions');
      this.setState({error:false});
    }

    renderEditableButton = (id, field, isEDS) => {
      const name = getFieldName(isEDS, field);
      const fieldValue = this.state.eidto[name];
      return <EditableButton
        id={id}
        name={name}
        inputWidth="100"
        size="small"
        value={fieldValue}
        options={[]}
        onBlur={(value) => this.updateLabel(name, value)}
        onChange={(value) => this.updateLabel(name, value)}
        onClick={(value) => this.updateLabel(name, value)}
        maxLength={35}
      />
    }

    handleCheck = (event) => {
      const {value} = event.target;
      this.props.updateMasterDocByFields({field: this.props.isEDS ? 'eidto.EIType' : 'eidto.eitype', data: ''});
      this.props.updateMasterDocByFields({field: 'clearOption', data: false});
      this.setOtherInstrValues('');
      this.setState({
        disposal: value,
      });
    }

    renderDocuments() {
      const {isEDS} = this.props;
      const docCols = [
        {field: getFieldName(isEDS, "Draft"), label: "Drafts"},
        {field: getFieldName(isEDS, "Custom"), label: "Custom certificate"},
        {field: getFieldName(isEDS, "PackingList"), label: "Packing list"},
        {field: getFieldName(isEDS, "Weight"), label: "Weight list / note"},
        {field: getFieldName(isEDS, "Inspection"), label: "Inspection certificate"},
        {field: getFieldName(isEDS, "CertOfOrigin"), label: "Certificate of origin"},
        {field: getFieldName(isEDS, "CommInv"), label: 'Invoices'},
        {field: getFieldName(isEDS, "CustomInv"), label: 'Customs invoice'},
        {field: getFieldName(isEDS, "BL"), label: "Bill of lading"},
        {field: getFieldName(isEDS, "AirWayBill"), label: "Airway bill"},
      ];


      const noOfCopies = {field: getFieldName(isEDS, "Copy"), label: "No. of copies"};
      const noOfOriginals = {field: getFieldName(isEDS, "Orig"), label: "No. of originals"};

      const noOfSetObj = {field: getFieldName(isEDS, "Set"), label: "No. of set"};

      const docRows = [noOfSetObj, noOfOriginals, noOfCopies];

      const billCols = [
          {field: getFieldName(isEDS, "Insurance"), label: "Insurance policy"},
          {field: getFieldName(isEDS, "NonNego"), label: "Non-negotiable seaway bill"},
          {field: getFieldName(isEDS, "ExpLic"), label: "Export license"},
          {field: getFieldName(isEDS, "Shipment"), label: "Shipment advice"},
          {field: getFieldName(isEDS, "Beneficiary"), label: "Beneficiary certificate"},
          {field: getFieldName(isEDS, "Cargo"), label: "Forwarders cargo certificate"},
          {field: getFieldName(isEDS, "GSP"), label: "GSP form A"},
          {field: getFieldName(isEDS, "Quality"), label: "Certificate of quality / quantity"},
      ];


      const packingRows = [noOfSetObj, {field: getFieldName(isEDS, "Orig"), label: "No. of originals"}, noOfCopies];

      const packingCols = [
        {field: getFieldName(isEDS, "OtherDoc1"), label: this.renderEditableButton("otherDocsOne", 'OtherDoc1_Label', isEDS), cellCenter: "center"},
        {field: getFieldName(isEDS, "OtherDoc2"), label: this.renderEditableButton("otherDocsTwo", 'OtherDoc2_Label', isEDS), cellCenter: "center"},
        {field: getFieldName(isEDS, "OtherDoc3"), label: this.renderEditableButton("otherDocsThree", 'OtherDoc3_Label', isEDS), cellCenter: "center"},
        {field: getFieldName(isEDS, "OtherDoc4"), label: this.renderEditableButton("otherDocsFour", 'OtherDoc4_Label', isEDS), cellCenter: "center"},
        {field: getFieldName(isEDS, "OtherDoc5"), label: this.renderEditableButton("otherDocsFive", 'OtherDoc5_Label', isEDS), cellCenter: "center"},
        {field: getFieldName(isEDS, "OtherDoc6"), label: this.renderEditableButton("otherDocsSix", 'OtherDoc6_Label', isEDS), cellCenter: "center"}
      ];

      return (
        <>
          <Heading level={2} brandbar><span className="medium">Documents</span></Heading>

          <Grid container justify="space-between" spacing={0}>
            <Grid item>
              <Datagrid
                splitter="_"
                value={this.state.eidto}
                valueChange={this.dataGridValueChange}
                columns={docCols}
                rows={docRows}
                padding="8"
                cellWidth="89"
                headerWidth="70"
                maxLength={1}
              />
            </Grid>
            <Grid item>
              <Datagrid
                splitter="_"
                value={this.state.eidto}
                valueChange={this.dataGridValueChange}
                columns={billCols}
                rows={docRows}
                padding="8"
                floatRight="true"
                cellWidth="115"
                headerWidth="70"
                maxLength={1}
              />
            </Grid>
          </Grid>

          <Grid container spacing={0} style={{ paddingTop: '20px' }}>
            <Grid item xs={12}>
              <Datagrid
                splitter="_"
                value={this.state.eidto}
                valueChange={this.dataGridValueChange}
                columns={packingCols}
                rows={packingRows}
                padding="8"
                cellWidth="158"
                headerWidth="70"
                maxLength={1}
              />
            </Grid>
          </Grid>
        </>
    )
  }

    render() {
      const {isEDS} = this.props;
      const disabledOption = this.props.masterDoc?.dcno?.length > 0 || (this.props.masterDoc?.dcdate?.length >0 && this.props.masterDoc?.dcdate !== 'Invalid date');

      let labelNU = "NEGOTIATION under Usance DC confirmed by HSBC";
      if(UserService.getCurrentUser().countryname?.toUpperCase() !== 'MALAYSIA'){
        labelNU += " (as per Terms and Conditions)";
      }

      const eiType = isEDS ? this.state.eidto?.EIType : this.state.eidto?.eitype;

      const disabledDC = isDADP(eiType) || this.state.oriDADP;
      const disabledDADP = isDC(eiType) || this.state.oriDC;

      return (
        <div>
          <Heading level={2} brandbar><span className="medium">Document for disposal</span></Heading>

          <Paragraph>This is an application for the trade service(s) specified below. Please select the trade service(s) you require,
            complete the required information and provide any instructions related to the trade service(s).
          </Paragraph>

          <Grid container spacing={0} style={{paddingTop:'20px'}}>
            <Grid item xs={3}>
              <Checkbox
                label="Presentation/Negotiation"
                id="pn"
                value="pn"
                onChange={this.handleCheck}
                checked={isDC(eiType) || this.state.disposal==='pn'}
                disabled={isEDS}
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={3}>
              <Checkbox
                label="Documents for Collection"
                id="dadp"
                value="dadp"
                onChange={this.handleCheck}
                checked={isDADP(eiType) || this.state.disposal==='dadp'}
                disabled={isEDS || disabledOption}
              />
            </Grid>

            {this.state.error &&
              <Grid item xs={12} style={{paddingTop:'20px'}}>
                <UserMessage
                  showCloseButton
                  border
                  type="userError"
                  iconName="triangle-error"
                  iconColor="ERROR"
                  handleCloseButtonClick={this.deleteErrorMsg}
                  closeText="Close"
                >
                  Please select one of the following options
                </UserMessage>
              </Grid>}

            <Grid item xs={7}>
              <h4>6.1 Presentation/Negotiation under DC</h4>
              <RadioVertical name="docType">
                <Radio
                  onChange={this.changeSelection}
                  id="payment-after-receipt"
                  checked={eiType === 'PA'}
                  label="PAYMENT to us after receipt of funds from the Issuing/Nominated bank under DC without financing."
                  value="PA"
                  disabled={disabledDC}
                />
                <Radio
                  onChange={this.changeSelection}
                  id="advance-payment"
                  label="ADVANCE PAYMENT under DC restricted to paying bank (Please also fill in the box 'Loan Granted under Restricted Bill')"
                  value="AD"
                  checked={eiType === 'AD'}
                  disabled={disabledDC}
                />
                <Radio
                  onChange={this.changeSelection}
                  id="nego-under-dc"
                  checked={eiType === 'ND'}
                  label="NEGOTIATION under documentary credit ('DC')"
                  value="ND"
                  disabled={disabledDC}
                />
                <Radio
                  onChange={this.changeSelection}
                  id="nego-after-receipt"
                  checked={eiType === 'NA'}
                  label="NEGOTIATION after receipt of acceptance from DC issuing bank"
                  value="NA"
                  disabled={disabledDC}
                />
                <Radio
                  onChange={this.changeSelection}
                  id="nego-under-usance"
                  checked={eiType === 'NU'}
                  label={labelNU}
                  value="NU"
                  disabled={disabledDC}
                />
              </RadioVertical>

            </Grid>
            <Grid item xs={5}>
              <h4>6.2 Documents for Collection</h4>
              <RadioVertical name="docType">
                <Radio
                  disabled={disabledDADP || disabledOption}
                  onChange={this.changeSelection}
                  id="financing-dc"
                  checked={eiType === 'FI'}
                  label="FINANCING under D/P or D/A"
                  value="FI"
                />
                <Radio
                  onChange={this.changeSelection}
                  id="payment-after-proceed"
                  checked={eiType === 'PP'}
                  label="PAYMENT to us after proceeds received under D/P or D/A"
                  value="PP"
                  disabled={disabledDADP || disabledOption}
                />
              </RadioVertical>
            </Grid>
          </Grid>

          <Divider short />

          {this.renderDocuments()}

        </div>
      );
    }
}

const mapStateToProps = state => ({
  eidto: state.form?.masterDoc?.values?.eidto || {},
  submitErrors: state.form?.masterDoc?.submitErrors,
  masterDoc: state.form?.masterDoc?.values,
})

const mapDispatchToProps = dispatch => {
  return {
    updateMasterDocByFields: (payload) => dispatch(updateMasterDocByFields(payload)),
    updateSubmitErrors: (payload) => dispatch(updateSubmitErrors(payload)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ExportInstruction)
