
import Grid from '@material-ui/core/Grid';
import renderInput from 'common/components/ReduxForm/RenderInput';
import renderDropdown from 'common/components/ReduxForm/RenderDropdown';
import ModuleService from 'common/services/module.service';
import React from 'react';
import { Field } from 'redux-form';
import { getFieldName } from 'common/utils/FieldNameUtil';

export default class BankImporter extends React.PureComponent {

    renderAddress = (isEDS, label, name) => {
      return (
        <Grid item xs={4}>
          <Field
            label={label}
            id={name.toLowerCase()}
            name={isEDS ? name : name.toLowerCase()}
            component={renderInput}
            maxLength={35}
          />
        </Grid>
      )
    }

    clearValue = (fieldName)=> {
      this.props.updateMasterDocByFields({field: fieldName, data: '', isInit: true})
    }

    render() {
        const {isEDS} = this.props;
        const star = ModuleService.hasLFEDSModule() || isEDS ? "*" : "";
        return (
          <Grid container spacing={0} style={{ paddingTop: '20px' }}>
            <Grid item xs={3}>
              <Field
                component={renderInput}
                label={`Bank name${  star}`}
                id="bankName"
                name={isEDS ? 'bankName' : 'bankname'}
                maxLength={55}
                disabled={this.props.isFieldDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderInput}
                label="Bank branch"
                id="bankBranch"
                name={isEDS ? 'bankBranch' : 'bankbranch'}
                maxLength={35}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderInput}
                label="Bank reference"
                id="bankRef"
                name={isEDS ? 'bankRef' : 'bankref'}
                maxLength={20}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderInput}
                label="Bank trade department"
                id="BankTradeDept"
                name={isEDS ? 'bankTradeDept' : 'banktradedept'}
                maxLength={35}
              />
            </Grid>

            {this.renderAddress(isEDS, "Address line 1", 'bankAddr1')}
            {this.renderAddress(isEDS, "Address line 2", 'bankAddr2')}
            {this.renderAddress(isEDS, "Address line 3", 'bankAddr3')}

            <Grid item xs={3}>
              <Field
                component={renderInput}
                label="City"
                id="BankCity"
                name={isEDS ? 'bankCity' : 'bankcity'}
                maxLength={35}
                disabled={this.props.isFieldDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderInput}
                label="State"
                id="BankState"
                name={isEDS ? 'bankState' : 'bankstate'}
                maxLength={35}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderDropdown}
                labelOutside="Country / Territory"
                id="country"
                name={isEDS ? 'bankCountry' : 'bankcountry'}
                options={this.props.optCountry}
                disabled={this.props.isFieldDisabled}
                clearValue={()=>this.clearValue(getFieldName(isEDS, 'bankCountry'))}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderInput}
                label="Postal code"
                id="BankPostCode"
                name={isEDS ? 'bankPostCode' : 'bankpostcode'}
                maxLength={10}
              />
            </Grid>
          </Grid>
        )
    }
}
