import CDLCheckbox from '@cdl/checkbox';
import { ROW_SELECTED_CLASS } from 'common/utils/Constants';
import { randomString } from 'common/utils/StringUtils';
import $ from "jquery";
import React from 'react';
import styled from "styled-components";

const CheckboxWrapper = styled(CDLCheckbox)`
    & span {
        top: -3px;
        width: 20px;
        height: 20px;
    }
`;

export default class TableCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            idStr: props.id || randomString(),
        };
    }

    componentDidMount() {
        this.toggleRowClass();
    }

    componentDidUpdate(){
        this.toggleRowClass();
    }

    handleChange = (event) => {
        console.log(event.target);

        const row = $(event.target).parent().parent().parent();
        const {checked} = event.target;

        if(checked){
            row.addClass(ROW_SELECTED_CLASS);
        }else{
            row.removeClass(ROW_SELECTED_CLASS);
        }

        if(this.props.onChange){
            this.props.onChange(event);
        }
    }

    toggleRowClass() {
        if (this.props.defaultChecked || this.props.checked) {
            $(`#${this.state.idStr}`).parent().parent().parent().addClass(ROW_SELECTED_CLASS);
        } else {
            $(`#${this.state.idStr}`).parent().parent().parent().removeClass(ROW_SELECTED_CLASS);
        }
    }

    render() {
        const {onChange, id, ...rest} = this.props;

        return (
          <CheckboxWrapper className="TableCheckbox" id={this.state.idStr} onChange={this.handleChange} {...rest} />
        );
    }
}

