import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Autocomplete from 'common/components/Autocomplete/Autocomplete';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import NumberField from "common/components/NumberField/NumberField";
import TextArea from "common/components/TextArea/TextArea";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import { required } from 'common/utils/ReduxFormValidation';
import React from 'react';
import { connect } from 'react-redux';

class InvoiceItemDetail extends React.Component {
  constructor(props) {
    super(props);
    const editData = props.data || {InvDetQty: 0, InvDetUnitPrice: 0}
    this.state = {
        data: {...editData},
        isEdit: props.data && Object.keys(props.data).length > 0
    };
  }


    save = () => {
      console.log(this.state);
      const msgProdCode = required(this.state.data?.InvDetProdCode);
      const msgProdDesc = required(this.state.data?.InvDetProdDesc);
      if(msgProdCode !=='' && msgProdDesc !==''){
        if(msgProdCode !==''){
          this.setState({
            invalidProdCode: msgProdCode,
          });
        }
        if(msgProdDesc !==''){
          this.setState({
            invalidProdDesc: msgProdDesc,
          });
        }
      }else{
        this.setState(prevState => {
          console.log(prevState);
          const dataNew = prevState.data;
          dataNew.InvDetSubTotal = Number(prevState.data.InvDetQty) * Number(prevState.data.InvDetUnitPrice);
          console.log(dataNew.InvDetSubTotal);
          return {
            data: dataNew
          }
        },
        ()=>this.props.save(this.state.data, this.props.selectedIndex)
        );
      }
    }

    handleInputValue = (name, val) => {
      const {data} = this.state;
      data[name] = val;
      this.setState({ data });
      if(name==='InvDetProdCode'){
        this.setState({ invalidProdCode: '' });
      }
    }

    handleChange = (event) => {
        const {data} = this.state;
        const {name, value} = event.target;
        data[name] = value;
        this.setState({ data });
        if(name==='InvDetProdDesc'){
          this.setState({ invalidProdDesc: '' });
        }
    }


    render(){
    return (
      <div>
        <Modal
          ariaLabelledby="modal-heading"
          isOpen={this.props.isOpen}
          width={600}
        >
          <ModalHeader hasTopLine>
            <ModalTitle>Invoice item details</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.props.close} />
            </ModalBtnList>

          </ModalHeader>
          <ModalContent>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <TextFieldForm
                  label={this.props.labelHeaders.casenoheading}
                  name="InvDetCaseNo"
                  value={this.state.data?.InvDetCaseNo}
                  onChange={this.handleChange}
                  maxLength={35}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  label={this.props.labelHeaders.partnoheading}
                  id="prodCode"
                  name="prodCode"
                  userInput={this.state.data?.InvDetProdCode}
                  options={this.props.prodCodeList}
                  onChange={value => this.handleInputValue('InvDetProdCode', value)}
                  maxLength={35}
                  invalidMessage={this.state.invalidProdCode}
                  invalid={this.state.invalidProdCode}
                />
              </Grid>
              <Grid item xs={12}>
                <TextArea
                  label={this.props.labelHeaders.proddescheading}
                  name="InvDetProdDesc"
                  value={this.state.data?.InvDetProdDesc}
                  onChange={this.handleChange}
                  invalidMessage={this.state.invalidProdDesc}
                  invalid={this.state.invalidProdDesc?.length>0}
                  maxLength={255}
                />
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={4}>
                  <NumberField
                    label={this.props.labelHeaders.qtynoheading}
                    name="InvDetQty"
                    value={this.state.data?.InvDetQty}
                    onChange={this.handleInputValue}
                    maxLength={14}
                    decimalScale={this.props.masterDoc.qtydeccat}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumberField
                    label="Unit price"
                    name="InvDetUnitPrice"
                    value={this.state.data?.InvDetUnitPrice}
                    onChange={this.handleInputValue}
                    maxLength={16}
                    decimalScale={this.props.masterDoc.unitpricedeccat}
                  />
                </Grid>
                <Grid item xs={4}>
                  <NumberField
                    label="Amount"
                    name="InvDetSubTotal"
                    value={this.state.data?.InvDetQty * this.state.data?.InvDetUnitPrice}
                    disabled
                    decimalScale={this.props.masterDoc.amtdeccat}
                    maxLength={16}
                  />
                </Grid>
              </Grid>
            </Grid>
          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.props.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button onClick={this.save} themeColor="primary">Save</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>


      </div>
    )
    }
}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values,
})

export default connect(mapStateToProps)(InvoiceItemDetail)
