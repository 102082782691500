import Collapsible from "common/components/Collapsible/Collapsible";
import BillExchangeSections from 'de/views/DocPreparation/Sections/BillExchangeSections';
import React from 'react';
import { fixActionBarBottom, initActionBarPosition } from 'common/utils/UserInterfaceUtil';

export default class BillofExchange extends React.PureComponent {

  componentDidMount(){
      initActionBarPosition();
      fixActionBarBottom();
  }

  render() {

    return (
      <Collapsible label="5. Bill of exchange" className="collapsible last" section='boe'>
        <BillExchangeSections />
      </Collapsible>
    )
  }
}

