import CDLIcon from "@cdl/icon";
import { ModalAction, ModalActionButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import React from 'react';

export default class WolfMsg extends React.PureComponent {

    render() {

        return (
          <Modal
            ariaLabelledby="modal-heading"
            width={500}
            isOpen={this.props.isOpen}
          >
            <ModalHeader>
              <ModalTitle>
                <span style={{paddingRight:'5px'}}>
                  <CDLIcon name="circle-error-solid" color="WARNING" size={29} />
                </span>
                Something goes wrong!
              </ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={this.props.close} />
              </ModalBtnList>
            </ModalHeader>
            <ModalContent>
              <div style={{paddingBottom: '20px'}}>
                Invalid character found in: <br />
                {
                  this.props.fields.map(key => {
                    return (<React.Fragment key={key}>{key}<br /></React.Fragment>)
                  })
                }
                To be compliant with SWIFT standard, only English characters will be accepted.
              </div>
            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>
                <ModalActionButton>
                  <Button themeColor="primary" onClick={this.props.close}>OK</Button>
                </ModalActionButton>
              </ModalAction>
            </ModalFooter>
          </Modal>
        )
    }
}
