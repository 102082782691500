import React from 'react';
import ImporterBankService from 'common/services/importerBank.service';
import UserService from 'common/services/user.service';
import { WOLF_SCAN_BANK_FIELDS } from 'common/utils/Constants';
import GenericPopup from 'common/views/Popup/GenericPopup';

const initalData = Object.freeze({
  bankName: "", bankBranch: "", bankCBIDCode: 0, bankRef: "", bankTradeDept: "",
  bankAddr1: "", bankAddr2: "", bankAddr3: "", bankCity: "", bankState: "", bankCountry: "", bankPostCode: ""
});

export default class AddImporterBank extends React.Component {

    save = (data) => {
      if (this.props.edit) {
        return ImporterBankService.saveCollectingBank(data);
      }
      return ImporterBankService.addCollectingBank(data, this.props.coDocExpressID);
    }

    render(){

      const fields = [
        {id: 'bankName', label:'Bank name*', name: 'bankName', maxLength: 55, size: 3, isMandatory: true, trim: true},
        {id: 'bankBranch', label:'Bank branch', name: 'bankBranch', maxLength: 35, size: 3, },
        {id: 'bankRef', label:'Bank reference', name: 'bankRef', maxLength: 20, size: 3,},
        {id: 'bankTradeDept', label:'Bank trade department', name: 'bankTradeDept', maxLength: 35, size: 3,},

        {id: 'bankAddr1', label:'Address line 1', name: 'bankAddr1', maxLength: 35, size: 4, },
        {id: 'bankAddr2', label:'Address line 2', name: 'bankAddr2', maxLength: 35, size: 4, },
        {id: 'bankAddr3', label:'Address line 3', name: 'bankAddr3', maxLength: 35, size: 4, },

        {id: 'bankCity', label:'City*', name: 'bankCity', maxLength: 35, size: 3, isMandatory: true,},
        {id: 'bankState', label:'State', name: 'bankState', maxLength: 35, size: 3, },
        {id: 'bankCountry', label:'Country / Territory', name: 'bankCountry', type: 'dropdown', size: 3, options: this.props.options},
        {id: 'bankPostCode', label:'Postal code', name: 'bankPostCode', maxLength: 10, size: 3, },
      ]

      const displayFieldTile = UserService.getCurrentUser()?.userroles === 'BU' ? "Importer's bank / collecting bank" : "Collecting bank";

      return (
        <GenericPopup
          initalData={initalData}
          fields={fields}
          {...this.props}
          save={this.save}
          displayField={['bankName', 'bankCity']}
          displayFieldTile={displayFieldTile}
          wolfField={WOLF_SCAN_BANK_FIELDS}
          popupWidth={800}
          tailMap={{'-8':'Reached maximum number of entries allowed.'}}
        />
      )
    }
}
