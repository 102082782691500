import Grid from '@material-ui/core/Grid';
import Divider from "common/components/Divider/Divider";
import Heading from "common/components/Heading/Heading";
import NewButton from "common/components/NewButton/NewButton";
import renderInput from 'common/components/ReduxForm/RenderInput';
import renderAutocomplete from 'common/components/ReduxForm/RenderAutocomplete';
import renderDropdown from 'common/components/ReduxForm/RenderDropdown';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import { updateMasterDocByFieldArray, updateMasterDocByFields } from 'common/redux/actions';
import MasterDocService from "common/services/masterDoc.service";
import ModuleService from 'common/services/module.service';
import UserService from 'common/services/user.service';
import { getCurrentTab } from "common/utils/AppUtil";
import { TABS } from 'common/utils/Constants';
import { getFieldName } from 'common/utils/FieldNameUtil';
import { getCountry, getOptionRealTime } from 'common/utils/OptionUtil';
import { isBlank } from 'common/utils/StringUtils';
import AddImporterConsigneeFowardingAgent from 'common/views/Popup/AddImporterConsigneeFowardingAgent';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import AddExporter from '../Popup/AddExporter';

const exporterFieldForEDS = {
  exportercode: 'exporterCode',
  expname: 'expName',
  expacctno: 'expAcctNo',
  expaddr1: 'expAddr1',
  expaddr2: 'expAddr2',
  expaddr3: 'expAddr3',
  expcity: 'expCity',
  expstate: 'expState',
  expcountry: 'expCountry',
  exppostcode: 'expPostCode',
  exptel: 'expTel',
  expfax: 'expFax',
  expemail: 'expEmail',
  expref: 'expRef',
  expregno: 'expRegNo',
  expdbacctno: 'expDBAcctNo',
  amtdeccat: 'amtDecCat',
  interestratedeccat: 'interestRateDecCat',
  tradegatecustomsid: 'tradeGateCustomsID',

  importercode: 'importerCode',
  impname: 'impName',
  impref: 'impRef',
  impaddr1: 'impAddr1',
  impaddr2: 'impAddr2',
  impaddr3: 'impAddr3',
  impcity: 'impCity',
  impstate: 'impState',
  impcountry: 'impCountry',
  imppostcode: 'impPostCode',
  imptel: 'impTel',
  impfax: 'impFax',
  impemail: 'impEmail',
  def_tenor: 'def_tenor',
  def_incoterm: 'def_incoterm'
}

class ExporterDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openExporterPopup: false,
      openImporterPopup: false,
      openMessageDialog: false,
      message: "",
      exporterOptions: [],
      importerOptions: []
    }

    this.optCountry = getCountry();
    this.isFieldDisabled = false;
  }


  componentDidUpdate() {

    this.isFieldDisabled = UserService.getCurrentUser()?.isCompanyEDS || this.props.isEDS;

    if(this.state.importerOptions.length===0 && (this.props.masterDoc.codocexpressid||this.props.masterDoc.CoDocExpressId)){
      this.getImporterCode().then(res=>{
        if(res.length>0){
          this.setState({
            importerOptions: res
          })
        }
      })
    }
  }

  showSuccessMessage = (msg) => {
    this.setState({
      message: msg,
      openMessageDialog: true
    });
  }


  closeExporterPopup = () => {
    this.setState({openExporterPopup: false});
  }

  closeImporterPopup = () => {
    this.setState({openImporterPopup: false});
  }


  closeMessage = () => {
    this.setState({
      message: "",
      openMessageDialog: false
    });
  }

  changeExporter = (value) => {
    const currentTab = getCurrentTab();

    if(this.state.exporterOptions.find(option => option.value === value)){

      MasterDocService.getMasterDocExpDetailsByCode(value, currentTab === TABS.EDS_DASHBOARD ? this.props.masterDoc.CoDocExpressId : null).then(res => {
        if(res){

          const data = this.appendFields(res, currentTab === TABS.EDS_DASHBOARD ? 'exporterCode' : 'exportercode');

          if(currentTab === TABS.BOE){
            data.push({ field: "billofexchangedto.forandonbehalfof", data: res.expName });
            data.push({ field: "billofexchangedto.forandonbehalfofaddr1", data: res.expAddr1 });
            data.push({ field: "billofexchangedto.forandonbehalfofaddr2", data: res.expAddr2 });
            data.push({ field: "billofexchangedto.forandonbehalfofaddr3", data: res.expAddr3 });
            data.push({ field: "billofexchangedto.forandonbehalfofcity", data: res.expCity });
            data.push({ field: "billofexchangedto.forandonbehalfofstate", data: res.expState });
            data.push({ field: "billofexchangedto.forandonbehalfofcountry", data: res.expCountry });
            data.push({ field: "billofexchangedto.forandonbehalfofpostcode", data: res.expPostCode });
          }

          this.props.updateMasterDocByFieldArray({ fieldArray: data });
        }
      });
    }
  }

  populateOther = (res, data, isEDS) => {
    const transportationMode = isEDS ? this.props.masterDoc.transportationMode : this.props.masterDoc.transportationmode;
    if(transportationMode!=="Not Applicable"){
      data.push({ field: getFieldName(isEDS, 'portLoading'), data: res.def_PortLoad });
      data.push({ field: getFieldName(isEDS, 'portDischarge'), data: res.def_PortDischarge });
    }
    data.push({ field: getFieldName(isEDS, 'tenorID'), data: res.def_Tenor });
    data.push({ field: getFieldName(isEDS, 'incotermID'), data: res.def_Incoterm });
  }

  changeImporter = (value) => {
    const currentTab = getCurrentTab();
    const isEDS = currentTab === TABS.EDS_DASHBOARD;

    if(this.state.importerOptions.find(option => option.value === value)
        && value!==(isEDS ? this.props.masterDoc.importerCode : this.props.masterDoc.importercode)){

      MasterDocService.getMasterDocImpDetailsByCode(value, isEDS ? this.props.masterDoc.CoDocExpressId : null).then(res => {
        if(res){
          const data = this.appendFields(res, getFieldName(isEDS, 'importerCode'));

          this.populateOther(res, data, isEDS);

          this.populateConignee(res.def_Consignee, isEDS);

          this.populateBank(res.def_Bank, isEDS);

          if(currentTab === TABS.BOE){
            data.push({ field: "billofexchangedto.boeto", data: res.impName });
            data.push({ field: "billofexchangedto.boetoaddr1", data: res.impAddr1 });
            data.push({ field: "billofexchangedto.boetoaddr2", data: res.impAddr2 });
            data.push({ field: "billofexchangedto.boetoaddr3", data: res.impAddr3 });
            data.push({ field: "billofexchangedto.boetocity", data: res.impCity });
            data.push({ field: "billofexchangedto.boetostate", data: res.impState });
            data.push({ field: "billofexchangedto.boetocountry", data: res.impCountry });
            data.push({ field: "billofexchangedto.boetopostcode", data: res.impPostCode });
          }

          this.props.updateMasterDocByFieldArray({ fieldArray: data });


        }
      });
    }
  }

  appendFields = (res, exclude) => {
    const data = [];
    Object.keys(res).forEach(key => {
      let keyStr = key.toLowerCase();
      if(this.props.isEDS && exporterFieldForEDS[keyStr]){
        keyStr = exporterFieldForEDS[keyStr];
      }
      const val = res[key];
      if(keyStr!==exclude){
        data.push({ field: keyStr, data: val });
      }
    });
    return data;
  }

  getImporterCode = () => {
    return getOptionRealTime('getMasterDocImpCode', this.props.isEDS ? this.props.masterDoc.CoDocExpressId : null);
  }

  clearValue = (fieldName)=> {
    this.props.updateMasterDocByFields({field: fieldName, data: '', isInit: true})
  }

  populateConignee = (consigneeCode, isEDS) => {
    const consigneeData = [];
    if(!isBlank(consigneeCode) && consigneeCode !== this.props.masterDoc.consigneecode){
      MasterDocService.getMasterDocImpDetailsByCode(consigneeCode, isEDS ? this.props.masterDoc.CoDocExpressId : null).then(resp => {
        if (resp) {
          consigneeData.push({ field: getFieldName(isEDS, 'consigneeCode'), data: consigneeCode });
          consigneeData.push({ field: getFieldName(isEDS, 'consigneeName'), data: resp.impName });
          consigneeData.push({ field: getFieldName(isEDS, 'consigneeAddr1'), data: resp.impAddr1 });
          consigneeData.push({ field: getFieldName(isEDS, 'consigneeAddr2'), data: resp.impAddr2 });
          consigneeData.push({ field: getFieldName(isEDS, 'consigneeAddr3'), data: resp.impAddr3 });
          consigneeData.push({ field: getFieldName(isEDS, 'consigneeCity'), data: resp.impCity });
          consigneeData.push({ field: getFieldName(isEDS, 'consigneeState'), data: resp.impState });
          consigneeData.push({ field: getFieldName(isEDS, 'consigneeCountry'), data: resp.impCountry });
          consigneeData.push({ field: getFieldName(isEDS, 'consigneePostCode'), data: resp.impPostCode });

          this.props.updateMasterDocByFieldArray({ fieldArray: consigneeData });
        }
      });
    }else if(!consigneeCode){
      consigneeData.push({ field: getFieldName(isEDS, 'consigneeCode'), data: '' });
      consigneeData.push({ field: getFieldName(isEDS, 'consigneeName'), data: '' });
      consigneeData.push({ field: getFieldName(isEDS, 'consigneeAddr1'), data: '' });
      consigneeData.push({ field: getFieldName(isEDS, 'consigneeAddr2'), data: '' });
      consigneeData.push({ field: getFieldName(isEDS, 'consigneeAddr3'), data: '' });
      consigneeData.push({ field: getFieldName(isEDS, 'consigneeCity'), data: '' });
      consigneeData.push({ field: getFieldName(isEDS, 'consigneeState'), data: '' });
      consigneeData.push({ field: getFieldName(isEDS, 'consigneeCountry'), data: '' });
      consigneeData.push({ field: getFieldName(isEDS, 'consigneePostCode'), data: '' });

      this.props.updateMasterDocByFieldArray({ fieldArray: consigneeData });
    }
  }

  populateBank = (bankCode, isEDS) => {
    const bankData = [];
    if(!isBlank(bankCode) && bankCode !== this.props.masterDoc.bankcode){
      MasterDocService.getMasterDocBankDetail(bankCode).then(resp => {
        if (resp) {
          Object.keys(resp).forEach(key => {
            const keyStr = isEDS ? key : key.toLowerCase();
            const val = resp[key];
            bankData.push({ field: keyStr, data: val });
          });
          this.props.updateMasterDocByFieldArray({ fieldArray: bankData });
        }
      });
    }else if(!bankCode){

      bankData.push({ field: getFieldName(isEDS, 'bankName'), data: '' });
      bankData.push({ field: getFieldName(isEDS, 'bankBranch'), data: '' });
      bankData.push({ field: getFieldName(isEDS, 'bankRef'), data: '' });
      bankData.push({ field: getFieldName(isEDS, 'bankTradeDept'), data: '' });
      bankData.push({ field: getFieldName(isEDS, 'bankAddr1'), data: '' });
      bankData.push({ field: getFieldName(isEDS, 'bankAddr2'), data: '' });
      bankData.push({ field: getFieldName(isEDS, 'bankAddr3'), data: '' });
      bankData.push({ field: getFieldName(isEDS, 'bankCity'), data: '' });
      bankData.push({ field: getFieldName(isEDS, 'bankState'), data: '' });
      bankData.push({ field: getFieldName(isEDS, 'bankCountry'), data: '' });
      bankData.push({ field: getFieldName(isEDS, 'bankPostCode'), data: '' });
      this.props.updateMasterDocByFieldArray({ fieldArray: bankData });
    }
  }

  renderAddress(isEDS, label, name) {
    return (
      <Grid item xs={4}>
        <Field
          label={label}
          id={name.toLowerCase()}
          name={isEDS ? name : name.toLowerCase()}
          component={renderInput}
          maxLength={35}
          disabled={this.isFieldDisabled}
        />
      </Grid>
    )
  }

  renderImporter(isEDS) {
    return (
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <Field
            component={renderDropdown}
            labelOutside="Importer code*"
            id="importerCode"
            name={getFieldName(isEDS, 'importerCode')}
            maxLength={18}
            options={this.state.importerOptions}
            setOptions={(res) => this.setState({importerOptions: res})}
            onChange={this.changeImporter}
            fetchData={this.getImporterCode}
            clearValue={()=>this.clearValue(getFieldName(isEDS, 'importerCode'))}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            label="Importer name"
            id="impname"
            name={isEDS ? 'impName' : 'impname'}
            component={renderInput}
            maxLength={55}
            disabled={this.isFieldDisabled}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            label="Importer reference"
            id="impref"
            name={isEDS ? 'impRef' : 'impref'}
            component={renderInput}
            maxLength={20}
          />
        </Grid>

        {this.renderAddress(isEDS, "Address line 1", 'impAddr1')}
        {this.renderAddress(isEDS, "Address line 2", 'impAddr2')}
        {this.renderAddress(isEDS, "Address line 3", 'impAddr3')}

        <Grid item xs={3}>
          <Field
            label="City"
            id="ImpCity"
            name={isEDS ? 'impCity' : 'impcity'}
            component={renderInput}
            maxLength={35}
            disabled={this.isFieldDisabled}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            label="State"
            id="ImpState"
            name={isEDS ? 'impState' : 'impstate'}
            component={renderInput}
            maxLength={35}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={renderDropdown}
            labelOutside="Country / Territory"
            id="importerCountry"
            name={getFieldName(isEDS, 'impCountry')}
            options={this.optCountry}
            maxLength={35}
            disabled={this.isFieldDisabled}
            clearValue={()=>this.clearValue(getFieldName(isEDS, 'impCountry'))}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={renderInput}
            label="Postal code"
            id="ImpPostCode"
            name={isEDS ? 'impPostCode' : 'imppostcode'}
            maxLength={10}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={renderInput}
            label="Telephone number"
            id="ImpTel"
            name={isEDS ? 'impTel' : 'imptel'}
            maxLength={30}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={renderInput}
            label="Fax number"
            id="ImpFax"
            name={isEDS ? 'impFax' : 'impfax'}
            maxLength={30}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            label="Email address"
            id="ImpEmail"
            name={isEDS ? 'impEmail' : 'impemail'}
            component={renderInput}
            maxLength={100}
          />
        </Grid>
      </Grid>
    )
  }

  renderExporter(isEDS) {
    const star = ModuleService.hasLFEDSModule() || isEDS ? "*" : "";

    return (
      <Grid container spacing={0}>
        <Grid item xs={4}>
          <Field
            component={renderAutocomplete}
            label="Exporter code*"
            id="exportercode"
            name={getFieldName(isEDS, 'exporterCode')}
            options={[]}
            setOptions={(res) => this.setState({exporterOptions: res})}
            onChange={this.changeExporter}
            fetchData={()=>getOptionRealTime('getMasterDocExpCode', isEDS ? this.props.masterDoc.CoDocExpressId : null)}
            maxLength={18}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            label="Exporter name"
            id="exporterName"
            name={getFieldName(isEDS, 'expName')}
            component={renderInput}
            maxLength={55}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            label="Contact name"
            id="contactName"
            name={getFieldName(isEDS, 'expContact')}
            component={renderInput}
            maxLength={55}
          />
        </Grid>


        <Grid item xs={4}>
          <Field
            label="Address line 1"
            id="expaddr1"
            name={getFieldName(isEDS, 'expAddr1')}
            component={renderInput}
            maxLength={35}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            label="Address line 2"
            id="expaddr2"
            name={getFieldName(isEDS, 'expAddr2')}
            component={renderInput}
            maxLength={35}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            label="Address line 3"
            id="expaddr3"
            name={getFieldName(isEDS, 'expAddr3')}
            component={renderInput}
            maxLength={35}
          />
        </Grid>


        <Grid item xs={3}>
          <Field
            label="City"
            id="ExpCity"
            name={getFieldName(isEDS, 'expCity')}
            component={renderInput}
            maxLength={35}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            label="State"
            id="ExpState"
            name={getFieldName(isEDS, 'expState')}
            component={renderInput}
            maxLength={35}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={renderDropdown}
            labelOutside="Country / Territory"
            id="exporterCountry"
            name={getFieldName(isEDS, 'expCountry')}
            options={this.optCountry}
            maxLength={35}
            clearValue={()=>this.clearValue(getFieldName(isEDS, 'expCountry'))}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={renderInput}
            label="Postal code"
            id="ExpPostCode"
            name={getFieldName(isEDS, 'expPostCode')}
            maxLength={10}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={renderInput}
            label="Telephone number"
            id="ExpTel"
            name={getFieldName(isEDS, 'expTel')}
            maxLength={30}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={renderInput}
            label="Fax number"
            id="ExpFax"
            name={getFieldName(isEDS, 'expFax')}
            maxLength={30}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={renderInput}
            label="Email address"
            id="expemail"
            name={getFieldName(isEDS, 'expEmail')}
            maxLength={100}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            component={renderInput}
            label={`Exporter reference${star}`}
            id="exporterReference"
            name={getFieldName(isEDS, 'expRef')}
            maxLength={20}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            label="Registration number"
            id="ExpRegNo"
            name={getFieldName(isEDS, 'expRegNo')}
            component={renderInput}
            maxLength={20}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            label={`Exporter account number${star}`}
            id="ExpAcctNo"
            name={getFieldName(isEDS, 'expAcctNo')}
            component={renderInput}
            maxLength={14}
            isAccount
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            label="Debit charges account number"
            id="ExpDBAcctNo"
            name={getFieldName(isEDS, 'expDBAcctNo')}
            component={renderInput}
            maxLength={14}
            isAccount
          />
        </Grid>
      </Grid>
    )
  }

  render(){
    const {
      openExporterPopup,
      openImporterPopup,
      openMessageDialog,
      message
    } = this.state;
    const {isEDS} = this.props;
    return (
      <div style={{paddingTop:'20px'}}>
        {openExporterPopup &&
          <AddExporter isOpen={openExporterPopup} close={this.closeExporterPopup} save={this.closeExporterPopup} success={this.showSuccessMessage} />}
        {openMessageDialog &&
          <div style={{paddingRight: '20px'}}>
            <SuccessMessage message={message} handleCloseButtonClick={this.closeMessage} />
          </div>}
        {openImporterPopup &&
        <AddImporterConsigneeFowardingAgent id="exporter" isOpen={openImporterPopup} close={this.closeImporterPopup} success={this.showSuccessMessage} />}

        <Heading level={2} brandbar>
          <span className="medium">Exporter details (drawer / DC beneficiary)</span>
          {this.props.queryType !== 'enquiry' &&
            <NewButton onClick={() => this.setState({openExporterPopup: true, openMessageDialog: false})} />}
        </Heading>

        {this.renderExporter(isEDS)}

        <Divider short />


        <Heading level={2} brandbar>
          <span className="medium">Importer details (drawee / DC applicant)</span>
          {this.props.queryType !== 'enquiry' &&
            <NewButton onClick={() => this.setState({openImporterPopup: true, openMessageDialog: false})} />}
        </Heading>

        {this.renderImporter(isEDS)}


      </div>
    )
  }
}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values || {},
})

const mapDispatchToProps = dispatch => {
  return {
    updateMasterDocByFields: (payload) => dispatch(updateMasterDocByFields(payload)),
    updateMasterDocByFieldArray: (payload) => dispatch(updateMasterDocByFieldArray(payload))
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ExporterDetails)

