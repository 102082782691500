import UserService from 'common/services/user.service';
import BaseService from 'common/services/BaseService';
import { getFormName } from "common/utils/AppUtil";

class UserActLogService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/CommonLogBO/';
    this.userDTO = null;
  }

  getUserDTO() {
      const user = UserService.getCurrentUser();
      user.formname = getFormName();
      this.userDTO = {
        "userdto": {
          "user": user
        }
      }

    return this.userDTO;
  }

  // eslint-disable-next-line class-methods-use-this
  getDocExpressId() {
    return UserService.getCurrentUser().codocexpressid;
  }

  // eslint-disable-next-line class-methods-use-this
  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }


  getReportType()
  {
    const userDTO = this.getUserDTO();
    const requestBody = {
      ...userDTO
    };

    return this.post('getReportList', requestBody, false);
  }

  getCompany(sdbname){
    const requestBody = {
      "sdbname":sdbname,
      ...this.getUserDTO()
    };

    return this.post('getCompanyList', requestBody, false);
  }

  getUserList(getUserListDTO)
  {
    const userDTO = this.getUserDTO();

    const requestBody = {
      "scompanycode":getUserListDTO.scompanycode,
      "scountrycode":getUserListDTO.scountrycode,
      "susertype":getUserListDTO.susertype,
      ...userDTO
    };

    return this.post('getUserList', requestBody, false);
  }

  generateActivityReport(activityReportDto){
    const requestBody = {
      "scodocexpressid":activityReportDto.scodocexpressid,
      "sdatefrom":activityReportDto.sdatefrom,
      "sdateto":activityReportDto.sdateto,
      "sdbname":activityReportDto.sdbname,
      "sreportname":activityReportDto.sreportname,
      "sroleid":activityReportDto.sroleid,
      "srpttype":activityReportDto.srpttype,
      "stimefrom":activityReportDto.stimefrom,
      "stimeto":activityReportDto.stimeto,
      "susername":activityReportDto.susername,
      ...this.getUserDTO()
    };

    return this.post('generateActivityReport', requestBody, true);
  }

  checkBUCompany()
  {
    const userDTO = this.getUserDTO();

    const userDto = UserService.getCurrentUser();
    const sdbname = userDto.countrycode+userDto.groupmemberid;

    const requestBody = {
      "sdbname":sdbname,
      ...userDTO
    };

    return this.post('checkSBUCompany', requestBody, false);
  }

  getCountryList(sroletype){
    const requestBody = {
      "sroletype":sroletype,
      ...this.getUserDTO()
    };
    return this.post('getCountryList', requestBody);
  }
}

export default new UserActLogService();
