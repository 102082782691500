import React from "react";
import NumberFormat from 'react-number-format';
import CDLTextField from '@cdl/text-field';
import styled from "styled-components";

const TextFieldContainer = styled.div`
    width: calc(100% - 20px);

    & > div > input {
        box-sizing: border-box;
        height: 30px;
        width: 100% !important;
    } 
`;

export default class AmountField extends React.PureComponent {

    handleChange = (values) => {
        const { formattedValue } = values;
        if (this.props.onChange) {
        this.props.onChange(this.props.name, formattedValue);
        }
    }

    render() {
        return (
          <TextFieldContainer>
            <NumberFormat
              customInput={CDLTextField}
              thousandSeparator
              fixedDecimalScale
              onValueChange={this.handleChange}
              {...this.props}
            />
          </TextFieldContainer>
        )
    }
}
