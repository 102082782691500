import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import Autocomplete from "common/components/Autocomplete/Autocomplete";
import Button from "common/components/Button/Button";
import Divider from "common/components/Divider/Divider";
import IconButton from "common/components/IconButton/IconButton";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import Table from 'common/components/Table/Table';
import TextArea from "common/components/TextArea/TextArea";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import UploadFile from "common/components/UploadFile/UploadFile";
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import FileService from 'common/services/file.service';
import MasterDocService from "common/services/masterDoc.service";
import UserService from 'common/services/user.service';
import { OVERFLOW_CLASS } from "common/utils/Constants";
import { getOption } from 'common/utils/OptionUtil';
import CommentDiscrepancy from 'common/views/Popup/CommentDiscrepancy';
import Confirm from 'common/views/Popup/Confirm';
import ViewDiscrepancy from 'common/views/Popup/ViewDiscrepancy';
import React from 'react';
import { connect } from 'react-redux';
import { isAC, getFormName } from "common/utils/AppUtil";
import ValidationMessage from 'common/components/UserMessage/ValidationMessage';

const docDetailInit = {
  "contenttype": "",
  "datetimeupload": "",
  "docdesc": "",
  "docid": "",
  "docimage": "",
  "docrefno": "",
  "docrefnoname": "",
  "docsize": 0,
  "docstatus": "Pending Review",
  "doctitle": "",
  "doctype": "",
  "filename": "",
  "isdeleted": "",
  "otherdoctype": "1st Party Document",
  "remarks": "",
  "uploadedby": "",
  "userroles": ""
};


const buttonComment = [
                      {id: 'viewComment', name: 'View',  showModal: 'viewComment'}
                    ];

const discrepancyColumn = [
  {
      Header: 'Discrepancy',
      accessor: 'discrepancy'
  },
  {
      Header: 'Fixed on',
      accessor: 'fixedon'
  },
  {
      Header: 'Fixed by',
      accessor: 'fixedby'
  },
  {
      Header: 'Action',
      accessor: 'actionBtn',
      width: 120,
      sortable: false
  }
]

const commentColumn = [
  {
      Header: 'Date / Time',
      accessor: 'dateupdate'
  },
  {
      Header: 'Comment',
      accessor: 'history'
  },
  {
      Header: 'Comment by',
      accessor: 'userupdate'
  },
  {
      Header: 'Action',
      accessor: 'actionBtn',
      width: 120,
      sortable: false
  }
]

const _ = require('lodash');

class DocumentDetail extends React.Component {
    constructor(props) {
      super(props);
      const isEdit = props.data && Object.keys(props.data).length > 0;
      this.state = {
          data: props.data || _.cloneDeep(docDetailInit),
          isEdit,
          openCommentDiscrepancy: false,
          tableDiscrepancy: [],
          selectedDiscrepancy: null,
          tableComment: [],
          selectedComment: null,
          viewModeDiscrepancy: false,
          isComment: false,
          invalid: false,
          invalidMessage: "",
          success: false,
          message: "",
          isFix: false,
          openConfirm: false,
          isReadOnly: window.location.pathname.includes('GenericMDDashboard')
      };
      this.refFile = React.createRef();
      this.refDocTitle = React.createRef();
      this.refDocType = React.createRef();
      this.refDocStatus = React.createRef();
      this.docTypeOpt = getOption('getDocTypeList');
      this.docTitleOpt = getOption('getDocTitles');
      this.refNameOpt = getOption('executeSQLSP');
      this.docStatusOpt = getOption('getDocStatus');
    }

    componentDidMount() {
      this.loadTableData();
      let totalSize = 0;
      if(this.props.masterDoc?.thirdpartydoc?.length>0){
        for(let i=0; i<this.props.masterDoc?.thirdpartydoc?.length; i++){
          totalSize += this.props.masterDoc?.thirdpartydoc[i].docsize;
        }
      }
      this.setState({
        totalSize
      });
    }

    loadTableData = () =>{
      if(this.state.isEdit){
        MasterDocService.getCommentDetails(this.state.data?.docid, this.props.queryType).then(res => {
          if(res.list && res.list.length>0){
            this.setState({ tableComment: res.list });
          }
        });

        MasterDocService.getDiscrepancyDetails(this.state.data?.docid, this.props.queryType).then(res => {
          if(res.list && res.list.length>0){
            this.setState({ tableDiscrepancy: res.list });
          }
        });
      }
    }

    newCommentDiscrepancy = () => {
        this.setState({
          openCommentDiscrepancy: true,
          selectedDiscrepancy: null,
          selectedComment: null,
          isComment: false,
          viewModeDiscrepancy: false,
        });
        document.documentElement.classList.add(OVERFLOW_CLASS);
    }


    closeCommentDiscrepancy = () => {
      this.setState({
        openCommentDiscrepancy: false,
        isComment: false,
        viewModeDiscrepancy: false,
        isFix: false,
      });
    }

    handleClose = () => {
      this.props.close();
    }


    validateFile = () => {
      const file = this.refFile.current.inputRef.current.files[0];
      if(file){
        if((file.size + this.state.totalSize) > 10*1024*1024){
          this.setState({
            showErrorMessage: true,
            message: "Total uploaded file(s) size can not larger than 10MB per MD",
          })
          return false;
        }
        return this.refFile.current.validate();
      }

      return true;
    }

    isDuplicated = () =>{
      if(this.state.data.doctitle?.toUpperCase() === "COMPLETE DOCUMENTS IMAGE"){
        const completedDoc = this.props.masterDoc.thirdpartydoc.filter((item) => {
          return item.doctitle?.toUpperCase() === "COMPLETE DOCUMENTS IMAGE";
        })

        let isExist = completedDoc?.length > 0;
        if(isExist && this.state.isEdit && completedDoc[0].docid === this.state.data.docid){
          isExist = false;
        }

        if(isExist){
          this.setState({
            showErrorMessage: true,
            message: "Complete Documents Image already exists. No duplication allowed.",
          })
        }
        return isExist;
      }
      return false;
    }

    validation = () => {
      return this.validateFile() && this.refDocTitle.current.validate()
        && this.refDocType.current.validate() && this.refDocStatus.current.validate()
        && !this.isDuplicated();
    }

    uploadDoc = (docDetail) => {
      const user = UserService.getCurrentUser();
      const formData = new FormData();
      const file = this.refFile.current.inputRef.current.files[0];
      formData.append("encryptsid", user.encryptsid);
      formData.append("MDocID", this.props.mdocid);
      formData.append("docTitle", docDetail.doctitle);
      formData.append("docStatus", docDetail.docstatus);
      formData.append("remarks", docDetail.remarks);
      formData.append("docDesc", docDetail.docdesc);
      formData.append("docRefNoName", docDetail.docrefnoname);
      formData.append("docRefNo", docDetail.docrefno);
      formData.append("otherDocType", docDetail.otherdoctype);
      formData.append("DocID", docDetail.docid);
      formData.append("countrycode", user.countrycode);
      formData.append("groupmemberid", user.groupmemberid);
      formData.append("username", user.username);
      formData.append("userroles", user.userroles);
      formData.append("codocexpressid", user.codocexpressid);
      formData.append("formname", getFormName());
      formData.append("jobNo", Math.floor(Math.random() * (1 + 9999 - 1000)) + 1000);
      formData.append("currentFile", 1);
      formData.append("totalFile", 1);
      formData.append("file", file);

      FileService.upload(formData).then(res => {
        if(res && res.includes('Scan Failed')){
          this.setState({
            showErrorMessage: true,
            message: 'The uploaded file cannot be processed. Please select another file.',
          });
        }else if (this.props.isEDS) {
            MasterDocService.get3rdPartyDocsForAccessControl(this.props.mdocid, this.props.queryType).then(res2 => {
              if(res2.list && res2.list.length>0){
                this.props.renew(res2.list, docDetail.filename);
              }
            });
        } else {
          MasterDocService.get3rdPartyDocs(this.props.mdocid).then(resp => {
            if(resp.list && resp.list.length>0){
              this.props.renew(resp.list, docDetail.filename);
            }
          });
        }
      }).catch(error => {
        this.setState({
          showErrorMessage: true,
          message: error.response.data,
        })
      });

    }

    save = () => {
      if(this.validation()){
        const {data} = this.state;
        const docDetail = _.cloneDeep(docDetailInit);
        Object.keys(docDetail).forEach(key => {
          if(data[key]){
            docDetail[key] = data[key];
          }
        });

        let docid = "0";
        if(docDetail.docid && docDetail.docid.length>0){
          docid = docDetail.docid;
        }

        if(docDetail.filename !== ""){
          this.uploadDoc(docDetail);
        }else{
          MasterDocService.saveOtherDocs(docid, this.props.mdocid, docDetail).then(res => {
            if(res > 0){
             this.props.save(this.state.data, this.state.isEdit, this.props.selectedIndex);
            }
          });
        }

      }
    }

    delete = () => {
      MasterDocService.removeDocFile(this.state.data?.docid, this.props.mdocid).then(res=>{
        if(res && res>0){
          this.props.callback('', 'Uplaoded file successfully removed.');
        }
      });
      this.handleClose();
    }

    handleChange = (event) => {
      const {data} = this.state;
      data[event.target.name] = event.target.value
      this.setState({ data });
    }

    handleInputValue = (name, val) => {
      const {data} = this.state;
      data[name] = val;
      this.setState({ data });
    }

    viewDiscrepancy = (rowIndex) => {
      this.setState({
        openViewDiscrepancy: true,
        selectedDiscrepancy: rowIndex,
        viewModeDiscrepancy: true,
        isComment: false,
      })
      document.documentElement.classList.add(OVERFLOW_CLASS);
    }

    viewComment = (rowIndex) => {
      this.setState({
        openViewDiscrepancy: true,
        selectedComment: rowIndex,
        viewModeDiscrepancy: true,
        isComment: true,
      })
      document.documentElement.classList.add(OVERFLOW_CLASS);
    }

    fixDiscrepancy = (rowIndex) => {
      this.setState({
        openCommentDiscrepancy: true,
        selectedDiscrepancy: rowIndex,
        viewModeDiscrepancy: false,
        isFix: true,
      })
      document.documentElement.classList.add(OVERFLOW_CLASS);
    }

    saveFixDiscrepancy = (discrepancy) => {
      MasterDocService.saveDiscrepancyFix(discrepancy.id, discrepancy).then(res => {
        if(res>0){
          this.setState({
            success: true,
            message: "Fix discrepancy saved successfully.",
          }, this.loadTableData());
        }
      });
      this.closeCommentDiscrepancy();
    }

    saveCommentDiscrepancy = (discrepancy) => {
      MasterDocService.saveCommentDiscrepancyDetails("0", this.state.data?.docid, discrepancy).then(res => {
        if(res>0){
          this.setState({
            success: true,
            message: "Comment/Discrepancy saved successfully.",
          }, this.loadTableData());
        }
      });

      this.closeCommentDiscrepancy();
    }

    confirmRemove = () => {
      this.setState({openConfirm: true});
    }

    getDiscrepancyData = () => {
      return this.state.tableDiscrepancy?.map((t, i) => {
        const buttonArray = [
          { id: 'viewDiscrepancy', name: 'View', showModal: 'viewDiscrepancy' },
          {
            id: 'fixDiscrepancy', name: 'Fix', showModal: 'fixDiscrepancy',
            disabled: ( this.isDisableFix() || t.fixedby !== "" )
          }
        ];
        return Object.assign(t, {
          actionBtn: <ActionButtons
            rowIndex={i}
            buttonArray={buttonArray}
            viewDiscrepancy={this.viewDiscrepancy}
            fixDiscrepancy={this.fixDiscrepancy}
            justify='space-between'
          />
        });
      });
    }

    isEditableStatus= () => {
      const {masterDoc} = this.props;
      return this.isEditableInAC() || masterDoc?.status === 'COMPLETED' || masterDoc?.status === 'UNLOCKED';
    }

    isEditableInAC = () => {
      return (isAC() && this.props.queryType!=="E" && this.props.hasEdit);
    }

    shouldDisableField = () => {
      if(!this.state.isEdit){
        return false;
      }

      let isDisable = true;
      if(this.isEditableStatus()){
        const userRoleDoc = this.state.data?.userroles;
        const userRole = UserService.getCurrentUser()?.userroles;
        if((userRoleDoc === 'BU' && userRole === 'BU') ||
            ((userRoleDoc === 'CA' || userRoleDoc === 'CU') && (userRole === 'CA' || userRole === 'CU'))){
              isDisable = false;
        }
      }
      return isDisable || this.state.isReadOnly;
    }

    isDisableStatus = () => {
      let isDisable = true;
      const {masterDoc, queryType} = this.props;
      if(isAC()){
        isDisable = queryType==="E";
      } else if(masterDoc?.status === 'COMPLETED'){
        isDisable = false;
      } else if(masterDoc?.status === 'UNLOCKED') {
        const userRoleDoc = this.state.data?.userroles;
        const userRole = UserService.getCurrentUser()?.userroles;
        if((userRoleDoc === 'BU' && userRole === 'BU') || !userRoleDoc){
          isDisable = false;
        }
      }

      return isDisable || this.state.isReadOnly;
    }

    isDisableDiscrepancy = () => {
      let isDisable = true;
      const {masterDoc, queryType} = this.props;
      if(isAC()){
        isDisable = queryType==="E";
      } else if(masterDoc?.status === 'COMPLETED' || masterDoc?.status === 'UNLOCKED'){
        isDisable = false;
      }

      return isDisable || !this.state.data.docid || this.state.isReadOnly;
    }

    isDisableStatusField = () => {
      let isDisable = true;
      if(isAC()){
        isDisable = this.isDisableStatus();
      }
      return isDisable || this.state.isReadOnly;
    }

    isDisableFix = () => {
      let isDisable = true;
      const {masterDoc, queryType} = this.props;
      if(isAC()){
          isDisable = queryType==="E";
      } else if(masterDoc?.status === 'COMPLETED' || masterDoc?.status === 'UNLOCKED'){
          isDisable = false;
      }

      return isDisable || this.state.isReadOnly;
    }

    renderDiscrepancy(disableComment, discrepancyData, commentData) {
      return (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div style={{ float: 'right', paddingRight: '20px' }}>
              <IconButton disabled={disableComment} onClick={this.newCommentDiscrepancy} name="add">New comment / discrepancy</IconButton>
            </div>
          </Grid>
          <Grid item xs={12} style={{ paddingRight: '20px' }}>
            <div style={{ paddingBottom: '20px' }}>
              <strong>Discrepancy</strong>
            </div>
            <Table
              defaultPageSizeOption={{ 'value': 0 }}
              showPagination={false}
              data={discrepancyData}
              columns={discrepancyColumn}
            />
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: '10px', paddingRight: '20px' }}>
            <div style={{ paddingTop: '30px', paddingBottom: '20px' }}>
              <strong>Comment History</strong>
            </div>
            <Table
              defaultPageSizeOption={{ 'value': 0 }}
              showPagination={false}
              data={commentData}
              columns={commentColumn}
            />
          </Grid>
        </Grid>
      );
    }

    render(){

      const discrepancyData = this.getDiscrepancyData();

      const commentData = this.state.tableComment?.map((t, i) => {
        return Object.assign(t, {actionBtn: <ActionButtons
          rowIndex={i}
          buttonArray={buttonComment}
          viewComment={this.viewComment}
          fixDiscrepancy={this.fixDiscrepancy}
          justify='space-between'
        />});
      });

      const data = this.state.isComment ?
        this.state.tableComment[this.state.selectedComment] :
        this.state.tableDiscrepancy[this.state.selectedDiscrepancy];

      const selectedIndex = this.state.isComment ? this.state.selectedComment : this.state.selectedDiscrepancy;

      const disableField = this.shouldDisableField();
      const disableStatus = this.isDisableStatus();
      const disableDiscrepancy = this.isDisableDiscrepancy();
      const disableRemove = !this.state.data.datetimeupload || this.state.data.datetimeupload === '' || disableField;

      return (
        <div>
          <Modal
            ariaLabelledby="modal-heading"
            isOpen={this.props.isOpen  && !this.state.openCommentDiscrepancy
              && !this.state.openConfirm && !this.state.openViewDiscrepancy}
            width={946}
          >
            <ModalHeader hasTopLine>
              <ModalTitle>Document details</ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={this.props.close} />
              </ModalBtnList>
            </ModalHeader>
            <ModalContent>
              <Grid container spacing={0}>
                {this.state.showErrorMessage &&
                <Grid item xs={12} style={{paddingRight:'20px'}}>
                  <ValidationMessage
                    message={this.state.message}
                    onClose={()=>this.setState({showErrorMessage:false})}
                  />
                </Grid>}

                <Grid item xs={4}>
                  <Autocomplete
                    name="doctitle"
                    label="Document title*"
                    id="doctitle"
                    options={this.docTitleOpt}
                    userInput={this.state.data?.doctitle}
                    onChange={(value) => this.handleInputValue('doctitle', value)}
                    ref={this.refDocTitle}
                    disabled={disableField}
                    validations={["required"]}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextFieldForm
                    name="docdesc"
                    label="Description"
                    value={this.state.data?.docdesc}
                    onChange={(event) => this.handleChange(event)}
                    disabled={disableField}
                    maxLength={100}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    name="docrefnoname"
                    label="Reference name"
                    id="docrefnoname"
                    options={this.refNameOpt}
                    userInput={this.state.data?.docrefnoname}
                    onChange={(value) => this.handleInputValue('docrefnoname', value)}
                    disabled={disableField}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextFieldForm
                    name="docrefno"
                    label="Reference number"
                    value={this.state.data?.docrefno}
                    onChange={(event) => this.handleChange(event)}
                    disabled={disableField}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    name="otherdoctype"
                    label="Document type*"
                    id="otherdoctype"
                    options={this.docTypeOpt}
                    userInput={this.state.data?.otherdoctype}
                    onChange={(value) => this.handleInputValue('otherdoctype', value)}
                    validations={["required"]}
                    ref={this.refDocType}
                    disabled={disableField}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextArea
                    name="remarks"
                    maxLength={1500}
                    label="Remarks"
                    value={this.state.data?.remarks}
                    handleChange={this.handleChange}
                    disabled={disableField}
                  />
                </Grid>
                <Grid item xs={8} style={{paddingRight:'20px'}}>
                  <UploadFile
                    ref={this.refFile}
                    name="filename"
                    label="File to upload"
                    button="Browse"
                    accept=".pdf, .tif, .tiff, .jpg, .jpeg, .png, .bmp, .doc, .docx, .xls, .xlsx, .txt, .rtf, .msg"
                    invalid={this.state.invalid}
                    invalidMessage={this.state.invalidMessage}
                    disabled={disableField}
                    onChange={this.handleInputValue}
                    fileNameLength={200}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    name="docstatus"
                    label="Document status*"
                    id="docstatus"
                    userInput={this.state.data?.docstatus}
                    onChange={(value) => this.handleInputValue('docstatus', value)}
                    validations={["required"]}
                    ref={this.refDocStatus}
                    disabled={this.isDisableStatusField()}
                    options={this.docStatusOpt}
                    maxLength={20}
                  />
                </Grid>
              </Grid>

              <Divider short />

              {this.state.success &&
              <div style={{paddingRight: '20px'}}>
                <SuccessMessage message={this.state.message} handleCloseButtonClick={()=>{this.setState({success:false})}} />
              </div>}

              {this.renderDiscrepancy(disableDiscrepancy, discrepancyData, commentData)}

            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>
                <ModalActionExtraButton>
                  <Button onClick={this.handleClose}>Cancel</Button>
                </ModalActionExtraButton>
                <ModalActionButton>
                  <Button style={{marginRight:'10px'}} disabled={disableRemove} onClick={this.confirmRemove}>Remove uploaded file</Button>
                  <Button disabled={disableStatus} themeColor="primary" onClick={this.save} type="submit">Save</Button>
                </ModalActionButton>
              </ModalAction>
            </ModalFooter>
          </Modal>

          {this.state.openCommentDiscrepancy &&
          <CommentDiscrepancy
            isOpen={this.state.openCommentDiscrepancy}
            close={this.closeCommentDiscrepancy}
            save={this.saveCommentDiscrepancy}
            fix={this.saveFixDiscrepancy}
            data={data}
            selectedIndex={selectedIndex}
            isComment={this.state.isComment}
            isFix={this.state.isFix}
            viewMode={this.state.viewModeDiscrepancy}
          />}

          {this.state.openViewDiscrepancy &&
          <ViewDiscrepancy
            isOpen={this.state.openViewDiscrepancy}
            close={()=>{this.setState({openViewDiscrepancy:false})}}
            data={data}
          />}

          {this.state.openConfirm &&
          <Confirm
            content='Do you wish to delete the upload document?'
            isOpen={this.state.openConfirm}
            close={()=>{this.setState({openConfirm: false})}}
            confirm={this.delete}
          />}
        </div>
    )
  }


}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values,
})


export default connect(mapStateToProps)(DocumentDetail)
