import React from 'react';
import NumberFormat from 'react-number-format';
import TextFieldForm from '../TextField/TextFieldForm';

const renderNumberFormat = ({ decimalScale, input, meta: {  touched, invalid, error  }, ...rest }) => {
  const invalidMessage = (touched && error) ? error : '';
  if(decimalScale === 0 || decimalScale === "0"){
    return <NumberFormat
      invalid={touched && invalid}
      invalidMessage={invalidMessage}
      customInput={TextFieldForm}
      decimalScale={0}
      allowedDecimalSeparators=""
      {...input}
      {...rest}
    />
  }
    return <NumberFormat
      invalid={touched && invalid}
      invalidMessage={invalidMessage}
      customInput={TextFieldForm}
      decimalScale={decimalScale}
      fixedDecimalScale
      {...input}
      {...rest}
    />


}

export default renderNumberFormat
