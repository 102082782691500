import React from 'react';
import styled from "styled-components";
import { colors } from '@cdl/foundations';
import { NavLink } from 'react-router-dom';
import { detectChanges } from 'common/utils/MasterDocUtil';

const Borderline = styled.div`
    position: fixed;
    height: 40px;
    width: 100%;
    left: 0;
    top: 60px;
    max-width: 1000rem;
    box-shadow: inset 0 -0.0625rem 0 0 rgba(237,237,237,0.15);
    background: #253038;

    /* Microsoft IE10 and above */
    @media all and (-ms-high-contrast: none) {
        height: 41px;
    }
`;


const NavigationWrapper = styled.div`
    height: 2.5rem;
    background: ${colors.DARK_SLATE};
    box-shadow: rgb(237 237 237 / 15%) 0 -0.0625rem 0 0 inset;
    position: fixed;
    top: 3.75rem;
    width: 100%;
    left: auto;
    z-index: 99;
`

const NavContainer = styled.nav`
    max-width: 100rem;
    margin: 0 auto;
`
const UL = styled.ul`
    margin: 0 24px;
    padding: 0;
    display: flex;
    transition: margin 400ms ease 0s;
    will-change: margin;

    & li{
        padding: 0 30px 0 0;
    }

    & li a {
        display: block;
        height: 2.5rem;
        line-height: 2.5rem;
        white-space: nowrap;
        font-size: 0.875rem;
        color: ${colors.SILVER};
        text-decoration: none;
        transition: color 400ms ease 0s, box-shadow 400ms ease 0s;
    }

    & li a.active {
        color: ${colors.WHITE};
        box-shadow: inset 0 -3px 0 0 ${colors.RED};
    }

    & li a:hover {
        color: ${colors.WHITE};
    }

    & li a:not(.active):hover{
        box-shadow: none;
    }
    
`

export default class Navigation extends React.Component {

    Navigation = (links) => {
        return React.createElement(
            NavigationWrapper,
            null,
            React.createElement(
                NavContainer,
                null,
                links && React.createElement(
                    UL,
                    null,
                    links.map((item) => {
                        let clsName = '';
                        if (this.props.path.includes(item.url)) {
                            clsName = 'active';
                        }
                        return React.createElement(
                            'li',
                            { key: item.title },
                            React.createElement(
                                NavLink,
                                { to: item.url, exact: true, className: clsName, onClick: (event) => { this.redirectHome(event, item.url) } },
                                item.title
                            )
                        );
                    })
                )
            )
        );
    }

    redirectHome = (event, link) => {
        event.preventDefault();
        if (window.location.pathname.includes('/GenericDocPrep/') || window.location.pathname.includes('/GenericEDSDashboard')) {
            detectChanges(() => {
                this.props.history.push(link)
            });
        } else {
            this.props.history.push(link);
        }
    }


    render() {
        return (
          <div className="headerNav">
            {this.Navigation(this.props.links)}
            <Borderline />
          </div>
        );
    }
}
