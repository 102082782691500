/* eslint-disable import/no-extraneous-dependencies */
import CDLCheckbox from '@cdl/checkbox';
import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalContent, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import DropdownField from 'common/components/Dropdown/DropdownField';
import Heading from "common/components/Heading/Heading";
import Modal from "common/components/Modal/Modal";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import SwitchTab from "common/components/SwitchTab/SwitchTab";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import ValidationMessage from 'common/components/UserMessage/ValidationMessage';
import DrawerService from 'common/services/drawer.service';
import UserService from 'common/services/user.service';
import { WOLF_SCAN_DRAWER_FIELDS } from 'common/utils/Constants';
import { wolfScan } from 'common/utils/FieldValidation';
import { getCountry } from 'common/utils/OptionUtil';
import WolfMsg from 'common/views/Popup/WolfMsg';
import React from 'react';
import styled from "styled-components";
import Confirm from 'common/views/Popup/Confirm';


const Wrapper = styled.div`
& .content-wrapper {
    width: initial;
    padding: initial;
    padding-right: 20px;
}
`;

const count = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 }
];

const initialData  = Object.freeze({ exportercode: "", expref: "", expname: "", expregno: "", expaddr1: "", expaddr2: "", expaddr3: "",
  exppostcode: "", expcity: "", expcountry: "", expstate: "", exptel: "", expfax: "", expemail: "", exptelex: "",
  expgpobox: "", expacctno: "", expdbacctno: "", logo: false, signature: false, tradegatecustomsid: "",
  unitpricedeccat: 3, amtdeccat: 2, qtydeccat: 2, noofpkgdeccat: 0, grossmassdeccat: 2, netmassdeccat: 2,
  cubicmassdeccat: 2, interestratedeccat: 4
});


export default class DrawerAddDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: {...initialData},
          tabIndex: 0,
        };

        this.refexportercode = React.createRef();
        this.refexpemail = React.createRef();
        this.refexpacctno = React.createRef();
        this.refexpdbacctno = React.createRef();
        this.optCountry = getCountry(true);
    }

    componentDidMount(){
      const {edit, exporterCode} = this.props;
      if(edit){
        DrawerService.getDrawer(exporterCode).then(response => {
          if (response) {
            this.setState({
              data: response,
            });
          }
        })
      }
    }

    handleCheck = (event) => {
      const {data} = this.state;
      const {name, checked} = event.target;
      data[name] = checked;
      this.setState({ data });
    }

    handleChange = (event) => {
      const {data} = this.state;
      const {name, value} = event.target;
      data[name] = value;
      this.setState({ data });
    }

    closeMessage = () => {
      this.setState({
        message: "",
        showMessage: false,
      })
    }

    clearState = () => {
      this.setState({
        data: {...this.props.initalData},
        openConfirmPopup: false,
      })
    }

    showError = (msg) => {
      this.setState({
        message: msg,
        showMessage: true,
      })
    }

    getDisplayName = () => {
      const {data} = this.state;
      let displayName = `"${data.exportercode}"`;
      if(data.expname){
        displayName += ` - "${data.expname}"`;
      }
      return displayName;
    }

    handleSubmit = (e) => {
      e.preventDefault();
      const {data} = this.state;
      const action = this.props.edit ? DrawerService.saveDrawer(data) : DrawerService.addDrawer(data);
      action.then(response=>{
        if (response){
          const ver = this.props.edit ? 'updated' : 'added';
          const verErr = this.props.edit ? 'updating' : 'adding';
          if(response===1) {
            this.props.success(`Drawer ${this.getDisplayName()} has been ${ver} successfully`);
            this.clearState();
            this.props.close();
          }else if(response===-9){
            this.showError(`Error ${verErr} drawer ${this.getDisplayName()} - entry already exists`);
          }else if(response===-8){
            this.showError(`Error ${verErr} drawer ${this.getDisplayName()} - Reached maximum number of entries allowed.`);
          }else{
            this.showError(`Error ${verErr} drawer ${this.getDisplayName()}.`);
          }
        }
      })
    }

    handleOpenConfirmPopup = () => {

      const errorMsg = wolfScan(this.state.data, WOLF_SCAN_DRAWER_FIELDS);
      if(errorMsg && errorMsg.length>0){
        this.setState({
          wolfMsg:errorMsg,
          openWolfMsg: true,
        })
        return;
      }

      let isValid = true;
      isValid = this.refexportercode.current.validate() && isValid;
      isValid = this.refexpemail.current.validate() && isValid;
      isValid = this.refexpacctno.current.validate() && isValid;
      isValid = this.refexpdbacctno.current.validate() && isValid;

      if(isValid){
        this.setState({
          openConfirmPopup: true,
          openWolfMsg: false,
          showMessage: false,
        })
      }

    }

    handleClose = () => {
      this.setState({
        openConfirmPopup: false,
      })
    }

    handleAutocomplete = name => value => {
      const {data} = this.state;
      data[name] = value;
      this.setState({ data });
    }

    clearValue = (name) =>{
      const {data} = this.state;
      data[name] = '';
      this.setState({ data });
    }

    changeIndex = (index) => {
      this.setState({
        tabIndex: index,
      })
    }

  renderDetails() {
    const {tabIndex, data} = this.state;
    return (
      <Wrapper style={{ display: tabIndex === 0 ? 'block' : 'none', paddingTop: '20px' }}>
        <Grid container spacing={0} style={{ paddingTop: '20px' }}>
          <Grid item xs={3}>
            <TextFieldForm
              validations={["required"]}
              label="Drawer ID*"
              id="drawerId"
              name="exportercode"
              value={data.exportercode}
              maxLength={18}
              ref={this.refexportercode}
              onChange={this.handleChange}
              disabled={this.props.edit}
              regex="^[a-zA-Z0-9]+$"
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldForm
              label="Drawer name"
              name="expname"
              value={data.expname}
              maxLength={55}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldForm
              label="Drawer reference"
              name="expref"
              value={data.expref}
              maxLength={20}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldForm
              label="Drawer registration number"
              name="expregno"
              value={data.expregno}
              maxLength={20}
              onChange={this.handleChange}
            />
          </Grid>
        </Grid>

        <Grid container spacing={0}>

          <Grid item xs={4}>
            <TextFieldForm
              label="Address line 1"
              name="expaddr1"
              value={data.expaddr1}
              maxLength={35}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldForm
              label="Address line 2"
              name="expaddr2"
              value={data.expaddr2}
              maxLength={35}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldForm
              label="Address line 3"
              name="expaddr3"
              value={data.expaddr3}
              maxLength={35}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={3}>
            <TextFieldForm
              label="City"
              name="expcity"
              value={data.expcity}
              maxLength={35}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldForm
              label="State"
              name="expstate"
              value={data.expstate}
              maxLength={35}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <DropdownField
              labelOutside="Country / Territory"
              id="expcountry"
              name="expcountry"
              options={this.optCountry}
              onChange={this.handleAutocomplete("expcountry")}
              value={data.expcountry}
              selectWidth={217}
              country
              fontSizeFt
              clearValue={() => this.clearValue("expcountry")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldForm
              label="Postal code"
              name="exppostcode"
              value={data.exppostcode}
              maxLength={10}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={3}>
            <TextFieldForm
              label="GPO box"
              name="expgpobox"
              value={data.expgpobox}
              maxLength={50}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldForm
              label="Telephone number"
              id="exptel"
              name="exptel"
              maxLength={30}
              value={data.exptel}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldForm
              label="Facsimile"
              id="expfax"
              name="expfax"
              maxLength={30}
              value={data.expfax}
              onChange={this.handleChange}
            />
          </Grid>
          <Grid item xs={3}>
            <TextFieldForm
              label="Telex"
              id="exptelex"
              name="exptelex"
              customInput={TextFieldForm}
              maxLength={30}
              value={data.exptelex}
              onChange={this.handleChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextFieldForm
              label="Email address"
              id="expemail"
              name="expemail"
              value={data.expemail}
              validations={["multiEmail"]}
              ref={this.refexpemail}
              maxLength={100}
              onChange={this.handleChange}
            />
          </Grid>

        </Grid>
      </Wrapper>
    )
  }

  renderAccountDecimalMasking() {
    const {tabIndex, data} = this.state;
    const isDisabled = UserService.getCurrentUser()?.userroles === 'BA' && !this.props.edit;
    return (
      <Wrapper style={{ display: tabIndex === 1 ? 'block' : 'none' }}>
        <Grid container spacing={0}>
          <Grid container spacing={0} style={{ paddingTop: '30px' }}>

            <Grid item xs={4}>
              <TextFieldForm
                label="Exporter account number"
                id="expacctno"
                name="expacctno"
                customInput={TextFieldForm}
                maxLength={14}
                value={data.expacctno}
                onChange={this.handleChange}
                isAccount
                ref={this.refexpacctno}
                validations={["accountNumber"]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldForm
                label="Debit charges account number"
                id="expdbacctno"
                name="expdbacctno"
                customInput={TextFieldForm}
                maxLength={14}
                value={data.expdbacctno}
                onChange={this.handleChange}
                isAccount
                ref={this.refexpdbacctno}
                validations={["accountNumber"]}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldForm
                name="tradegatecustomsid"
                label="TradeGate customs ID"
                maxLength={35}
                value={data.tradegatecustomsid}
                onChange={this.handleChange}
              />
            </Grid>
          </Grid>

        </Grid>

        <Heading level={2} brandbar>
          <span className="medium">Decimal Masking</span>
        </Heading>

        <Grid container spacing={0} style={{ marginBottom: '20px' }}>
          <Grid item xs={2}>
            <DropdownField
              labelOutside="Unit price"
              id="unitpricedeccat"
              name="unitpricedeccat"
              options={count}
              onChange={this.handleAutocomplete("unitpricedeccat")}
              placeHolder={data.unitpricedeccat || count[0].label}
              value={data.unitpricedeccat}
              selectWidth={142}
              disabled={isDisabled}
              fontSizeFt
            />
          </Grid>
          <Grid item xs={2}>
            <DropdownField
              labelOutside="Amount"
              id="amtdeccat"
              name="amtdeccat"
              options={[
                {label: '0', value: '0'},
                {label: '1', value: '1'},
                {label: '2', value: '2'},
                {label: '3', value: '3'}
              ]}
              onChange={this.handleAutocomplete("amtdeccat")}
              placeHolder={data.amtdeccat || count[0].label}
              value={data.amtdeccat}
              selectWidth={144}
              disabled={isDisabled}
              fontSizeFt
            />
          </Grid>
          <Grid item xs={2}>
            <DropdownField
              labelOutside="Quantity"
              id="qtydeccat"
              name="qtydeccat"
              options={count}
              onChange={this.handleAutocomplete("qtydeccat")}
              placeHolder={data.qtydeccat || count[0].label}
              value={data.qtydeccat}
              selectWidth={144}
              disabled={isDisabled}
              fontSizeFt
            />
          </Grid>
          <Grid item xs={2}>
            <DropdownField
              labelOutside="No. of packages"
              id="noofpkgdeccat"
              name="noofpkgdeccat"
              options={count}
              onChange={this.handleAutocomplete("noofpkgdeccat")}
              placeHolder={data.noofpkgdeccat || count[0].label}
              value={data.noofpkgdeccat}
              selectWidth={144}
              disabled={isDisabled}
              fontSizeFt
            />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ marginBottom: '20px' }}>
          <Grid item xs={2}>
            <DropdownField
              labelOutside="Gross mass"
              id="grossmassdeccat"
              name="grossmassdeccat"
              options={count}
              onChange={this.handleAutocomplete("grossmassdeccat")}
              placeHolder={data.grossmassdeccat || count[0].label}
              value={data.grossmassdeccat}
              selectWidth={144}
              disabled={isDisabled}
              fontSizeFt
            />
          </Grid>
          <Grid item xs={2}>
            <DropdownField
              labelOutside="Net Mass"
              id="netmassdeccat"
              name="netmassdeccat"
              options={count}
              onChange={this.handleAutocomplete("netmassdeccat")}
              placeHolder={data.netmassdeccat || count[0].label}
              value={data.netmassdeccat}
              selectWidth={144}
              disabled={isDisabled}
              fontSizeFt
            />
          </Grid>
          <Grid item xs={2}>
            <DropdownField
              labelOutside="Cubic Mass"
              id="cubicmassdeccat"
              name="cubicmassdeccat"
              options={count}
              onChange={this.handleAutocomplete("cubicmassdeccat")}
              placeHolder={data.cubicmassdeccat || count[0].label}
              value={data.cubicmassdeccat}
              selectWidth={144}
              disabled={isDisabled}
              fontSizeFt
            />
          </Grid>
          <Grid item xs={2}>
            <DropdownField
              labelOutside="Interest rate"
              id="interestratedeccat"
              name="interestratedeccat"
              options={count}
              onChange={this.handleAutocomplete("interestratedeccat")}
              placeHolder={data.interestratedeccat || count[0].label}
              value={data.interestratedeccat}
              selectWidth={144}
              disabled={isDisabled}
              fontSizeFt
            />
          </Grid>
        </Grid>
        <Grid container spacing={0} style={{ paddingBottom: '20px', alignSelf: "center" }}>
          <Grid item xs={2}>
            <CDLCheckbox
              label="Logo"
              name="logo"
              checked={data.logo}
              onChange={this.handleCheck}
            />
          </Grid>
          <Grid item xs={2} style={{ alignSelf: "center" }}>
            <CDLCheckbox
              label="Signature"
              id="signature"
              name="signature"
              checked={data.signature}
              onChange={this.handleCheck}
            />
          </Grid>
        </Grid>
      </Wrapper>
    )
  }

  render(){
    const {openConfirmPopup, openWolfMsg, wolfMsg, showMessage, message} = this.state;
    return (
      <div>
        {openConfirmPopup &&
        <Confirm
          content={`Do you wish to ${this.props.edit ? 'update' : 'add'} the drawer ${this.getDisplayName()}?`}
          isOpen={openConfirmPopup}
          confirm={this.handleSubmit}
          close={this.handleClose}
        />}
        {openWolfMsg &&
        <WolfMsg
          fields={wolfMsg}
          isOpen={openWolfMsg}
          close={()=>this.setState({openWolfMsg: false})}
        />}
        <Modal
          ariaLabelledby="modal-heading"
          classname="myClass"
          isOpen={this.props.isOpen && !openConfirmPopup && !openWolfMsg}
          width={946}
        >
          <ModalHeader>
            <ModalTitle>Drawer details</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.props.close} />
            </ModalBtnList>
          </ModalHeader>
          <ModalContent style={{ paddingTop: showMessage ? '0px' : '20px', paddingRight: '0px', marginRight: '0px' }}>
            {showMessage &&
              <div style={{paddingTop: '20px'}}>
                <ValidationMessage message={message} onClose={this.closeMessage} />
              </div>}
            <SwitchTab
              tabs={[{title: 'Drawer details', url: 'applicant'}, {title: 'Account and decimal masking', url: 'defaultSettings'}]}
              onChangeIndex={this.changeIndex}
              reset={!this.props.isOpen}
            />

            {this.renderDetails()}

            {this.renderAccountDecimalMasking()}

          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.props.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button onClick={this.handleOpenConfirmPopup} themeColor="primary">Save</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
