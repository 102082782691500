import React, { useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import SessionTimeoutModal from "common/views/Popup/SessionTimeoutModal";
import AuthService from "common/services/auth.service";
import UserService from 'common/services/user.service';

let countdownInterval;
let timeout;

export default function SessionTimeout(props) {

  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const idleTimer = useRef(null);

  const clearSessionTimeout = () => {
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
    clearInterval(countdownInterval);
  };

  const handleLogout = (msg) => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
    AuthService.logout(msg).then(response => {
      props.history.push("/Logout", response);
    });
  };

  const handleContinue = () => {
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();

  };

  const onActive = () => {
    console.log("onActive...");
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

  const onIdle = () => {
      console.log("idle...");
    const delay = 1000;

    if (UserService.getCurrentUser() && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 60;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            --countDown;
            setTimeoutCountdown(countDown);
          } else {
            handleLogout("Timeout Session has logged out the User.");
          }
        }, 1000);
      }, delay);
    }
  };

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={1000*60*20}
      />
      <SessionTimeoutModal
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onClose={handleContinue}
        onLogout={() => handleLogout()}
        isOpen={timeoutModalOpen}
      />
    </>
  );
}
