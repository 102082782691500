import CDLDatePicker from '@cdl/date-picker';
import CDLIcon from "@cdl/icon";
import Icon from "common/components/Icon/Icon";
import { isBlank } from 'common/utils/StringUtils';
import PropTypes from 'prop-types';
import React from 'react';
import styled from "styled-components";

const moment = require('moment');


const DatePickerContainer = styled.div`
    #default-datepicker-wrapper>div:not([class]) {
        display: none;
    }
`;

const DATE_FORMAT = 'DD MMM yyyy';

export default class DocumentDate extends React.Component {
    constructor(props) {
        super(props);
        console.log('construct');
        this.state = {
            isEdit: false,
            val: props.val || new Date()
        };
        this.handleClick = this.handleClick.bind(this);
        this.checkExpanded = this.checkExpanded.bind(this);
        this.datePickerRef = React.createRef();
    }

    static getDerivedStateFromProps (props, prevState) {
      console.log(props, prevState);
      if (typeof props.val === 'string' && !isBlank(props.val) && typeof prevState.val === 'object') {
        if (moment(props.val, DATE_FORMAT).toDate().getTime() !== prevState.val?.getTime()) {
          return {
            val: moment(props.val, DATE_FORMAT).toDate()
          }
        }
      } else if (typeof props.val === typeof prevState.val &&
          typeof props.val === 'object' && typeof prevState.val === 'object'
        && props.val.getTime() !== prevState.val.getTime()) {
          console.log(props.val , prevState.val, props.val.getTime(), prevState.val.getTime());
          return {
            val: moment(props.val, DATE_FORMAT).toDate()
          };
      }

      return null;

    }

    handleClick = () =>{

        this.setState(prevState => ({
            isEdit: !prevState.isEdit
        }));


        if (!this.state.isEdit) {
          this.datePickerRef.current.instanceRef.toggleCalendar();
        }
    }

    onDateSelected = (date) =>{
        this.handleClick();
        this.setState({val: date.from});

        this.props.dateSelected(date.from);
    }

    checkExpanded = (event) => {
        this.setState({
            isEdit: event.expanded
        })
    }

    render() {

        const valDisplay = moment(this.state.val).format(this.props.dateFormat);
        const datePicker =
          <div className="date-picker-container" style={{position: 'absolute'}}>
            <DatePickerContainer>
              <CDLDatePicker
                onDateSelected={this.onDateSelected}
                defaultDate={{from: this.state.val}}
                inputFormat={DATE_FORMAT}
                onCalendarExpanded={this.checkExpanded}
                quickRangeCurrentDate={this.state.val}
                expanded
                ref={this.datePickerRef}
              />
            </DatePickerContainer>

          </div>

        let icon;
        if(this.state.isEdit){
            icon =
              <>
                <CDLIcon name='edit' size={18} onClick={this.handleClick} />
                {datePicker}
              </>;
        }else{
            icon =
              <>
                <Icon name='edit' size={18} onClick={this.handleClick} />
                {datePicker}
              </>;
        }

        return (
          <>
            <strong>{valDisplay}</strong>
            <span className="icon-container" style={{paddingLeft:'10px'}}>{icon}</span>
            <input type="hidden" name="docDate" value={valDisplay} />
          </>
        );
    }
}

DocumentDate.propTypes = {
    dateFormat: PropTypes.string
};

DocumentDate.defaultProps = {
    dateFormat: "DD MMM YYYY"
};
