import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Autocomplete from "common/components/Autocomplete/Autocomplete";
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import TextArea from "common/components/TextArea/TextArea";
import TextFieldForm from "common/components/TextField/TextFieldForm";
import { OVERFLOW_CLASS } from 'common/utils/Constants';
import React from 'react';
import { connect } from 'react-redux';
import { required } from 'common/utils/ReduxFormValidation';
import NumberField from "common/components/NumberField/NumberField";


class PackingListItemDetail extends React.Component {
  constructor(props) {
    super(props);
    const editData = props.data || {PLDetQty: 0, PLDetNoPKGS: 0, PLDetGrossMass: 0, PLDetNetMass: 0, PLDetCubic: 0};
    this.state = {
        data: {...editData},
        isEdit: props.data && Object.keys(props.data).length > 0
    };
  }

  componentDidMount() {
    document.documentElement.classList.add(OVERFLOW_CLASS);
  }

  componentWillUnmount() {
    document.documentElement.classList.remove(OVERFLOW_CLASS);
  }

  handleChange = (event) => {
      const {data} = this.state;
      const {name, value} = event.target;
      data[name] = value;
      this.setState({ data });
      if(name==='PLDetProdDesc'){
        this.setState({ invalidProdDesc: '' });
      }
  }

  handleSave = () => {
    const msgProdCode = required(this.state.data?.PLDetProdCode);
    const msgProdDesc = required(this.state.data?.PLDetProdDesc);
    if(msgProdCode !=='' && msgProdDesc !==''){
      if(msgProdCode !==''){
        this.setState({
          invalidProdCode: msgProdCode,
        });
      }
      if(msgProdDesc !==''){
        this.setState({
          invalidProdDesc: msgProdDesc,
        });
      }
    }else{
      this.props.save(this.state.data, this.state.isEdit, this.props.selectedIndex);
    }

  }

  handleInputValue = (name, val) => {
    const {data} = this.state;
    data[name] = val;
    this.setState({ data });
    if(name==='PLDetProdCode'){
      this.setState({ invalidProdCode: '' });
    }
  }

  render(){

    return (
      <div>
        <Modal
          ariaLabelledby="modal-heading"
          classname="myClass"
          isOpen={this.props.isOpen}
          isNested
          width={600}
        >
          <ModalHeader hasTopLine>
            <ModalTitle>Packing list item details</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.props.close} />
            </ModalBtnList>

          </ModalHeader>
          <ModalContent>
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <TextFieldForm
                  label={this.props.labelHeaders.casenoheading}
                  name="PLCaseNo"
                  maxLength={35}
                  value={this.state.data?.PLCaseNo}
                  onChange={(event) => this.handleChange(event)}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  label={this.props.labelHeaders.partnoheading}
                  name="PLDetProdCode"
                  id="PLDetProdCode"
                  options={[]}
                  userInput={this.state.data?.PLDetProdCode}
                  onChange={value => this.handleInputValue('PLDetProdCode', value)}
                  invalidMessage={this.state.invalidProdCode}
                  invalid={this.state.invalidProdCode}
                  maxLength={35}
                />
              </Grid>
              <Grid item xs={12}>
                <TextArea
                  label={this.props.labelHeaders.proddescheading}
                  name="PLDetProdDesc"
                  maxLength={255}
                  value={this.state.data?.PLDetProdDesc}
                  onChange={(event) => this.handleChange(event)}
                  invalidMessage={this.state.invalidProdDesc}
                  invalid={this.state.invalidProdDesc?.length>0}
                />
              </Grid>

              <Grid item xs={4}>
                <NumberField
                  decimalScale={this.props.masterDoc.qtydeccat}
                  label={this.props.labelHeaders.qtyheading}
                  name="PLDetQty"
                  maxLength={15}
                  value={this.state.data?.PLDetQty}
                  onChange={this.handleInputValue}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberField
                  decimalScale={this.props.masterDoc.noofpkgdeccat}
                  label={this.props.labelHeaders.nopkgsheading}
                  name="PLDetNoPKGS"
                  maxLength={15}
                  value={this.state.data?.PLDetNoPKGS}
                  onChange={this.handleInputValue}
                />
              </Grid>
              <Grid item xs={4} />

              <Grid item xs={4}>
                <NumberField
                  decimalScale={this.props.masterDoc.grossmassdeccat}
                  label={this.props.labelHeaders.gmassheading}
                  name="PLDetGrossMass"
                  maxLength={15}
                  value={this.state.data?.PLDetGrossMass}
                  onChange={this.handleInputValue}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberField
                  decimalScale={this.props.masterDoc.netmassdeccat}
                  label={this.props.labelHeaders.netmassheading}
                  name="PLDetNetMass"
                  maxLength={15}
                  value={this.state.data?.PLDetNetMass}
                  onChange={this.handleInputValue}
                />
              </Grid>
              <Grid item xs={4}>
                <NumberField
                  decimalScale={this.props.masterDoc.cubicmassdeccat}
                  label={this.props.labelHeaders.cubicmassheading}
                  name="PLDetCubic"
                  maxLength={15}
                  value={this.state.data?.PLDetCubic}
                  onChange={this.handleInputValue}
                />
              </Grid>
            </Grid>
          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.props.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button themeColor="primary" onClick={this.handleSave}>Save</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values,
})

export default connect(mapStateToProps)(PackingListItemDetail)
