import BaseService from 'common/services/BaseService';
import masterDocService from 'common/services/masterDoc.service';
import UserService from 'common/services/user.service';

const _ = require('lodash');

class DashboardService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/DashboardBO/';
  }

  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  postWithStatus(url, paras, showLoading) {
    return super.postReturnWithStatus(this.basePath + url, paras, showLoading);
  }


  // eslint-disable-next-line class-methods-use-this
  getExtraPropsFromUserDTO() {
    const currentUser = UserService.getCurrentUser();
    return {
      "codocexpressid": currentUser.codocexpressid,
      "countrycode": currentUser.countrycode,
      "groupmemberid": currentUser.groupmemberid,
    }
  }


  approveRejectMD(dashboardlist, isApprove, remark) {
    const requestBody = {
      "dashboardlist": dashboardlist,
      "isapprove": isApprove,
      "remark": remark,
      ...this.getUserDTO()
    };
    return this.axiosPost(`${this.basePath}approveRejectMD`, requestBody, true);
  }

  checkHasWolfServerSettings(mdocid) {
    const requestBody = {
      mdocid,
      ...this.getUserDTO()
    };

    return this.post('checkHasWolfServerSettings', requestBody, true);
  }

  getDocImage(inuserdto, thirdpartydocdto) {
    const requestBody = {
      inuserdto,
      mdocid: thirdpartydocdto
    };
    return this.post('getDocImage', requestBody, true);
  }

  getMasterDoc(mdocid, searchmode) {
    const requestBody = {
        "mdocid": mdocid,
        "searchmode": searchmode,
        ...this.getUserDTO()
        }

        return this.postWithStatus('getMasterDoc', requestBody, true);
  }

  loadDocument(mdocid) {
    const requestBody = {
      ...this.getInUserDTO(),
      "mdocid": mdocid,
      "querytype": 'O'
    }
    return this.post('loadDocument', requestBody, true);
  }

  printEI(mdocid) {
    const currentUser = UserService.getCurrentUser();
    const userDTO = this.getUserDTO();
    userDTO.userdto.user.modules = currentUser.modules;
    const requestBody = {
      mdocid,
      ...userDTO
    }

    return this.post('printEI', requestBody, true);
  }

  searchMasterDoc(dashboardquerydto) {
    const requestBody = {
        "dashboardquerydto": dashboardquerydto,
        ...this.getUserDTO()
        }
    return this.post('searchMasterDoc', requestBody, true);
  }

  updateMasterDoc(exportinstructiondto, masterdocdto) {
    const userDTO = this.getUserDTO();
    masterdocdto.EDS = true;
    masterdocdto.FormID = 'MD';
    masterdocdto.formID = 'MD';
    masterdocdto.bolVarForm = 1;
    masterdocdto.BolVarForm = 1;
    masterdocdto.CountryCode = userDTO.userdto.user.countrycode;
    masterdocdto.countryCode = userDTO.userdto.user.countrycode;
    masterdocdto.UserName = userDTO.userdto.user.username;
    masterdocdto.userName = userDTO.userdto.user.username;
    masterdocdto.thirdPartyDoc = masterdocdto.thirdPartyDoc || [];
    masterdocdto.tmplmdocid = masterdocdto.tmplmdocid || '';
    Object.keys(masterdocdto).forEach(key => {
      if (!masterdocdto[key]) {
        masterdocdto[key] = '';
      }
    })

    if (exportinstructiondto && exportinstructiondto.EIType) {
      if (exportinstructiondto.EIType === 'ND' || exportinstructiondto.EIType === 'NA' || exportinstructiondto.EIType === 'AD'
      || exportinstructiondto.EIType === 'PA' || exportinstructiondto.EIType === 'NU') {
        masterdocdto.cotype = 'DC';
      } else {
        masterdocdto.cotype = 'DADP';
      }
    }

    masterdocdto.InvGrandTotal = masterdocdto.InvGrandTotal ? parseFloat(masterdocdto.InvGrandTotal) : 0;

    masterdocdto.TotalInv = masterdocdto.TotalInv ? parseFloat(masterdocdto.TotalInv) : 0;

    if (!masterdocdto.invGrandTotal) {
      masterdocdto.invGrandTotal = 0;
    } else {
      masterdocdto.invGrandTotal = parseFloat(masterdocdto.invGrandTotal);
    }

    masterDocService.massageMasterDocBeforeSubmission(masterdocdto, userDTO);

    const requestBody = {
      exportinstructiondto,
      masterdocdto,
      ...this.getUserDTO()
    }
    return this.post('updateMasterDoc', requestBody, true);
  }

  validateMDStatus(arr) {
    const ardashboardlistr = _.cloneDeep(arr);

    ardashboardlistr.forEach(d => {
      if (d.actionBtn) {
        delete d.actionBtn;
      }
      d.bool_isselected = true;
    });
    const requestBody = {
        "dashboardlist": ardashboardlistr,
        ...this.getUserDTO()
        }
    return this.post('validateMDStatus', requestBody, true);
  }

  completeReview(mdocid, remark, email) {
    const requestBody = {
      ...this.getUserDTO(),
      mdocid,
      remark,
      email
    }
    return this.post('completeReview', requestBody, true);
  }

  getCountryModules() {
    const requestBody = {
      ...this.getUserDTO(),
    }
    return this.post('getCountryModules', requestBody, true);
  }

  is2LevelWorkflow(countrycode, groupmemberid) {
    const requestBody = {
      "userdto": {
        "user": {
          "countrycode": countrycode,
          "groupmemberid": groupmemberid,
        }
      },
    }
    return this.post('is2LevelWorkflow', requestBody, true);
  }

  isEDSWorkflow(countrycode, groupmemberid) {
    const requestBody = {
      "userdto": {
        "user": {
          "countrycode": countrycode,
          "groupmemberid": groupmemberid,
        }
      },
    }
    return this.post('isEDSWorkflow', requestBody, true);
  }

  queryStatus(mdocid) {
    const requestBody = {
      "mdocid": mdocid,
      ...this.getUserDTO()
    }
    return this.post('queryStatus', requestBody, true);
  }

  isHTS() {
    const requestBody = {
      ...this.getUserDTO()
    }
    return this.post('isHTS', requestBody, true);
  }

  resend(mdocid, refNO, instrumentID, isMDDashboard) {
    const requestBody = {
      "mdocid": mdocid,
      "refno": refNO,
      "instrumentid": instrumentID,
      "ismddashboard": isMDDashboard,
      ...this.getUserDTO()
    }
    return this.post('resend', requestBody, true);
  }

  confirm(mdocid, refno, isconfirmed) {
    const requestBody = {
      mdocid,
      refno,
      isconfirmed,
      ...this.getUserDTO()
    }
    return this.post('confirm', requestBody, true);
  }

  getMasterDocStatusHistory(mdocid) {
    const requestBody = {
      ...this.getUserDTO(),
      mdocid,
    }
    return this.post('getMasterDocStatusHistory', requestBody, true);
  }

  isGenericEDS(mdocid) {
    const requestBody = {
      ...this.getUserDTO(),
      mdocid,
    }
    return this.post('isGenericEDS', requestBody, true);
  }
}

export default new DashboardService();
