import BaseService from 'common/services/BaseService';
import { isBlank } from 'common/utils/StringUtils';
import { masterDocDefaultValue } from 'common/utils/DocPrepConstant';
import masterDocService from './masterDoc.service';
import exportInstructionService from "./export-instruction.service";


class BillOfExchangeService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/BillOfExchangeBO/';
  }

  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  postWithStatus(url, paras, showLoading) {
    return super.postReturnWithStatus(this.basePath + url, paras, showLoading);
  }

  getBOEList(formid, mdocid, mdocType) {
    const requestBody = {
      "formid": formid,
      "mdocid": mdocid,
      "mdoctype": mdocType,
      ...this.getUserDTO()
    }

    return new Promise((resolve, reject) => {
      return this.postWithStatus('getBOEList', requestBody, true).then(res => {
        if (res && res.statusCode === 200) {
          const billOfExchangeDTO = res.data;
          if (billOfExchangeDTO) {
            Object.keys(masterDocDefaultValue.billofexchangedto).forEach(key => {
              if (isBlank(billOfExchangeDTO[key])) {
                billOfExchangeDTO[key] = masterDocDefaultValue.billofexchangedto[key];
              }
            });
            resolve(res);
          } else {
            reject('error');
          }
        } else {
          reject('error');
        }
      })


    }, err => {
      console.log(err);
    });
  }

  saveNewBoe(billOfExchangeDTO, masterDocDTO, metaData) {
    const userDTO = this.getUserDTO();
    masterDocService.massageMasterDocBeforeSubmission(masterDocDTO, this.getUserDTO());
    masterDocDTO.tmplmdocid = masterDocDTO.mdocid;
    masterDocDTO.formid = 'BOE';

    const {user} = userDTO.userdto;
    return new Promise((resolve, reject) => {
      masterDocService.getMasterDocMDocID(user.codocexpressid, user.groupmemberid, '', '', user.codocexpressid, metaData.mdoctype).then(newMasterDocID => {
        masterDocDTO.mdocid = newMasterDocID;
        masterDocDTO.mdoctype = metaData.mdoctype;
        masterDocDTO.mdocdesc = metaData.mdocdesc ? metaData.mdocdesc : '';
        const requestBody = this.constructBoeRequestBody(billOfExchangeDTO, masterDocDTO);
        if(masterDocDTO.tmplmdocid.startsWith("TM")){
          resolve(this.post('saveNewBoe', requestBody, true));
        }else{
          this.post('saveNewBoe', requestBody, true).then(()=>{
            resolve(exportInstructionService.addExportInstruction(masterDocDTO.eidto, masterDocDTO));
          })
        }
      }, (err) => {
        reject(err);
      })
    })
  }

  updateBoe(billOfExchangeDTO, masterDocDTO) {
      masterDocService.massageMasterDocBeforeSubmission(masterDocDTO, this.getUserDTO());
      billOfExchangeDTO.dcbankname = masterDocDTO.bankname;
			billOfExchangeDTO.dcbankbranch = masterDocDTO.bankbranch;
			billOfExchangeDTO.dcbankref = masterDocDTO.bankref;
			billOfExchangeDTO.dcbanktradedept = masterDocDTO.banktradedept;
			billOfExchangeDTO.dcbankaddr1 = masterDocDTO.bankaddr1;
			billOfExchangeDTO.dcbankaddr2 = masterDocDTO.bankaddr2;
			billOfExchangeDTO.dcbankaddr3 = masterDocDTO.bankaddr3;
			billOfExchangeDTO.dcbankcity = masterDocDTO.bankcity;
			billOfExchangeDTO.dcbankstate = masterDocDTO.bankstate;
			billOfExchangeDTO.dcbankcountry = masterDocDTO.bankcountry;
			billOfExchangeDTO.dcbankpostcode = masterDocDTO.bankpostcode;
      const requestBody = this.constructBoeRequestBody(billOfExchangeDTO, masterDocDTO);
      return this.postWithStatus('updateBOE', requestBody, true);
  }

  constructBoeRequestBody(billOfExchangeDTO, masterDocDTO) {
    Object.keys(billOfExchangeDTO).forEach(key => {
      if (!billOfExchangeDTO[key]) {
        billOfExchangeDTO[key] = '';
      }
    });
    const userDTO = this.getUserDTO();
    billOfExchangeDTO.billamt = !isBlank(billOfExchangeDTO.billamt) ? parseFloat(billOfExchangeDTO.billamt) : 0;
    billOfExchangeDTO.noofboe = !isBlank(billOfExchangeDTO.noofboe) ? Number(billOfExchangeDTO.noofboe) : 0;
    billOfExchangeDTO.boesaved = !isBlank(billOfExchangeDTO.boesaved) ? Number(billOfExchangeDTO.boesaved) : 0;
    masterDocService.massageMasterDocBeforeSubmission(masterDocDTO, userDTO);
    const submitMasterDocDTO = { ...masterDocDTO};
    submitMasterDocDTO.formid = 'BOE';
      return {
        billofexchangedto: billOfExchangeDTO,
        masterdocdto: submitMasterDocDTO,
        ...userDTO
      };
  }

}

export default new BillOfExchangeService();
