import React from 'react';
import CDLUserMessage from '@cdl/user-message';
import styled from "styled-components";
import Select from 'react-select';
import {colors} from '@cdl/foundations';
import { runValidations } from 'common/utils/FieldValidation';

const customStyles = {
    control: function control(base, state) {
        let borderColor = colors.PEWTER;
        if(state.isFocused){
          borderColor = colors.SAPPHIRE;
        }else if(state.isDisabled){
          borderColor = colors.SILVER;
        }else if(state.selectProps.invalid){
          borderColor = colors.ERROR;
        }
        return {
          ...base,
          backgroundColor: state.selectProps.invalid ? colors.ERROR_FADE : colors.WHITE,
          borderRadius: 0,
          borderColor,
          fontSize: 12,
          boxShadow: null,
          height: state.selectProps.label ? '55' : '30',
          width: state.selectProps.selectWidth ? state.selectProps.selectWidth : '',
          cursor: 'pointer',
          flexWrap: 'nowrap',
          minHeight: '30px',
          outline: 'none',
          marginBottom: '10px',
          '&:hover': {
            backgroundColor: colors.PEARL
          }
        };
      },
      dropdownIndicator: function dropdownIndicator(base, state) {
        return {
          ...base,
          padding: '0 8px',
          color: colors.PEWTER,
          transition: 'transform 400ms ease',
          transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
        };
      },
      valueContainer: function valueContainer(base, state) {
        return {
          ...base,
          marginTop: state.selectProps.label ? '15' : '',
          fontWeight: 500,
          height: 28,
          padding: '0 8px',
          input: {
            textIndent: '-9999999em',
            position: 'absolute',
          }
        };
      },
      indicatorSeparator: function indicatorSeparator(base, state) {
        return {
          ...base,
          backgroundColor: state.isDisabled ? colors.SILVER : colors.PEWTER,
          margin: '5px 0'
        };
      },
      menu: function menu(base) {
        return {
          ...base,
          boxShadow: `0 0 0 1px ${  colors.PEWTER}`,
          zIndex: 201,
          paddingTop: 0,
          paddingBottom: 0,
          borderRadius: 0,
          marginTop: 0,
          fontSize: 12,
          fontWeight: 500,
          marginBottom: 0,
          width: 'calc(100% - 2px)',
          left: 1,
        };
      },
      menuList: function menuList(base) {
        return {
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
          maxHeight: 192,
          overflowX: 'hidden',
        };
      },
      menuPortal: base => ({
        ...base,
        zIndex: 999
      }),
      option: function option(base, state) {
        return {
          ...base,
          boxShadow: `inset 0 -1px 0 0 ${  colors.SILVER}`,
          backgroundColor: state.isSelected && colors.WHITE_SHADE || (state.isFocused ? colors.PEARL : 'transparent'),
          borderLeft: 'none',
          color: state.isDisabled && colors.GRAPHITE_TINT || (state.isSelected ? colors.GRAPHITE : 'inherit')
        };
      },
  }

const widthStyle = width => `width: ${width}px;`;
const leftStyle = width => `left: ${width}px;`;

const DropdownContainer = styled.div`
    display: block;
    ${props => props.width && !props.absolute ? widthStyle(props.width) : 'width: calc(100% - 20px)'};
    ${props => props.width && props.absolute ? widthStyle(props.width) : ''};
    
    #${props => props.name}Error {
        ${props => props.inline ? leftStyle(props.labelWidth) : ''};
        white-space: normal;
    }

    & > div > div:first-of-type > div > div:first-of-type{
        ${props => props.disabled ? 'opacity: 0.5;' : ''}
    }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.625rem;
  line-height: ${props => props.inline ? '30px' : '1'};
  font-size: 0.875rem;
  ${props => props.inline ? 'float: left;' : ''};
  ${props => props.labelWidth ? widthStyle(props.labelWidth) : ''};
`;

const dropDownMenuSelector = '.react-select__menu-list';

export default class DropdownField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            invalid: props.invalid || false,
            invalidMessage: props.invalidMessage || '',
        };
    }

    componentDidUpdate(){
        if(this.props.clearValue && this.props.options && this.props.options.length>0 && this.props.value && !this.contains(this.props.options, this.props.value)){
            this.props.clearValue(this.props.name);
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({value: ''});
        }
    }


    static getDerivedStateFromProps (props, state) {
        if (props.invalid) {
            return {
                value: props.value,
                invalid: props.invalid,
                invalidMessage: props.invalidMessage || '',
            };
        }
        if (props.invalid===false) {
            return {
                value: props.value,
                invalid: false,
                invalidMessage: '',
            };
        }
        if (props.value && props.value !== state.value) {
            return {
                value: props.value,
                invalid: false,
                invalidMessage: ''
            };
        }
        return null;
    }

    contains = (options, value) => {
        return value!=='' && (options.includes(value) || options.some(e => (e.value === value || e.label === value)))
    }


    onSelectChange = (option) => {
        if(this.props.onChange){
            const returnValue = this.props.returnOption ? option : option.value;
            this.props.onChange(returnValue);
        }
    }

    scrollTarget = (menuScrollTop, menuHeight, targetHeight, itemHeight) => {
        if(menuScrollTop + menuHeight < targetHeight + itemHeight || menuScrollTop > targetHeight){
            document.querySelector(dropDownMenuSelector).scrollTop = targetHeight;
        }
    }

    onInputChange = (event) => {
        if (document.querySelector(dropDownMenuSelector)) {
            const menuScrollTop = document.querySelector(dropDownMenuSelector).scrollTop;
            const menuHeight = document.querySelector(dropDownMenuSelector).getBoundingClientRect().height;
            const listScrollHeight = document.querySelector(dropDownMenuSelector).scrollHeight;
            const labelValue = this.props.options.find(o => o.value === this.props.value)?.label;
            const itemHeight = listScrollHeight / this.props.options.length;
            if (labelValue && labelValue[0]?.toLowerCase() === event.key.toLowerCase()) {
                const resultIndex = this.props.options.findIndex(o => o.label.toLowerCase() === labelValue.toLowerCase());
                if (this.props.options.length > resultIndex + 1) {
                    if (this.props.options[resultIndex + 1].label[0]?.toLowerCase() === labelValue[0]?.toLowerCase()) {
                        this.props.onChange(this.props.options[resultIndex + 1].value);
                        const targetHeight = listScrollHeight * (resultIndex + 1) / this.props.options.length;
                        this.scrollTarget(menuScrollTop, menuHeight, targetHeight, itemHeight);
                    } else {
                        const beginningIndexOfTheChar = this.props.options.findIndex(o => o.label[0]?.toLowerCase() === event.key.toLowerCase());
                        this.props.onChange(this.props.options[beginningIndexOfTheChar].value);
                        const targetHeight = listScrollHeight * beginningIndexOfTheChar / this.props.options.length;
                        this.scrollTarget(menuScrollTop, menuHeight, targetHeight, itemHeight);
                    }
                }
            } else {
                this.goToTarget(event, listScrollHeight, menuScrollTop, menuHeight, itemHeight);
            }
        }
    }

    menuOpen = () => {
      if (this.props.fetchData) {
          this.props.fetchData().then(res => {
              if (this.props.setOptions && res) {
                  this.props.setOptions(res);
              }
          })
      }
    }

    validate = () =>{
        const { validations } = this.props;
        const msg = runValidations(validations, this.state.value);
        if(msg.length > 0){
            this.setState({
                invalid: true,
                invalidMessage: msg
            });
        }
        return !this.state.invalid && msg.length === 0;
    }


    goToTarget(event, listScrollHeight, menuScrollTop, menuHeight, itemHeight) {
        const resultIndex = this.props.options.findIndex(o => o.label && o.label[0]?.toLowerCase() === event.key.toLowerCase());
        if (this.props.options[resultIndex]) {
            this.props.onChange(this.props.options[resultIndex].value);
            const targetHeight = listScrollHeight * resultIndex / this.props.options.length;
            this.scrollTarget(menuScrollTop, menuHeight, targetHeight, itemHeight);
        }
    }

    render(){
        const { value, width, inline, labelWidth, placeHolder, isDisabled, disabled, ...rest } = this.props;
        const {invalid, invalidMessage} = this.state;
        let option = null;
        if (this.props.options?.[0] && this.props?.value) {
            if (Number.isInteger(this.props.options[0]?.value) && Number.isInteger(this.props?.value)) {
                option = this.props.options?.find(o => o.value === this.props.value)
                || this.props.options?.find(o => o.label === this.props.value);
            } else if (this.props.value) {
                const val = this.props.value.toString();
                option = this.props.options?.find(o => o.value.toString().toUpperCase() === val.toUpperCase())
                || this.props.options?.find(o => o.label.toUpperCase() === val.toUpperCase())
                || this.props.options?.find(o => o.value.trim() === val.trim());
            }
        }

        return (
          <DropdownContainer disabled={isDisabled || disabled} width={width} inline={inline} labelWidth={labelWidth}>
            {this.props.labelOutside &&
            <Label htmlFor={this.props.name} inline={inline} labelWidth={labelWidth}>{this.props.labelOutside}</Label>}
            <Select
              value={option}
              styles={customStyles}
              placeholder={placeHolder || "Please select"}
              {...rest}
              menuPortalTarget={document.body}
              menuShouldBlockScroll
              menuPlacement="auto"
              menuPosition="fixed"
              isSearchable={false}
              onChange={this.onSelectChange}
              onKeyDown={this.onInputChange}
              onBlur={() => this.props.input?.onBlur(this.props.input.value)}
              invalid={invalid}
              classNamePrefix="react-select"
              isDisabled={isDisabled || disabled}
              filterOption={false}
              onMenuOpen={this.menuOpen}
            />
            {invalid && !!invalidMessage &&
              <CDLUserMessage
                id={`${this.props.name  }Error`}
                status='error'
                iconName='triangle-error'
              >
                {invalidMessage}
              </CDLUserMessage>}
          </DropdownContainer>
        );
    }
}

