import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import AuthService from "common/services/auth.service";
import UserService from 'common/services/user.service';
import React from 'react';

export default function ConcurrentLogon(props) {

    function handleClose() {
        UserService.removeUser();
        props.close();
    }

    function continueLogin() {
        AuthService.concurrentLogin().then(response => {
            console.log(response);
            if(response.statusCode === 200){
                props.close();
                props.login(props.user, props.pwd);

            }else{
                handleClose();
            }
        });
    }

    return (
      <Modal
        ariaLabelledby="modal-heading"
        classname="myClass"
        height={300}
        isOpen={props.isOpen}
        width={400}
      >
        <ModalHeader>
          <ModalTitle>Concurrent Logon</ModalTitle>
          <ModalBtnList>
            <ModalBtn name="delete" title="close" onClick={handleClose} />
          </ModalBtnList>

        </ModalHeader>
        <ModalContent>
          You have not logged out of your previous session.
          Please select Yes for system to cancel your previous session for your re-logon or No to exit.
        </ModalContent>
        <ModalFooter hasBottomLine>
          <ModalAction>
            <ModalActionExtraButton>
              <Button onClick={handleClose}>No</Button>
            </ModalActionExtraButton>
            <ModalActionButton>
              <Button themeColor="primary" onClick={continueLogin}>Yes</Button>
            </ModalActionButton>
          </ModalAction>
        </ModalFooter>
      </Modal>
    )
}
