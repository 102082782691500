import BaseService from 'common/services/BaseService';
import UserService from 'common/services/user.service';

class UserMaintService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/UserMaintBO/';
  }

  // eslint-disable-next-line class-methods-use-this
  getExtraPropsFromUserDTO() {
    const currentUser = UserService.getCurrentUser();
    return {
      "codocexpressid": currentUser.codocexpressid,
      "countrycode": currentUser.countrycode,
      "groupmemberid": currentUser.groupmemberid,
    }
  }

  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }


  saveOrUpdate(dto, role, isUpdate){

    const qdto = this.getExtraPropsFromUserDTO();
    qdto.roles = role;

    const requestBody = {
      dto,
      qdto,
      ...this.getUserDTO()
    };

    if(isUpdate){
      return this.post('saveUserMaint', requestBody, true);
    }
      return this.post('addUserMaint', requestBody, true);


  }

  getUserMaint(userName, role) {
    const requestBody = {
      username: userName,
      forrole: role,
      ...this.getUserDTO(),
    };
    return this.post('getUserMaint', requestBody, false);
  }

  loadUserMaint(roleDTO) {
    const userDTO = this.getUserDTO();
    const extraPropsFromUserDTO = this.getExtraPropsFromUserDTO();
    const requestBody = {
      dto: {
        ...extraPropsFromUserDTO,
        roles: roleDTO
      },
      ...userDTO
    };
    return this.post('loadUserMaint', requestBody, true);
  }

  deleteUserMaint(roleDTO) {
    const userDTO = this.getUserDTO();
    const extraPropsFromUserDTO = this.getExtraPropsFromUserDTO();
    const requestBody = {
      dto: {
        ...extraPropsFromUserDTO,
        fullName: roleDTO.fullName,
        userName: roleDTO.userName
      },
      ...userDTO
    };
    return this.post('deleteUserMaint', requestBody, true);
  }

  loadCompany(proctrID) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      proctrid: proctrID,
      ...userDTO
    }
    return this.post('loadCompany', requestBody, true);
  }

  loadProCtr() {
    return this.post('loadProCtr', this.getUserDTO(), true);
  }

  loadLanguage() {
    return this.post('loadLanguage', this.getUserDTO(), true);
  }

  getModulesSelectedNonSelected(userMaint){
    const requestBody = {
      dto: userMaint,
      ...this.getUserDTO()
    }
    return this.post('getModulesSelectedNonSelected', requestBody, true);
  }

  saveModules(userMaint, modules){
    if(modules){
      modules.forEach(module=>{
        delete module.actionBtn;
      });
    }
    const requestBody = {
      dto: userMaint,
      modulesdto: modules,
      ...this.getUserDTO()
    }
    return this.post('saveModules', requestBody, true);
  }

  loadUserMaintRoles(userrole) {
    const requestBody = {
      userrole,
      ...this.getUserDTO()
    }
    return this.post('loadUserMaintRoles', requestBody);
  }

  getRefAllocByRefNo(refNO) {
    const requestBody = {
      strinput: refNO,
      ...this.getUserDTO()
    }
    return this.post('getRefAllocByRefNo', requestBody);
  }

  loadCountryCode(role) {
    const requestBody = {
      forrole: role,
      ...this.getUserDTO()
    }
    return this.post('loadCountryCode', requestBody);
  }

  loadGroupMemberID(countrycode, role) {
    const requestBody = {
      countrycode,
      forrole: role,
      ...this.getUserDTO()
    }
    return this.post('loadGroupMemberID', requestBody);
  }

  loadCSCountry() {
    return super.post('/api/CSCountryBO/loadCSCountry', {}, true);
  }

}

export default new UserMaintService();
