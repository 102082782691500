import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { loadingChange } from './loading';
import { masterDocAction } from './masterDoc';
import { pwdAction } from './pwdAction';

const appReducer = combineReducers({
  form: reduxFormReducer,
  masterDocAction,
  loadingChange,
  pwdAction,
});

function updateField(state, fieldName, data, isInit) {
  if (!state.form) {
    state.form = {};
  }
  if (!state.form.masterDoc) {
    state.form.masterDoc = {};
  }
  if (!state.form.masterDoc.values) {
    state.form.masterDoc.values = {};
  }
  if (!state.masterDocAction) {
    state.masterDocAction = {};
  }


  let obj = state.form.masterDoc.values;
  const {submitErrors} = state.form.masterDoc;
  const fields = fieldName.split('.');
  fields.forEach((field, index) => {
    if (index < fields.length - 1 && fields.length > 1) {
      if (!obj[field]) {
        obj[field] = {};
      }
      obj = obj[field];
    }
    else {
      obj[field] = data;
    }

    if(submitErrors && submitErrors[field]){
      delete submitErrors[field];
    }
  });
  if (!isInit) {
    state.masterDocAction.isDirty = true;
  }
}


const rootReducer = (state, action) => {
  switch (action.type) {
    case 'USER_LOGIN':
      return {
        ...state,
        isLogin: true
      };
    case 'USER_LOGOUT':
      // eslint-disable-next-line no-undefined
      state = undefined;
      break;
    case 'UPDATE_MSATER_DOC_FIELD': {
      const fieldName = action.payload.field;
      const {data} = action.payload;
      const {isInit} = action.payload;
      updateField(state, fieldName, data, isInit);
      return {...state};
    }
    case 'UPDATE_MSATER_DOC_FIELD_ARRAY': {
      const {fieldArray} = action.payload;
      if(fieldArray.length>0){
        fieldArray.forEach((fieldObj) => {
          updateField(state, fieldObj.field, fieldObj.data);
        })
      }
      return {...state};
    }
    case 'UPDATE_SUBMIT_ERRORS': {
      if (state.form.masterDoc.submitErrors && state.form.masterDoc.submitErrors[action.payload]) {
        delete state.form.masterDoc.submitErrors[action.payload];
      }
      return {...state};
    }
    case 'UPDATE_MASTER_DOC_FORM' : {
      if (!state.form) {
        state.form = {
          masterDoc: {}
        }
        state.form.masterDoc.values = action.payload;
      } else {
        state.form.masterDoc = {
          initials: action.payload,
          values: action.payload
        }
      }
      return {...state};
    }

    case 'SET_PREVIOUS_PAGE' : {
      if (!state.masterDocAction) {
        state.masterDocAction = {};
      }
      state.masterDocAction.previousPage = action.payload;
      return {
        ...state
      }
    }
    default:
  }

  return appReducer(state, action);
}

export default rootReducer;


