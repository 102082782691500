import CDLIcon from "@cdl/icon";
import Icon from "common/components/Icon/Icon";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled from "styled-components";

const ButtonEditMode = styled.button`
    height: 22px;
    position: relative;
    border: none;
    background: white;
    top: 5px;
    padding: 0 5px 0 10px;
    outline: none;
    border-left: 1px solid #767676;
`;

const Button = styled.button`
    height: 22px;
    position: relative;
    border: none;
    background: none;
    padding: 0 5px;
    outline: none;
    top: -3px;
`;

const Container = styled.div`
    display: flex;
    position: relative;
    width: ${props => props.width ? props.width : '100%'};
    justify-content: space-between;
    background: ${props => props.showOptions ? 'white' : 'transparent'};
    border: ${props => props.showOptions ? '1px solid black' : 'none'};

    #${props => props.containerId} {
        width: calc(100% + 18px);
    }

    & .option-active{
        background-color: #f1f1f1;
    }

    &:focus{
        outline: none;
    }
`;

const Input = styled.input`
    box-sizing: border-box;
    height: 30px;
    width: ${props => props.width ? props.width : '100%'};
    background: white;
    border: none;
    outline: none;
    font-family:HSBC-Universe-Medium;
    &:focus{
        outline: none !important;
    }
`;


const Item = styled.div`
    padding: 8px 10px;
    cursor: pointer;
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
    font-size: 12px;
    color: black;
`;

const ItemList = styled.div`
    position: absolute;
    border: 1px solid #929292;
    border-bottom: none;
    border-top: none;
    z-index: 101;
    top: 31px;
    left: 0;
    right: 0;
    // width: calc(100% - 35px);
    max-height: 200px;
    overflow: auto;

    & ${Item}:hover{
        background-color: #f1f1f1;
    }
`;


export default class DropdownHeader extends Component {


  constructor(props) {
    super(props);
    this.state = {
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: this.props.userInput && this.props.userInput !== '' ? this.props.userInput : this.props.options[0]?.label || this.props.options[0] || ''
    };
    this.ref = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  onChange = (e) => {
    const { options } = this.props;
    const userInput = e.currentTarget.value;

    const filteredOptions = options.filter((item) => {
      let text;
      if(typeof item === 'object'){
         text = item.label;
      }else{
         text = item;
      }

      return text.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    });

    if(filteredOptions.length>0){
      this.setState({
        activeOption: 0,
        filteredOptions,
        showOptions: true,
        userInput: e.currentTarget.value
      });
    }else{
      this.setState({
        userInput: e.currentTarget.value
      });
    }
    if (this.props.valueChange) {
      console.log(e.currentTarget.value);
      this.props.valueChange(e.currentTarget.value);
    }

  };

  onClick = (e) => {
    this.setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText
    });
    if (this.props.onItemClick) {
      this.props.onItemClick(e.currentTarget.innerText);
    }
  };

  onButtonClick = () => {

    this.setState(prevState => ({
      activeOption: 0,
      filteredOptions: this.props.options,
      showOptions: !prevState.showOptions,
      userSelect: true
    }));
  };

  onKeyDown = (e) => {
    const { activeOption, filteredOptions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: filteredOptions[activeOption]
      });
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        console.log(activeOption);
        return;
      }
      this.setState({ activeOption: activeOption + 1 });
    }
  };

  onBlur = () => {
    this.setState({
      showOptions: false
    });
  };

  handleClickOutside(e) {
      if (this.ref.current && !this.ref.current.contains(e.target)) {
          this.setState({
              showOptions: false,
              userSelect: false
          });
      }
  }

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      onButtonClick,
      onBlur,
      state: { activeOption, filteredOptions, showOptions, userInput, userSelect }
    } = this;

    let optionList;

    let icon;
    let textField;
    let button;
    if(showOptions){
        icon = <CDLIcon name='edit' size={18} />;
        textField = <Input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          value={userInput}
          {...this.props}
          readOnly={!this.props.editable}
        />;
        button = (
          <ButtonEditMode title="Open" type="button" onClick={onButtonClick}>
            {icon}
          </ButtonEditMode>
                 );
    }else{
        icon = <Icon name='edit' size={18} />;
        textField = (<div style={{maxWidth: this.props.width, overflow: 'hidden'}}>{userInput}</div>);
        button = (
          <Button title="Open" type="button" onClick={onButtonClick}>
            {icon}
          </Button>
                 );
    }

    if (showOptions && (userInput || userSelect) && filteredOptions && filteredOptions.length) {
        const items = filteredOptions.map((option, index) => {

          let label;
          let val;
          if (typeof option === 'object') {
            label = option.label;
            val = option.value;
          } else {
            label = option;
            val = option;
          }
          let className;
          if (index === activeOption) {
            className = 'option-active';
          }

          return (
            <Item className={className} key={val} onClick={onClick}>
              {label}
            </Item>)
        });

        optionList = (
          <ItemList>
            {items}
          </ItemList>
        );

    }


    return (
      <div ref={this.ref}>
        <Container containerId={this.props.id} showOptions={showOptions}>
          {textField}
          {button}
          {optionList}
        </Container>
      </div>

    );
  }
}


DropdownHeader.propTypes = {
  options: PropTypes.instanceOf(Array).isRequired
};
