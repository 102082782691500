import UserService from 'common/services/user.service';
import BaseService from 'common/services/BaseService';

class LiFungService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/LiFungBO/';
    this.userDTO = null;
  }

  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  getUserDTO() {

      this.userDTO = {
        "userdto": {
          "user": UserService.getCurrentUser()
        }
      }

    return this.userDTO;
  }

  generateReport(liFungDto) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      ...liFungDto,
      ...userDTO
    }
    return this.post('generateReport', requestBody, false);
  }
}

export default new LiFungService();
