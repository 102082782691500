import Collapsible from "common/components/Collapsible/Collapsible";
import { disableInvoiceAmount, setMasterDoc, setMasterDocState } from 'common/redux/actions';
import docPrepService from 'common/services/doc-prep.service';
import { fixActionBarBottom, initActionBarPosition } from 'common/utils/UserInterfaceUtil';
import InvoiceSection from "de/views/DocPreparation/Sections/InvoiceSection";
import React from 'react';
import { connect } from "react-redux";


class Invoice extends React.Component {


  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.docStatus !== 'isNew' && this.props.masterDoc) {
      const {masterDoc} = this.props;
      const metaData = {mdocid: masterDoc.values.mdocid, mdoctype: masterDoc.values.mdoctype};
      docPrepService.loadInvoiceSection(metaData).subscribe(responses => {
        this.props.setMasterDoc(null);
        const [masterDocResponse, billOfExchangeResponse, invoiceResponse] = responses;
        masterDocResponse.data.billofexchangedto = billOfExchangeResponse.data;
        masterDocResponse.data.invoicedetaildto = invoiceResponse.data.list;
        console.log(masterDocResponse, invoiceResponse);
        this.props.setMasterDoc(masterDocResponse.data);
        this.props.setMasterDocState(masterDocResponse.data.status);
      })
    } else {
      this.props.disableInvoiceAmount(false);
    }
    initActionBarPosition();
    fixActionBarBottom();
    sessionStorage.setItem('INV', true);
  }

  render() {

    const accordionMark = {};
    if (this.props.submitErrors && this.props.submitErrors.invoicedetaildto) {
      accordionMark.invoicedetaildto = true;
    } else {
      accordionMark.invoicedetaildto = false;
    }

    return (


      <Collapsible label="5. Invoice(s)" className="collapsible last" section='invoice'>
        <InvoiceSection
          showErrorMessage={accordionMark.invoicedetaildto && !this.props.hideErrors}
          errorMessage={this.props.submitErrors?.invoicedetaildto}
          metaData={{mdocid: this.props.masterDoc.values?.mdocid, mdoctype: this.props.masterDoc.values?.mdoctype}}
        />
      </Collapsible>


    )
  }


}

const mapStateToProps = state => ({
  submitErrors: state.form?.masterDoc?.submitErrors,
  masterDoc: state.form?.masterDoc,
  invoiceList: state.masterDocAction.invoiceList,
  docStatus: state.masterDocAction?.masterDocState || 'isNew',
  isReset: state.masterDocAction?.isReset,
  hideErrors: state.masterDocAction?.hideErrors?.invoicedetaildto
})


const mapDispatchToProps = dispatch => ({
  setMasterDoc: (data) => dispatch(setMasterDoc(data)),
  setMasterDocState: (data) => dispatch(setMasterDocState(data)),
  disableInvoiceAmount: (data) => dispatch(disableInvoiceAmount(data)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Invoice)
