import TextAreaCDL from '@cdl/textarea';
import CDLUserMessage from '@cdl/user-message';
import { runValidations } from 'common/utils/FieldValidation';
import React from 'react';
import styled from "styled-components";

const TextAreaContainer = styled.div`
    display: inline-block;
    width: 100%;

    label{
        font-size: 14px; 
        display: block;
        padding-bottom: 10px;
    }

    textarea{
        box-sizing: border-box;
        width: ${props => (props.fullWidth ? '100%' : 'calc(100% - 20px)') };
        margin-bottom: 20px;
        background-color: ${props => props.invalid ? '#f9f2f3': 'white'};
        ${props => props.invalid ? 'border-color: #af000d;': ''};
        overflow: auto;
    }

    & > div > div[aria-live="polite"]{
        display:none;
    }

    textarea:disabled{
      opacity: ${props => props.enabledStyle ? '1' : '0.5'}
    }

    & div#${props => props.id}Error {
      top: -10px;
    }

`;


export default class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          value: props.value || '',
          invalid: props.invalid || false,
          invalidMessage: props.invalidMessage || '',
        };

        this.handleChange = this.handleChange.bind(this);
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
          value: nextProps.value,
          invalid: nextProps.invalid,
          invalidMessage: nextProps.invalidMessage,
        })
    }

    validate = () =>{
      const { validations } = this.props;
      const msg = runValidations(validations, this.state.value);
      if(msg.length > 0){
        this.setState({
          invalid: true,
          invalidMessage: msg
        });
      }
      return !this.state.invalid && msg.length === 0;
    }

    handleChange = (event) => {
        this.setState({
          value: event.target.value,
          invalid: false,
          invalidMessage: '',
        });
    }

    handleBlur = (event) => {
      if (this.props.handleChange) {
        this.props.handleChange(event);
      }
      if (this.props.onChange) {
          this.props.onChange(event);
      }
    }

    render() {
        const {invalid, invalidMessage} = this.state;
        const id = this.props.id || this.props.name;
        return (
          <TextAreaContainer fullWidth={this.props.fullWidth} invalid={invalid} id={id} enabledStyle={this.props.enabledStyle}>
            <label htmlFor={id}>{this.props.label}</label>
            <TextAreaCDL
              value={this.state.value}
              name={this.props.name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              disabled={this.props.disabled}
              maxLength={this.props.maxLength}
              height={this.props.height}
            />
            {invalid && !!invalidMessage &&
              <CDLUserMessage
                id={`${this.props.name  }Error`}
                status='error'
                iconName='triangle-error'
              >
                {invalidMessage}
              </CDLUserMessage>}
          </TextAreaContainer>
        );
    }
}

