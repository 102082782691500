import Grid from '@material-ui/core/Grid';
import IconButton from "common/components/IconButton/IconButton";
import TableHelper from 'common/components/Table/TableHelper';
import UserMessage from 'common/components/UserMessage/UserMessage';
import { setHideError, setMasterDoc, setMasterDocState, setPackingList, updateSubmitErrors } from 'common/redux/actions';
import docPrepService from 'common/services/doc-prep.service';
import { isNotNew } from 'common/utils/MasterDocUtil';
import Confirm from "common/views/Popup/Confirm";
import PackingListDetail from "common/views/Popup/PackingList/PackingListDetail";
import PackingListItems from "common/views/Popup/PackingList/PackingListItems";
import React from 'react';
import { connect } from 'react-redux';

const buttonArray = [
                     {id: 'openDetail', name: 'Edit', showModal: 'showRowItem'},
                     {id: 'openDelete', name: 'Delete', action: 'lazyDeleteRow',
                        modal: Confirm, modalProps :{content: 'Do you wish to delete the packing list "@title"?'} },
                     {id: 'openItem', name: 'PL. item(s)', showModal: 'showItemList'}
                    ];

class PackingListSection extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
      this.tableRef = React.createRef();
      this.props.setPackingList([]);
    }

    componentDidMount() {
      if (isNotNew(this.props.docStatus) && this.props.masterDoc) {
        const {masterDoc} = this.props;
        const metaData = {mdocid: masterDoc.mdocid, mdoctype: masterDoc.mdoctype};

        docPrepService.loadPackingListSection(metaData).subscribe(responses => {
          this.props.setMasterDoc(null);
          const [masterDocResponse, billOfExchangeResponse, packingListResponse] = responses;
          masterDocResponse.data.billofexchangedto = billOfExchangeResponse.data
          this.props.setMasterDoc(masterDocResponse.data);
          this.props.setMasterDocState(masterDocResponse.data.status);
          this.props.setPackingList(packingListResponse.data.list);
        })

      }
    }

    newPackingList = () => {
      this.tableRef.current.newRow();
    }

    handleSave = (data) => {
      const packingList = this.props.packingList.values;
      packingList.push(data);
      this.props.updatePackingListSubmitErrors('packingList');
      this.props.updateStatus();
    }

    closeButtonClick = () => {
      this.props.setHideError('packingList');
    }

    refreshData = (data) => {
      this.props.packingList.values = data;
    }

    render() {

      return (
        <div>

          <Grid container spacing={0} style={{paddingTop:'20px'}}>
            <Grid item xs={12} style={{paddingRight: '20px'}}>
              {this.props.showErrorMessage &&
              <UserMessage
                showCloseButton
                border
                type="userError"
                iconName="triangle-error"
                iconColor="ERROR"
                handleCloseButtonClick={this.closeButtonClick}
                closeText="Close"
              >
                Please create at least 1 packing list
              </UserMessage>}
            </Grid>
            <Grid item xs={12}>
              <div className="rightBtn">
                <IconButton onClick={this.newPackingList} name="add">Add new packing list</IconButton>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{paddingRight:'20px'}}>
            <Grid item xs={12}>
              <TableHelper
                ref={this.tableRef}
                component={PackingListDetail}
                componentItemList={PackingListItems}
                listField='packinglistitemdetaildtolist'
                buttonArray={buttonArray}
                titleField="plno"
                data={this.props.packingList?.values || []}
                callback={this.handleSave}
                refreshData={this.refreshData}
                columns={[
                {
                  Header: 'Packing list number',
                  accessor: 'plno',
                  Cell: row => <span title={row.original.plno}>{row.original.plno}</span>
                },
                {
                  Header: 'Packing list date',
                  accessor: 'pldate'
                },
                {
                  Header: 'Invoice number',
                  accessor: 'plinvno',
                  Cell: row => <span title={row.original.plinvno}>{row.original.plinvno}</span>
                },
                {
                  Header: 'Invoice date',
                  accessor: 'plinvdate'
                },
                {
                  Header: 'Action',
                  accessor: 'actionBtn',
                  maxWidth: 190,
                  sortable: false
                }
              ]}
              />
            </Grid>
          </Grid>
        </div>
      )
    }
}

const mapStateToProps = state => ({
  packingList: state.masterDocAction?.packingList,
  docStatus: state.masterDocAction.masterDocState,
  masterDoc: state.form?.masterDoc?.values
})

const mapDispatchToProps = dispatch => ({
  setPackingList: (data) => dispatch(setPackingList(data)),
  setMasterDoc: (data) => dispatch(setMasterDoc(data)),
  setMasterDocState: (data) => dispatch(setMasterDocState(data)),
  updatePackingListSubmitErrors: (data) => dispatch(updateSubmitErrors(data)),
  setHideError: (data) => dispatch(setHideError(data))
})


export default connect(mapStateToProps, mapDispatchToProps)(PackingListSection)
