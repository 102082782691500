import BaseService from 'common/services/BaseService';
import UserService from 'common/services/user.service';

class PrintService extends BaseService {

    getPrintchoice(isEDS, mdid, mdoctype) {
        const userdto = this.getUserDTO();
        const user = UserService.getCurrentUser();
        userdto.userdto.user.submodules = user.submodules;
        userdto.userdto.user.approvalright = user.approvalright;
        userdto.userdto.user.isCompanyEDS = user.isCompanyEDS;
        const requestBody = {
            "dc_dadp_eds": isEDS,
            "mdid": mdid,
            "mdoctype": mdoctype,
            ...userdto
        }

        return this.post('/api/PdfBO/getPrintchoice', requestBody);
    }

    generatePdf(iswindowcontrol, pdfprint, pdfsetting) {
        const userdto = this.getUserDTO();
        const user = UserService.getCurrentUser();
        userdto.userdto.user.modules = user.modules;
        userdto.userdto.user.submodules = user.submodules;
        userdto.userdto.user.userroles = user.userroles;
        userdto.userdto.user.isCompanyEDS = user.isCompanyEDS;
        const requestBody = {
            "iswindowcontrol": iswindowcontrol,
            "pdfprint": pdfprint,
            "pdfsetting": pdfsetting,
            ...userdto
        }

        return this.post('/api/PdfBO/generatePdf', requestBody, true);
    }

    initPrintPanel(mdid) {
        const requestBody = {
            "mdid": mdid,
            ...this.getUserDTO()
        }

        return this.post('/api/PdfBO/initPrintPanel', requestBody);
    }


    initPrintPanelAddFld(mdid) {
        const requestBody = {
            "mdid": mdid,
            ...this.getUserDTO()
        }

        return this.post('/api/PdfBO/initPrintPanelAddFld', requestBody);
    }

    updateAddFld(mdid, fldlist) {
        const requestBody = {
            "mdid": mdid,
            "fldlist": fldlist,
            ...this.getUserDTO()
        }

        return this.post('/api/PdfBO/updateAddFld', requestBody);
    }
}

export default new PrintService();
