/* eslint-disable no-useless-escape */
import { colors } from '@cdl/foundations';
import CDLTextField from '@cdl/text-field';
import { basicValidation, REQUIRED, runValidations } from 'common/utils/FieldValidation';
import { isBlank } from 'common/utils/StringUtils';
import React from 'react';
import styled from "styled-components";

const _ = require('lodash');

const widthStyle = props => props.inputWidth ? `width: ${props.inputWidth}px`: '';

const TextFieldContainer = styled.div`
    margin-bottom: 20px;
    ${props => props.right ? 'float: right' : ''}

    
    &.seamless {
        background: white;
        position: relative;
        margin-bottom: initial;
        border: 1px solid #929292;
        height: 100%;
        & >div {
            height: 100%;
            margin-bottom: initial;
            & >label {
                position: absolute;
                top: ${props => props.eds ? '12px' : '14px'};
                left: 10px;
                font-size: ${props => props.eds ? '12px' : '10px'};
                color: ${props => props.eds ? colors.SLATE : colors.DARK_SLATE};
                margin-bottom: 4px;
            }
            & >input {
                position: absolute;
                background: transparent;
                top: 27px;
                font-size: 12px;
                border: none;
            }
            & >input:focus {
                outline: none;
            }
        }

    }

    #${props => props.containerId} {
        ${props => props.inline ? 'margin-right: 10px' : 'width: calc(100% - 20px)'};
        ${props => props.right ? 'margin-right: 0' : ''}
        ${props => props.fullWidth ? 'width: 100%' : ''}
        ${props => widthStyle(props)};
    }

    #${props => props.containerId}Label {
        ${props => props.inline && props.email ? 'width: 194.5px; display: inline-block;' : ''}
        ${props => props.inline && !props.email && props.className !== 'no-fixed-width' && props.label !== 'Email Address' ? 'width: 100px; display: inline-block;' : ''};
        ${props => props.size === 'big' ? 'height: 40px; line-height: 40px; margin-right: 0' : ''};
        ${props => props.longLabel ? 'width: 200px;' : ''};
        ${props => props.right ? 'text-align: right; margin-right: 10px;' : ''};
        ${props => props.noLabel ? 'display: none;' : ''};
    }



    #${props => props.containerId}Error {
        position: relative;
        ${props => props.inline && props.email ? 'left: 198px; margin-top: 10px;' : ''}
        ${props => props.inline && !props.right && !props.email? 'left: 100px; margin-top: 10px;' : ''};
        ${props => props.longLabel ? 'left: 200px;' : ''};   
        ${props => widthStyle(props)};
        white-space: normal;
    }

    & input {
        box-sizing: border-box;
        ${props => props.size === 'big' ? 'height: 40px;' : 'height: 30px;'};        
    } 

    
`;


export default class TextFieldForm extends React.Component {
    containerId;

    constructor(props) {
        super(props);


        let containerId;
        if(this.props.id){
            containerId = this.props.id;
        }else if(this.props.name){
            containerId = this.props.name;
        }else if(this.props.label){
            containerId = _.camelCase(this.props.label);
        }
        this.containerId = containerId;
        this.state = {
            value: props.value || '',
            invalidMessage: '',
        };

        if (!this.props.disableDebounce) {
            this.handleChange = _.debounce(this.handleChange.bind(this), 100);
            if (this.props.onChange) {
                this.forwardChange = _.debounce(this.props.onChange, 100);
            }
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ((nextProps.value || nextProps.value === 0)) {
            if(nextProps.isAccount){
                this.setState(prevState=>{
                    return {
                        value: /^[0-9\*\-\s]+$/.test(nextProps.value) ? nextProps.value : prevState.value,
                    }
                })
            } else if (nextProps.decimials) {
                const value = parseFloat(nextProps.value.toString().replace(/,/g, ""))
                .toFixed(nextProps.decimials)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                this.setState({
                    value
                })
            } else {
                this.setState({
                    value: nextProps.value
                })
            }

        } else if(this.state.value !== nextProps.value) {
            this.setState({
                value: nextProps.value
            })
        }

    }

    onChange = (event) => {
        event.persist();
        event.preventDefault();
        this.setState(prevState => {
            const originalValue = !isBlank(event.target.value) ? prevState.value : '';
            if (prevState.invalidMessage === basicValidation(REQUIRED, prevState.value) && !isBlank(prevState.value)) {
                prevState.invalidMessage = null;
            }
            if (this.props.isAccount){
                if (isBlank(event.target.value)) {
                    return {
                        value: '',
                        invalidMessage: prevState.invalidMessage
                    }
                }
                return {
                    value: /^[0-9\*\-\s]+$/.test(event.target.value) ? event.target.value : originalValue,
                    invalidMessage: prevState.invalidMessage
                }
            }

            if(this.props.regex){
                return {
                    value: new RegExp(this.props.regex).test(event.target.value) ? event.target.value : originalValue,
                    invalidMessage: prevState.invalidMessage
                }
            }

            return {
                value: event.target.value,
                invalidMessage: prevState.invalidMessage
            }
        })
    }


    validate = (name) =>{

        const { validations } = this.props;
        const { value } = this.state;
        const msg = runValidations(validations, value);

        this.setState({invalidMessage: msg});

        const isValid = (msg.length === 0);
        if(isValid && this.props.handleInputValue){
            this.props.handleInputValue(name, this.props.value);
        }

        return isValid;
    }


    onBlur = (event) => {
        event.persist();
        event.preventDefault();

        const { name } = event.currentTarget;

        this.validate(name);
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
        if (this.props.onBlurWithValue) {
            this.props.onBlurWithValue(event.currentTarget.value);
        }
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    isNotNumber = (e) => {
        return !((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105)) || e.shiftKey;
    }

    isNotPlusMinus = (e) => {
        return (e.key !== '-' || (e.key === '-' && e.shiftKey)) && (e.key !== '+' || (e.key === '+' && !e.shiftKey));
    }

    onKeyDown = (e) => {
        const isNotNumber = this.isNotNumber(e);
        const isNotPlusMinus = this.isNotPlusMinus(e);
        const isCtrlAll = e.ctrlKey && (e.key === 'a' || e.key === 'A');
        const isBackSpaceOrTab = e.keyCode !== 8 && e.keyCode !== 9;
        if (this.props.numericOnly && isNotNumber && !isCtrlAll && isBackSpaceOrTab) {
            e.preventDefault();
        }

        if (this.props.phoneNumberFormat && isNotNumber && isNotPlusMinus && !isCtrlAll && isBackSpaceOrTab) {
            e.preventDefault();
        }

        if (e.keyCode === 13 && this.props.onBlur) {
            this.props.onBlur(e);
          }
    }

    handleChange(event) {
        const { value } = event.target;
        this.setState(
          {
            value
          }
        );
    }

    render() {

        const { id, inline, right, eds, fullWidth, value, size, inputWidth, longLabel, noLabel,
            className, style, invalid, invalidMessage,email, onChange, onBlur, reduxInput,  ...rest } = this.props;

        return (
          <TextFieldContainer
            email={email}
            containerId={this.containerId || id}
            inline={inline}
            right={right}
            fullWidth={fullWidth}
            size={size}
            inputWidth={inputWidth}
            longLabel={longLabel}
            noLabel={noLabel}
            style={style}
            className={this.props.seamless ? 'seamless': className}
            eds={this.props.eds}
          >
            {reduxInput &&
            <CDLTextField
              email={email}
              id={this.containerId || id}
              inline={inline}
              invalid={invalid || this.state.invalidMessage?.length > 0}
              invalidMessage={invalidMessage || this.state.invalidMessage}
              value={this.state.value}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
              onBlur={this.onBlur}
              {...rest}
            />}

            {!reduxInput &&
            <CDLTextField
              email={email}
              id={this.containerId || id}
              inline={inline}
              invalid={invalid || this.state.invalidMessage?.length > 0}
              invalidMessage={invalidMessage || this.state.invalidMessage}
              value={this.state.value}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
              onBlur={onBlur || this.onBlur}
              {...rest}
            />}
          </TextFieldContainer>
        )
    }
}
