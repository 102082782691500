import UserService from 'common/services/user.service';
import BaseService from 'common/services/BaseService';
import { getFormName } from "common/utils/AppUtil";

const initialFilters = {
  indraweecompanydto: {
    coDocExpressID: "",
    coName: ""
  },
  indraweeprocctrdto: {
    processCtrID: "",
    processCtrName: ""
  }
}

class DraweeService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/DraweeBO/';
    this.userDTO = null;
  }

  getUserDTO() {
      const user = UserService.getCurrentUser();
      user.formname = getFormName();
      this.userDTO = {
        "userdto": {
          "user": user
        }
      }

    return this.userDTO;
  }

  // eslint-disable-next-line class-methods-use-this
  getDocExpressId() {
    return UserService.getCurrentUser().codocexpressid;
  }

  // eslint-disable-next-line class-methods-use-this
  getExtraPropsFromUserDTO() {
    const currentUser = UserService.getCurrentUser();
    return {
      "coDocExpressID": currentUser.codocexpressid,
      "countryCode": currentUser.countrycode,
      "groupMemberID": currentUser.groupmemberid,
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getExtraPropsFromDrawee() {
    const current = new Date();
    return {
      "dateUpdate": current,
      "deleted": false,
      "userUpdate": "",
      "wolfStatus": ""
    }
  }

  // eslint-disable-next-line class-methods-use-this
  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  addDrawee(formData, coDocExpressID = "") {
    const userDTO = this.getUserDTO();
    const extraPropsFromUserDTO = this.getExtraPropsFromUserDTO();
    const extraPropsFromDrawee = this.getExtraPropsFromDrawee();
    const requestBody = {
      "indraweedto": {
        ...formData,
        ...extraPropsFromUserDTO,
        ...extraPropsFromDrawee
      },
      ...userDTO
    }
    if (coDocExpressID) {
      requestBody.indraweedto.coDocExpressID = coDocExpressID
    }
    return this.post('addDrawee', requestBody, true);
  }

  getCollectingBank(deid, selid) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      "deid": deid,
      "selid": selid,
      ...userDTO
    }
    return this.post('getCollectingBank', requestBody, false);
  }

  getConsigneeName(consigneeDTO, coDocExpressID = "") {
    const userDTO = this.getUserDTO();
    const extraPropsFromUserDTO = this.getExtraPropsFromUserDTO();
    const requestBody = {
      "indraweedto": {
        "def_Consignee": consigneeDTO,
      ...extraPropsFromUserDTO
      },
      ...userDTO
    }
    if (coDocExpressID) {
      requestBody.indraweedto.coDocExpressID = coDocExpressID
    }
    return this.post('getConsigneeName', requestBody, false);
  }

  loadDrawee(draweeDTO) {
    const userDTO = this.getUserDTO();
    let requestBody = {};
    if (draweeDTO && Object.keys(draweeDTO).length !== 0) {
      requestBody = {
        ...draweeDTO,
        ...userDTO
      }
    } else {
      requestBody = {
        ...userDTO
      }
    }
    return this.post('loadDrawee', requestBody, true);
  }

  getDrawee(selectedDTO) {
    const userDTO = this.getUserDTO();
    let requestBody = {};
    if (selectedDTO?.actionBtn) {
      delete selectedDTO.actionBtn;
      requestBody = {
        "indraweedto": {
          ...selectedDTO        },
        ...userDTO
      }
    } else {
      requestBody = {
        "indraweedto": {
          "importerCode": selectedDTO,
          "coDocExpressID": this.getDocExpressId()
        },
      ...userDTO
      }
    }
    return this.post('getDrawee', requestBody, false);
  }

  initDrawee(filterDTO = initialFilters) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      ...filterDTO,
      ...userDTO
    }
    return this.post('initDrawee', requestBody, true);
  }

  deleteDrawee(draweeDTO) {
    const userDTO = this.getUserDTO();
    delete draweeDTO.actionBtn;
    const requestBody = {
      "indraweedto": {
        ...draweeDTO
      },
      ...userDTO
    }
    return this.post('deleteDrawee', requestBody, true);
  }

  saveDrawee(draweeDTO) {
    const userDTO = this.getUserDTO();
    const requestBody = {
      "indraweedto": {
        ...draweeDTO
      },
      ...userDTO
    }
    return this.post('saveDrawee', requestBody, true);
  }

  loadCompanyLFMode(company) {
    const requestBody = {
      "indraweecompanydto": company,
      ...this.getInUserDTO()
    }
    return this.post('loadCompanyLFMode', requestBody, true);
  }
}

export default new DraweeService();
