import RadioGroup from 'common/components/RadioGroup/RadioGroup';
import React from 'react';


const renderRadioGroup = ({ input, legend, name, id, radio, vertical }) => (
  <RadioGroup
    legend={legend}
    id={id}
    name={name}
    {...input}
    vertical={vertical}
  >
    {radio}
  </RadioGroup>
)

export default renderRadioGroup
