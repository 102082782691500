export function loadingChange(state = {}, action = {}) {
  let loadingCount = state.loadingCount || 0;
  let showLoading = false;
  switch (action.type) {
    case 'CHANGE_LOADING_STATE':
      if (action.payload) {
        loadingCount++;
      } else if (loadingCount) {
        loadingCount--;
      }
      if (loadingCount > 0) {
        showLoading = true;
      }

      return {showLoading, loadingCount};
    case '@@redux-form/SET_SUBMIT_FAILED':
    case 'SYS_ERR':
      return {showLoading: false};
    default:
      return state;
  }
}

