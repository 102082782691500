import React from 'react';
import Checkbox from 'common/components/Checkbox/Checkbox';
import DropdownField from 'common/components/Dropdown/DropdownField';
import Grid from '@material-ui/core/Grid';


export default class Criteria extends React.PureComponent {

  render() {
    const {label, id, idDropdown, changeCheckbox, changeDropdown, options, disabled, value, disabledCheckbox, checked} = this.props;

    return (
      <Grid item xs={9}>
        <Grid container spacing={0}>
          <Grid item xs={2}>
            <Checkbox
              label={label}
              id={id}
              name={id}
              onChange={changeCheckbox}
              disabled={disabledCheckbox}
              checked={checked}
            />
          </Grid>
          <Grid item xs={8}>
            <DropdownField
              id={idDropdown}
              options={options}
              selectWidth={300}
              onChange={changeDropdown}
              value={value}
              disabled={disabled}
              inline
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

