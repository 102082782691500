import { AndOperator } from 'common/utils/CyclomaticComplexityUtil';
import { isAC } from "common/utils/AppUtil";

const MODULES = ["GenericEDS", "GenericFinancedEDS", "GenericLiFungEDS", "GenericEDSWorkflow", "GenericEDSWorkflow2Level"];

const hasModule = (submodule) => {
    return MODULES.some(r=> submodule?.includes(r))
}

const isNotNew = (docStatus) => {
    return docStatus && docStatus!=='isNew'
}

const orStatus = (docStatus, statusArray) => {
    if(!docStatus){
        return false;
    }

    return statusArray.some((status)=>{
        return docStatus === status;
    });
}

const hasPrintChoice = (printChoice, option) => {
    return printChoice && printChoice[option] === 1
}

const checkPrintMenu = (button, docStatus, printChoice) => {
    switch(button) {
        case 'Print draft':
        case 'Print':
            if(isNotNew(docStatus)){
                return true;
            }
            break;
        case 'Print for traditional collection':
        case 'Transmit via email':
        case 'Print and email':
            if(isNotNew(docStatus) && hasPrintChoice(printChoice, 'cPrintTradCol') ){
                return true;
            }
            break;
        case 'Reprint EDS document':
        case 'Re-send EDS document via email':
            if(AndOperator(isNotNew(docStatus), hasPrintChoice(printChoice, 'cReprintEDS'), !printChoice?.isLFEDS)){
                return true;
            }
            break;
        case 'Electronic direct send':
            if(isNotNew(docStatus) && hasPrintChoice(printChoice, 'cEDS') ){
                return true;
            }
            break;
        default:
            return false;
    }
    return false;
}

const checkFileMenu = (button, docStatus, docType) => {
    switch(button) {
        case 'New':
        case 'Open':
            return true;
        case 'Save':
        case 'Delete':
            if(isNotNew(docStatus)){
                return true;
            }
            break;
        case 'Save as':
            if((docStatus === 'isNew' && docType !== "MD") || docType === 'TM'){
                return true;
            }
            break;
        default:
            return false;
    }
    return false;
}

const approveCondition = (userPermissions, docStatus) => {
    return userPermissions.approvalright && orStatus(docStatus, ['COMPLETED', 'UNLOCKED']);
}

const unlockCondition = (userPermissions, docStatus) => {
    return userPermissions.approvalright && orStatus(docStatus, ['APPROVED', 'PRINTED', 'PARTIAL', 'BANK_APPROVED', 'BANK_REJECTED', 'BANK APPROVED', 'BANK REJECTED']);
}

const submitCondition = (userPermissions, docStatus, printChoice) => {
    return userPermissions.submitright && isNotNew(docStatus)
                && docStatus !== 'SUBMITTED'
                && hasPrintChoice(printChoice, 'cEDS');
}

const checkApprovalMenu = (button, docStatus, userPermissions, docType, printChoice) => {
    switch(button) {
        case 'Approval':
            if(docType === "MD"
                && (approveCondition(userPermissions, docStatus)
                        || unlockCondition(userPermissions, docStatus)
                        || submitCondition(userPermissions, docStatus, printChoice) )){
                return true;
            }
            break;
        case 'Approve':
            if(approveCondition(userPermissions, docStatus)){
                return true;
            }
            break;
        case 'Unlock':
            if(unlockCondition(userPermissions, docStatus)){
                return true;
            }
            break;
        case 'Submit':
            if(submitCondition(userPermissions, docStatus, printChoice)){
                return true;
            }
            break;
        default:
            return false;
    }
    return false;
}

const checkOthers = (button, docStatus, userPermissions, submodule) => {
    switch(button) {
        case 'addTenor':
        case 'addCarrier':
            if(!isAC()){
                return true;
            }
            break;
        case 'HSBC approval':
            if(orStatus(docStatus, ['REVIEWED', 'SUBMITTED', 'BANK_APPROVED', 'BANK_REJECTED', 'BANK APPROVED', 'BANK REJECTED'])){
				return true;
            }
            break;
        case 'Wolf scan':
            if(userPermissions.wolfexceptionright && isNotNew(docStatus)){
                return true;
            }
            break;
        case 'Add new':
            if(AndOperator(!isAC() , !userPermissions.isCompanyEDS , !hasModule(submodule))){
                return true;
            }
            break;
        case 'Doc image':
            if(submodule && submodule.includes("GenericLiFungEDS") && isNotNew(docStatus)){
                return true;
            }
            break;
        default:
            return false;
    }
    return false;
}

const checkPermissions = (button, docStatus, userPermissions, docType, submodule, printChoice) => {

    return checkPrintMenu(button, docStatus, printChoice)
            || checkFileMenu(button, docStatus, docType)
            || checkApprovalMenu(button, docStatus, userPermissions, docType, printChoice)
            || checkOthers(button, docStatus, userPermissions, submodule)
};

const AccessControl = ({
    button,
    docStatus,
    userPermissions,
    children,
    renderNoAccess,
    docType,
    submodule,
    printChoice,
}) => {
  const permitted = checkPermissions(button, docStatus, userPermissions, docType, submodule, printChoice);

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

AccessControl.defaultProps = {
    userPermissions: {},
    renderNoAccess: () => null,
};

export default AccessControl;


