import CDLIcon from "@cdl/icon";
import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import React, { useEffect } from 'react';

export default function Msg(props) {

  useEffect(() => {
    if (props.isOpen) {
      document.documentElement.classList.add('over-flow-hidden');
    }
    return () => {
      if (props.isOpen) {
        document.documentElement.classList.remove('over-flow-hidden');
      }
    };
  });
        const extraButton = props.extraButton ? props.extraButton : "No";
        const actionButton = props.actionButton ? props.actionButton : "Yes";

        return (
          <Modal
            ariaLabelledby="modal-heading"
            width={props.width || 400 - (props.hideExtraButton ? 50 : 0)}
            height={props.height || 200}
            isOpen={props.isOpen}
          >
            <ModalHeader>
              <ModalTitle>
                {props.warning &&
                <span style={{paddingRight:'5px'}}>
                  <CDLIcon name="circle-error-solid" color="WARNING" size={29} />
                </span>}
                {props.title}
              </ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={props.close} />
              </ModalBtnList>

            </ModalHeader>
            <ModalContent>
              {props.content}
            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>
                {!props.hideExtraButton &&
                <ModalActionExtraButton>
                  <Button onClick={props.close}>{extraButton}</Button>
                </ModalActionExtraButton>}
                <ModalActionButton>
                  <Button onClick={props.confirm} themeColor="primary">{actionButton}</Button>
                </ModalActionButton>
              </ModalAction>
            </ModalFooter>
          </Modal>
        )
}
