import BaseService from 'common/services/BaseService';
import masterDocService from './masterDoc.service';
import exportInstructionService from "./export-instruction.service";

const _ = require('lodash');

class BeneficiaryCertService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/BenecertBO/';
  }


  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  postWithStatus(url, paras, showLoading) {
    return super.postReturnWithStatus(this.basePath + url, paras, showLoading);
  }

  getBenecertList(formid, mdocid, mdoctype) {
    const requestBody = {
      "formid": formid,
      "mdocid": mdocid,
      "mdoctype": mdoctype,
      ...this.getUserDTO()
    }

    return this.postWithStatus('getBenecertList', requestBody, true);
  }

  saveBenecertList(beneficiaryDTO, masterDocDTO) {
    this.removeBeneCertActionButton(beneficiaryDTO);
    beneficiaryDTO = beneficiaryDTO.filter(bene => !bene.isDelete);
    const requestBody = {
        "arrbenecertlistdetaildto": _.cloneDeep(beneficiaryDTO),
        "masterdocdto": masterDocDTO,
        ...this.getUserDTO()
      }
      return this.postWithStatus('saveBenecertList', requestBody, true);
  }

  saveNewBenecertList(arrbenecertlistdetaildto, masterdocdto, metaData) {
    const userDTO = this.getUserDTO();
    masterdocdto.mdoctype = metaData.mdoctype.toUpperCase();
    masterdocdto.mdocdesc = metaData.mdocdesc ? metaData.mdocdesc : '';
    masterdocdto.tmplmdocid = masterdocdto.mdocid;
    masterDocService.massageMasterDocBeforeSubmission(masterdocdto, userDTO);
    masterdocdto.formid = "BC";
    const {user} = userDTO.userdto;
    return new Promise((resolve, reject) => {
      masterDocService.getMasterDocMDocID(user.codocexpressid, user.groupmemberid, '', '', user.codocexpressid, metaData.mdoctype).then(newMasterDocID => {
        masterdocdto.mdocid = newMasterDocID;
        this.removeBeneCertActionButton(arrbenecertlistdetaildto);
        const requestBody = {
          "arrbenecertlistdetaildto": _.cloneDeep(arrbenecertlistdetaildto),
          "masterdocdto": masterdocdto,
          ...userDTO
        }
        if(masterdocdto.tmplmdocid.startsWith("TM")){
          resolve(this.post('saveNewBenecertList', requestBody, true));
        }else{
          this.post('saveNewBenecertList', requestBody, true).then(()=>{
            resolve(exportInstructionService.addExportInstruction(masterdocdto.eidto, masterdocdto));
          })
        }
      }, (err) => {
        reject(err);
      })
    })
  }

  saveTMPLBenecertList(arrbenecertlistdetaildto, masterdocdto) {
    this.removeBeneCertActionButton(arrbenecertlistdetaildto);
    arrbenecertlistdetaildto = arrbenecertlistdetaildto.filter(bene => !bene.isDelete);
    const requestBody = {
        "arrbenecertlistdetaildto": _.cloneDeep(arrbenecertlistdetaildto),
        "masterdocdto": masterdocdto,
        ...this.getUserDTO()
      }
      return this.postWithStatus('saveTMPLBenecertList', requestBody, true);
  }

  updateMasterDoc(masterdocdto) {
    const userDTO = this.getUserDTO();
    const submitMasterDocDTO = { ...masterdocdto};

    masterDocService.massageMasterDocBeforeSubmission(submitMasterDocDTO, userDTO);
    submitMasterDocDTO.formid = 'BC';

    this.massageInvoiceDTOBeforeSubmission(masterdocdto.invoicedetaildto, userDTO);
    const requestBody = {
        "masterdocdto": submitMasterDocDTO,
        ...this.getUserDTO()
      }
      return this.postWithStatus('updateMasterDoc', requestBody, true);
  }

  massageInvoiceDTOBeforeSubmission(invoiceDTOList) {
    console.log(this);
    if (invoiceDTOList) {
        invoiceDTOList.forEach(invoiceDTO => {
          Object.keys(invoiceDTO).forEach(key => {
              if (!invoiceDTO[key]) {
                  invoiceDTO[key] = '';
              }
          })
          console.log(invoiceDTO);
          invoiceDTO.invitemdetaildtolist.forEach(item => {
              Object.keys(item).forEach(key => {
                  if (!item[key]) {
                      item[key] = '';
                  }
              })
          })
      })
    }

  }

  removeBeneCertActionButton(values) {
    console.log(this);
    for (let i = 0 ; i < values?.length ; i++) {
      delete values[i].actionBtn;
    }
  }


}

export default new BeneficiaryCertService();
