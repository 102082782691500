import { changeLoadingState, setHideError, updateSubmitErrors } from 'common/redux/actions';
import store from 'common/redux/store';
import ModuleService from 'common/services/module.service';
import { getCurrentTab, isAC } from "common/utils/AppUtil";
import { TABS } from 'common/utils/Constants';
import {
    billOfExchangeFields, EDSFields, masterDocBankConsigneeMandatoryFields,
    masterDocBankConsigneeMandatoryFieldsForEDS, masterDocCommonMandatoryFields,
    masterDocExporterImporterMandatoryFields, masterDocExporterImporterMandatoryFieldsForEDS,
    masterDocOtherInformationMandatoryFields, masterDocOtherInformationMandatoryFieldsForEDS
} from 'common/utils/DocPrepConstant';
import { validateAccountNumber, basicValidation } from 'common/utils/FieldValidation';
import { isBlank } from 'common/utils/StringUtils';
import { SubmissionError } from 'redux-form';
import UserService from 'common/services/user.service';

const isAccessControl = isAC();

const _ = require('lodash');

const errAmount = "This amount field cannot be equal to zero in master document";

function checkRequiredFields(values, error, fields) {
    if(!values){
        return;
    }
    fields.forEach(field => {

        if (isBlank(values[field])) {
            error[field] = 'This field is required';
        }
        if((field==='dcdate' || field==='boedate') && values[field]==='Invalid date'){
            error[field] = 'This field is required';
        }
    })
}

function isDC(values){
    const eiType = values.eidto?.eitype || values.eidto?.EIType;
    return (eiType === 'ND' || eiType === 'NA' || eiType === 'AD'
                || eiType === 'PA' || eiType === 'NU');
}

function checkAmount(values, error) {
    console.log(values);
    const submitType = store.getState().masterDocAction?.submitType || values.mdoctype;
    if (getCurrentTab() === TABS.EDS_DASHBOARD && values.invGrandTotal < 0.000001) {
        error.invGrandTotal = errAmount;
        masterDocOtherInformationMandatoryFieldsForEDS.push('invGrandTotal');
    } else if(getCurrentTab() !== 'GenericBillOfExchange' && values.invgrandtotal < 0.000001 && submitType === 'MD') {
         error.invgrandtotal = errAmount;
         masterDocOtherInformationMandatoryFields.push('invgrandtotal');
    }else if(getCurrentTab() === 'GenericBillOfExchange' && values.billofexchangedto?.billamt < 0.000001 && submitType === 'MD'){
        error.billofexchangedto = {};
        error.billamt = errAmount;
        masterDocOtherInformationMandatoryFields.push('invgrandtotal');
        error.billofexchangedto.billamt = errAmount;
    } else if (values.EDS) {
            store.dispatch(updateSubmitErrors('invGrandTotal'));
        } else {
            store.dispatch(updateSubmitErrors('invgrandtotal'));
        }
}

function checkAccountNumber(values, field, error){
    const err = validateAccountNumber(values[field]);
    if(err){
        error[field] = err;
    }
}

function checkAccountNumbers(values, error){
    checkAccountNumber(values, "expacctno", error);
    checkAccountNumber(values, "expdbacctno", error);
    checkAccountNumber(values, "expAcctNo", error);
    checkAccountNumber(values, "expDBAcctNo", error);
}

function checkEmail(values, field, error){
    const err = basicValidation('multiEmail', values[field]);
    if(err.length>0){
        error[field] = err;
    }
}

function checkEmails(values, error){
    checkEmail(values, "expemail", error);
    checkEmail(values, "impemail", error);
    checkEmail(values, "expEmail", error);
    checkEmail(values, "impEmail", error);
}

function checkVessel(values) {
    const vesselName = isAccessControl ? "vesselName" : "vesselname";
    const transportationMode = isAccessControl ? values.transportationMode : values.transportationmode;
    const fields = isAccessControl ? masterDocOtherInformationMandatoryFieldsForEDS : masterDocOtherInformationMandatoryFields;
    if (transportationMode === 'By Sea' || transportationMode === 'By Air' || transportationMode === 'By Railway') {
        fields.push(vesselName);
        if (transportationMode === 'By Sea') {
            fields.push("voyage");
        } else {
            _.remove(fields, (n) => {
                return n === "voyage";
            });
        }
    } else {
        _.remove(fields, (n) => {
            return n === vesselName || n === "voyage";
        });
    }
}

function removeOtherRequiredFields(values, fields){
    const transportationMode = isAccessControl ? values.transportationMode : values.transportationmode;
    const mandatoryFields = [
        'portloading', 'portdischarge', 'countryoforigin', 'finaldestination'
    ];
    const mandatoryFieldsEDS = [
        'portLoading', 'portDischarge', 'countryOfOrigin', 'finalDestination'
    ];

    const toRemoveField = isAccessControl ? mandatoryFieldsEDS : mandatoryFields;

    if(transportationMode==='Not Applicable'){
        _.remove(fields, (n) => {
            return toRemoveField.indexOf(n) > -1;
        });
    }else{
        toRemoveField.forEach(item=>{
            if(fields.indexOf(item)===-1){
                fields.push(item);
            }
        });
    }
}

function checkMasterDocCommon(values, error) {
    checkEmails(values, error);
    checkAccountNumbers(values, error);
    if(ModuleService.hasLFEDSModule()){
        masterDocBankConsigneeMandatoryFields.push("bankname");
        masterDocExporterImporterMandatoryFields.push("expref");
        masterDocExporterImporterMandatoryFields.push("expacctno");
        masterDocOtherInformationMandatoryFields.push("tenorid");
    } else if (values.EDS) {
        masterDocBankConsigneeMandatoryFieldsForEDS.push("bankName");
        masterDocOtherInformationMandatoryFieldsForEDS.push("tenorID");
    } else{
        _.remove(masterDocBankConsigneeMandatoryFields, function(n) {
            return n === "bankname";
        });
        _.remove(masterDocExporterImporterMandatoryFields, function(n) {
            return n === "expref" || n === "expacctno";
        });
        _.remove(masterDocOtherInformationMandatoryFields, function(n) {
            return n === "tenorid";
        });
    }

    if(ModuleService.isEDSWorkflow()){
        masterDocOtherInformationMandatoryFields.push("commoditydesc");

        checkAmount(values, error);
    }else{
        _.remove(masterDocOtherInformationMandatoryFields, function(n) {
            return n === "commoditydesc";
        });

        checkAmount(values, error);
    }

    if(isDC(values)){
        masterDocOtherInformationMandatoryFields.push("dcno");
        masterDocOtherInformationMandatoryFields.push("dcdate");
        masterDocOtherInformationMandatoryFieldsForEDS.push("dcno");
        masterDocOtherInformationMandatoryFieldsForEDS.push("dcdate");
    }else{
        _.remove(masterDocOtherInformationMandatoryFields, function(n) {
            return n === "dcno" || n === "dcdate";
        });
        _.remove(masterDocOtherInformationMandatoryFieldsForEDS, function(n) {
            return n === "dcno" || n === "dcdate";
        });
    }


    checkVessel(values);


    const currentTab = getCurrentTab();
    if (currentTab !== TABS.BOE) {
        masterDocOtherInformationMandatoryFields.push("currencycode");
    }else{
        _.remove(masterDocOtherInformationMandatoryFields, function(n) {
            return n === "currencycode";
        });
    }

    if (currentTab !== TABS.EDS_DASHBOARD) {
        checkRequiredFields(values, error, masterDocCommonMandatoryFields);
        checkRequiredFields(values, error, masterDocExporterImporterMandatoryFields);
        checkRequiredFields(values, error, masterDocBankConsigneeMandatoryFields);
        removeOtherRequiredFields(values, masterDocOtherInformationMandatoryFields);
        checkRequiredFields(values, error, masterDocOtherInformationMandatoryFields);
    } else {
        checkRequiredFields(values, error, masterDocExporterImporterMandatoryFieldsForEDS);
        checkRequiredFields(values, error, masterDocBankConsigneeMandatoryFieldsForEDS);
        removeOtherRequiredFields(values, masterDocOtherInformationMandatoryFieldsForEDS);
        checkRequiredFields(values, error, masterDocOtherInformationMandatoryFieldsForEDS);
    }
}


function checkBillOfExchange(values, error) {
    if(!error.billofexchangedto){
        error.billofexchangedto = {};
    }
    checkRequiredFields(values, error.billofexchangedto, ['boecurrencycode']);
    checkRequiredFields(values, error.billofexchangedto, billOfExchangeFields);
    if (Object.keys(error.billofexchangedto).length <= 0) {
        delete error.billofexchangedto;
    }
}

function checkExportInstructions(values, error) {
    error.exportinstructions = {};
    checkRequiredFields(values, error.exportinstructions, isAC() ? ["EIType"] : ["eitype"]);
    if (Object.keys(error.exportinstructions).length <= 0) {
        delete error.exportinstructions;
    }
}

function checkPackingList(state, error) {
    const packingList = state.masterDocAction.packingList.values;

    if (!packingList || packingList.filter(packingItem => !packingItem.isDelete).length <= 0) {
        error.packingList = 'Please create at least 1 PackingList';
    }
}

function checkInvoice(values, error) {
    if (!values.invoicedetaildto || values.invoicedetaildto.filter(inv => !inv.isDelete).length <= 0) {
        error.invoicedetaildto = 'Please create at least 1 invoice';
    }
    else if (values.invoicedetaildto) {
        let needEnterAmount = true;
        values.invoicedetaildto.forEach(invoice => {
            if (parseFloat(invoice.invtotal) >= 1) {
                needEnterAmount = false;
            }
        });
        if (needEnterAmount) {
            error.invoicedetaildto = 'Please ensure amount is greater or equal 1.00.';
        }
    }
}

function checkBeneficiaryCert(state, error) {
    const beneficiaryCert = state.masterDocAction.beneficiaryCert.values;
    if (!beneficiaryCert || beneficiaryCert.filter(beneficiaryCertItem => !beneficiaryCertItem.isDelete).length <= 0) {
        error.beneficiaryCert = 'Please create at least 1 Beneficiary Certificate';
    }
}


function submit(values) {
    store.dispatch(setHideError(null));
    const currentTab = getCurrentTab();
    const error = {};
    const state = store.getState();

    if (!ModuleService.isEDSWorkflow() || UserService.getCurrentUser()?.edswfrequirecheck) {
        checkMasterDocCommon(values, error);
    }

    if(isDC(values)){
        checkRequiredFields(values, error, EDSFields);
    }

    if (currentTab === 'GenericInvoice') {
        checkInvoice(values, error);
    } else if (currentTab === 'GenericBillOfExchange') {
        checkBillOfExchange(values.billofexchangedto, error);
    } else if (currentTab === 'GenericExportInstruction' || currentTab === TABS.EDS_DASHBOARD) {
        checkExportInstructions(values.eidto, error);
    } else if (currentTab === 'GenericPackingList') {
        checkPackingList(state, error);
    } else if (currentTab === 'GenericBeneCert') {
        checkBeneficiaryCert(state, error);
    }

    store.dispatch(changeLoadingState(false));
    if (Object.entries(error).length > 0) {
        console.log('error!!', error);
        throw new SubmissionError(error)
    }

    return true;

}


export default submit


