import CDLIcon from "@cdl/icon";
import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import React, { useEffect } from 'react';

export default function MultiChoiceMessage(props) {

  useEffect(() => {
    if (props.isOpen) {
      document.documentElement.classList.add('over-flow-hidden');
    }
    return () => {
      if (props.isOpen) {
        document.documentElement.classList.remove('over-flow-hidden');
      }
    };
  });

        return (
          <Modal
            ariaLabelledby="modal-heading"
            width={props.width || 400 - (props.hideExtraButton ? 50 : 0)}
            height={props.height || 200}
            isOpen={props.isOpen}
          >
            <ModalHeader>
              <ModalTitle>
                {props.warning &&
                <span style={{paddingRight:'5px'}}>
                  <CDLIcon name="circle-error-solid" color="WARNING" size={29} />
                </span>}
                {props.title}
              </ModalTitle>
              <ModalBtnList><ModalBtn name="delete" title="close" onClick={props.close} /></ModalBtnList>
            </ModalHeader>
            <ModalContent>
              {props.content}
            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>
                <ModalActionExtraButton>
                  <Button onClick={props.close}>Cancel</Button>
                </ModalActionExtraButton>
                <ModalActionButton>
                  <Button style={{marginRight:'10px'}} onClick={props.no}>No</Button>
                  <Button themeColor="primary" onClick={props.confirm}>Yes</Button>
                </ModalActionButton>
              </ModalAction>
            </ModalFooter>
          </Modal>
        )
}
