import TextArea from 'common/components/TextArea/TextArea';
import React from 'react';

const renderTextArea = ({ input, label, maxLength, name, id, disabled }) => (
  <TextArea
    id={id}
    name={name}
    label={label}
    maxLength={maxLength}
    disabled={disabled}
    {...input}
  />
  )

export default renderTextArea
