import Paragraph from '@cdl/layout';
import Grid from '@material-ui/core/Grid';
import ContactBox from "common/components/ContactBox/ContactBox";
import Divider from "common/components/Divider/Divider";
import Heading from "common/components/Heading/Heading";
import PageHeader from "common/components/PageHeader/PageHeader";
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import React from 'react';
import { Link } from 'react-router-dom';
import ContactService from "common/services/contact.service";

export default class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      success: props.location?.state?.isForceUpdated,
      contacts: [],
    }
  }

  componentDidMount(){
    ContactService.getContactList().then(response => {
      if(response && response.list){
        this.setState({
          contacts: response.list
        });
      }
    })
  }

  renderContacts() {
    const { contacts } = this.state;

    return (
      <Grid container spacing={0} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        {contacts.map((item) => (
          <Grid item xs={3}>
            <ContactBox
              title={item.country}
              emails={item.email}
              phones={item.phone}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  render() {
    return (
      <div>

        <PageHeader title="Welcome to HSBC DocumentExpress main page" />

        <div className="mainContent">

          <div className="empty" />

          {this.state.success &&
            <Grid item xs={12} style={{ paddingTop: '20px' }}>
              <SuccessMessage message="Password has been changed successfully." handleCloseButtonClick={() => this.setState({ success: false })} />
            </Grid>}

          <Heading level={2} brandbar><span className="medium">Important information</span></Heading>

          <Paragraph>If you have any specific queries or experience technical difficulties please contact your local HSBC Global Trade Solutions office:</Paragraph>

          {this.renderContacts()}

          <Divider />

          <Heading level={2} brandbar><span className="medium">Important security advice</span></Heading>

          <div>Please familiarise yourself with these important details:</div>
          <ul className="importantNotesUL">
            <li><strong>Never</strong> write down your password or <strong>reveal</strong> it to anyone, not <strong>even</strong> to colleagues, family or friends.</li>
            <li>No one at HSBC will <strong>ever</strong> ask you for your password.</li>
            <li>Choose a unique user ID and password, using a combination of letters and numbers for passwords (letters are not case sensitive).</li>
            <li>Do not use user IDs or passwords which you also use for other online services or Internet sites.</li>
            <li>Do not access DocumentExpress from computer terminals which are shared with other users.</li>
            <li><strong>Change</strong> your password on a regular basis. To change your password, click <Link to="/ChangePWD">here</Link>.</li>
            <li>Always <strong>logout properly</strong> using the &quot;Logout&quot; button and <strong>close the browser window</strong> when you finish using DocumentExpress.
              This will ensure that the session is terminated securely.
            </li>
            <li>If you suspect any unusual account activity or discrepancies with your last logon time, date and status,
              inform your DocumentExpress administrator <strong>immediately</strong>.
            </li>
            <li>DocumentExpress users are advised to install appropriate virus detection software and firewall.
              Download and apply security updates and patches to the PC/browser when they are made available.
            </li>
          </ul>

        </div>
      </div>
    )
  }
}
