import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import Button from "common/components/Button/Button";
import DropdownHeader from "common/components/Dropdown/DropdownHeader";
import EditableHeader from "common/components/EditableHeader/EditableHeader";
import IconButton from "common/components/IconButton/IconButton";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import NumberField from "common/components/NumberField/NumberField";
import Table from 'common/components/Table/Table';
import { OVERFLOW_CLASS, packingListLabelHeadersObj } from "common/utils/Constants";
import { CASE_NO, PROD_CODE, PROD_DESC, QUANTITY } from "common/utils/OptionUtil";
import Confirm from "common/views/Popup/Confirm";
import PackingListItemDetail from "common/views/Popup/PackingList/PackingListItemDetail";
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const TotalTag = styled.div`
    & span{
      width: 104px;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
    }
`;


const buttonArray = [{id: 'openDetail', name: 'Edit', showModal: 'showItem'},
                    {id: 'openDelete', name: 'Delete', modal: Confirm, action: 'deleteItem',
                     modalProps :{content: 'Do you with to delete the packing list item "@title"?'} }
                   ];


class PackingListItems extends React.Component {
    constructor(props) {
      super(props);

      let labelData = null;
      if (props.labelHeaders) {
        labelData = { ...props.labelHeaders};
      } else if (props.headers) {

      labelData = {
          casenoheading: props.headers.casenoheading || packingListLabelHeadersObj.casenoheading,
          partnoheading: props.headers.partnoheading || packingListLabelHeadersObj.partnoheading,
          proddescheading: props.headers.proddescheading || packingListLabelHeadersObj.proddescheading,
          qtyheading: props.headers.qtyheading || packingListLabelHeadersObj.qtyheading,
          nopkgsheading: props.headers.nopkgsheading || packingListLabelHeadersObj.nopkgsheading,
          gmassheading: props.headers.gmassheading || packingListLabelHeadersObj.gmassheading,
          netmassheading: props.headers.netmassheading || packingListLabelHeadersObj.netmassheading,
          cubicmassheading: props.headers.cubicmassheading || packingListLabelHeadersObj.cubicmassheading,
        }
      } else {
        labelData = { ...packingListLabelHeadersObj};
      }

      let tableData = [];
      if(props.data){
        tableData = props.data.map(a => {
          return {...a}
        });
      }

      this.state = {
        tableData,
        selectedIndex: null,
        openItem: false,
        isNew: false,
        labelHeaders: labelData,
      };
    }

    addPackingList = () => {
      this.setState({
        openItem: true,
        isNew: true
      });
    }

    close = () => {
      this.setState({
        openItem: false,
        isNew: false
      });
    }

    showItem = (rowIndex) => {
      this.setState({
        openItem: true,
        selectedIndex: rowIndex
      })
      document.documentElement.classList.add(OVERFLOW_CLASS);
    }

    deleteItem = (rowIndex) => {
      console.log('delete row index', rowIndex);
      this.setState(prevState => {
        const nonDeletedItems = prevState.tableData.filter(item => !item.isDelete);
        nonDeletedItems[rowIndex].isDelete = true;
        return {
        tableData: prevState.tableData
        }
      })
    }

    saveItem = (packingListItem, isEdit, rowIndex) => {
      console.log(packingListItem);
      this.setState(prevState => {
        if (isEdit) {

          const newTableData = prevState.tableData.find((data, i) => i === rowIndex);
          Object.keys(packingListItem).forEach(key => {
            newTableData[key] = packingListItem[key];
          });
          return ({
            tableData: prevState.tableData,
            selectedIndex: null
          });
        }
        return ({
          tableData: [...prevState.tableData, packingListItem],
          selectedIndex: null
        });
      });


      this.close();
    }

    savePackingLists = () => {
      const obj = {
        plqtytotal: 0,
        plpkgstotal: 0,
        plgmasstotal: 0,
        plnetmasstotal: 0,
        plcubictotal: 0,
        casenoheading: this.state.labelHeaders.casenoheading || packingListLabelHeadersObj.casenoheading,
        partnoheading:  this.state.labelHeaders.partnoheading || packingListLabelHeadersObj.partnoheading,
        proddescheading:  this.state.labelHeaders.proddescheading || packingListLabelHeadersObj.proddescheading,
        qtyheading:  this.state.labelHeaders.qtyheading || packingListLabelHeadersObj.qtyheading,
        nopkgsheading:  this.state.labelHeaders.nopkgsheading || packingListLabelHeadersObj.nopkgsheading,
        gmassheading:  this.state.labelHeaders.gmassheading || packingListLabelHeadersObj.gmassheading,
        netmassheading:  this.state.labelHeaders.netmassheading || packingListLabelHeadersObj.netmassheading,
        cubicmassheading:  this.state.labelHeaders.cubicmassheading || packingListLabelHeadersObj.cubicmassheading
      };

      this.state.tableData.filter(d => !d.isDelete).forEach(t => {
        if(t.PLDetQty){
          obj.plqtytotal += Number(t.PLDetQty);
        }
        if(t.PLDetNoPKGS){
          obj.plpkgstotal += Number(t.PLDetNoPKGS);
        }
        if(t.PLDetGrossMass){
          obj.plgmasstotal += Number(t.PLDetGrossMass);
        }
        if(t.PLDetNetMass){
          obj.plnetmasstotal += Number(t.PLDetNetMass);
        }
        if(t.PLDetCubic){
          obj.plcubictotal += Number(t.PLDetCubic);
        }
      });

      console.log(this.state.tableData);
      this.props.saveForParentAsWell(this.state.tableData, this.state.labelHeaders, obj);
      document.documentElement.classList.remove(OVERFLOW_CLASS);
    }

    changeDropHeader = (header, value) => {
      console.log(header, value)
      this.setState(prevState => {
        prevState.labelHeaders[header] = value;
        return {
          labelHeaders: prevState.labelHeaders
        }
      });
    }

    constructDropdownHeader = (headerField, option, accessor) => {
      return {
        Header: <DropdownHeader
          editable
          valueChange={(value) => this.changeDropHeader(headerField, value)}
          onItemClick={(value) => this.changeDropHeader(headerField, value)}
          userInput={this.state.labelHeaders[headerField]}
          options={option}
          width="50px"
        />,
        accessor,
        sortable: false,
        Cell: row => <span title={row.original[accessor]}>{row.original[accessor]}</span>
      }
    }

    constructEditableHeader = (headerField, accessor, decimal) => {
      return {
        Header: <EditableHeader
          value={this.state.labelHeaders[headerField]}
          width="50px"
          displayTextWidth="35px"
          onChange={(value) => this.changeDropHeader(headerField, value)}
        />,
        accessor,
        sortable: false,
        Cell: ( cell ) => (<NumberField
          displayType="text"
          decimalScale={this.props.masterDoc[decimal]}
          value={cell.original[accessor]}
        />)
      }
    }


  render(){
    console.log(this.state.tableData);
    let totalQuantity = 0;
    let totalPkg = 0;
    let totalGross = 0;
    let totalNet = 0;
    let totalCubic = 0;
    const displayData = this.state.tableData?.filter(d => !d.isDelete).map((t, i) => {
      if(t.PLDetQty){
        totalQuantity += Number(t.PLDetQty);
      }
      if(t.PLDetNoPKGS){
        totalPkg += Number(t.PLDetNoPKGS);
      }
      if(t.PLDetGrossMass){
        totalGross += Number(t.PLDetGrossMass);
      }
      if(t.PLDetNetMass){
        totalNet += Number(t.PLDetNetMass);
      }
      if(t.PLDetCubic){
        totalCubic += Number(t.PLDetCubic);
      }

      let deleteTitle = t.PLCaseNo;
      if(t.PLDetProdCode){
        deleteTitle += ` - ${  t.PLDetProdCode}`;
      }
      return Object.assign(t, {actionBtn: <ActionButtons
        rowIndex={i}
        buttonArray={buttonArray}
        deleteItem={this.deleteItem}
        showItem={this.showItem}
        justify='space-between'
        titleField={deleteTitle}
      />});
    });

    return (
      <div>
        <Modal
          ariaLabelledby="modal-heading"
          classname="myClass"
          isOpen={this.props.isOpen && !this.state.openItem}
          width={946}
        >
          <ModalHeader hasTopLine>
            <ModalTitle>Packing list item(s)</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.props.close} />
            </ModalBtnList>

          </ModalHeader>
          <ModalContent>
            <Grid container spacing={0} style={{paddingRight: '10px'}}>
              <Grid item xs={10} />
              <Grid item xs={2}>
                <div style={{float: 'right'}}>
                  <IconButton onClick={this.addPackingList} name="add">Add new item</IconButton>
                </div>
              </Grid>
              <Grid item xs={12} style={{padding: '20px 0'}}>
                <Table
                  showPagination={false}
                  defaultPageSizeOption={{'value':0}}
                  data={displayData}
                  columns={[
                                this.constructDropdownHeader('casenoheading', CASE_NO, 'PLCaseNo'),
                                this.constructDropdownHeader('partnoheading', PROD_CODE, 'PLDetProdCode'),
                                this.constructDropdownHeader('proddescheading', PROD_DESC, 'PLDetProdDesc'),
                                {
                                    Header: <DropdownHeader
                                      editable
                                      valueChange={(value) => this.changeDropHeader('qtyheading', value)}
                                      onItemClick={(value) => this.changeDropHeader('qtyheading', value)}
                                      userInput={this.state.labelHeaders?.qtyheading}
                                      options={QUANTITY}
                                      width="50px"
                                    />,
                                    accessor: 'PLDetQty',
                                    sortable: false,
                                    Cell: ( cell ) => (<NumberField
                                      displayType="text"
                                      decimalScale={this.props.masterDoc.qtydeccat}
                                      value={cell.original.PLDetQty}
                                    />)
                                },
                                this.constructEditableHeader('nopkgsheading', 'PLDetNoPKGS', 'noofpkgdeccat'),
                                this.constructEditableHeader('gmassheading', 'PLDetGrossMass', 'grossmassdeccat'),
                                this.constructEditableHeader('netmassheading', 'PLDetNetMass', 'netmassdeccat'),
                                this.constructEditableHeader('cubicmassheading', 'PLDetCubic', 'cubicmassdeccat'),
                                {
                                    Header: 'Action',
                                    accessor: 'actionBtn',
                                    sortable: false,
                                    width: 105
                                }
                                ]}
                />
              </Grid>
            </Grid>

            <Grid container spacing={0} style={{paddingRight: '10px', paddingBottom: '20px'}} justify="flex-end">

              <Grid item xs={12} style={{textAlign: 'right'}}>
                <TotalTag>
                  <span style={{paddingRight: '10px'}}>
                    Total
                  </span>
                  <NumberField displayType="text" decimalScale={this.props.masterDoc.qtydeccat} value={totalQuantity} />

                  <NumberField displayType="text" decimalScale={this.props.masterDoc.noofpkgdeccat} value={totalPkg} />

                  <NumberField displayType="text" decimalScale={this.props.masterDoc.grossmassdeccat} value={totalGross} />

                  <NumberField displayType="text" decimalScale={this.props.masterDoc.netmassdeccat} value={totalNet} />

                  <NumberField displayType="text" decimalScale={this.props.masterDoc.cubicmassdeccat} value={totalCubic} />
                  <span style={{width: '105px', display: 'inline-block'}} />
                </TotalTag>
              </Grid>


            </Grid>
          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.props.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button themeColor="primary" onClick={this.savePackingLists}>Save</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>

        {this.state.openItem &&
        <PackingListItemDetail
          isOpen={this.state.openItem}
          close={this.close}
          data={!this.state.isNew ? displayData[this.state.selectedIndex] : null}
          selectedIndex={this.state.selectedIndex}
          labelHeaders={this.state.labelHeaders}
          save={this.saveItem}
        />}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  masterDoc: state.form?.masterDoc?.values,
})

export default connect(mapStateToProps)(PackingListItems)
