import React from 'react';
import UserMessage from './UserMessage';

export default class InfoMessage extends React.Component {

    constructor(props) {
      super(props);

      this.state = {
        showMsg: true
      }
    }

    onClose = () => {
        this.setState({showMsg: false});
    }

    render() {

        const {children, ...others} = this.props;

        return (
          <>
            {this.state.showMsg &&
              <UserMessage
                border
                showCloseButton
                closeText="Close"
                {...others}
                handleCloseButtonClick={this.onClose}
              >{children}
              </UserMessage>}
          </>
        );
    }
  }
