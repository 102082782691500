
import React from 'react';
import styled from "styled-components";

const Item = styled.div`
    padding: 13px 20px;
    cursor: pointer;
    background-color: #fff;    
    border-bottom: 1px solid #d4d4d4;
    font-size: 14px;
    color: black;

    &:hover{
        background-color: #f1f1f1;
    }
`;

export default class ButtonItem extends React.PureComponent {

    render() {
      return (
        <Item onClick={this.props.onClick}>
          {this.props.label}
        </Item>
      )
    }
  }
