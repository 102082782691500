import { colors, rem } from '@cdl/foundations';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const activeClassName = 'active';

const ContainerWrapper = styled.div`
    background: ${colors.DARK_SLATE};
    width: 1260px;
    position: relative;
    left: -30px;
`;

const Container = styled.nav`
  max-width: ${rem('1600px')};
  margin: 0 auto;

  ul {
    margin: 0 ${rem('10px')};
    padding: 0;
    display: flex;
    margin-right: 0;
  }

  li {
    margin-right: ${rem('2px')};
    // flex: 1;
    // white-space: pre;
    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: block;
    padding: ${rem('13px')} ${rem('20px')};
    transition: padding 400ms ease, box-shadow 400ms ease;
    will-change: padding, box-shadow;
    line-height: 1;
    font-size: ${rem('14px')};
    background: ${colors.LIGHT_SLATE};
    color: ${colors.WHITE};
    font-weight: 500;
    text-decoration: none;
    font-family:HSBC-Universe-Medium;



    &.active,
    &.active:hover {
      background: ${colors.WHITE};
      color: ${colors.BLACK};
      box-shadow: inset 0 3px 0 0 ${colors.RED};
    }

    &:hover {
      background: ${colors.LIGHT_SLATE_TINT};
    }

  }
`;

const StyledLink = styled(NavLink).attrs({
  activeClassName
})`
  &.${activeClassName} {
    color: #fff;
  }

`;

const StyledTab = styled(NavLink).attrs({
  activeClassName
})`
  &.${activeClassName} {
    color: #fff;
  }

  pointer-events: none;
`;

export default function Tab({tabs, click}) {


    const linkClick = (event, url) => {
      if (click) {
        click(url);
      }
    }


    return (
      <ContainerWrapper>
        <div>
          <Container>
            {tabs &&
              <ul>
                { tabs.map(item => (
                  <li style={{cursor: 'pointer'}} key={item.url} onClick={(event) => linkClick(event, item.url)}>
                    {click ? <StyledTab exact to={item.url} activeClassName="active">{item.title}</StyledTab> :
                    <StyledLink exact to={item.url} activeClassName="active">{item.title}</StyledLink> }
                  </li>
                    ))}
              </ul>}
          </Container>
        </div>
      </ContainerWrapper>
    );

}
