import CDLRadioGroup from '@cdl/radio-group';
import styled from "styled-components";
import React from 'react';

const horizontalStyle = `
  & fieldset > div > div{
    display: inline-block;   
  }

  & fieldset > div > div:first-of-type {
    margin-right: 20px;
    vertical-align: top;
  }

`;

const Container = styled.div`
    & fieldset{
      margin-bottom: 15px;
    }    
    ${props => props.horizontal ? horizontalStyle : ''}
`;


export default function RadioGroupStyled(props) {
    return (
      <Container horizontal={props?.horizontal}><CDLRadioGroup {...props} /></Container>
    );
}

