import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import CDLRadioGroup, { Radio } from '@cdl/radio-group';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import DatePicker from "common/components/DatePicker/DatePicker";
import IconButton from "common/components/IconButton/IconButton";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import Table from "common/components/Table/Table";
import TableCheckbox from "common/components/TableCheckbox/TableCheckbox";
import docPrepService from 'common/services/doc-prep.service';
import MasterDocService from "common/services/masterDoc.service";
import Message from 'common/views/Popup/Message';
import React from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";
import { disableInvoiceAmount, setBeneficiaryCert, setMasterDoc, setMasterDocState, setPackingList, showMasterDocMessage } from '../../redux/actions';

const RadioNoLabelWrapper = styled.div`
    >fieldset {
        margin-bottom: 20px;
    }
`;

const RadioWrapper = styled.div`
    >fieldset {
        margin-bottom: auto;
    }
`;


const initState = {
  MDocType: null,
  isDisabled: true,
  isOpenDisabled: true,
  MDOCID: "",
  criteria: null,
  preChecked: null,
  docList: [],
  loading: false,
  selected: {},
}


class OpenDocument extends React.Component {


    constructor(props) {
      super(props);
      const from = new Date();
      from.setDate(from.getDate() -30);
      initState.to = new Date();
      initState.from = from;
      this.state = Object.assign(initState, this.props.masterDoc)
    }

    handleClose = () =>{
      this.setState(initState);
      this.props.close();
    }

    handleDocList = (result) => {
      console.log(result);
      if(result && result.list){
        const doclist = []
        result.list.forEach(element => {
          if(this.state.MDocType==='MD'){
           doclist.push({
            id : element[0],
            desc : element[1],
            ver : element[2],
            importer : element[3],
            status : element[4],
            creater : element[5],
            lastModifyUser : element[6],
            lastModifyOn : element[7],
            lastUpdateTime : element[8],
            insQuote : element[9],
           });
          }else{
            doclist.push({
              id : element[0],
              desc : element[1],
              creater : element[2],
              lastModifyOn : element[3],
             });
          }
        });
        this.setState(
          {
            docList: doclist,
            loading: false
          });
      }else{
        this.setState({loading: false});
      }
    }

    getDocList = () => {
      this.setState(
        {
          loading: true
        },
        ()=>{
          if(this.state.criteria === "all"){
            MasterDocService.getMasterDocList(this.state.MDocType)
            .then(response => {
              if (response.statusCode === 200  && response.mdocType === this.state.MDocType) {
                const {data} = response;
                this.handleDocList(data);
              }
            });
          }else{
            MasterDocService.getMasterDocList(this.state.MDocType, this.state.from, this.state.to)
            .then(response => {
              if (response && response.statusCode === 200 && response.mdocType === this.state.MDocType) {
                const {data} = response;
                this.handleDocList(data);
              }
            });
          }
        }
      );


    }

    handleDocTypeChange = (event) =>{
      const val = event.target.value;
      let {criteria} = this.state;
      if(this.state.criteria === null){
        criteria = "lastModify";
      }
      this.setState(
        {
          MDocType: val,
          criteria,
          loading: true,
          selected: {},
          isOpenDisabled: true,
        },
        this.getDocList
      );


    }

    handleCriteriaChange = (event) =>{
      const val = event.target.value;
      this.setState({
        selected: {},
        isOpenDisabled: true,
      });
      if(val==="lastModify"){
        this.setState({criteria: val});
      }else{
        this.setState(
          {
            criteria: val,
          },
          ()=>{
            if(this.state.MDocType!==null){
              this.getDocList();
            }
          });
      }


    }

    search = () =>{
      console.log(this.state.MDocType);
      if(this.state.MDocType){
        this.getDocList();
      }else{
        Message.notice("Please specify the type of Master Document to search.");
      }

    }


    onOpen = () =>{
      console.log(`${this.state.MDOCID  } ${  this.state.MDocType}`);

      if (this.state.MDOCID) {
        const metaData = {mdocid: this.state.MDOCID, mdoctype: this.state.MDocType};
        docPrepService.loadSectionByTab(metaData, this.props).then(response => {
          console.log(response);
          if (response) {
            this.props.showMasterDocMessage({isShowMessage: false, showMasterDocMessage: ''});
            this.handleClose();
          }
        })
      }
    }


  onDateSelected = (dateObj) => {
    this.setState(dateObj)
  }


  toggleRow = (id) => {
    const newSelected = {};
    newSelected[id] = !this.state.selected[id];
    this.setState({
      selected: newSelected,
      MDOCID: id,
      isOpenDisabled: !newSelected[id],
    });
  }

  getColumns = () => {
    const masterDocColumn = [
      {
          Header: '',
          width: 40,
          sortable: false,
          Cell: ({ original }) => {
            return (
              <TableCheckbox
                checked={this.state.selected[original.id] === true}
                onChange={() => this.toggleRow(original.id)}
              />
            );
          },
      },
      {
          Header: 'Doc. ID',
          accessor: 'id',
          className: 'docID',
          width: 130
      },
      {
          Header: 'Desc',
          accessor: 'desc',
          Cell: row => <span title={row.original.desc}>{row.original.desc}</span>
      },
      {
          Header: 'Ver',
          accessor: 'ver',
          width: 50
      },
      {
          Header: 'Importer',
          accessor: 'importer',
          width: 140,
          Cell: row => <span title={row.original.importer}>{row.original.importer}</span>
      },
      {
          Header: 'Status',
          accessor: 'status',
          width: 100,
          Cell: row => <span title={row.original.status}>{row.original.status}</span>
      },
      {
          Header: 'Creator',
          accessor: 'creater',
          width: 100,
          Cell: row => <span title={row.original.creater}>{row.original.creater}</span>
      },
      {
          Header: 'Modified by',
          accessor: 'lastModifyUser',
          width: 100,
          Cell: row => <span title={row.original.lastModifyUser}>{row.original.lastModifyUser}</span>
      },
      {
          Header: 'Last modified on',
          accessor: 'lastModifyOn',
          width: 140
      }
    ]

    const templateColumn = [
      {
          Header: '',
          width: 40,
          sortable: false,
          Cell: ({ original }) => {
            return (
              <TableCheckbox
                checked={this.state.selected[original.id] === true}
                onChange={() => this.toggleRow(original.id)}
                id={original.id}
              />
            );
          },
      },
      {
          Header: 'Template ID',
          accessor: 'id',
          className: 'docID',
          width: 180
      },
      {
          Header: 'Template description',
          accessor: 'desc'
      },
      {
          Header: 'Creator',
          accessor: 'creater',
          width: 200
      },
      {
          Header: 'Last modified on',
          accessor: 'lastModifyOn',
          width: 200
      }
    ]

    return this.state.MDocType === 'TM' ? templateColumn : masterDocColumn;
  }

  render(){
    let disableSearch = true;
    if(this.state.MDocType!==null && this.state.criteria!==null){
      disableSearch = false;
    }

    let disableDatePicker = true;
    if(this.state.MDocType!==null && this.state.criteria==='lastModify' ){
      disableDatePicker = false;
    }

    const tableColumn = this.getColumns();

    return (
      <div>
        <Modal
          ariaLabelledby="modal-heading"
          classname="myClass"
          isOpen={this.props.isOpen}
          width={946}
        >
          <ModalHeader hasTopLine>
            <ModalTitle>Open</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.handleClose} />
            </ModalBtnList>

          </ModalHeader>
          <ModalContent>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <RadioNoLabelWrapper>
                  <CDLRadioGroup name="MDocType">
                    <Radio
                      id="openMasterDoc"
                      label="Open master document"
                      value="MD"
                      onChange={this.handleDocTypeChange}
                    />
                    <Radio
                      id="templateMasterDoc"
                      label="Open master document template"
                      value="TM"
                      onChange={this.handleDocTypeChange}
                    />
                  </CDLRadioGroup>
                </RadioNoLabelWrapper>
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={0} style={{marginBottom: '30px'}}>

              <Grid item xs="auto">
                Record filtering criteria
              </Grid>
              <Grid item style={{marginLeft: '35px'}}>
                <RadioWrapper>
                  <CDLRadioGroup name="filteringCriteria">
                    <Radio
                      id="filteringCriteriaAll"
                      label="All"
                      value="all"
                      onChange={this.handleCriteriaChange}
                      checked={this.state.criteria === "all"}
                    />
                    <Radio
                      id="lastModifyOn"
                      label="Last modified on"
                      value="lastModify"
                      onChange={this.handleCriteriaChange}
                      checked={this.state.criteria === "lastModify"}
                    />
                  </CDLRadioGroup>
                </RadioWrapper>
              </Grid>
              <Grid item xs={3} style={{marginRight: '10px'}}>
                <DatePicker
                  defaultDate={{from: this.state.from, to: this.state.to}}
                  onDateSelected={this.onDateSelected}
                  inputFormat="DD MMM yyyy"
                  displayFormat="DD MMM yyyy"
                  id="qtyUOM"
                  type="quickRange"
                  icon="calendar"
                  localData={{'displayLabel':''}}
                  disabled={disableDatePicker}
                />
              </Grid>
              <Grid item xs="auto">
                <IconButton disabled={disableSearch} onClick={this.search} name="search">Search</IconButton>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item xs={12} style={{paddingBottom: '20px'}}>
                <Table
                  id="openDocTable"
                  defaultPageSizeOption={{ 'value': 0 }}
                  showPagination
                  data={this.state.docList}
                  loading={this.state.loading}
                  maxRows={11}
                  columns={tableColumn}
                />
              </Grid>
            </Grid>

          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.handleClose}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button disabled={this.state.isOpenDisabled} themeColor="primary" onClick={this.onOpen}>Open</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  masterDoc: state.form.masterDoc
})

const mapDispatchToProps = dispatch => {
  return {
    setMasterDoc: (data) => dispatch(setMasterDoc(data)),
    setMasterDocState: (data) => dispatch(setMasterDocState(data)),
    setPackingList: (data) => dispatch(setPackingList(data)),
    setBeneficiaryCert: (data) => dispatch(setBeneficiaryCert(data)),
    disableInvoiceAmount: (data) => dispatch(disableInvoiceAmount(data)),
    showMasterDocMessage: (data) => dispatch(showMasterDocMessage(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenDocument)
