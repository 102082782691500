const moment = require('moment');

export const DATE_FORMAT = "DD MMM YYYY";
export const DATETIME_FORMAT = "DD MMM YYYY HH:mm:ss";

export function format(date, formatStr) {
    if (typeof date === 'object') {
        return moment(date).format(formatStr);
    }
        console.warn(`invalid date value: ${  date}`);
        return date;

}

export function formatDate(date, formatStr) {
    if(!formatStr){
        return format(date, DATE_FORMAT);
    }
        return format(date, formatStr);

}

export function formatDateTime(date, formatStr) {
    if(!formatStr){
        return format(date, DATETIME_FORMAT);
    }
        return format(date, formatStr);

}

export function stringToDate(dateStr, formatStr) {
    if (typeof dateStr === 'string') {
        return moment(dateStr, formatStr).toDate();
    }
        console.warn(`invalid date string: ${  dateStr}`);
        return dateStr;

}

export function toDate(dateStr) {
    return stringToDate(dateStr, DATE_FORMAT);
}

export function toDateTime(dateStr) {
    return stringToDate(dateStr, DATETIME_FORMAT);
}
