import Grid from '@material-ui/core/Grid';
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import IconButton from "common/components/IconButton/IconButton";
import Table from 'common/components/Table/Table';
import React, { useState, useEffect } from 'react';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import Msg from "common/views/Popup/Msg";
import AddTenor from 'common/views/Popup/AddTenor';
import tenorService from 'common/services/tenor.service';


const buttonArray = [
  { id: 'openEdit', name: 'Edit', showModal: 'openEditTenorPopup' },
  { id: 'openDelete', name: 'Delete', showModal: 'showDeleteRequestMessage' }
];

const TENOR_DESC = "Tenor description";
const modalTitle = "Tenor details";

export default function Tenor(props) {

  const [tableData, setTableData] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [openTenorPopup, setOpenTenorPopup] = useState(false);
  const [openDeletePopup, setDeletePopupOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editProps, setEditProps] = useState({});
  const [tenorSuccess, setTenorSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);

  useEffect(() => {
    const fetchTableData = () => {
      tenorService.loadTenor().then(response => {
        if (response && response.list) {
          setTableData(response.list);
        }
      }).catch(error => {
        throw error;
      });
    }
    fetchTableData();
  }, [isRefresh]);

  // use async await pair if necessary
  useEffect(() => {
    const fetchEditData = () =>  {
      const selectedItem = tableData.find((item, index) => index === selectedIndex);
      setEditProps(selectedItem);
      setOpenTenorPopup(true);
    }
    if (isEdit) {
      fetchEditData();
    }

  }, [isEdit]);

  const showSuccessMessage = (msg) => {
    setMessage(msg);
    setTenorSuccess(true);
    setIsRefresh(!isRefresh);
    setIsEdit(false);
  }

  const openEditTenorPopup = (rowIndex) => {
    setSelectedIndex(rowIndex);
    setIsEdit(true);
  }

  const showDeleteRequestMessage = (rowIndex) => {
    setSelectedIndex(rowIndex);
    setDeletePopupOpen(true);
  }

  const closeDeletePopup = () => {
    setSelectedIndex(null);
    setDeletePopupOpen(false);
  }

  const closeTenorPopup = () => {
    setIsEdit(false);
    setOpenTenorPopup(false);
  }

  const closeMessage = () => {
    setMessage("");
    setTenorSuccess(false);
  }

  const deleteTenor = () => {
    const selectedDTO = props?.raFlag ? tableData.find((item, index) => index === selectedIndex) :tableData.find((item, index) => index === selectedIndex)?.tenorID;
    setSelectedIndex(selectedIndex);
    tenorService.deleteTenor(selectedDTO, props?.raFlag).then(response => {
      if (response) {
        showSuccessMessage(`Tenor "${tableData[selectedIndex]?.tenorID}" - "${tableData[selectedIndex]?.tenorDesc}" has been deleted successfully.`);
      }
    }).then(() => {
      setDeletePopupOpen(false);
    }).catch(error => {
      throw error;
    });
  }

  const addNew = () => {
    setOpenTenorPopup(true);
    setIsEdit(false);
    setEditProps({});
  }

  const displayData = tableData.filter(d => !d.deleted).map((t, i) => {
      return Object.assign(t, {actionBtn: <ActionButtons
        rowIndex={i}
        buttonArray={buttonArray}
        openEditTenorPopup={openEditTenorPopup}
        showDeleteRequestMessage={showDeleteRequestMessage}
        justify='flex-start'
      />});
  });

  return (
    <div>
      <Msg
        title="Are you sure?"
        content={`Do you wish to delete the tenor "${tableData[selectedIndex]?.tenorID}" - "${tableData[selectedIndex]?.tenorDesc}"?`}
        width={500}
        confirm={deleteTenor}
        isOpen={openDeletePopup}
        close={closeDeletePopup}
        warning
      />
      {openTenorPopup &&
      <AddTenor
        isOpen={openTenorPopup}
        edit={isEdit}
        editProps={editProps}
        close={closeTenorPopup}
        success={showSuccessMessage}
        title={modalTitle}
        raFlag={props?.raFlag}
      />}
      <Grid container spacing={0} style={{ paddingTop: '20px' }}>
        {tenorSuccess &&
          <Grid item xs={12}>
            <SuccessMessage message={message} handleCloseButtonClick={closeMessage} />
          </Grid>}
        <Grid item xs={10} />
        <Grid item xs={2}>
          <div className="rightBtn" style={{paddingRight: '0px'}}>
            <IconButton onClick={addNew} name="add">Add new tenor</IconButton>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Table
            maxRows={12}
            showPagination={false}
            defaultPageSizeOption={{'value':0}}
            data={displayData}
            columns={[
                {
                  Header: 'Tenor',
                  accessor: 'tenorID'
                },
                {
                  Header: TENOR_DESC,
                  accessor: 'tenorDesc'
                },
                {
                  Header: 'Action',
                  accessor: 'actionBtn',
                  maxWidth: 140,
                  sortable: false
                }
            ]}
          />
        </Grid>
      </Grid>
    </div>
  );
}
