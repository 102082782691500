import { formateAmount } from 'common/utils/AmountFormatter';

export const saveExporterDetails = state => ({
  type: 'SAVE_EXPORTER_DETAILS',
  payload: state
})

export const saveAdditionalFields = state => ({
    type: 'SAVE_ADDITIONAL_FIELDS',
    payload: state
})

export const setCountryList = state => ({
  type: 'SET_COUNTRY_LIST',
  payload: state
})

export const resetMasterDocForm = state => ({
  type: 'RESET_FORM',
  payload: state
})

export const showSaveAs = state => ({
  type: 'SHOW_SAVE_AS',
  payload: state
})

export const dismissTargetUrl = state => ({
  type: 'DISMISS_TARGET_URL',
  payload: state
})

export const showMasterDocMessage = state => ({
  type: 'SHOW_MASTER_DOC_MESSAGE',
  payload: state
})

export const updateSubmitErrors = state => ({
  type: 'UPDATE_SUBMIT_ERRORS',
  payload: state
})

export const disableInvoiceAmount = state => ({
  type: 'DISABLE_INVOICE_AMOUNT',
  payload: state
})

export const setMasterDoc = state => {
  if(state?.invgrandtotal){
    state.invgrandtotal = formateAmount(state.invgrandtotal, state.amtdeccat);
  }
  if(state?.billofexchangedto?.billamt){
    state.billofexchangedto.billamt = formateAmount(state.billofexchangedto.billamt, state.amtdeccat);
  }
  if(state?.eidto){
    state.eidto.fromproceeddeduct = formateAmount(state.eidto.fromproceeddeduct, state.amtdeccat);
    state.eidto.interestrate = formateAmount(state.eidto.interestrate, state.interestratedeccat);
    state.eidto.deductamt = formateAmount(state.eidto.deductamt, state.amtdeccat);
    state.eidto.proceeds_amount = formateAmount(state.eidto.proceeds_amount, state.amtdeccat);
    state.eidto.loans_amount = formateAmount(state.eidto.loans_amount, state.amtdeccat);
  }
  return({
    type: 'SET_MASTER_DOC',
    payload: state
  })
}

export const setMasterDocState = state => ({
  type: 'SET_MASTER_DOC_STATE',
  payload: state
})

export const setMasterDocSubmitType = state => ({
  type: 'SET_MASTER_DOC_SUBMIT_TYPE',
  payload: state
})

export const setInvoiceList = state => ({
  type: 'SET_INVOICE_LIST',
  payload: state
})

export const setPackingList = state => ({
  type: 'SET_PACKING_LIST',
  payload: state
})

export const setBeneficiaryCert = state => ({
  type: 'SET_BENEFICIARY_CERT',
  payload: state
})

export const updateMasterDocByFields = state => ({
  type: 'UPDATE_MSATER_DOC_FIELD',
  payload: state
})

export const updateMasterDocByFieldArray = state => ({
  type: 'UPDATE_MSATER_DOC_FIELD_ARRAY',
  payload: state
})

export const setHideError = state => ({
  type: 'SET_HIDE_ERROR',
  payload: state
})

export const setPreviousPage = state => ({
  type: 'SET_PREVIOUS_PAGE',
  payload: state
})

export const setVesselList = state => ({
  type: 'SET_VESSEL_LIST',
  payload: state
})

export const dismissReset = state => ({
  type: 'DISMISS_RESET',
  payload: state
})

export const confirmChangeRoute = state => ({
  type: 'CONFIRM_CHANGE_ROUTE',
  payload: state
})


export const confirmTabChange = state => ({
  type: 'CONFIRM_CHANGE_TAB',
  payload: state
})

export const getTheValue = state => ({
    type: 'GET_THE_VALUE',
    value: state
});

export const changeLoadingState = state => ({
  type: 'CHANGE_LOADING_STATE',
  payload: state
});

export const updateMasterDocForm = state => ({
  type: 'UPDATE_MASTER_DOC_FORM',
  payload: state
})

export const logout = state => ({
  type: 'USER_LOGOUT',
  payload: state
});

export const forceUpdatePWD = state => ({
  type: 'FORCE_PWD_UPDATED',
  payload: state
});
