import Icon from "common/components/Icon/Icon";
import React from 'react';
import styled from "styled-components";
import UserService from 'common/services/user.service';
import {isAC} from 'common/utils/AppUtil';

const FooterTag = styled.footer`
    div.footer{
        height: 50px;
        padding: 0 24px;
    }

    div.flex-container{
        display: flex;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;
    }

    div.footerLink{
        position: relative;
        margin-bottom: 1.25rem;
        margin-right: 30px;
        display: inline-block;
    }

    & a{
        display: inline-block;
        color: #fff;
        text-decoration: none;
        line-height: 1;
        font-size:12px;
        margin: 18px 0;

        span{
            padding-right:10px;
        }
    }

    

    & ul {
        margin: 0;
        padding: 0;
    }

    & svg {
        position: relative;
        top: -2px;
    }

    .footerBackground{
        height: 50px;
        position: absolute;
        width: 100%;
        left: 0;
        max-width: 1000rem;
        background: #1d262c;

    }

    .copyRight{
        color: white;
        float: right;
        font-size:12px;
        font-weight: 200;
        margin: 18px 0;
        position: relative;
    }
`;

export default function Footer() {
    const user = UserService.getCurrentUser();
    const urlCountry = user ? user.countrycode.trim() : 'AMH';
    const urlDomain = isAC() ? 'AccessControl2':'DocExpress2';
    const port = window.location.port ? `:${ window.location.port}`: '';
    const urlPrefix = `${window.location.protocol}//${window.location.hostname}${port}/${urlDomain}/flex/html/`;
    const privacyURL = `${urlPrefix  }Privacy_Security_${  urlCountry  }.html`;

    return (
      <FooterTag>
        <div className="footer">
          <nav>
            <ul>
              <li>
                <a href={privacyURL} target="_blank" rel="noopener noreferrer">
                  <Icon name="security-lock" />
                  Privacy and security
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="footer">

          <div className="footerBackground" />

          <div className="copyRight">© Copyright. The Hongkong and Shanghai Banking Corporation Limited 2002-2015. All rights reserved</div>

        </div>

      </FooterTag>
    )
}
