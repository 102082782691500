import BaseService from 'common/services/BaseService';

class DocImageService extends BaseService {

    getFiles(mdocid) {
        const requestBody = {
            "mdocid": mdocid,
            ...this.getInUserDTO()
        }

        return this.post('/api/DocImageBO/getFiles', requestBody, true);
    }


}

export default new DocImageService();
