/* eslint-disable import/no-extraneous-dependencies */
import CDLIcon from "@cdl/icon";
import Grid from '@material-ui/core/Grid';
import ActionButtons from "common/components/ActionButtons/ActionButtons";
import Dropdown from 'common/components/Dropdown/Dropdown';
import IconButton from "common/components/IconButton/IconButton";
import Table from 'common/components/Table/Table';
import SuccessMessage from 'common/components/UserMessage/SuccessMessage';
import ImporterBankService from 'common/services/importerBank.service';
import UserMaintService from 'common/services/user-maint.service';
import { isAC } from "common/utils/AppUtil";
import AddImporterBank from 'common/views/Popup/AddImporterBank';
import Msg from "common/views/Popup/Msg";
import React from 'react';
import { getCountry } from 'common/utils/OptionUtil';

const buttonArray = [
  { id: 'openEdit', name: 'Edit', showModal: 'openEditCollectingBankPopup' },
  { id: 'openDelete', name: 'Delete', showModal: 'showDeleteRequestMessage' }
];

const tableArray = [
  {
    Header: 'Bank name',
    accessor: 'bankName',
    Cell: row => <span title={row.original.bankName}>{row.original.bankName}</span>
  },
  {
    Header: 'Bank reference',
    accessor: 'bankRef',
    width: 280,
  },
  {
    Header: 'Bank trade department',
    accessor: 'bankTradeDept',
  }
];

const initialFilters = {
  incbankcompanydto: {
    coDocExpressID: "",
    coName: ""
  },
  incbankprocctrdto: {
    processCtrID: "",
    processCtrName: ""
  }
}

const caFlag = isAC();

export default class CollectingBank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defProcCtrlOptions: [],
      defCompanyOptions: [],
      tableData: [],
      showFilter: true,
      filters: initialFilters,
      countryOptions: getCountry(),
    };
  }

  componentDidMount() {
    this.fetchProcCtrlOptions();
  }

  fetchProcCtrlOptions = () => {
    UserMaintService.loadProCtr().then(response => {
      let opt = [];
      if (response && response.list) {
        opt = response.list.map((element) => {
          return {label: element.proctridname, value: element.proctrid}
        });
      }
      this.setState(prevState => {
        const {filters} = prevState;
        filters.incbankprocctrdto = { processCtrID: opt[0]?.value, processCtrName: opt[0]?.label };
        return {
          defProcCtrlOptions: opt,
          filters,
        }
      },
      this.fetchCompanyOptions);
    }).catch(error => {
      throw error;
    });

  }

  fetchCompanyOptions = () => {
    ImporterBankService.loadCollectingBank(this.state.filters).then(response => {
      let opt = [];
      if (response && response.defCompany) {
        opt = response.defCompany.map((element) => {
          return {label: `${element.coDocExpressID  } - ${  element.coName}`, value: element.coDocExpressID}
        });
      }
      this.setState(prevState => {
        const {filters} = prevState;
        filters.incbankcompanydto = { coDocExpressID: opt[0]?.value, coName: opt?.[0]?.label.split(' - ')[1] };
        return {
          defCompanyOptions: opt,
          filters
        }
      },
      this.fetchTableData);
    }).catch(error => {
      throw error;
    });
  }


  fetchTableData = () => {
    ImporterBankService.loadCollectingBank(this.state.filters).then(response => {
      if (response && response.defCollectingBank) {
        this.setState({
          tableData: response.defCollectingBank,
          showAddNew: !response.showNewBtn,
        })
      }
    }).catch(error => {
      throw error;
    });
  }

  fetchEditData = () =>  {
    const {tableData, selectedIndex} = this.state;
    const selectedDTO = caFlag ? tableData.find((item, index) => index === selectedIndex) : tableData.find((item, index) => index === selectedIndex)?.bankCBIDCode;
    ImporterBankService.getCollectingBank(selectedDTO).then(response => {
      if (response) {
        this.setState({
          editProps: response,
          openCollectingBankPopup: true,
        })
      }
    }).catch(error => {
      throw error;
    });
  }


  showSuccessMessage = (msg) => {
    this.setState({
      message: msg,
      success: true,
      isEdit: false,
      openDeletePopup: false,
    },
    this.fetchTableData)
  }

  handleAutocomplete = name => value => {
    if (name === 'incbankcompanydto') {
      this.setState(prevState => {
        const {filters} = prevState;
        filters.incbankcompanydto = {coDocExpressID: value.value, coName: value.label.split(' - ')[1]};
        return {
          filters
        }
      },
      this.fetchTableData);
    } else if (name === 'incbankprocctrdto') {
      this.setState(prevState => {
        const {filters} = prevState;
        filters.incbankprocctrdto = {processCtrID: value.value, processCtrName: value.label};
        return {
          filters
        }
      },
      this.fetchCompanyOptions)
    }
  }

  openEditCollectingBankPopup = (rowIndex) => {
    this.setState({
      selectedIndex: rowIndex,
      isEdit: true,
    },
    this.fetchEditData);
  }

  showDeleteRequestMessage = (rowIndex) => {
    this.setState({
      selectedIndex: rowIndex,
      openDeletePopup: true,
    });
  }

  closeDeletePopup = () => {
    this.setState({
      selectedIndex: null,
      openDeletePopup: false,
    });
  }

  closeCollectingBankPopup = () => {
    this.setState({
      isEdit: false,
      openCollectingBankPopup: false,
    });
  }

  closeMessage = () => {
    this.setState({
      message: '',
      success: false,
    });
  }

  deleteCollectingBank = () => {
    const {tableData, selectedIndex} = this.state;
    const selectedItem = tableData.find((item, index) => index === selectedIndex);

    ImporterBankService.deleteCollectingBank(selectedItem).then(response => {
      if (response) {
        this.showSuccessMessage(`Collecting bank "${tableData[selectedIndex]?.bankName}" - "${tableData[selectedIndex]?.bankCity}" has been deleted successfully.`);
      }
    })
  }

  toggleFilter = () => {
    this.setState(prevState=>{
      return {
        showFilter: !prevState.showFilter
      }
    })
  }

  renderFilter = () => {
    const { defProcCtrlOptions, defCompanyOptions, filters, showFilter } = this.state;
    const companyVal = filters.incbankcompanydto?.coName ? `${filters.incbankcompanydto?.coDocExpressID} - ${filters.incbankcompanydto?.coName}` : '';

    if(caFlag){
      return (
        <>
          <IconButton
            transparent
            noBorder
            name='filter'
            size={14}
            onClick={this.toggleFilter}
          >
            Filter
            {showFilter &&
            <CDLIcon name="chevron-up-small" /> || <CDLIcon name="chevron-down-small" />}
          </IconButton>
          {showFilter &&
          <Grid container spacing={0} style={{ paddingTop: '20px', paddingBottom: '30px', paddingLeft: '20px', paddingRight: '20px', backgroundColor: '#ededed' }}>
            <Grid container style={{ backgroundColor: '#eee' }}>
              <Grid item xs={6}>
                <Dropdown
                  seamless
                  id="processing-centre"
                  options={defProcCtrlOptions}
                  onChange={this.handleAutocomplete("incbankprocctrdto")}
                  placeHolder={filters.incbankprocctrdto?.processCtrName}
                  value={filters.incbankprocctrdto?.processCtrName}
                  labelInside="Processing centre"
                />
              </Grid>
              <Grid item xs={6}>
                <Dropdown
                  seamless
                  id="company-name"
                  options={defCompanyOptions}
                  onChange={this.handleAutocomplete("incbankcompanydto")}
                  placeHolder={companyVal}
                  value={companyVal}
                  labelInside="Company"
                />
              </Grid>
            </Grid>
          </Grid>}
        </>)
    }

    return <></>;

  }

  newCollectingBank = () => {
    this.setState({
      openCollectingBankPopup:true,
      isEdit: false,
      selectedIndex: null,
      editProps: null,
    })
  }

  render() {
    const {tableData, selectedIndex, openDeletePopup, success, message, filters, showAddNew, openCollectingBankPopup, editProps, isEdit, countryOptions} = this.state;

    const displayData = tableData.length > 0 ? tableData.filter(d => !d.deleted).map((t, i) => {
        return Object.assign(t, {actionBtn: <ActionButtons
          rowIndex={i}
          buttonArray={buttonArray}
          openEditCollectingBankPopup={this.openEditCollectingBankPopup}
          showDeleteRequestMessage={this.showDeleteRequestMessage}
          justify='flex-start'
        />});
    }) : [];

    return (
      <div>
        <Msg
          title="Are you sure?"
          content={`Do you wish to delete the bank "${tableData[selectedIndex]?.bankName}" - "${tableData[selectedIndex]?.bankCity}"?`}
          width={500}
          confirm={this.deleteCollectingBank}
          isOpen={openDeletePopup}
          close={this.closeDeletePopup}
          warning
        />
        <Grid container spacing={0} style={{ paddingTop: '20px' }}>
          {success &&
            <Grid item xs={12}>
              <SuccessMessage message={message} handleCloseButtonClick={this.closeMessage} />
            </Grid>}

          {this.renderFilter()}

          {(caFlag ||showAddNew) &&
            <Grid container spacing={0}>
              <Grid item xs={9} style={{ paddingTop: caFlag ? '10px' : '0px' }} />
              <Grid item xs={3}>
                <div className="rightBtn" style={{ paddingRight: '0px', paddingTop: caFlag ? '20px' : '0px' }}>
                  <IconButton disabled={caFlag && !filters?.incbankcompanydto?.coDocExpressID} onClick={this.newCollectingBank} name="add">
                    Add new collecting bank
                  </IconButton>
                </div>
              </Grid>
            </Grid>}
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Table
              maxRows={12}
              showPagination={false}
              defaultPageSizeOption={{'value':0}}
              data={displayData}
              columns={caFlag ? tableArray.concat(
                [{
                  Header: 'Wolf Status',
                  accessor: 'wolfStatus',
                  width: 128
                },
                {
                  Header: 'Action',
                  accessor: 'actionBtn',
                  maxWidth: 140,
                  sortable: false
                }]) : tableArray.concat(
                [{
                  Header: 'Action',
                  accessor: 'actionBtn',
                  maxWidth: 140,
                  sortable: false
                }])}
            />
          </Grid>
        </Grid>
        {openCollectingBankPopup &&
        <AddImporterBank
          isOpen={openCollectingBankPopup}
          edit={isEdit}
          editProps={editProps}
          close={this.closeCollectingBankPopup}
          success={this.showSuccessMessage}
          title="Collecting bank details"
          hasEditRight={showAddNew}
          coDocExpressID={filters.incbankcompanydto?.coDocExpressID}
          options={countryOptions}
        />}
      </div>
    );
  }
}
