import { ModalAction, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Autocomplete from "common/components/Autocomplete/Autocomplete";
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import TextArea from "common/components/TextArea/TextArea";
import React from 'react';


export default class ViewDiscrepancy extends React.PureComponent {

    render(){
      console.log(this.props);
      let displayRemark = '';
      let displayComment = '';
      if(this.props.data.isgeneralcomment){
        displayComment = this.props.data.comments;
      }else{
        displayRemark = this.props.data.comments;
        displayComment = this.props.data.fixcomments || '';
      }
      return (
        <div>
          <Modal
            ariaLabelledby="modal-heading"
            isOpen={this.props.isOpen}
            width={560}
          >
            <ModalHeader hasTopLine>
              <ModalTitle>View comment / discrepancy</ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={this.props.close} />
              </ModalBtnList>

            </ModalHeader>
            <ModalContent>
              <Grid container spacing={0}>

                <Grid item xs={6}>
                  <Autocomplete
                    label="Discrepancy*"
                    id="discrepancy"
                    name="discrepancy"
                    userInput={this.props.data?.discrepancy}
                    disabled
                    options={[]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextArea
                    name="comments"
                    maxLength={1500}
                    label="Discrepancy remarks"
                    value={displayRemark}
                    disabled
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextArea
                    name="comments"
                    maxLength={1500}
                    label="Comment*"
                    value={displayComment}
                    disabled
                  />
                </Grid>

              </Grid>
            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>
                <ModalActionExtraButton>
                  <Button onClick={this.props.close}>Cancel</Button>
                </ModalActionExtraButton>
              </ModalAction>
            </ModalFooter>
          </Modal>
        </div>
      )
    }
}
