import React from 'react';
import Parser from 'html-react-parser';
import styled from "styled-components";
import CDLIcon from '@cdl/icon';

const Title = styled.div`
    padding-bottom: 5px;
    font-size: 14px;
    font-family: "HSBC-Universe-Medium";
`;

const EmailBox = styled.div`
    padding-bottom: 10px;
`;

const PhoneBox = styled.div`
    float: left;
    padding-top: 3px;

    & .lastItem{
        padding-bottom: 0;
    }
`;

export default function ContactBox(props) {
    const {emails} = props;
    const {phones} = props;
    let phoneStr = "";
    if(phones){
        phones.forEach((phone, index) => {
            let lastItem = '';
            if(index === phones.length-1){
                lastItem = 'lastItem';
            }
            phoneStr += `<div class="contactInfo ${lastItem}">${ phone }</div>`;
        });
    }

    return (
      <div className="contactBox">
        <Title>{props.title}</Title>
        {emails &&
        <EmailBox>
          <CDLIcon name="message" size={14} /><span className="contactInfo">{emails}</span>
        </EmailBox>}

        {phones &&
        <div>
          <div className="inline"><CDLIcon name="phone" size={14} /></div>
          <PhoneBox>{Parser(phoneStr)}</PhoneBox>
        </div>}
      </div>
    )
}
