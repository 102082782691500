import BaseService from 'common/services/BaseService';
import { isBlank } from 'common/utils/StringUtils';
import { isDC } from 'common/utils/MasterDocUtil';
import { masterDocDefaultValue } from 'common/utils/DocPrepConstant';
import masterDocService from './masterDoc.service';


class ExportInstructionService extends BaseService {
  constructor() {
    super();
    this.basePath = '/api/ExportInstructionBO/';
    this.userDTO = null;
  }

  post(url, paras, showLoading) {
    return super.post(this.basePath + url, paras, showLoading);
  }

  postWithStatus(url, paras, showLoading) {
    return super.postReturnWithStatus(this.basePath + url, paras, showLoading);
  }

  getExportInstruction(mdocid) {
    const requestBody = {
      "mdocid": mdocid,
      ...this.getUserDTO()
    }
    return this.postWithStatus('getExportInstruction', requestBody, true);
  }

  addExportInstruction(exportInstructionDTO, masterDocDTO, formname) {
    exportInstructionDTO.expinstrid = "";
    exportInstructionDTO.mdocid = masterDocDTO.mdocid;
    this.massageExportInstructionForSubmission(exportInstructionDTO);

    exportInstructionDTO.donotprotest = exportInstructionDTO.donotprotest || false;
    exportInstructionDTO.eiprinted = exportInstructionDTO.eiprinted || false;
    exportInstructionDTO.eiprint = exportInstructionDTO.eiprint || false;
    exportInstructionDTO.eiprinton = exportInstructionDTO.eiprinton || new Date();

    if (exportInstructionDTO && exportInstructionDTO.eitype) {
      if (isDC(exportInstructionDTO.eitype)) {
        masterDocDTO.cotype = 'DC';
      } else {
        masterDocDTO.cotype = 'DADP';
      }
    }

    const userDTO = this.getUserDTO();
    if(formname){
      userDTO.userdto.user.formname = formname;
    }
    const requestBody = {
      "exportinstructiondto": exportInstructionDTO,
      "masterdocdto": masterDocDTO,
      ...userDTO
    }
    return this.postWithStatus('updateForm', requestBody, true);
  }

  saveNewExportInstruction(exportInstructionDTO, masterDocDTO, metadata) {
    const userDTO = this.getUserDTO();
    masterDocService.massageMasterDocBeforeSubmission(masterDocDTO, userDTO);
    this.massageExportInstructionForSubmission(exportInstructionDTO);
    masterDocDTO.thirdpartydoc = isBlank(masterDocDTO.thirdpartydoc) ? [] : masterDocDTO.thirdpartydoc;
    masterDocDTO.billofexchangedto = isBlank(masterDocDTO.billofexchangedto) ? {} : masterDocDTO.billofexchangedto;
    masterDocDTO.invoicedetaildto = isBlank(masterDocDTO.invoicedetaildto) ? [] : masterDocDTO.invoicedetaildto;
    masterDocDTO.mdoctype = metadata.mdoctype.toUpperCase();
    masterDocDTO.mdocdesc = metadata.mdocdesc ? metadata.mdocdesc : '';
    masterDocDTO.tmplmdocid = masterDocDTO.mdocid;
    masterDocDTO.formid = "EI";

    if (exportInstructionDTO && exportInstructionDTO.eitype) {
      if (isDC(exportInstructionDTO.eitype)) {
        masterDocDTO.cotype = 'DC';
      } else {
        masterDocDTO.cotype = 'DADP';
      }
    }

    exportInstructionDTO.donotprotest = exportInstructionDTO.donotprotest || false;
    exportInstructionDTO.eiprinted = exportInstructionDTO.eiprinted || false;
    exportInstructionDTO.eiprint = exportInstructionDTO.eiprint || false;
    exportInstructionDTO.eiprinton = exportInstructionDTO.eiprinton || new Date();

    const requestBody = {
        "exportinstructiondto": exportInstructionDTO,
        "masterdocdto": masterDocDTO,
        ...userDTO
      }

    const {user} = userDTO.userdto;
    return new Promise((resolve, reject) => {
      masterDocService.getMasterDocMDocID(user.codocexpressid, user.groupmemberid, '', '', user.codocexpressid, metadata.mdoctype.toUpperCase()).then(newMasterDocID => {
        masterDocDTO.mdocid = newMasterDocID;
        exportInstructionDTO.mdocid = masterDocDTO.mdocid;


        resolve(this.post('saveNewExportInstruction', requestBody, true));
      }, (err) => {
        reject(err);
      })
    })
  }

  updateForm(exportInstructionDTO, masterDocDTO, formID) {
    if(!exportInstructionDTO.expinstrid){
      const eidto = { ...masterDocDefaultValue.eidto};
      eidto.exprefinvno = exportInstructionDTO.exprefinvno;
      Object.keys(exportInstructionDTO).forEach(element => {
        eidto[element] = exportInstructionDTO[element];
      });
      masterDocDTO.formid = formID || 'EI';
      masterDocDTO.eidto = eidto;
      return this.addExportInstruction(eidto, masterDocDTO);
    }
    const submittedMasterDTO = { ...masterDocDTO};
    submittedMasterDTO.formid = formID || 'EI';

    this.massageExportInstructionForSubmission(exportInstructionDTO);

    exportInstructionDTO.mdocid = masterDocDTO.mdocid;
    exportInstructionDTO.donotprotest = exportInstructionDTO.donotprotest || false;
    exportInstructionDTO.eiprinted = exportInstructionDTO.eiprinted || false;
    exportInstructionDTO.eiprint = exportInstructionDTO.eiprint || false;
    exportInstructionDTO.eiprinton = exportInstructionDTO.eiprinton || new Date();

    if (exportInstructionDTO && exportInstructionDTO.eitype) {
      if (exportInstructionDTO.eitype === 'ND' || exportInstructionDTO.eitype === 'NA' || exportInstructionDTO.eitype === 'AD'
      || exportInstructionDTO.eitype === 'PA' || exportInstructionDTO.eitype === 'NU') {
        submittedMasterDTO.cotype = 'DC';
      } else {
        submittedMasterDTO.cotype = 'DADP';
      }
    }

    const requestBody = {
        "exportinstructiondto": exportInstructionDTO,
        "masterdocdto": submittedMasterDTO,
        ...this.getUserDTO()
      }
      return this.postWithStatus('updateForm', requestBody, true);
  }

  massageInvoiceDTOBeforeSubmission(invoiceDTOList) {
    console.log(this);
    invoiceDTOList.forEach(invoiceDTO => {
        Object.keys(invoiceDTO).forEach(key => {
            if (!invoiceDTO[key]) {
                invoiceDTO[key] = '';
            }
        })
        console.log(invoiceDTO);
        invoiceDTO.invitemdetaildtolist.forEach(item => {
            Object.keys(item).forEach(key => {
                if (!item[key]) {
                    item[key] = '';
                }
            })
        })
    })
  }

  massageExportInstructionForSubmission(exportInstructionDTO) {
    console.log(this);
    exportInstructionDTO.fromproceeddeduct = exportInstructionDTO.fromproceeddeduct ? parseFloat(exportInstructionDTO.fromproceeddeduct) : 0;
    exportInstructionDTO.interestrate = exportInstructionDTO.interestrate ? parseFloat(exportInstructionDTO.interestrate) : 0;
    exportInstructionDTO.loans_amount = exportInstructionDTO.loans_amount ? parseFloat(exportInstructionDTO.loans_amount) : 0;
    exportInstructionDTO.proceeds_amount = exportInstructionDTO.proceeds_amount ? parseFloat(exportInstructionDTO.proceeds_amount) : 0;
    exportInstructionDTO.deductamt = exportInstructionDTO.deductamt ? parseFloat(exportInstructionDTO.deductamt) : 0;
    exportInstructionDTO.applyproceedstoexploan = exportInstructionDTO.applyproceedstoexploan ? exportInstructionDTO.applyproceedstoexploan : false;
    exportInstructionDTO.fromproceed = exportInstructionDTO.fromproceed ? exportInstructionDTO.fromproceed : false;

  }


}

export default new ExportInstructionService();
