import CDLIcon from "@cdl/icon";
import { ModalAction, ModalActionButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import React from 'react';

export default class Notice extends React.PureComponent {

    render() {
        const actionButton = this.props.actionButton || "OK";
        const title = this.props.title?.length > 0 ? this.props.title : "Something goes wrong!";

        return (
          <Modal
            ariaLabelledby="modal-heading"
            width={this.props.width || 400}
            height={this.props.height || 200}
            isOpen={this.props.isOpen}
          >
            <ModalHeader>
              <ModalTitle>
                <span style={{paddingRight:'5px'}}><CDLIcon name="circle-error-solid" color="WARNING" size={29} /></span>
                {title}
              </ModalTitle>
              <ModalBtnList>
                <ModalBtn name="delete" title="close" onClick={this.props.close} />
              </ModalBtnList>

            </ModalHeader>
            <ModalContent>
              {this.props.content}
            </ModalContent>
            <ModalFooter hasBottomLine>
              <ModalAction>
                <ModalActionButton>
                  <Button themeColor="primary" onClick={this.props.close}>{actionButton}</Button>
                </ModalActionButton>
              </ModalAction>
            </ModalFooter>
          </Modal>
        )
    }
}
