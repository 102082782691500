import { formateAmount } from 'common/utils/AmountFormatter';
import { Radio } from '@cdl/radio-group';
import Grid from '@material-ui/core/Grid';
import Divider from 'common/components/Divider/Divider';
import Heading from "common/components/Heading/Heading";
import renderInput from 'common/components/ReduxForm/RenderInput';
import renderCheckbox from 'common/components/ReduxForm/RenderCheckbox';
import renderNumberField from 'common/components/ReduxForm/RenderNumberField';
import renderRadioGroup from 'common/components/ReduxForm/RenderRadioGroup';
import renderTextArea from 'common/components/ReduxForm/RenderTextArea';
import { updateMasterDocByFields } from 'common/redux/actions';
import { getFieldName } from 'common/utils/FieldNameUtil';
import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import styled from "styled-components";

const RadioButtonWrapper = styled.div`
    display: flex;
    &>div {
      margin-right: 20px;
    }
`;

const CheckboxContainer = styled.div`
    & .cdl-checkbox {
        margin-top: 10px;

    }
`;

const TextFieldFromWrapper = styled.div`
    &>div {
        display: inline;
    }

`;

const TitleStyle = styled.div`
    font-family: 'HSBC-Universe-Medium'; 
    margin: 20px 0; 
    ${props => props.noMarginTop ? 'margin-top: 0' : ''}
`;

const Slash = () => <Grid item xs={1} style={{paddingTop: '3px'}}>&nbsp;&nbsp;&nbsp;/</Grid>

const SubTitle = ({title, noMarginTop}) => <Grid item xs={12}><TitleStyle noMarginTop={noMarginTop}>{title}</TitleStyle></Grid>

class OtherInstructions extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};

      this.collectoverseacharges = getFieldName(props.isEDS, 'eidto.CollectOverseaCharges');
      this.deductproceedcharges = getFieldName(props.isEDS, "eidto.DeductProceedCharges");
      this.waive = getFieldName(props.isEDS, 'eidto.Waive');
      this.releasedocPayment = getFieldName(props.isEDS, 'eidto.ReleaseDoc_Payment');
      this.protest = getFieldName(props.isEDS, 'eidto.Protest');
      this.donotprotest = getFieldName(props.isEDS, 'eidto.DoNotProtest');
      this.notWaive = getFieldName(props.isEDS, 'eidto.DoNotWaive');
      this.releaseDocAccept = getFieldName(props.isEDS, 'eidto.ReleaseDoc_Accept');
      this.incaseofneedreferto = getFieldName(props.isEDS, "eidto.InCaseOfNeedReferTo");
      this.incaseofneed = getFieldName(props.isEDS, "eidto.InCaseOfNeed");
      this.fromproceeddeduct = getFieldName(props.isEDS, "eidto.FromProceedDeduct");
      this.intereststartdate = getFieldName(props.isEDS, "eidto.InterestStartDate");
      this.interestenddate = getFieldName(props.isEDS, "eidto.InterestEndDate");
      this.deductAmt = getFieldName(props.isEDS, "eidto.DeductAmt");
      this.acceptawaitarrival = getFieldName(props.isEDS, "eidto.AcceptAwaitArrival");
      this.fromproceed = getFieldName(props.isEDS, "eidto.FromProceed");
      this.collectinterest = getFieldName(props.isEDS, "eidto.CollectInterest");
      this.deductPCANo = getFieldName(props.isEDS, "eidto.DeductPCANo");
      this.HSBCAcctNo = getFieldName(props.isEDS, "eidto.HSBCAcctNo");
      this.exchgContractNo = getFieldName(props.isEDS, "eidto.ExchgContractNo");
      this.cable = getFieldName(props.isEDS, 'eidto.ByCable');
      this.airmail = getFieldName(props.isEDS, 'eidto.ByAirmail');
    }

    componentDidMount() {

      this.setState({
        isDisabledCheckbox: true,
      });
      this.initCheckboxs();

    }

    componentDidUpdate(prevProps){
      if (this.props.eidto){
        if(this.props.eidto.deductpcano){
          this.props.eidto.deductpcano = this.props.eidto.deductpcano.trim();
        }
        if(this.props.eidto.hsbcacctno){
          this.props.eidto.hsbcacctno = this.props.eidto.hsbcacctno.trim();
        }
      }
      if (this.props.isEDS) {
        if(this.props.EIType !== prevProps.EIType){
          this.initCheckboxs();
        }
      } else if(this.props.eitype !== prevProps.eitype){
          this.initCheckboxs();
        }

    }


    initCheckboxs = () => {

      const values = ['FI', 'PP'];
      if (values.find(v => v === (this.props.isEDS ? this.props.EIType : this.props.eitype))) {
        this.setState({isDisabledCheckbox: false});
      } else {
        this.setState({isDisabledCheckbox: true});
      }
    }


    toggleField = (event, field) =>{

      let val = event.target.value;
      if(typeof val !== 'boolean'){
        val = (val === "true");
      }

      this.props.updateMasterDocByFields({field, data: val});
    }

    unCheckOther = (event, field) => {

      let val = event.target.value;
      if(typeof val !== 'boolean'){
        val = (val === "true");
      }

      if(!val){
        this.props.updateMasterDocByFields({field, data: false});
      }
    }

    onRadioSelection = (name, event) => {
      this.props.updateMasterDocByFields({field: name, data: event.target.value});
    }

    resetField = (event, field1, field2) => {
      if(event.target.value === "true"){
        if(field1!==this.deductAmt){
          this.props.updateMasterDocByFields({field: field1, data: ''});
        }
        this.props.updateMasterDocByFields({field: field2, data: ''});
      }

      if(field1===this.deductAmt){
        const decimal = this.props.isEDS ? this.props.masterDoc?.AmtDecCat : this.props.masterDoc?.amtdeccat || 2;
        this.props.updateMasterDocByFields({field: field1, data: formateAmount(0, decimal)});
      }
    }

    renderLeft(){
      return (
        <Grid item xs={6}>
          <SubTitle title="7.1 Document release" />

          <CheckboxContainer>
            <Field
              component={renderCheckbox}
              label="Release documents against PAYMENT - D/P"
              id="releasedoc_payment"
              name={this.releasedocPayment}
              disabled={this.state.isDisabledCheckbox}
              onChange={(event)=>{this.toggleField(event, this.releaseDocAccept)}}
            />
            <Field
              component={renderCheckbox}
              label="Release documents against ACCEPTANCE - D/A"
              id="releasedoc_accept"
              name={this.releaseDocAccept}
              disabled={this.state.isDisabledCheckbox}
              onChange={(event)=>{this.toggleField(event, this.releasedocPayment)}}
            />
            <Field
              component={renderCheckbox}
              label="ACCEPTANCE/PAYMENT may await Arrival of Carrying Vessel"
              id="acceptawaitarrival"
              name={this.acceptawaitarrival}
              disabled={this.state.isDisabledCheckbox}
            />
          </CheckboxContainer>

          <SubTitle title="7.3 Waive Interest and Charges" />

          <span>If charges and/or interest refused</span>
          <Grid container spacing={0} style={{paddingTop:'20px'}}>
            <Grid item xs={2}>
              <Field
                component={renderCheckbox}
                label="Waive"
                id="waive"
                name={this.waive}
                disabled={this.state.isDisabledCheckbox}
                onChange={(event) => { this.toggleField(event, this.notWaive) }}
              />
            </Grid>
            <Slash />
            <Grid item xs={3}>
              <Field
                component={renderCheckbox}
                label="Do not Waive"
                id="notwaive"
                name={this.notWaive}
                disabled={this.state.isDisabledCheckbox}
                onChange={(event) => { this.toggleField(event, this.waive) }}
              />
            </Grid>
          </Grid>

          <SubTitle title="7.5 Charges" />

          <CheckboxContainer>
            <Field
              component={renderCheckbox}
              label={
                <>From Proceeds deduct&nbsp;&nbsp;
                  <TextFieldFromWrapper>
                    <Field
                      component={renderNumberField}
                      id="fromproceeddeduct"
                      name={this.fromproceeddeduct}
                      inline
                      disabled={this.state.isDisabledCheckbox}
                      decimalScale={(this.props.isEDS ? this.props.masterDoc?.AmtDecCat : this.props.masterDoc?.amtdeccat) || 2}
                      maxLength={26}
                      thousandSeparator
                      noLabel
                      inputWidth={150}
                    />
                  </TextFieldFromWrapper>
                  to be paid to above Agent
                </>
                    }
              id="fromproceed"
              name={this.fromproceed}
              disabled={this.state.isDisabledCheckbox}
            />
            <Field
              component={renderCheckbox}
              label="COLLECT Oversea Charges from the DRAWEES"
              id="collectoverseacharges"
              name={this.collectoverseacharges}
              disabled={this.state.isDisabledCheckbox}
              onChange={(event)=>{this.toggleField(event, this.deductproceedcharges)}}
            />
            <Field
              component={renderCheckbox}
              label="DEDUCT Charges from the PROCEEDS"
              id="deductproceedcharges"
              name={this.deductproceedcharges}
              disabled={this.state.isDisabledCheckbox}
              onChange={(event)=>{this.toggleField(event, this.collectoverseacharges)}}
            />
          </CheckboxContainer>
        </Grid>
      )
    }

    renderRight(){
      return (
        <Grid item xs={6}>
          <SubTitle title="7.2 Protest" />

          <span>For Non-acceptance and/or Non-payment</span>
          <Grid container spacing={0} style={{paddingTop:'20px'}}>
            <Grid item xs={2}>
              <Field
                component={renderCheckbox}
                label="Protest"
                name={this.protest}
                disabled={this.state.isDisabledCheckbox}
                onChange={(event) => { this.toggleField(event, this.donotprotest) }}
              />
            </Grid>
            <Slash />
            <Grid item xs={3}>
              <Field
                component={renderCheckbox}
                label="Do not Protest"
                name={this.donotprotest}
                disabled={this.state.isDisabledCheckbox}
                onChange={(event) => { this.toggleField(event, this.protest) }}
              />
            </Grid>
            <Grid item xs={10} style={{paddingLeft:'32px', paddingTop:'10px'}}>
              Note: If no instructions are given regarding protest the Bank will assume that protest is NOT required.
            </Grid>
          </Grid>

          <SubTitle title="7.4 Interest" />

          <CheckboxContainer>
            <Field
              component={renderCheckbox}
              label={
                <>Collect Interest @&nbsp;&nbsp;
                  <TextFieldFromWrapper>
                    <Field
                      component={renderNumberField}
                      id="interestrate"
                      name={getFieldName(this.props.isEDS, "eidto.InterestRate")}
                      disabled={this.state.isDisabledCheckbox}
                      maxLength={25}
                      decimalScale={(this.props.isEDS ? this.props.masterDoc?.InterestRateDecCat : this.props.masterDoc?.interestratedeccat) || 4}
                      thousandSeparator
                      noLabel
                      inputWidth={150}
                      inline
                    />
                  </TextFieldFromWrapper>
                  % p.a. from Drawee
                </>
                  }
              id="collectinterest"
              name={this.collectinterest}
              disabled={this.state.isDisabledCheckbox}
            />
            <Grid container spacing={0} style={{paddingTop: '20px', paddingLeft: '30px'}}>
              <Grid item xs={4}>
                <Field
                  component={renderInput}
                  label="from date of"
                  id="intereststartdate"
                  name={this.intereststartdate}
                  disabled={this.state.isDisabledCheckbox}
                  maxLength={35}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  component={renderInput}
                  label="until date of"
                  id="interestenddate"
                  name={this.interestenddate}
                  disabled={this.state.isDisabledCheckbox}
                  maxLength={35}
                />
              </Grid>
            </Grid>
          </CheckboxContainer>

          <SubTitle title="7.6 Advise if Unpaid/Unacceptable" noMarginTop />

          <Grid container spacing={0}>
            <Grid item xs={3}>
              <span style={{float: 'left', paddingTop: '3px'}}>By&nbsp;&nbsp;</span>
              <Field
                component={renderCheckbox}
                label="Cable"
                name={this.cable}
                disabled={this.state.isDisabledCheckbox}
                onChange={(event)=>{this.unCheckOther(event, this.airmail)}}
              />
            </Grid>
            <Grid item xs={3}>
              <Field
                component={renderCheckbox}
                label="Airmail"
                name={this.airmail}
                disabled={this.state.isDisabledCheckbox}
                onChange={(event)=>{this.unCheckOther(event, this.cable)}}
              />
            </Grid>
          </Grid>
        </Grid>
      )
    }

    renderIntruction(){

      return (
        <>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Heading level={2} brandbar><span className="medium">Instructions for bills not under Documentary Credit</span></Heading>
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{paddingBottom:'20px', marginTop: '-20px'}}>

            {this.renderLeft()}

            {this.renderRight()}

          </Grid>
        </>
      )
    }

    render() {

      const {isEDS} = this.props;


      return (
        <div>
          {this.renderIntruction()}

          <Divider short />

          <Heading level={2} brandbar><span className="medium">Other instructions</span></Heading>

          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Field
                component={renderTextArea}
                id="incaseofneed"
                name={this.incaseofneed}
                disabled={this.state.isDisabledCheckbox}
                maxLength={200}
                label="In cases of need refer to"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={renderTextArea}
                id="otherinstr"
                name={getFieldName(isEDS, "eidto.OtherInstr")}
                label="Other instructions"
                maxLength={520}
              />
            </Grid>
          </Grid>
          <Divider short />

          <Heading level={2} brandbar><span className="medium">Proceeds disposal</span></Heading>


          <CheckboxContainer>
            <Field
              component={renderCheckbox}
              label={
                <>Deduct (Amount)
                  <TextFieldFromWrapper>
                    <Field
                      component={renderNumberField}
                      id="deductamt"
                      name={this.deductAmt}
                      className="no-fixed-width"
                      inline
                      disabled={this.props.isEDS ? !this.props.eidto?.Deduct : !this.props.eidto?.deduct}
                      decimalScale={(this.props.isEDS ? this.props.masterDoc?.AmtDecCat : this.props.masterDoc?.amtdeccat) || 2}
                      maxLength={26}
                      thousandSeparator
                    />
                  </TextFieldFromWrapper>
                  for Packing Credit Advance Number
                  <TextFieldFromWrapper>
                    <Field
                      component={renderInput}
                      id="deductpcano"
                      name={this.deductPCANo}
                      className="no-fixed-width"
                      inline
                      disabled={this.props.isEDS ? !this.props.eidto?.Deduct : !this.props.eidto?.deduct}
                      maxLength={15}
                    />
                  </TextFieldFromWrapper>
                </>
                }
              id="deduct"
              name={getFieldName(isEDS, "eidto.Deduct")}
              onChange={(event)=>{this.resetField(event, this.deductAmt, this.deductPCANo)}}
            />
            <Field
              component={renderCheckbox}
              label={
                <>Credit our Account Number
                  <TextFieldFromWrapper>
                    <Field
                      component={renderInput}
                      id="hsbcacctno"
                      name={this.HSBCAcctNo}
                      className="no-fixed-width"
                      inline
                      disabled={this.props.isEDS ? !this.props.eidto?.CreditAcctNo : !this.props.eidto?.creditacctno}
                      maxLength={15}
                    />
                  </TextFieldFromWrapper> under Exchange Contract Number (if applicable)
                  <TextFieldFromWrapper>
                    <Field
                      component={renderInput}
                      id="exchgcontractno"
                      name={this.exchgContractNo}
                      inline
                      disabled={this.props.isEDS ? !this.props.eidto?.CreditAcctNo : !this.props.eidto?.creditacctno}
                      maxLength={35}
                    />
                  </TextFieldFromWrapper>
                </>
                  }
              id="creditacctno"
              name={getFieldName(isEDS, "eidto.CreditAcctNo")}
              onChange={(event)=>{this.resetField(event, this.HSBCAcctNo, this.exchgContractNo)}}
            />
          </CheckboxContainer>

          <Grid container spacing={0} style={{paddingTop:'20px'}}>
            <Grid item xs={12} style={{marginBottom: '10px'}}>
              <div>Proceeds to Import for</div>
            </Grid>
            <Grid item xs={12}>
              <Field
                component={renderRadioGroup}
                id="proceedstoimport"
                name={getFieldName(isEDS, "eidto.ProceedsToImport")}
                legend="Proceeds to Import for"
                radio={
                  <RadioButtonWrapper>
                    <Radio id="BB" label="B/B" value="BB" />
                    <Radio id="EW" label="EWB" value="EW" />
                    <Radio id="TD" label="Transfer DC Documents" value="TD" />
                  </RadioButtonWrapper>
                }
              />
            </Grid>
          </Grid>

          <Grid container spacing={0} style={{paddingTop:'5px'}}>
            <Grid item xs={2}>
              <Field
                component={renderNumberField}
                id="proceeds_amount"
                name={getFieldName(isEDS, "eidto.Proceeds_Amount")}
                label="Amount"
                decimalScale={(this.props.isEDS ? this.props.masterDoc?.AmtDecCat : this.props.masterDoc?.amtdeccat) || 2}
                maxLength={35}
                thousandSeparator
              />
            </Grid>
            <Grid item xs={2}>
              <Field
                component={renderInput}
                id="proceeds_billno"
                name={getFieldName(isEDS, "eidto.Proceeds_BillNo")}
                label="Bill Number/Transfer DC Number"
                maxLength={35}
              />
            </Grid>
          </Grid>

        </div>
      );
    }
}

const mapStateToProps = state => ({
  eitype: state.form.masterDoc.values?.eidto?.eitype,
  EIType: state.form.masterDoc.values?.eidto?.EIType,
  eidto: state.form.masterDoc.values?.eidto,
  masterDoc: state.form.masterDoc.values,
})

const mapDispatchToProps = dispatch => ({
  updateMasterDocByFields: (payload) => dispatch(updateMasterDocByFields(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OtherInstructions)
