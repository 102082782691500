import CDLDataTable from '@cdl/data-table';
import CDLHeading from '@cdl/heading';
import { Column, Paragraph, Row } from '@cdl/layout';
import 'App.css';
import Button from "common/components/Button/Button";
import Footer from "common/components/Footer/Footer";
import Header from "common/components/Header/Header";
import InfoMessage from 'common/components/UserMessage/InfoMessage';
import React, { useState } from 'react';
import Notice from 'common/views/Popup/Notice';

function LogoutPage(props) {
  const keepAlivefailed = props.location?.state?.keepAlivefailed;
  const [open, setOpen] = useState(keepAlivefailed);

  const login = () => {
    props.history.push('/Login');
  }

  const tableData = [];
  let username = "";

  if(props.location){
    tableData.push({'loginDate':props.location?.state?.loginDate,
                    'loginTime':props.location?.state?.loginTime,
                    'logoutDate':props.location?.state?.logoutDateOnly,
                    'logoutTime':props.location?.state?.logoutTimeOnly});

    username = props.location.state?.username;

  }


  return (
    <div className="App">

      <Header />


      <div className="mainContent mainContent1">

        <div style={{paddingTop: '20px'}}>
          <InfoMessage type='cookie'>
            This system may only be accessed by authorised users,
            and that unauthorised access might be considered as a criminal act in certain jurisdictions.
          </InfoMessage>
        </div>
        <CDLHeading>You’ve been logged out successfully</CDLHeading>

        <Paragraph>Thank you for using HSBC DocumentExpress, {username}!</Paragraph>

        <Row>
          <Column size={8}>
            <CDLDataTable
              defaultPageSizeOption={{'value':1}}
              showPagination={false}
              defaultPageSize={1}
              style={{overflowY: 'hidden'}}
              data={tableData}
              columns={[
            {
              Header: 'Login date',
              accessor: 'loginDate',
              sortable: false
            },
            {
              Header: 'Login time',
              accessor: 'loginTime',
              sortable: false
            },
            {
              Header: 'Logout date',
              accessor: 'logoutDate',
              sortable: false
            },
            {
              Header: 'Logout time',
              accessor: 'logoutTime',
              sortable: false
            }
          ]}
            />
          </Column>
        </Row>

        <Row>
          <Column size={8}>
            <Button themeColor="primary" onClick={login} style={{float: 'right'}}>Re-login</Button>
          </Column>
        </Row>

      </div>


      <Footer />

      <Notice
        content="You either have not logged on, or your session has expired. Please logon again."
        isOpen={open}
        close={()=>{setOpen(false)}}
        warning
      />

    </div>
  );
}

export default LogoutPage;
