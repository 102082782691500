import CDLCheckbox from '@cdl/checkbox';
import { ModalAction, ModalActionButton, ModalActionExtraButton, ModalBtn, ModalBtnList, ModalTitle } from '@cdl/modal';
import Grid from '@material-ui/core/Grid';
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal/Modal";
import ModalContent from "common/components/Modal/ModalContent";
import ModalFooter from "common/components/Modal/ModalFooter";
import ModalHeader from "common/components/Modal/ModalHeader";
import masterDocService from 'common/services/masterDoc.service';
import ModuleService from 'common/services/module.service';
import printService from 'common/services/print.service';
import Confirm from 'common/views/Popup/Confirm';
import Notice from 'common/views/Popup/Notice';
import React from 'react';
import styled from "styled-components";
import { DOC_STATUS } from 'common/utils/Constants';

const CheckboxWrapper = styled.div`
  padding-bottom: 10px;
`;

const MD = "MD";
const INV = "INV";
const PL = "PL";
const BC = "BC";
const BOC = "BOC";
const EXP = "EXP";

export default class DeleteDocument extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
    }
  }

  componentDidMount() {
    printService.initPrintPanel(this.props.masterDoc.mdocid).then(res => {
      if (res && res.list) {
        this.setState({
          disableINV: res.list[0] === '0',
          disablePL: res.list[1] === '0',
          disableBC: res.list[2] === '0',
          disableBOC: res.list[3] === '0',
          disableEXP: res.list[4] === '0',
        })
      }
    });
  }

  replaceName = (str) => {
    return str.replace("INV","Invoice").replace("PL", "Packing List")
    .replace("BC", "Beneficiary Certificate / Miscellaneous Doc.")
    .replace("BOC","Bill of Exchange")
    .replace("EXP", "Export Trade Collection / Negotiation instructions");
  }

  performDelete = () => {
    const masterDoc = this.props.masterDoc || {};
    let formtype = this.state.selected;
    let deleteAll = false;
    if (formtype.includes(MD)) {
      formtype = [];
      deleteAll = true;
    }
    masterDocService.getCreatorDetails(formtype, masterDoc, masterDoc.mdoctype).then(res => {
      if (res) {
        masterDocService.deleteMasterDoc(masterDoc.mdocid, masterDoc.mdoctype, formtype).then(res2 => {
          if (res2.statusCode === 200) {
            const formnames = this.replaceName(formtype.join(', '));
            this.props.confirmDelete(deleteAll, formnames);
            this.props.close();
          }
        });
      } else {
        let msg = "You do not have the appropriate rights to delete the current record. Only the creator can delete the Master Document.";
        if(masterDoc.status===DOC_STATUS.SUBMITTED || masterDoc.status===DOC_STATUS.BANK_APPROVED || masterDoc.status===DOC_STATUS.BANK_REJECTED){
          msg = "Current record has already been sent to bank for processing, delete is not allowed.";
        }
        this.setState({
          openNotice: true,
          noticeMsg: msg,
        })
      }
    });
  }

  removeDisabled = (selected) => {
    const { disableINV, disablePL, disableBC, disableBOC, disableEXP } = this.state;
    if (disableINV) {
      this.removeArray(selected, INV);
    }
    if (disablePL) {
      this.removeArray(selected, PL);
    }
    if (disableBC) {
      this.removeArray(selected, BC);
    }
    if (disableBOC) {
      this.removeArray(selected, BOC);
    }
    if (disableEXP) {
      this.removeArray(selected, EXP);
    }
  }

  handleChange = (event) => {
    const { value, checked } = event.target;
    console.log(value, checked);
    this.setState(prevState => {
      let {selected} = prevState;
      if (checked) {
        if (value === MD) {
          selected = [MD, INV, PL, BC, BOC, EXP];
        } else {
          selected.push(value);
        }
      } else {
        this.removeArray(selected, value);

        if (value === MD) {
          selected = [];
        } else {
          this.removeArray(selected, MD);
        }
      }
      this.removeDisabled(selected);
      return { selected }
    })

  }

  confirmDeleteMsg = () => {
    const { selected } = this.state;
    let msg = '';
    if (selected.includes(MD)) {
      msg = 'Do you wish to delete the entire master document now?';
    } else {
      msg = 'Do you wish to delete the selected docs of the current master document now?';
    }
    this.setState({
      confirmMsg: msg,
      openConfirmPopup: true,
    })
  }

  confirmDelete = () => {
    const { selected } = this.state;
    if (selected.length > 0) {
      if (selected.includes(MD) || selected.includes('EXP')) {
        const masterDoc = this.props.masterDoc || {};
        this.checkAllowDelete(masterDoc);
      } else {
        this.confirmDeleteMsg();
      }
    }
  }

  removeArray = (selected, value) => {
    const foundIndex = selected.findIndex(s => s === value);
    if (foundIndex > -1) {
      selected.splice(foundIndex, 1);
    }
  }

  checkAllowDelete(masterDoc) {
    printService.getPrintchoice(ModuleService.hasLFEDSModule(), masterDoc.mdocid, masterDoc.mdoctype).then(res => {
      if (res && res.list) {
        if (!res.list[0].cEDS && res.list[0].cReprintEDS) {
          this.setState({
            openNotice: true,
            noticeMsg: "Current record has already been sent for EDS processing, delete is not allowed.",
          });
        } else {
          this.confirmDeleteMsg();
        }
      }
    });
  }

  render() {
    const { disableINV, disablePL, disableBC, disableBOC, disableEXP, openNotice, noticeMsg, openConfirmPopup, confirmMsg, selected } = this.state;

    return (
      <>
        {openNotice &&
          <Notice
            title='Delete'
            content={noticeMsg}
            isOpen={openNotice}
            close={() => this.setState({ openNotice: false })}
          />}
        {openConfirmPopup &&
          <Confirm
            isOpen={openConfirmPopup}
            close={() => this.setState({ openConfirmPopup: false })}
            content={confirmMsg}
            confirm={this.performDelete}
          />}


        <Modal
          ariaLabelledby="modal-heading"
          isOpen={this.props.isOpen && !openNotice && !openConfirmPopup}
          width={500}
        >
          <ModalHeader>
            <ModalTitle>Delete</ModalTitle>
            <ModalBtnList>
              <ModalBtn name="delete" title="close" onClick={this.props.close} />
            </ModalBtnList>
          </ModalHeader>
          <ModalContent>
            <Grid container spacing={0} id="deleteContainer" style={{ paddingBottom: '20px' }}>
              <Grid item xs={12}>
                <CheckboxWrapper>Select document(s) to delete:</CheckboxWrapper>
                <CheckboxWrapper>
                  <CDLCheckbox label="Master document" id="mastDocChk" value={MD} onChange={this.handleChange} checked={selected.includes(MD)} />
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <CDLCheckbox label="Invoice" id="invChk" value={INV} onChange={this.handleChange} checked={selected.includes(INV)} disabled={disableINV} />
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <CDLCheckbox label="Packing list" id="plChk" value={PL} onChange={this.handleChange} checked={selected.includes(PL)} disabled={disablePL} />
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <CDLCheckbox label="Beneficiary cert/miscellaneous doc" id="bcChk" value={BC} onChange={this.handleChange} checked={selected.includes(BC)} disabled={disableBC} />
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <CDLCheckbox label="Bill of exchange" id="bocChk" value={BOC} onChange={this.handleChange} checked={selected.includes(BOC)} disabled={disableBOC} />
                </CheckboxWrapper>
                <CheckboxWrapper>
                  <CDLCheckbox
                    label="Export trade collection/negotiation instructions"
                    id="expChk"
                    value={EXP}
                    onChange={this.handleChange}
                    checked={selected.includes(EXP)}
                    disabled={disableEXP}
                  />
                </CheckboxWrapper>
              </Grid>
            </Grid>
          </ModalContent>
          <ModalFooter hasBottomLine>
            <ModalAction>
              <ModalActionExtraButton>
                <Button onClick={this.props.close}>Cancel</Button>
              </ModalActionExtraButton>
              <ModalActionButton>
                <Button themeColor="primary" onClick={this.confirmDelete} disabled={selected.length === 0}>Delete</Button>
              </ModalActionButton>
            </ModalAction>
          </ModalFooter>
        </Modal>
      </>
    )
  }
}
