import CDLUserMessage from '@cdl/user-message';
import React from 'react';
import styled from "styled-components";

const Container = styled.div`
    & > div{
      padding: 14px 20px;
      font-size: 14px;
      
      button{
        font-size: 14px;
        top: 50%;
        transform: translateY(-50%);
      }     
    }

    & > div > span:nth-child(2){
      width: calc(100% - 75px);
      word-wrap: break-word;
    }
`;

export default class UserMessage extends React.PureComponent {

    render() {
        return <Container><CDLUserMessage {...this.props} /></Container>
    }
  }
