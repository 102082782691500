import CDLButton from '@cdl/button';
import AccessControl from "common/components/AccessControl/AccessControl";
import ModuleService from 'common/services/module.service';
import UserService from 'common/services/user.service';
import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.div`
    display: inline;
    position: absolute;
    right: 20px;
`;

export default class NewButton extends React.PureComponent {

  render() {
    const {onClick, ...rest} = this.props;
    const user = UserService.getCurrentUser() || {};
    const userPermissions = {
      isCompanyEDS: user.isCompanyEDS,
    }
    const submodule = ModuleService.getSubModules();
    return (
      <ButtonContainer>
        <AccessControl
          userPermissions={userPermissions}
          button={this.props.id || "Add new"}
          submodule={submodule}
        >
          <CDLButton onClick={onClick} plain {...rest}>
            Add new
          </CDLButton>
        </AccessControl>
      </ButtonContainer>
    )
  }
}

