import Grid from '@material-ui/core/Grid';
import ActionButtons from 'common/components/ActionButtons/ActionButtons';
import IconButton from "common/components/IconButton/IconButton";
import Table from 'common/components/Table/Table';
import UserMessage from 'common/components/UserMessage/UserMessage';
import {
  disableInvoiceAmount, setHideError, setInvoiceList, setMasterDoc,
  updateMasterDocByFieldArray, updateMasterDocByFields, updateSubmitErrors
} from 'common/redux/actions';
import { formatAmountWords, formateAmount } from 'common/utils/AmountFormatter';
import { DATE_FORMAT } from 'common/utils/DateUtil';
import Confirm from "common/views/Popup/Confirm";
import InvoiceDetail from "common/views/Popup/InvoiceDetail";
import InvoiceItems from "common/views/Popup/InvoiceItems";
import React from 'react';
import { connect } from 'react-redux';

const moment = require('moment');

const buttonArray = [{id: 'openDetail', name: 'Edit', action: 'invoiceDetails', showModal: 'showInvoiceDetails'},
                     {id: 'openDelete', name: 'Delete', action: 'deleteInvoice', modal: Confirm,
                      modalProps :{content: 'Do you wish to delete the invoice "@title"?' }},
                     {id: 'openItem', name: 'Inv. item(s)', action: 'invoiceItem', showModal: 'showInvoiceItem'}
                    ];

const overflowClass = 'over-flow-hidden';
class InvoiceSection extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        tableData: props.tableData || [],
        selectedIndex: null,
        openInvoice: false,
      };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
      if (nextProps.masterDoc && nextProps.masterDoc !== this.props.masterDoc) {
          nextProps.masterDoc.invoicedetaildto = nextProps.masterDoc.invoicedetaildto || [];
          nextProps.masterDoc.invoicedetaildto = nextProps.masterDoc.invoicedetaildto.map((t, i) => {
            return Object.assign(t, {actionBtn: <ActionButtons
              rowIndex={i}
              buttonArray={buttonArray}
              deleteInvoice={this.deleteInvoie}
              showInvoiceDetails={this.showInvoiceDetails}
              showInvoiceItem={this.showInvoiceItem}
              justify='space-between'
              titleField={t.invno}
            />});
          })
          this.setState({
            tableData: nextProps.masterDoc.invoicedetaildto || [],
          });
      }
    }


    openInvoice = () => {
      this.setState({
        openInvoice: true
      });
      document.documentElement.classList.add(overflowClass);
    }

    closeInvoice = () => {
      this.setState({
        openInvoice: false,
        selectedIndex: null,
      });
      document.documentElement.classList.remove(overflowClass);
    }

    showInvoiceItem = (rowIndex) => {
      this.setState({
        openInvoiceItem: true,
        selectedIndex: rowIndex
      });
      document.documentElement.classList.add(overflowClass);
    }

    closeInvoiceItem = () => {
      this.setState({
        openInvoiceItem: false,
        selectedIndex: null
      });
      document.documentElement.classList.remove(overflowClass);
    }

    saveInvoiceItem = (invoiceItemTable, invoiceObj, labelHeaders) => {
      this.setState(prevState => {
        prevState.tableData[prevState.selectedIndex].invitemdetaildtolist = invoiceItemTable;
        Object.keys(invoiceObj).forEach(key => {
          prevState.tableData[prevState.selectedIndex][key] = invoiceObj[key];
        });
        console.log(labelHeaders);

        Object.keys(labelHeaders).forEach(key => {
          prevState.tableData[prevState.selectedIndex][key] = labelHeaders[key];
        })

        prevState.tableData[prevState.selectedIndex].labelHeaders = labelHeaders;

        let tmpAllSubTotal = 0;
        let tmpAllInvsubchrg1 = 0;
        let tmpAllInvsubchrg2 = 0;
        let tmpAllInvsubchrg3 = 0;
        Object.keys(prevState.tableData).forEach(function(key) {
          tmpAllSubTotal += prevState.tableData[key].invitemdetaildtolist.reduce((allSubTotal, sTotal) => Number(allSubTotal) + Number(sTotal.InvDetSubTotal), 0);
          tmpAllInvsubchrg1 = tmpAllInvsubchrg1 + Number(prevState.tableData[key].invsubchrg1)||0;
          tmpAllInvsubchrg2 = tmpAllInvsubchrg2 + Number(prevState.tableData[key].invsubchrg2)||0;
          tmpAllInvsubchrg3 = tmpAllInvsubchrg3 + Number(prevState.tableData[key].invsubchrg3)||0;
        });
        const tmpAllTotal = tmpAllSubTotal + tmpAllInvsubchrg1 + tmpAllInvsubchrg2 + tmpAllInvsubchrg3;
        this.updateMasDocAmount(tmpAllTotal);
        this.props.updateMasterDocByFields(prevState.tableData);
        return ({
          tableData: prevState.tableData,
          openInvoiceItem: false,
          selectedIndex: null
        })
      });
    }

    saveInvoice = (invoice, isEdit, rowIndex) => {
      this.setState(prevState => {
        const nonDeletedItems = prevState.tableData.filter(item => !item.isDelete);
        if (isEdit) {
          const oldTableData = nonDeletedItems.find((item, i) => i === rowIndex);


            invoice.invdate = moment(invoice.invdate.from).format(DATE_FORMAT);
            Object.keys(invoice).forEach(key => {
              oldTableData[key] = invoice[key];
            });

            this.props.updateMasterDocByFields(prevState.tableData);

            return ({
              tableData: prevState.tableData,
              selectedIndex: null,
            });
        }


          invoice.invitemdetaildtolist = [];
          invoice.invdate = moment(invoice.invdate.from).format(DATE_FORMAT);
          invoice.actionBtn = <ActionButtons
            rowIndex={prevState.tableData.length}
            buttonArray={buttonArray}
            deleteInvoice={this.deleteInvoie}
            showInvoiceDetails={this.showInvoiceDetails}
            showInvoiceItem={this.showInvoiceItem}
            justify='space-between'
            titleField={invoice.invno}
          />
          const data = [...prevState.tableData, invoice];
          this.props.updateMasterDocByFields(data);
          this.props.updateInvoiceSubmitErrors('invoicedetaildto');
          this.props.setInvoiceList(data);
          this.props.disableInvoiceAmount(true);
          const total = data.reduce((acc, d) => {
            return acc + (Number(d.invtotal) || 0);
          }, 0)
          this.updateMasDocAmount(total);

          return ({
            tableData: [...prevState.tableData, invoice],
            selectedIndex: null,
          });
      });
    }

    deleteInvoie = (rowIndex) => {

      this.setState(prevState => {
        const {tableData} = prevState;
        tableData[rowIndex].isDelete = true;
        const nonDeletedItems = tableData.filter((item, index) => index !== rowIndex);

        let total = 0;
        nonDeletedItems.forEach((item, i) =>{
          if(item.invtotal){
            total += Number(item.invtotal);
          }
          item.actionBtn = <ActionButtons
            rowIndex={i}
            buttonArray={buttonArray}
            deleteInvoice={this.deleteInvoie}
            showInvoiceDetails={this.showInvoiceDetails}
            showInvoiceItem={this.showInvoiceItem}
            justify='space-between'
            titleField={item.invno}
          />
        })
        if (nonDeletedItems.length === 0) {
          this.props.disableInvoiceAmount(false);
        }
        this.updateMasDocAmount(total);
        this.props.updateMasterDocByFields(nonDeletedItems);
        return {
          tableData: nonDeletedItems
        }
      });
    }

    showInvoiceDetails = (rowIndex) => {
      this.setState({
        openInvoice: true,
        selectedIndex: rowIndex
      })
      document.documentElement.classList.add(overflowClass);
    }

    updateMasDocAmount = (amount) => {
      const decimal = this.props.masterDoc.amtdeccat;
      const formatedAmount = formateAmount(amount, decimal);
      const words = formatAmountWords(this.props.masterDoc.currencycode || 'USD', formatedAmount, decimal);
      const fields = [
        { field: 'invgrandtotal', data: formatedAmount },
        { field: 'invgrandtotalinwords', data: words }
      ];
      this.props.updateMasterDocByFieldArray({ fieldArray: fields });
    }

    render() {
      const displayData = this.state.tableData;

      return (
        <div>

          <Grid container spacing={0} style={{paddingTop:'20px'}}>
            <Grid item xs={12} style={{paddingRight: '20px'}}>
              {this.props.showErrorMessage &&
              <UserMessage
                showCloseButton
                border
                type="userError"
                iconName="triangle-error"
                iconColor="ERROR"
                handleCloseButtonClick={() => this.props.setHideError('invoicedetaildto')}
                closeText="Close"
              >
                {this.props.errorMessage}
              </UserMessage>}
            </Grid>
            <Grid item xs={10} />
            <Grid item xs={2}>
              <div className="rightBtn">
                <IconButton onClick={this.openInvoice} name="add">Add new invoice</IconButton>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={0} style={{paddingRight:'20px'}}>

            <Grid item xs={12}>
              <Table
                shortTable
                showPagination={false}
                defaultPageSizeOption={{'value':0}}
                data={displayData}
                columns={[
                        {
                          Header: 'Invoice number',
                          accessor: 'invno',
                          Cell: row => <span title={row.original.invno}>{row.original.invno}</span>
                        },
                        {
                          Header: 'Invoice date',
                          accessor: 'invdate'
                        },
                        {
                          Header: 'Packing list number',
                          accessor: 'plno',
                          maxWidth: 250,
                          Cell: row => <span title={row.original.plno}>{row.original.plno}</span>
                        },
                        {
                          Header: 'Packing mode',
                          accessor: 'plpackingmode',
                          Cell: row => <span title={row.original.plpackingmode}>{row.original.plpackingmode}</span>
                        },
                        {
                          Header: 'Action',
                          accessor: 'actionBtn',
                          maxWidth: 190,
                          sortable: false
                        }
                      ]}

              />

            </Grid>


          </Grid>

          {this.state.openInvoice &&
          <InvoiceDetail
            data={displayData[this.state.selectedIndex]}
            selectedIndex={this.state.selectedIndex}
            isOpen={this.state.openInvoice}
            close={this.closeInvoice}
            save={this.saveInvoice}
            invoiceList={displayData}
          />}

          {this.state.openInvoiceItem &&
          <InvoiceItems
            isOpen={this.state.openInvoiceItem}
            data={displayData[this.state.selectedIndex]}
            selectedIndex={this.state.selectedIndex}
            close={this.closeInvoiceItem}
            saveInvoiceItem={this.saveInvoiceItem}
          />}


        </div>
      )
    }
}

const mapStateToProps = state => ({
  tableData: state.masterDocAction?.initDoc?.invoicedetaildto,
  masterDoc: state.form?.masterDoc?.values
})

const mapDispatchToProps = dispatch => {
  return {
      setMasterDoc: (data) => dispatch(setMasterDoc(data)),
      updateMasterDocByFields: (data) => dispatch(updateMasterDocByFields({field: 'invoicedetaildto', data})),
      updateMasterDocByFieldArray: (payload) => dispatch(updateMasterDocByFieldArray(payload)),
      updateInvoiceSubmitErrors: (data) => dispatch(updateSubmitErrors(data)),
      setInvoiceList: (data) => dispatch(setInvoiceList(data)),
      setHideError: (data) => dispatch(setHideError(data)),
      disableInvoiceAmount: (data) => dispatch(disableInvoiceAmount(data))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceSection)
