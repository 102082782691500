import VesselService from 'common/services/vessel.service';
import React from 'react';
import GenericPopup from 'common/views/Popup/GenericPopup';
import { isAC } from "common/utils/AppUtil";
import { WOLF_SCAN_CARRIER_FIELDS } from 'common/utils/Constants';

const initalData  = Object.freeze({ vesseldesc: ""});

export default class AddCarrier extends React.Component {

    beforeSave = (data) => {
      return new Promise((resolve, reject) => {
        const caFlag = isAC();
        const vesselListFunction = this.props.isEDS ? VesselService.getVesselList({incompanydto: {coDocExpressID: this.props?.codocexpressid}}, true)
        : VesselService.getVesselList({incompanydto: {coDocExpressID: this.props?.codocexpressid}}, caFlag);
        return vesselListFunction.then(response => {
          if (response && response.list) {
            const duplicatedItem = caFlag ? response.list.filter(r => r.vesselDesc !== "" && r.vesselDesc === data?.vesselDesc)
            : response.list.filter(r => r.vesseldesc !== "" && r.vesseldesc === data?.vesseldesc);
            if(duplicatedItem.length > 0){
              resolve(-9)
            }else{
              resolve(this.save(data));
            }
          } else {
            reject(response);
          }
        })
      });
    }

    save = (data) => {
      if (this.props.edit) {
        return VesselService.updateVessel(data);
      }
      data.codocexpressid = this.props.codocexpressid;
      return VesselService.addVessel(data);
    }

    render(){
      const fieldName = isAC() ? 'vesselDesc' : 'vesseldesc';
      const fields = [
        {id: fieldName, label:'Carrier*', name: fieldName, maxLength: 255, isMandatory: true, trim: true},
      ]
      return (
        <GenericPopup
          initalData={initalData}
          fields={fields}
          {...this.props}
          save={this.beforeSave}
          displayField={[fieldName]}
          displayFieldTile="Carrier"
          wolfField={WOLF_SCAN_CARRIER_FIELDS}
        />
      )
    }
}
